import React from "react";

const Polygon = () => {
  return (
    <div>
      <svg
        class="min-w-[19px]"
        width="19"
        height="21"
        viewBox="0 0 19 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m2.349 7.315-.743-.413v8.394l.257.143 6.742 3.744.743.413v-8.394l-.258-.143zm15.247.437v-.85l-.742.413-6.742 3.748-.257.143v8.395l.742-.413 6.742-3.744.258-.143zm-7.75-5.676-.242-.135-.243.135L2.4 5.94l-.788.437.788.437 6.96 3.866.244.134.242-.134 6.961-3.866.788-.437-.788-.437zM1.104 6.138l8.5-4.723 8.5 4.723v9.41l-8.5 4.723-8.5-4.724z"
          fill="#FFAF1D"
          stroke="#FFAF1D"
        ></path>
      </svg>
    </div>
  );
};

export default Polygon;
