import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import cascading from "../assests/css/videointrostyle.module.css";
import { API_URL } from '../config/env.js'

import { IoMdClose } from "react-icons/io";
export default function Lockmodal(props) {
  console.log("consdfsdf", props);
  let Data = props.Data
  console.log("DataDetails", Data);

  // let videoIntro = Data?.Link?.split("?")[0]?.split("/")[3]
  let videoIntro = Data?.Link

  let videolink = videoIntro ? videoIntro?.slice(0, 23) : ""
  let videoid = videoIntro ? videoIntro?.split("=")[1] : ""
  console.log("videoid", videoid, `${videolink}/embed/${videoid}`)
  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal
          centered
          show={props.showVideoIntro}
          onHide={props.handleVideoIntroClose}
          className={`${cascading.modalstyle}`}
          // backdrop="static"
          size="lg"
        >
          {/* <Modal.Header
            className={`${cascading.modalclr} ${cascading.modalheader}`}
          >
            <h4 className={`${cascading.modaltitle}`}>Lock Position</h4>
            <IoMdClose
              className={`${cascading.closebtn}`}
              onClick={props.handleVideoIntroClose}
            />
          </Modal.Header> */}
          <Modal.Body className={`${cascading.modalbody}`}>
            <iframe
              width="100%"
              // src="https://www.youtube.com/embed/tgbNymZ7vqY"
              src={`${videolink}/embed/${videoid}`}
              className={`${cascading.iframe}`}
            ></iframe>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
