import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/stakestyle.module.css';
import toast, { Toaster } from 'react-hot-toast';

import { useDispatch } from 'react-redux';

import { IoMdClose } from "react-icons/io";

import bnbimg from '../assests/images/bnb.png'
import { AiOutlineCalculator } from 'react-icons/ai';
import BigNumber from 'bignumber.js'
import Calculatormodal from './calculatormodal';

//lib
import { toFixedNumber } from '../lib/FixedNumber';

//hooks
import { Deposit } from '../hooks/usePools';
import { roundToSignificant } from '../hooks/useCommon';

import { getActivePools, fetchPoolsStakingLimits, fetchPoolsPublicDataAsync, fetchPoolsUserDataAsync } from '../hooks/usePools'
import { getApy, getInterestBreakdown, getPrincipalForInterest, getRoi } from '../hooks/useFarms'


export default function Stakepoolmodal(props) {


  // state 
  const [stakepoolmodal, setStakepoolmodal] = useState(true);
  const [points, setPoints] = useState(0)
  const [stakingBal, setStakingBal] = useState(0)
  const [usdValue, setUsdValue] = useState(0)
  const [pending, setPending] = useState(false);
  const [calshow, setcalshow] = useState(false);
  const [ROIRate, setROIRate] = useState(0);

  const dispatch = useDispatch()

  const { userData, datas } = props.stake


  useEffect(() => {
    console.log("props_stakepools", props.stake)
  })

  const rangeOnchange = async (value) => {
    // const { name, value } = e.target;
    setPoints(value)
    let stakingbalance = datas?.stakingLimit * value / 100
    const usdValueStaked = new BigNumber(stakingbalance).times(datas.stakingTokenPrice)
    setStakingBal((stakingbalance) / 10 ** 18)
    setUsdValue(usdValueStaked.toNumber() / 10 ** 18)
  }

  const onStakevalueChange = (e) => {
    try {
      const { value, name } = e.target
      setStakingBal(value)
      const usdValueStaked = new BigNumber(value * 10 ** 18).times(datas.stakingTokenPrice)
      console.log("usdValueStaked", usdValueStaked, datas.stakingTokenPrice)
      setUsdValue(usdValueStaked.toNumber() / 10 ** 18)
    } catch (err) {
      console.log(err, "onStakevalueChange")
    }
  }

  const staking = async () => {
    setPending(true)
    console.log("stakingBal", stakingBal * 10 ** 18)
    let stake = await Deposit(toFixedNumber(parseFloat(stakingBal * 10 ** 18).toFixed(0)), datas?.contractAddress)
    console.log("stake", stake)
    if (stake == true) {
      fetchPoolsPublicDataAsync(dispatch)
      fetchPoolsUserDataAsync(dispatch)
      toast.success(`Your ${datas?.stakingToken?.symbol} funds have been staked in the pool!`)
      props.onHide()
      setPending(false)
    }
    else {
      setPending(false)
      toast.error('Try Again!')
    }
  }

  const ROICal = () => {
    try {
      const principalInUSDAsNumber = toFixedNumber(usdValue)
      console.log("principalInUSDAsNumber", principalInUSDAsNumber)
      const interestBreakdown = getInterestBreakdown({
        principalInUSD: principalInUSDAsNumber,
        apr: props?.apy,
        earningTokenPrice: datas?.earningTokenPrice
      })
      const annualRoi = interestBreakdown[3] * datas?.earningTokenPrice
      const formattedAnnualRoi = annualRoi
      setROIRate(annualRoi)
      console.log("formattedAnnualRoi", formattedAnnualRoi)
    }
    catch (err) {
      console.log("err_ROI", err)
    }
  }


  useEffect(() => {
    ROICal()
  })


  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal centered show={stakepoolmodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
          <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
            <h4 className={`${cascading.modaltitle}`}>
            <svg width="21" height="26" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.1951 9.08478C20.9946 9.45252 21.3445 10.3992 20.9771 11.1995L15.6224 22.8572L16.699 12.5645C16.7624 11.9559 16.4314 11.3742 15.8759 11.1185L10.7707 8.76967L12.8315 16.5138L0.499673 0.0214844L11.2076 4.94854L20.1957 9.08411L20.1951 9.08478Z" fill="#EAECF0"></path><path d="M10.8033 26.9189C10.0037 26.5512 9.65388 25.6045 10.0213 24.8042L15.3759 13.1465L14.2993 23.4392C14.2359 24.0478 14.5669 24.6294 15.1224 24.8852L20.2276 27.234L18.1668 19.4898L30.5 35.9808L19.7921 31.0538L10.8039 26.9175L10.8033 26.9189Z" fill="#EAECF0"></path></svg>
            <span className='mx-2'>Stake in Pool</span>
            <svg width="21" height="26" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8049 9.08478C10.0054 9.45252 9.65555 10.3992 10.0229 11.1995L15.3776 22.8572L14.301 12.5645C14.2376 11.9559 14.5686 11.3742 15.1241 11.1185L20.2293 8.76967L18.1685 16.5138L30.5003 0.0214844L19.7924 4.94854L10.8043 9.08411L10.8049 9.08478Z" fill="#EAECF0"></path><path d="M20.1967 26.9189C20.9963 26.5512 21.3461 25.6045 20.9787 24.8042L15.6241 13.1465L16.7007 23.4392C16.7641 24.0478 16.4331 24.6294 15.8776 24.8852L10.7724 27.234L12.8332 19.4898L0.5 35.9808L11.2079 31.0538L20.1961 26.9175L20.1967 26.9189Z" fill="#EAECF0"></path></svg>
           
              </h4>
            <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

          </Modal.Header>
          <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
            <p className='text-center text-white'>Max stake for this pool: {roundToSignificant(datas?.stakingLimit / 10 ** 18, 6)} {datas?.stakingToken?.symbol}</p>
            {/* <div className={`${cascading.inputsec} mb-4`}>

              <div className={`${cascading.balancerow}`}>
                <p className={`${cascading.inputlabel}`}>Stake :</p>
                <img src={props?.image} className={`img-fluid ${cascading.logoimg}`} alt='img' />

                <p className={`${cascading.inputlabel}`}>{datas?.stakingToken?.symbol}</p>
              </div>

            </div> */}
            <div className={`${cascading.inputsec} mb-4`}>
              <div className={`${cascading.balancerow} ${cascading.balancerownowrap}`}>
                <p className={`${cascading.inputlabel} mb-0`}>Stake</p>
                <div className='d-flex align-items-center'>
                  <img src={props?.image} className={`img-fluid ${cascading.logoimg_coin}`} alt='img' />
                  <p className={`${cascading.inputlabel} mb-0 ms-2`}>{datas?.stakingToken?.symbol}</p>
                </div>

              </div>

              <div className={`${cascading.inputsecrowcol}`}>
                <div >
                  <input
                    autoComplete={"off"}
                    type="text"
                    className={`from-control`}
                    id="tokenAval"
                    aria-describedby="emailHelp"
                    placeholder="0.0"
                    value={stakingBal}

                    onChange={(e) => {
                      var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
                      if (!numbers.test(e.target.value) && e.target.value !== "") {
                        return false
                      }
                      onStakevalueChange(e)
                    }}

                  />
                </div>

                <div className={`${cascading.rightmaxbtn}`}>
                  {/* <button
                                                    className={`btn ${cascading.currencybtn}`}
                                                   > Max </button> */}

                  <p className={`${cascading.inputlabel} mb-0`}>~ {usdValue ? toFixedNumber(parseFloat(usdValue).toFixed(15)) : 0.00} USD</p>


                </div>



              </div>

            </div>
            <p className={`${cascading.inputlabelwhite} text-end`}>Balance :{userData?.stakingTokenBalance / 10 ** 18}</p>

            <div className={`${cascading.modalslider}`}>


              <div className={`${cascading.rangesec} mt-0`}>
                <p className={`${cascading.inputlabeltheem}`}>{points} %</p>

                <input type="range" className={`form-range ${cascading.rangeinput}`} id="customRange" name="points" min="0" max="100" value={points} onChange={(e) => { rangeOnchange(e.target.value) }} />
                <div className={`${cascading.btngrpsec} ${cascading.btngrpsecnowrap}`}>
                  <button className={`btn ${cascading.btnstyle}`} onClick={() => { rangeOnchange(25) }}>25%</button>
                  <button className={`btn ${cascading.btnstyle}`} onClick={() => { rangeOnchange(50) }}>50%</button>
                  <button className={`btn ${cascading.btnstyle}`} onClick={() => { rangeOnchange(75) }}>75%</button>
                  <button className={`btn ${cascading.btnstyle}`} onClick={() => { rangeOnchange(100) }}>Max</button>


                </div>

              </div>
            </div>
            <p className='text-center text-white mt-4'>Annual ROI at current rates: ${!isNaN(ROIRate) ? ROIRate : 0}
              <button className={`btn ${cascading.connect_btn_disable} ms-2`} onClick={() => { props.onshowcalculator() }}><AiOutlineCalculator /></button>

            </p>


            <div className='d-flex align-items-center justify-content-between'>


              <button className={`btn ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>

              <button className={`btn ms-3 ${cascading.connectwalletbtn}`} type='button' onClick={() => { staking() }} disabled={stakingBal == 0}>{pending == true ? "Confirming..." : "Confirm"}</button>

            </div>
            {/* <div className='text-center mt-4'>
              <button className={`${cascading.declabel}`} target="_blank" >Get DSP-BNB LP</button>

            </div> */}

          </Modal.Body>

        </Modal>
      </div>



    </>
  )
}

