import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/expertstyle.module.css';
import { FaRegQuestionCircle } from "react-icons/fa";
import { TiInfo } from "react-icons/ti";
import { useDispatch } from 'react-redux';

import { IoMdClose } from "react-icons/io";
import { CHAINS } from '../config/env';
import { GetChainIndex } from '../hooks/useWeb3';
import { SetuserTokens, getuserTokens } from '../lib/localStorage';
import { USER_TOKEN } from '../constants';
export default function Importtokenmodal(props) {
  const { Token } = props
  const dispatch = useDispatch()
  const [isCheck, SetisCheck] = useState(false)
  const [errors, Seterrors] = useState({})
  const ImportToken = () => {
    try {
      if (isCheck) {
        let userTokens = getuserTokens()
        userTokens.push(Token)
        SetuserTokens(JSON.stringify(userTokens))
        props.onHide()
        dispatch({
          type: USER_TOKEN,
          payload: userTokens
        })
      } else {
        let error = { ...errors }
        error['risk'] = 'Please enable the risk acknowledge'
        Seterrors(error)
      }

    } catch (err) {
      console.log(err, 'ImportToken__err')
    }
  }
  return (
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal centered show={props.show} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
        <h4 className={`${cascading.modaltitle}`}>
          <svg width="21" height="26" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.1951 9.08478C20.9946 9.45252 21.3445 10.3992 20.9771 11.1995L15.6224 22.8572L16.699 12.5645C16.7624 11.9559 16.4314 11.3742 15.8759 11.1185L10.7707 8.76967L12.8315 16.5138L0.499673 0.0214844L11.2076 4.94854L20.1957 9.08411L20.1951 9.08478Z" fill="#EAECF0"></path><path d="M10.8033 26.9189C10.0037 26.5512 9.65388 25.6045 10.0213 24.8042L15.3759 13.1465L14.2993 23.4392C14.2359 24.0478 14.5669 24.6294 15.1224 24.8852L20.2276 27.234L18.1668 19.4898L30.5 35.9808L19.7921 31.0538L10.8039 26.9175L10.8033 26.9189Z" fill="#EAECF0"></path></svg>
            <span className='mx-2'>Import Tokens</span>
            <svg width="21" height="26" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8049 9.08478C10.0054 9.45252 9.65555 10.3992 10.0229 11.1995L15.3776 22.8572L14.301 12.5645C14.2376 11.9559 14.5686 11.3742 15.1241 11.1185L20.2293 8.76967L18.1685 16.5138L30.5003 0.0214844L19.7924 4.94854L10.8043 9.08411L10.8049 9.08478Z" fill="#EAECF0"></path><path d="M20.1967 26.9189C20.9963 26.5512 21.3461 25.6045 20.9787 24.8042L15.6241 13.1465L16.7007 23.4392C16.7641 24.0478 16.4331 24.6294 15.8776 24.8852L10.7724 27.234L12.8332 19.4898L0.5 35.9808L11.2079 31.0538L20.1961 26.9175L20.1967 26.9189Z" fill="#EAECF0"></path></svg>
           
            </h4>
          {/* <h4 className={`${cascading.modaltitle}`}>Import Tokens</h4> */}
          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />
        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          <div className={`${cascading.pinkBox}`}>
            <TiInfo className={`${cascading.pinktriangle}`} />
            <p className='mb-1'>
              Anyone can create tokens on {CHAINS[GetChainIndex()].NAME} with any name, including creating fake versions of existing tokens and tokens that claim to represent projects that do not have a token.
            </p>
          </div>
          <p className={`${cascading.innerheadwhite}`}>If you purchase a fraudulent token, you may be exposed to permanent loss of funds.
          </p>
          <div className={`${cascading.plandiv}`}>
            <div className={`${cascading.badgetheme}`}>
              <FaRegQuestionCircle className={`${cascading.whiteinfo} ${cascading.innerheadwhite} me-2`} />
              <span className={`${cascading.innerheadwhite}`}>Unknown Source</span>
            </div>
            <div className='d-flex align-items-center justify-content-between mt-3'>
              <div>
                <span className={`${cascading.plantext} ${cascading.innerheadwhite} me-2`}>{Token.name}</span>
                <span className={`${cascading.planname} ${cascading.innerheadwhite}`}>{Token.symbol}</span>
                <p className='text-white mt-2 mb-0'>{`${(Token.address).substring(0, 5)}...${Token.address.substring(38, 42)}`}</p>
              </div>
              <div>
                <div className={`${cascading.badgethemefill} ${cascading.innerheadwhite}`}>
                  Medium Risk
                </div>
              </div>
            </div>
            <button className={`${cascading.declabel} ${cascading.connectwalletbtn} mt-2 px-0`} 
            onClick={() => {
              window.open(`${CHAINS[GetChainIndex()].Testnet}/${Token.address}`, '_blank');
            }} target="_blank">
              View on BscScan
            </button>
          </div>
          <div className="form-check mt-2 mb-4">
            <input
              className={`form-check-input ${cascading.checkboxstyle}`}
              type="checkbox"
              checked={isCheck}
              id="flexCheckDefault"
              onChange={(e) => {
                Seterrors({})
                SetisCheck(e.target.checked)
              }}
            />
            <label className={`form-check-label mb-0 mt-0 ${cascading.innerheadwhite}`} for="flexCheckDefault">
              I acknowledge the risk
            </label>
          </div>
          <p className="text-danger f-12 d-block text-left">{errors?.risk}</p>
          <button
            className={`btn ${cascading.connectwalletbtn}`}
            type='button'
            onClick={() => { ImportToken() }}
          >
            Proceed
          </button>
          <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={() => { props.onHide() }}>Cancel</button>
        </Modal.Body>

      </Modal>
    </div>
  )
}

