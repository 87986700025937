import React, { useEffect, useState } from 'react'
import { Modal, Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import cascading from '../assests/css/tokenstyle.module.css';
import bnblogo from '../assests/images/bnb.png';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import Walletmodal from './walletmodal';
import InfoIcon from '../assests/images/infoicon.png'

import { IoMdClose } from "react-icons/io";
import Importtokenmodal from './importtokenmodal';

import { FaRegQuestionCircle } from "react-icons/fa";

//lib
import { isEmpty } from "../lib/isEmpty";
import { getWalletAddress, walletControl } from '../lib/localStorage';
import fileObjectUrl from '../lib/img'

//hooks
import { getTokenBalance, GetCurrencyBalance } from '../hooks/useTokens'
import { getTokens } from '../hooks/useTokens'
import { IsCurrency, Significant } from '../hooks/useCommon'
import { TokenInfo } from '../hooks/useErc20';
import { CURRENT_CHAIN_ID } from '../hooks/useWeb3';


const tokendetail = {
  results: [{
    coinimg: bnblogo,
    tokename: "BNB",
    tokendesc: "BNB",
    balance: "0.666"

  },
  {
    coinimg: bnblogo,
    tokename: "BUSD",
    tokendesc: "BUSD Token",
    balance: "0.0"
  },
  {
    coinimg: bnblogo,
    tokename: "BNB",
    tokendesc: "BNB",
    balance: "0.0"
  },
  {
    coinimg: bnblogo,
    tokename: "BUSD",
    tokendesc: "BUSD Token",
    balance: "0"
  }]


};
export default function Selecttoken(props) {
  console.log(props, "props")
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setTokens] = useState([])
  const [searchResults, setSearchResults] = useState([]);
  const [activebtn, setActivebtn] = useState("")
  const [balance, setBalance] = useState(0)
  const [walletmodalshow, setWalletmodalshow] = useState(false)
  const [Loading, setLoading] = useState(false)
  const [importToken, SetimportToken] = useState({})
  const [isToken, SetisToken] = useState(false)
  const [showText, SetshowText] = useState('')
  const [showimport, setShowimport] = useState(false);
  const [selecttokenmodal, setSelecttokenmodal] = useState(true)
  const { usertokens } = useSelector((state) => (state.token))
  const { walletaddress } = useSelector((state) => (state.wallet))

  const handleChange = e => {
    let addressRegx = "^(0x)[0-9A-Fa-f]{40}$"
    var pattern = new RegExp(addressRegx)

    setSearchTerm(e.target.value);
    if (!isEmpty(e.target.value)) {
      let regx = new RegExp(e.target.value, 'i')
      let results = token.filter((val) => {
        return (val?.symbol?.match(regx) || val?.name?.match(regx) || val?.address?.match(regx))
      })
      if (isEmpty(results)) {
        if ((pattern.test(e.target.value))) {
          CheckToken(e.target.value)
        } else {
          SetisToken(false)
          SetshowText('The token was not found ,please paste the address and import the token')
        }
      }
      setSearchResults(results);
    } else {
      //results = getTokens()
      setSearchResults(token);
    }
    // setSearchResults(results);
    console.log(searchResults, "search")
  };

  const CheckToken = async (token) => {
    try {
      let checkToken = await TokenInfo(token)
      console.log(checkToken, 'checkToken')
      if (!checkToken) {
        SetisToken(false)
        SetshowText('Pasted address is not a token')
      } else if (!isEmpty(checkToken)) {
        console.log(checkToken, 'checkToken')
        let importToken = { ...checkToken }
        importToken['chainId'] = CURRENT_CHAIN_ID()
        importToken['image'] = InfoIcon
        SetisToken(true)
        SetimportToken(importToken)
      } else {
        SetisToken(false)
      }
    } catch (err) {
      console.log(err, 'CheckToken__err')
    }
  }

  // useEffect(() => {
  //   console.log("testnetTokens")

  //   let results = []

  //   if (!isEmpty(searchTerm)) {
  //     let regx = new RegExp(searchTerm, 'i')

  //     results = token.filter((val) => {
  //       return (val?.symbol?.match(regx) || val?.name?.match(regx))
  //     })
  //     // results = getTokens().map((val, index) => {
  //     //   val?.symbol?.toLowerCase().includes(searchTerm)
  //     // });
  //     setSearchResults(results);
  //   } else {
  //     results = getTokens()
  //     setSearchResults(token);
  //     // Object.entries(testnetTokens).filter(([token, tokenName],index) =>{
  //     //   console.log("testnetTokens3",token, tokenName)
  //     //   results.push(tokenName)
  //     //   // return tokenName
  //     // }
  //     // );
  //   }
  //   // setSearchResults(results);
  //   console.log(searchResults, "search", results)
  // }, [searchTerm]);


  useEffect(() => {
    if (!isEmpty(getWalletAddress())) {
      setLoading(true)
      TokensBalance()
    }
  }, [walletaddress,usertokens])

  const TokensBalance = async () => {
    let Token = getTokens()
    let tokens = JSON.stringify(Token)
    tokens = JSON.parse(tokens)
    let calls = []
    let balance = []
    for (let i = 0; i < Token.length; i++) {
      let val = Token[i]
      if (!IsCurrency(val)) {
        console.log(val, 'TokensBalance')
        let obj = {
          address: val?.address,
          name: 'balanceOf',
          params: [getWalletAddress()]
        }
        calls.push(obj)
      } else {
        balance[i] = await GetCurrencyBalance()
      }
    }
    // Token.map(async(val, i) => {
    // })
    console.log(calls, 'calls')
    let balan = await getTokenBalance(calls)
    balance = [...balance, ...balan]
    console.log("balance", balance)
    for (let j = 0; j < tokens.length; j++) {
      tokens[j]['balance'] = balance[j]
      if (j == tokens.length - 1) {
        console.log(tokens, 'Token')
        setSearchResults(tokens)
        setTokens(tokens)
        setLoading(false)
      }
    }
    setBalance(balance)

  }

  // const [tokenbtn, setTokenbtn] = useState([{
  //   img: bnblogo,
  //   tokenname: "BNB"

  // },
  // {
  //   img: bnblogo,
  //   tokenname: "BNB"

  // },
  // {
  //   img: bnblogo,
  //   tokenname: "BNB"
  // },
  // {
  //   img: bnblogo,
  //   tokenname: "BNB"

  // }]);
  return (

    <div className={`${cascading.walletmodalstyle}`}>
      {console.log(props, "checking")}
      <Modal centered show={selecttokenmodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>
            <svg width="21" height="26" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.1951 9.08478C20.9946 9.45252 21.3445 10.3992 20.9771 11.1995L15.6224 22.8572L16.699 12.5645C16.7624 11.9559 16.4314 11.3742 15.8759 11.1185L10.7707 8.76967L12.8315 16.5138L0.499673 0.0214844L11.2076 4.94854L20.1957 9.08411L20.1951 9.08478Z" fill="#EAECF0"></path><path d="M10.8033 26.9189C10.0037 26.5512 9.65388 25.6045 10.0213 24.8042L15.3759 13.1465L14.2993 23.4392C14.2359 24.0478 14.5669 24.6294 15.1224 24.8852L20.2276 27.234L18.1668 19.4898L30.5 35.9808L19.7921 31.0538L10.8039 26.9175L10.8033 26.9189Z" fill="#EAECF0"></path></svg>
            <span className='mx-2'>Select a Token</span>
            <svg width="21" height="26" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8049 9.08478C10.0054 9.45252 9.65555 10.3992 10.0229 11.1995L15.3776 22.8572L14.301 12.5645C14.2376 11.9559 14.5686 11.3742 15.1241 11.1185L20.2293 8.76967L18.1685 16.5138L30.5003 0.0214844L19.7924 4.94854L10.8043 9.08411L10.8049 9.08478Z" fill="#EAECF0"></path><path d="M20.1967 26.9189C20.9963 26.5512 21.3461 25.6045 20.9787 24.8042L15.6241 13.1465L16.7007 23.4392C16.7641 24.0478 16.4331 24.6294 15.8776 24.8852L10.7724 27.234L12.8332 19.4898L0.5 35.9808L11.2079 31.0538L20.1961 26.9175L20.1967 26.9189Z" fill="#EAECF0"></path></svg>

          </h4>

          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />
          {/* <button className={`${cascading.closebtn}`} onClick={props.onHide}>X</button> */}
        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          {walletaddress && !isEmpty(walletaddress) ?
            Loading && Loading ?
              <div className={`${cascading.commonsec}`}>
                <p className={`${cascading.labels}`}>Loading...</p>
              </div> :
              <>
                <div className={`${cascading.topsearchsec}`}>
                  <input
                    type="text"
                    placeholder="Search name or paste address"
                    value={searchTerm}
                    onChange={handleChange}
                    className={`${cascading.searchinput}`}
                  />
                </div>
                {
                  isToken ?
                    <>
                      <div className={`${cascading.plandiv}`}>
                        <div className='d-flex align-items-center justify-content-between'>
                          <div>
                            <FaRegQuestionCircle className={`${cascading.whiteinfo} ${cascading.white} me-2`} />
                            <span className={`${cascading.plantext} ${cascading.white} me-2`}>{importToken.symbol}</span>
                            <span className={`${cascading.planname} ${cascading.white}`}>{importToken.name}</span>
                          </div>
                          <div>
                            <Button className={`${cascading.btnstyle}`} onClick={() => { setShowimport(true); setSelecttokenmodal(false); }}>
                              Import
                            </Button>
                          </div>
                        </div>

                      </div>
                    </> :
                    <>
                      {props.commontoken &&
                        <div className={`${cascading.commonsec}`}>
                          <p className={`${cascading.labels}`}>Common bases<OverlayTrigger
                            className={`${cascading.tooltip_style}`}
                            key="right"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-right`}>
                                These tokens are commonly paired with other tokens.
                              </Tooltip>
                            }
                          >
                            <span className={`mx-2`} ><IoIosHelpCircleOutline fill='#fff' fontSize={18} /></span>
                          </OverlayTrigger></p>
                          <div className={`${cascading.btngrpsec}`}>
                            <ButtonGroup aria-label="Basic example">
                              {searchResults.map((e, i) => (
                                e.commonBase == true ?
                                  <Button variant="secondary" id={i} onClick={() => {
                                    console.log(e, 'SetToken')
                                    let obj = { ...e }
                                    setActivebtn(e)
                                    // obj['balance'] = balance[i]
                                    props.SetToken(obj)
                                    props.onHide()
                                  }} ><img src={fileObjectUrl(e?.image)} alt='img' className={`img-fluid ${cascading.tokenimgs}`} /> {e?.symbol}</Button>
                                  : ""

                              ))}
                            </ButtonGroup>
                          </div>


                          {/* <Button variant="secondary" className={activebtn == i ? `${cascading.activebtn}` : ""} id={i} onClick={() => setActivebtn(i)}><img src={fileObjectUrl(e?.image)} alt='img' className={`img-fluid ${cascading.tokenimgs}`} /> {e?.symbol}</Button> */}


                        </div>
                      }


                      {searchResults.length > 0 ? <ul className={`${cascading.tokenlists}`}>
                        {searchResults.map((e, i) => (
                          <li key={i} onClick={() => {
                            console.log(e, 'SetToken')
                            let obj = { ...e }
                            // obj['balance'] = balance[i]
                            props.SetToken(obj)
                            props.onHide()
                          }}>
                            <div className={`${cascading.tokenrow}`}>
                              <img src={isEmpty(e?.image) ?InfoIcon: fileObjectUrl(e?.image)} alt='coin' className={`img-fluid ${cascading.timg}`} />
                              <div>
                                <p className={`${cascading.name}`}>{e?.symbol}</p>
                                <p className={`${cascading.desc}`}>{e?.name}</p>
                              </div>
                            </div>
                            <div>
                              {console.log("e.balance", e.balance)}
                              {/* <p className={`${cascading.desc}`}>{isEmpty(balance[i]) ? 0 : parseFloat(balance[i])?.toFixed(2)}</p> */}
                              <p className={`${cascading.desc}`}>{isEmpty(e.balance) ? 0 : parseFloat(e?.balance).toFixed(8)}</p>
                            </div>
                          </li>
                        ))}
                      </ul> :
                        <p className='text-center text-white px-3'>{showText}</p>
                      }
                    </>
                }
              </>
            :
            <div className='px-4 py-5'>
              <button
                className={`btn ${cascading.connect_btn}`}
                onClick={() => setWalletmodalshow(true)}>
                Connect Wallet
              </button></div>}

        </Modal.Body>

      </Modal>

      {walletmodalshow && <Walletmodal show={walletmodalshow} onHide={() => setWalletmodalshow(false)} />}
      {showimport && <Importtokenmodal
        Token={importToken}
        show={showimport}
        onHide={() => {
          setShowimport(false);
          setSelecttokenmodal(true);
          SetisToken(false)
          setSearchTerm('')
        }}
      />
      }
    </div>
  )
}