import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/confirmremovestyle.module.css';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import infoimg from '../assests/images/infoicon.png'

import fileObjectUrl from '../lib/img'
import { toFixedNumber } from "../lib/FixedNumber"


import { IoMdClose } from "react-icons/io";
import { FaDownLong } from 'react-icons/fa6';
import { FaPlus } from 'react-icons/fa6';

import {roundToSignificant } from '../hooks/useCommon';

export default function ConfirmRemovemodal(props) {

  var propsdata = props?.supplyObj
  console.log("propsdata", propsdata)

  useEffect(() => {
    console.log("props", props?.supplyObj, props?.pairInfo)
  }, [])



  return (
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal centered show={props.show} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}><svg width="21" height="26" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.1951 9.08478C20.9946 9.45252 21.3445 10.3992 20.9771 11.1995L15.6224 22.8572L16.699 12.5645C16.7624 11.9559 16.4314 11.3742 15.8759 11.1185L10.7707 8.76967L12.8315 16.5138L0.499673 0.0214844L11.2076 4.94854L20.1957 9.08411L20.1951 9.08478Z" fill="#EAECF0"></path><path d="M10.8033 26.9189C10.0037 26.5512 9.65388 25.6045 10.0213 24.8042L15.3759 13.1465L14.2993 23.4392C14.2359 24.0478 14.5669 24.6294 15.1224 24.8852L20.2276 27.234L18.1668 19.4898L30.5 35.9808L19.7921 31.0538L10.8039 26.9175L10.8033 26.9189Z" fill="#EAECF0"></path></svg>
            <span className='mx-2'>You will receive</span>
            <svg width="21" height="26" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8049 9.08478C10.0054 9.45252 9.65555 10.3992 10.0229 11.1995L15.3776 22.8572L14.301 12.5645C14.2376 11.9559 14.5686 11.3742 15.1241 11.1185L20.2293 8.76967L18.1685 16.5138L30.5003 0.0214844L19.7924 4.94854L10.8043 9.08411L10.8049 9.08478Z" fill="#EAECF0"></path><path d="M20.1967 26.9189C20.9963 26.5512 21.3461 25.6045 20.9787 24.8042L15.6241 13.1465L16.7007 23.4392C16.7641 24.0478 16.4331 24.6294 15.8776 24.8852L10.7724 27.234L12.8332 19.4898L0.5 35.9808L11.2079 31.0538L20.1961 26.9175L20.1967 26.9189Z" fill="#EAECF0"></path></svg>
           </h4>
          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          <div className={`${cascading.swapsec}`}>

            <div className={`${cascading.rowslist}`}>
              <p>
                {/* 0.0001567890765678 */}
                {(roundToSignificant(propsdata?.out1,6))}
              </p>
              <p><img src={fileObjectUrl(propsdata?.fromToken?.image)} className={`me-2 ${cascading.coinimg}`} />{propsdata?.fromToken?.symbol}</p>
            </div>
            <div className={`my-3`}>
              <FaPlus fill='#fff' />
            </div>
            <div className={`${cascading.rowslist}`}>
              <p>
                {/* 0.0001567890765678 */}

                {roundToSignificant(propsdata?.out2,6)}
              </p>
              <p><img src={fileObjectUrl(propsdata?.toToken?.image)} className={`me-2 ${cascading.coinimg}`} />{propsdata?.toToken?.symbol}</p>
            </div>


          </div>
          <p className={`${cascading.desccontent}`}>Output is estimated. If the price changes by more than 0.5% your transaction will revert.</p>

          <div className={`${cascading.listcard}`}>
            <ul>
              <li>
                <p>{`${propsdata?.fromToken?.symbol}/${propsdata?.toToken?.symbol}`}  Burned</p>
                <div>
                  <img src={fileObjectUrl(propsdata?.fromToken?.image)} className={`${cascading.coinimg} ${cascading.coinimgstyle}`} />
                  <img src={fileObjectUrl(propsdata?.toToken?.image)} className={`ms-1 ${cascading.coinimg} ${cascading.coinimgstyle}`} />
                  <span className={`${cascading.coinvaluespan} text-white ms-1`}>
                    {/* 0.00015676786050 */}
                    {roundToSignificant(propsdata?.lpValue,6)}
                  </span>
                </div>

              </li>
              {/* <li>
        <p>MInimum received</p>
        <p>0.000 WBNB</p>
      </li> */}
              <li>
                <p>Price</p>
                <div>
                  <p className='text-center text-sm-end'>1{propsdata?.fromToken?.symbol} = {roundToSignificant(propsdata?.toTokenPer,6)} {propsdata?.toToken?.symbol}</p>
                  <p className='text-center text-sm-end'>1 {propsdata?.toToken?.symbol} = {roundToSignificant(propsdata?.fromTokenPer,6)} {propsdata?.fromToken?.symbol}</p>
                </div>
              </li>

              {/* <li>
        <p>Liquidity Provider Fee</p>
        <p>0.000 xFLASH</p>
      </li> */}
            </ul>

          </div>


          <button className={`btn ${cascading.connectwalletbtn}`} type='button' onClick={() => { props?.supplyData() }} >Confirm</button>
          {/* <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button> */}






        </Modal.Body>

      </Modal>
    </div>
  )
}
