import React, { useState } from 'react'
import cascading from '../assests/css/all.module.css';
import { Button, ButtonGroup } from 'react-bootstrap';
import { BiRightArrowAlt } from "react-icons/bi";
import { FaMinus, FaPlus } from "react-icons/fa6";

import { useSelector, useDispatch } from 'react-redux';

//modals
import ComingSoonmodal from '../modals/comingSoonmodal';
import DividendAllocatemodal from '../modals/dividendsAllocateModal';
import DividendsDeallocatemodal from '../modals/dividendsDeallocate';
import { roundToSignificant } from '../hooks/useCommon';
import DividendsSuccessmodal from '../modals/DividendsSuccessmodal';

export default function Yourallocation(props) {

    const [allocatemodal, setAllocatemodal] = useState(false);
    const [deallocatemodal, setDeallocatemodal] = useState(false);
    const [modalcoming, setmodalcoming] = useState(false);

    const { dividendsdetails } = useSelector((state) => (state.dividends))
    const { redeemAllocation } = useSelector((state) => (state.xtoken))

    const [title, settitle] = useState('')
    const [successLoader, setsuccessLoader] = useState(false)
    const [loaderText, setloaderText] = useState('')
    const [successText, setsuccessText] = useState('')
    const [errorText, seterrorText] = useState('')
    const [Trxn, setTrxn] = useState('')
    const [modalsucessdivident, setmodalsuccessdivident] = useState(false);


    const showsuccesModal = (text, title) => {
        try {
            settitle(title)
            setloaderText(text)
            setsuccessLoader(false)
            setmodalsuccessdivident(true);
        } catch (err) {
            console.log(err, 'showsuccesModal___err')
        }
    }

    const SetsuccessLoader = (text, hash) => {
        setTrxn(hash)
        setsuccessText(text);
        setsuccessLoader(true)
    }

    const setError = (text) => {
        seterrorText(text);
        setsuccessLoader(undefined)
    }
    return (
        <div className={`${cascading.approvecard} mb-4`}>

            <div className={`${cascading.innercard} ${cascading.dashcard}`}>
                <p className={`${cascading.head} ${cascading.headfelx}`}>
                    Your Allocation
                    <div>

                        <button
                            className={`btn me-2 ${cascading.stakebtnicon}`}
                            disabled={dividendsdetails.usersAllocation > 0 ? false : true}
                            onClick={() => { setDeallocatemodal(true); }}>
                            {/* <button className={`btn me-2 ${cascading.stakebtnicon}`} onClick={() => { setmodalcoming(true) }}> */}
                            <FaMinus />
                        </button>
                        <button className={`btn ${cascading.stakebtnicon}`} onClick={() => { setAllocatemodal(true); }}>
                            {/* <button className={`btn ${cascading.stakebtnicon}`} onClick={() => { setmodalcoming(true) }}> */}
                            <FaPlus />
                        </button>

                    </div>

                </p>
                <p className={`${cascading.desc}`}>
                    Earn real yield weekly from protocol earnings with xFLASH.
                </p>

                <div className={`${cascading.innercard} ${cascading.innercardalloc} mt-3`}>
                    <div className='mt-2'>
                        <p className={`${cascading.labelname}`}>Your allocation</p>
                        <p className={`${cascading.value}`}>{roundToSignificant(dividendsdetails.usersAllocation, 6)} xFLASH</p>
                    </div>
                    <div className='mt-2'>
                        <p className={`${cascading.labelname}`}>Manual allocations</p>
                        <p className={`${cascading.value}`}>{roundToSignificant((dividendsdetails.usersAllocation - redeemAllocation), 6)} xFLASH</p>
                    </div>
                    <div className='mt-2'>
                        <p className={`${cascading.labelname}`}>Total share</p>
                        <p className={`${cascading.value} ${cascading.valuebrknew}`}>{dividendsdetails.share} %</p>
                    </div>
                    <div className='mt-2'>
                        <p className={`${cascading.labelname}`}>Redeem allocation</p>
                        <p className={`${cascading.value}`}>{redeemAllocation} xFLASH</p>
                    </div>
                </div>

                {/* <div className='text-center mt-3'>
          <button className={`btn ${cascading.stakebtnicon}`}>
                 Allocate
                </button>
          </div> */}



            </div>

            {allocatemodal && <DividendAllocatemodal
                onHide={() => setAllocatemodal(false)}
                showsuccesModal={(text, title) => {
                    showsuccesModal(text, title);
                    setAllocatemodal(false)
                }}
                SetsuccessLoader={(text, hash) => { SetsuccessLoader(text, hash) }}
                setError={(text) => { setError(text) }}
            />
            }
            {deallocatemodal && <DividendsDeallocatemodal
                onHide={() => setDeallocatemodal(false)}
                showsuccesModal={(text, title) => {
                    showsuccesModal(text, title)
                    setDeallocatemodal(false)
                }}
                SetsuccessLoader={(text, hash) => { SetsuccessLoader(text, hash) }}
                setError={(text) => { setError(text) }}
            />
            }
            {modalcoming && <ComingSoonmodal show={modalcoming} onHide={() => { setmodalcoming(false); }} />}
            {modalsucessdivident && <DividendsSuccessmodal
                show={modalsucessdivident}
                title={title}
                loaderText={loaderText}
                successText={successText}
                isLoader={successLoader}
                // lpSymbol={viewrecord.lpsymbol}
                hash={Trxn}
                errorText={errorText}
                onHide={() => { setmodalsuccessdivident(false) }}
            />}
        </div>
    )
}
