const BASE_URL = "/user";
const CMS_BASE_URL = "/cms"

export const addReferrer = BASE_URL + "/addReferrer"

export const userinvested = BASE_URL + '/userinvested';

export const createuserApi = BASE_URL + '/createuser'

export const saveTransaction = BASE_URL + '/saveTransaction'

export const getTransaction = BASE_URL + '/getTransaction'

export const cmslist = CMS_BASE_URL + '/cmslist'

export const addsociallink = CMS_BASE_URL + '/addsociallink'
