import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { IoMdSettings } from "react-icons/io";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { MdHistory } from "react-icons/md";
import cascading from '../../assests/css/liquidity.module.css';
import Footer from '../../common/footer'
import { FaArrowDown, FaRegQuestionCircle } from "react-icons/fa";
import Header from '../../common/header';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { ethers, FixedNumber } from "ethers";
import { formatUnits, parseUnits } from "ethers/lib/utils";
// import {tokens} from '../../config/tokens'
import { ERC20, useContractFunction, useEthers, useTokenAllowance, useTokenBalance, useEtherBalance } from "@usedapp/core";
import { toastAlert } from "../../lib/toastAlert";
import { ZEROTH_ADDRESS, MINIMUM_LIQUIDITY, MAX_UNIT256 } from '../../config/env'
import { FaAngleDown, FaPlus, FaArrowLeft } from "react-icons/fa";
import { IoIosHelpCircleOutline } from "react-icons/io";
import bnbimg from '../../assests/images/bnb.png'
import Walletmodal from '../../modals/walletmodal';
import { useSelector } from 'react-redux';
import Recenthistory from '../../modals/recenthistory';
import Selecttoken from '../../modals/selecttoken';
import { Button, Tooltip } from 'react-bootstrap';
import Settingsmodal from '../../modals/settingsmodal';
import ConfirmSupplymodal from '../../modals/confirmSupplymodal';

import { MdOutlineWarning } from "react-icons/md";
import iconimg from '../../assests/images/bnb.png';
import iconimg1 from '../../assests/images/coinicon.png'
import BigNumber from 'bignumber.js';
//hooks
import { getTokens, getCurrencyBalance, multicall } from '../../hooks/useTokens'
import { ApproveToken, checkIsApproved, Balance } from '../../hooks/useErc20'
import { Addliquidity, AddLiquidityETH, GetAmountsOut, GetAmountsIn, getRouterAddress } from "../../hooks/useRouter"
import { GetPairInfo } from '../../hooks/useFactory'


//lib
import { isEmpty } from "../../lib/isEmpty";
import fileObjectUrl from '../../lib/img'
import WaitingSupplymodal from '../../modals/waitingSupplymodal';
import { toFixedNumber } from "../../lib/FixedNumber"
import { getWalletAddress } from '../../lib/localStorage';


//abi 
import pair_ABI from '../../config/abi/pairAbi.json'


import CompletedSupplymodal from '../../modals/completedSupplymodal.jsx';
import { IsCurrency, roundToSignificant } from '../../hooks/useCommon';

export default function AddLiquidity() {
    const navigate = useNavigate()
    // const {account} = useEthers();
    const { state } = useLocation()
    console.log("state", state)
    const account = useSelector((state) => state.wallet)
    console.log("walletdetail", account)


    let WETHAddress = "0x3742Fc9A0662c86a3f65A975b4D3Ef87Fa13af0c"
    const [swapsec, setSwapsec] = useState(true)
    const [walletmodalshow, setWalletmodalshow] = useState(false)
    const [recentshow, setRecentshow] = useState(false);
    const [tokenshow, setTokenshow] = useState(false);
    const [tokenshow2, setTokenshow2] = useState(false);
    const [settingshow, setSettingshow] = useState(false);
    const [commontoken, setCommontoken] = useState(false)
    const [confirmSupply, setConfirmSupply] = useState(false)
    const [waitingSupply, setWaitingSupply] = useState(false)
    const [succesSupply, setSuccesSupply] = useState(false)
    // const initialFromToken = getTokens()[0]
    // console.log("initialFromToken", initialFromToken, getTokens()[0])
    const [fromValue, setFromValue] = useState("");
    const [toValue, setToValue] = useState("");
    const [fromTokenApprove, setfromTokenApprove] = useState(false);
    const [toTokenApprove, settoTokenApprove] = useState(false);

    const [fromToken, setFromToken] = useState("");
    const [toToken, setToToken] = useState("");
    const [pairInfo, setPairInfo] = useState("");
    const [toTokenper, setToTokenper] = useState(0);
    const [fromTokenper, setFromTokenper] = useState(0);
    const [lpBalance, setlpBalance] = useState(0);
    const [poolA, setPoolA] = useState(0);
    const [poolB, setPoolB] = useState(0);
    const [token0, setToken0] = useState(0);
    const [token1, setToken1] = useState(0);
    const [totalVal, setTotalVal] = useState(0);
    const [poolShare, setPoolShare] = useState(0);
    const [getLiquidity, setgetLiquidity] = useState(0)

    const [Insufficient, setInsufficient] = useState(false)
    const [InsufficientText, setInsufficientText] = useState(false)
    // const [waitingShow, setWaitingShow] = useState(false);

    // const fromValueBigNumber = parseUnits(fromValue || "0");

    const userdetail = useSelector((state) => state.admin)
    const { deadline, slippage } = useSelector((state) => state.settings)

    // const fromTokenBalance = useTokenBalance(fromToken?.address, account?.walletaddress);
    // console.log("fromTokenBalance", fromToken.address, fromTokenBalance, account?.walletaddress)
    // const toTokenBalance = useTokenBalance(toToken?.address, account?.walletaddress);

    // const isFromWETH = fromToken == WETHAddress;

    // const etherBalance = useEtherBalance(account?.walletaddress);
    // const hasEnoughBalance = fromValueBigNumber.lte(isFromWETH ? etherBalance : fromTokenBalance ?? parseUnits("0"));

    useEffect(() => {
        getpair()
        console.log("fromToken", fromToken, toToken)

    }, [fromToken, toToken])

    useEffect(() => {
        console.log("state", state)
        if (state && state?.key === 'add') {
            setPoolShare(state.token.poolShare)
            if (!isEmpty(state.token.token1)) {
                setFromToken(state.token.token1)
                setFromTokenper(state.token.reserveA)
            }
            if (!isEmpty(state.token.token2)) {
                setToToken(state.token.token2)
                setToTokenper(state.token.reserveB)
            }
        }
        else if (state && state?.key === 'find') {
            setPoolShare(state.poolShare)
            if (!isEmpty(state.fromToken)) {
                setFromToken(state.fromToken)
                setFromTokenper(state.reserveA)
            }
            if (!isEmpty(state.toToken)) {
                setToToken(state.toToken)
                setToTokenper(state.reserveB)
            }
        }
        else if(state && state?.key === 'firstadd'){
            if (!isEmpty(state.fromToken)) {
                setFromToken(state.fromToken)
            }
            if (!isEmpty(state.toToken)) {
                setToToken(state.toToken)
            }
        }
    }, [state])



    const getpair = async () => {
        var get = await GetPairInfo(fromToken?.address, toToken?.address)

        console.log("getpairaddress", get)
        setPairInfo(get)
        var bal = await Balance(get)
        console.log("getpairaddress__bal", bal)
        setlpBalance(bal)
        let calls = [{
            address: get,
            name: 'getReserves',
        },
        {
            address: get,
            name: 'totalSupply'
        },
        {
            address: get,
            name: 'token0'
        },
        {
            address: get,
            name: 'token1'
        }
        ]

        var pooldata = await multicall(pair_ABI, calls)
        console.log(pooldata, 'getpairaddress1')
        if (pooldata) {
            let poolA = new BigNumber(pooldata[0][0]._hex).toNumber() / 10 ** 18
            let poolB = new BigNumber(pooldata[0][1]._hex).toNumber() / 10 ** 18
            console.log(get !== ZEROTH_ADDRESS, 'getpairaddress3')
            if (get !== ZEROTH_ADDRESS) {
                console.log(!isEmpty(fromValue) && !isNaN(fromValue), 'getpairaddress2')
                if (!isEmpty(fromValue) && !isNaN(fromValue)) {
                    console.log(fromValue, "getpairaddress4", poolB, poolA)
                    let price
                    if (fromToken?.address == pooldata[2][0]) {
                        price = poolB / poolA
                    } else {
                        price = poolA / poolB
                    }
                    let amount1 = fromValue * price

                    console.log('getpairaddress4', amount1, price)
                    if (amount1 > toToken.balance) {
                        setInsufficient(true)
                        setInsufficientText(`Insufficient ${toToken.symbol} balance`)
                    } else {
                        setInsufficient(false)
                        setInsufficientText('')
                    }
                    setToValue(amount1)
                    // GetAmounts(fromValue)
                }
                else if (!isEmpty(toValue) && !isNaN(toValue)) {
                    console.log(toValue, "toValue_getpair")
                    let price
                    if (toToken?.address == pooldata[3][0]) {
                        price = toFixedNumber(poolA / poolB)
                    }
                    else {
                        price = toFixedNumber(poolB / poolA)
                    }
                    let amount2 = toValue * price
                    if (amount2 > fromToken.balance) {
                        setInsufficient(true)
                        setInsufficientText(`Insufficient ${fromToken.symbol} balance`)
                    } else {
                        setInsufficient(false)
                        setInsufficientText('')
                    }
                    setFromValue(amount2)
                }
            } else {
                setFromValue('')
                setToValue('')
                setToTokenper('')
                setFromTokenper('')
            }
            setPoolA(new BigNumber(pooldata[0][0]._hex).toNumber() / 10 ** 18)
            setPoolB(new BigNumber(pooldata[0][1]._hex).toNumber() / 10 ** 18)
            console.log("pooldata", pooldata, new BigNumber(pooldata[0][0]._hex).toNumber() / 10 ** 18, new BigNumber(pooldata[0][1]._hex).toNumber() / 10 ** 18, pooldata[2][0], pooldata[3][0])
            console.log("share", new BigNumber(pooldata[1][0]._hex).toNumber() / 10 ** 18)
            setTotalVal(new BigNumber(pooldata[1][0]._hex).toNumber() / 10 ** 18)
            setToken0(pooldata[2][0])
            setToken1(pooldata[3][0])

        }

    }

    useEffect(() => {
        priceCalculation(fromValue, toValue)
    }, [fromValue, toValue])

    const priceCalculation = (fromValue, toValue) => {
        if (!isEmpty(fromValue) && !isEmpty(toValue)) {
            let totokenper = toValue / fromValue
            totokenper = !isNaN(totokenper) && isFinite(totokenper) ? totokenper : 0
            setToTokenper(totokenper)
            let fromtokenper = fromValue / toValue
            fromtokenper = !isNaN(fromtokenper) && isFinite(fromtokenper) ? fromtokenper : 0
            setFromTokenper(fromtokenper)
            console.log("priceCalculation", totokenper, fromtokenper, isFinite(fromtokenper))
            if (pairInfo !== ZEROTH_ADDRESS && poolA != '0' && poolB != '0') {
                let liquidity = Math.min(fromValue * totalVal / poolA, toValue * totalVal / poolB);
                let supply = liquidity + totalVal
                let shareOfPool = liquidity * 100 / supply
                console.log("shareOfPool", shareOfPool, 'addliquidity', liquidity, totalVal, 'totalliquidity', supply)
                setPoolShare(shareOfPool)
                setgetLiquidity(liquidity)
            }
            else {
                // liquidity = Math.sqrt(amount0.mul(amount1)).sub(MINIMUM_LIQUIDITY);

                let liquidity = Math.sqrt((fromValue * toValue) - MINIMUM_LIQUIDITY);
                console.log("liquidity", liquidity)
                setPoolShare(100)
            }

        }
    }

    const fromApprove = async (address, token, value) => {
        console.log(address, token, value, "fromApprove")
        if (IsCurrency(token)) {
            setfromTokenApprove(true)
            return true
        }
        var result = await checkIsApproved(address, isEmpty(value) ? MAX_UNIT256 : value)
        setfromTokenApprove(result)
        console.log("setfromTokenApprove", result)
    }


    const toApprove = async (address, token, value) => {
        console.log(address, token, value, "toApprove")

        if (IsCurrency(token)) {
            settoTokenApprove(true)
            return true
        }
        var result = await checkIsApproved(address, isEmpty(value) ? MAX_UNIT256 : value)
        settoTokenApprove(result)
        console.log("settoTokenApprove", result)
    }

    useEffect(() => {
        if (!isEmpty(fromToken)) {
            fromApprove(fromToken?.address, fromToken, fromValue)
        }
    }, [fromToken, fromValue])

    useEffect(() => {
        if (!isEmpty(toToken)) {
            toApprove(toToken?.address, toToken, toValue)
        }
    }, [toToken, toValue])


    const onFromTokenChange = async value => {
        if (value.address == toToken?.address) {
            setToToken(fromToken)
        }
        setFromToken(value);
        if (value.balance <= 0 || fromValue > value.balance) {
            setInsufficient(true)
            setInsufficientText(`Insufficient ${value.symbol} balance`)
        } else {
            setInsufficient(false)
            setInsufficientText('')
        }
    };

    const onToTokenChange = async value => {
        console.log(value, "value")
        if (value.address == fromToken?.address) {
            setFromToken(toToken)
        }
        setToToken(value);
        if (value.balance <= 0 || toValue > value.balance) {
            setInsufficient(true)
            setInsufficientText(`Insufficient ${value.symbol} balance`)
        } else {
            setInsufficient(false)
            setInsufficientText('')
        }
    };
    console.log(toToken, "toToken")

    const onChange = (e) => {
        try {
            const { name, value } = e.target;
            if (name == "fromValue") {
                if (value == 0) {
                    setToTokenper('')
                    setFromTokenper('')
                }
                var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
                if (!numbers.test(e.target.value) && e.target.value !== "") {
                    return false
                }
                console.log("value_onchange", value)
                if (pairInfo !== ZEROTH_ADDRESS && poolA != '0' && poolB != '0') {
                    // GetAmounts(value)
                    let price
                    console.log("pairInfo", poolB, poolA)
                    if (fromToken?.address == token0) {
                        price = toFixedNumber(poolB / poolA)
                    }
                    else {
                        price = toFixedNumber(poolA / poolB)
                    }

                    let amount1 = value * price;
                    console.log("amount1", amount1, price, amount1 > toToken.balance)
                    if (amount1 > toToken.balance) {
                        setInsufficient(true)
                        setInsufficientText(`Insufficient ${fromToken.symbol} balance`)
                    } else {
                        setInsufficient(false)
                        setInsufficientText('')
                    }
                    setToValue(amount1)
                    // priceCalculation(value,amount1)
                }
                if (value > fromToken.balance) {
                    setInsufficient(true)
                    setInsufficientText(`Insufficient ${fromToken.symbol} balance`)
                } else {
                    setInsufficient(false)
                    setInsufficientText('')
                }
                console.log("value_onchange1", value)
                setFromValue(value)
            }
            if (name == "toValue") {
                if (value == 0) {
                    setToTokenper('')
                    setFromTokenper('')
                }
                // var numbers = /^\d+((.)|(.\d{0,30})?)$/;
                // if (!numbers.test(e.target.value) && e.target.value !== "") {
                //     return false
                // }
                // if (name == "toValue") {
                var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
                if (!numbers.test(e.target.value) && e.target.value !== "") {
                    return false
                }
                let price

                if (pairInfo !== ZEROTH_ADDRESS && poolA != '0' && poolB != '0') {
                    if (toToken?.address == token1) {
                        price = toFixedNumber(poolA / poolB)
                    }
                    else {
                        price = toFixedNumber(poolB / poolA)
                    }

                    let amount0 = value * price;
                    console.log("amount0", amount0, price)
                    if (amount0 > fromToken.balance) {
                        setInsufficient(true)
                        setInsufficientText(`Insufficient ${toToken.symbol} balance`)
                    } else {
                        setInsufficient(false)
                        setInsufficientText('')
                    }
                    setFromValue(amount0)
                    // priceCalculation(amount0,value)
                }
                if (value > toToken.balance) {
                    setInsufficient(true)
                    setInsufficientText(`Insufficient ${toToken.symbol} balance`)
                } else {
                    setInsufficient(false)
                    setInsufficientText('')
                }
                setToValue(value)
                // }
            };
        } catch (err) {
            console.log(err, "onChange__errr")
        }
    }

    const GetAmounts = async (Token2) => {
        let addressArr = [
            fromToken?.address,
            toToken?.address
        ]
        let amountsIn = toFixedNumber(Token2 * 10 ** 18)
        var amount = await GetAmountsOut(addressArr, amountsIn)
        setToValue(amount?.amountOut)
        console.log("amount", amount, amount?.amountOut)
    }


    const fromMax = () => {
        console.log("fromMax", fromToken?.balance)
        setFromValue(fromToken?.balance)
        if (pairInfo !== ZEROTH_ADDRESS && poolA != '0' && poolB != '0') {
            let price
            console.log("pairInfo", poolB, poolA)
            if (fromToken?.address == token0) {
                price = toFixedNumber(poolB / poolA)
            }
            else {
                price = toFixedNumber(poolA / poolB)
            }

            let amount1 = fromToken?.balance * price;
            console.log("amount1", amount1, price)
            if (amount1 > toToken.balance) {
                setInsufficient(true)
                setInsufficientText(`Insufficient ${toToken.symbol} balance`)
            } else {
                setInsufficient(false)
                setInsufficientText('')
            }
            setToValue(amount1)
        }
    }

    const toMax = () => {

        setToValue(toToken?.balance)


        if (pairInfo !== ZEROTH_ADDRESS && poolA != '0' && poolB != '0') {
            let price
            if (toToken?.address == token1) {
                price = toFixedNumber(poolA / poolB)
            }
            else {
                price = toFixedNumber(poolB / poolA)
            }

            let amount0 = toToken?.balance * price;
            console.log("amount0", amount0, price)
            setFromValue(amount0)
            if (amount0 > fromToken.balance) {
                setInsufficient(true)
                setInsufficientText(`Insufficient ${fromToken.symbol} balance`)
            } else {
                setInsufficient(false)
                setInsufficientText('')
            }
        }
    }


    const initalStateSet = () => {
        try {
            setFromToken('')
            setToToken('')
            setFromValue('')
            setToValue('')
            setFromTokenper(0)
            setToTokenper(0)
            setlpBalance(0)
            setPoolA(0)
            setPoolB(0)
            setPoolShare(0)

        } catch (err) {
            console.log('initalStateSet__err', err, true)
        }
    }


    console.log("Liquidity", fromValue, toValue)



    const supplyData = async () => {
        setConfirmSupply(false)
        setWaitingSupply(true)
        let isFromBNB = IsCurrency(fromToken)
        let isToBNB = IsCurrency(toToken)
        // if (fromToken?.name == 'BNB' || toToken?.name == "BNB") {
        if (IsCurrency(fromToken) || IsCurrency(toToken)) {
            let tokens = isFromBNB ? toToken?.address : fromToken?.address
            // let amountTokenDesired = fromToken?.name == 'BNB' ? (toValue * 10 ** 18).toFixed(0) : (fromValue * 10 ** 18).toFixed(0)
            // let amountETHMin = fromToken?.name == 'BNB' ? (fromValue * 10 ** 18).toFixed(0) : (toValue * 10 ** 18).toFixed(0)

            console.log("slippage", slippage)
            let slippageval = parseFloat(slippage) / 100

            let amountTokenDesired = isFromBNB ? toFixedNumber(toValue) : toFixedNumber(fromValue)
            var amountTokenMin = parseFloat(amountTokenDesired) * (1 - slippageval);
            amountTokenDesired = isFromBNB ? amountTokenDesired * 10 ** toToken?.decimal : amountTokenDesired * 10 ** fromToken?.decimal
            amountTokenDesired = toFixedNumber(parseFloat(amountTokenDesired).toFixed(0))
            console.log("amountTokenDesired", amountTokenDesired)

            amountTokenMin = isFromBNB ? amountTokenMin * 10 ** toToken?.decimal : amountTokenMin * 10 ** fromToken?.decimal
            amountTokenMin = toFixedNumber(parseFloat(amountTokenMin).toFixed(0))
            console.log("amountTokenMin", amountTokenMin)

            let amountETHDesired = isFromBNB ? toFixedNumber(fromValue) : toFixedNumber(toValue)
            var amountETHMin = parseFloat(amountETHDesired) * (1 - slippageval);
            amountETHDesired = toFixedNumber(parseFloat(amountETHDesired * 10 ** 18).toFixed(0))
            amountETHMin = amountETHMin * 10 ** 18
            amountETHMin = toFixedNumber(parseFloat(amountETHMin).toFixed(0))
            console.log("amountETHMin", amountETHMin)

            let deadLine = ((new Date().getTime() / 1000) + parseFloat(deadline * 60)).toFixed(0)
            let qureyString = `Add ${toFixedNumber(fromValue)} ${fromToken.symbol} and ${toFixedNumber(toValue)} ${toToken.symbol}`
            let result = await AddLiquidityETH(amountETHDesired, tokens, amountTokenDesired, amountTokenMin, amountETHMin, deadLine, qureyString)
            setWaitingSupply(false)
            if (result) {
                initalStateSet()
                setSuccesSupply(true)
            } else {
                setConfirmSupply(true)
            }
            console.log("AddLiquidityETH", result)
        }
        else {

            let slippageval = parseFloat(slippage) / 100
            console.log(fromValue, toValue, slippageval, "slippage_slip")
            var TokenAMin = parseFloat(fromValue) * (1 - slippageval);
            TokenAMin = TokenAMin * 10 ** fromToken?.decimal
            TokenAMin = toFixedNumber(parseFloat(TokenAMin).toFixed(0))
            console.log("TokenAMin", TokenAMin)

            var TokenBMin = parseFloat(toValue) * (1 - slippageval);
            TokenBMin = TokenBMin * 10 ** toToken?.decimal
            TokenBMin = toFixedNumber(parseFloat(TokenBMin).toFixed(0))
            console.log("TokenBMin", TokenBMin)

            let deadLine = ((new Date().getTime() / 1000) + parseFloat(deadline * 60)).toFixed(0)
            let qureyString = `Add ${toFixedNumber(fromValue)} ${fromToken.symbol} and ${toFixedNumber(toValue)} ${toToken.symbol}`
            let result = await Addliquidity(fromToken?.address, toToken?.address, (fromValue * 10 ** 18).toFixed(0), (toValue * 10 ** 18).toFixed(0), TokenAMin, TokenBMin, deadLine, qureyString)
            setWaitingSupply(false)
            if (result) {
                initalStateSet()
                setSuccesSupply(true)
            } else {
                setConfirmSupply(true)
            }
            console.log("AddLiquidity", result)
        }
    }

    // const supplyObj = async () =>{
    var obj = {
        fromToken: fromToken,
        toToken: toToken,
        fromValue: fromValue,
        toValue: toValue,
        toTokenper: toTokenper,
        fromTokenper: fromTokenper,
        lpBalance: lpBalance,
        getLiquidity: getLiquidity,
        poolShare: poolShare
    }

    console.log(Insufficient, InsufficientText, 'InsufficientText')

    return (
        <>
            <Header />
            <div className={`${cascading.innerpages} ${cascading.liquiditypage}`}>
                <div className={`container`}>
                    <div className={`row justify-content-center ${cascading.swapcardrow}`}>
                        <div className={`col-12 col-sm-10 col-md-9 col-lg-8 col-xl-7`}>
                        <div className='text-end mb-4'>
                                <Link to="/createposition" className={`btn ${cascading.currencybtn} ${cascading.btnrightpostion}`}>spNFT</Link>
                            </div>
                            <div className={` ${cascading.cardstyle}`}>
                            <div className={`d-flex align-items-center justify-content-center ${cascading.cardstylecolor}`}>
                                    <svg width="31" height="36" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.1951 9.08478C20.9946 9.45252 21.3445 10.3992 20.9771 11.1995L15.6224 22.8572L16.699 12.5645C16.7624 11.9559 16.4314 11.3742 15.8759 11.1185L10.7707 8.76967L12.8315 16.5138L0.499673 0.0214844L11.2076 4.94854L20.1957 9.08411L20.1951 9.08478Z" fill="#EAECF0"></path><path d="M10.8033 26.9189C10.0037 26.5512 9.65388 25.6045 10.0213 24.8042L15.3759 13.1465L14.2993 23.4392C14.2359 24.0478 14.5669 24.6294 15.1224 24.8852L20.2276 27.234L18.1668 19.4898L30.5 35.9808L19.7921 31.0538L10.8039 26.9175L10.8033 26.9189Z" fill="#EAECF0"></path></svg>
                                      
                                      <span>ADD LIQUIDITY</span>

                                      <svg width="31" height="36" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8049 9.08478C10.0054 9.45252 9.65555 10.3992 10.0229 11.1995L15.3776 22.8572L14.301 12.5645C14.2376 11.9559 14.5686 11.3742 15.1241 11.1185L20.2293 8.76967L18.1685 16.5138L30.5003 0.0214844L19.7924 4.94854L10.8043 9.08411L10.8049 9.08478Z" fill="#EAECF0"></path><path d="M20.1967 26.9189C20.9963 26.5512 21.3461 25.6045 20.9787 24.8042L15.6241 13.1465L16.7007 23.4392C16.7641 24.0478 16.4331 24.6294 15.8776 24.8852L10.7724 27.234L12.8332 19.4898L0.5 35.9808L11.2079 31.0538L20.1961 26.9175L20.1967 26.9189Z" fill="#EAECF0"></path></svg>
                                    </div>
                                <div className={`${cascading.toprow} mt-4`}>
                                    <div className={`${cascading.backsec}`}>
                                        <div className={`${cascading.backbtn}`}>
                                            <Link to="/liquidity">  <FaArrowLeft fill='#fff' fontSize={18} /></Link>
                                        </div>
                                        <div>
                                            {/* <p className={`${cascading.headings}`}>Add Liquidity</p> */}
                                            <p className={`${cascading.desc}`}>Add liquidity to receive LP tokens
                                                <OverlayTrigger
                                                    className={`${cascading.tooltip_style}`}
                                                    key="right"
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id={`tooltip-right`}>
                                                            Liquidity providers earn a 0.17% trading fee on all trades made for that token pair, proportional to their share of the liquidity pool.
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className={`mx-2`} ><IoIosHelpCircleOutline fill='#fff' fontSize={18} /></span>
                                                </OverlayTrigger></p>


                                        </div>

                                    </div>

                                    <div className={`${cascading.iconsec}`}>
                                        <IoMdSettings onClick={() => setSettingshow(true)} />
                                        <MdHistory onClick={() => setRecentshow(true)} />
                                    </div>
                                </div>

                                {pairInfo === ZEROTH_ADDRESS || (!isEmpty(pairInfo) && pairInfo != ZEROTH_ADDRESS && poolA == '0' && poolB == '0') ? <div className={`${cascading.desc_cardsec} mb-4`}>
                                    <p className={`${cascading.descpara}`}><MdOutlineWarning fill='#ffaf1d' /> You are the first liquidity provider.
                                        The ratio of tokens you add will set the price of this pool.
                                        Once you are happy with the rate click supply to review.</p>

                                </div> : ""}
                                <div className={`${cascading.inputsec} mb-4`}>

                                <div className={`${cascading.inputsecrow}`}>

                                        <div className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}>
                                            <button className={`btn ${cascading.currencybtn}`} onClick={() => { setTokenshow(true); setCommontoken(true) }}>
                                                <div className='d-flex align-items-center justify-content-between w-100'>
                                                <div className='d-flex align-items-center'>
                                                 
                                                     {isEmpty(fromToken) ? "From" : fromToken && fromToken?.symbol} 
                                                     {!isEmpty(toToken) ?
                                                     <img src={fileObjectUrl(fromToken?.image)} alt='bnb' className={`img-fluid ms-2 ${cascading.coinimg}`} /> 
                                                     : ""}
                                                     </div>
                                               <FaAngleDown fill='#fff' />
                                               </div>
                                               </button>
                                        </div>

                                        <div className={`${cascading.righsideinout}`}>
                                        <label className={`${cascading.righsideinoutlabel}`}>Amount</label>
                                         
                                            <input type="email" autoComplete={"off"} className={`from-control`} id="exampleInputEmail1" name='fromValue' aria-describedby="emailHelp" placeholder="0.0"
                                                value={isEmpty(fromToken) || isEmpty(fromValue) || isNaN(fromValue) ? '' : toFixedNumber(fromValue)} onChange={(e) => { onChange(e) }} disabled={isEmpty(fromToken)} />
                                        
                                        </div>
                                    </div>
                                    <div className={`${cascading.balancerow} mt-3 mb-0`}>
                                        {userdetail?.walletStatus == 'connect' && !isEmpty(getWalletAddress()) && <p className={`${cascading.inputlabel} mb-0`}>Balance :{isEmpty(fromToken) ? 0 : roundToSignificant(fromToken.balance, 6)}</p>}
                                    
                                        {userdetail?.walletStatus == 'connect' && !isEmpty(getWalletAddress()) && <button className={`btn ${cascading.currencybtnmx}`} onClick={() => { fromMax() }}> Max </button>}
                                    
                                    </div>

                                

                                </div>
                                <div className={`row ${cascading.arrowrow}`} >
                                    <div className={`${cascading.swaparrow}`}>
                                        <FaPlus />
                                    </div>
                                </div>
                                <div className={`${cascading.inputsec} mb-4`}>

                                <div className={`${cascading.inputsecrow}`}>

                                <div className={`${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}>
                                            <button className={`btn ${cascading.currencybtn}`} onClick={() => { setTokenshow2(true); setCommontoken(true) }}>
                                               
                                            <div className='d-flex align-items-center justify-content-between w-100'>
                                                <div className='d-flex align-items-center'>
                                                 
                                               
                                                {isEmpty(toToken) ? "To" : toToken && toToken?.symbol} 
                                                {!isEmpty(toToken) ?
                                                    <img src={fileObjectUrl(toToken?.image)} alt='bnb' className={`img-fluid ${cascading.coinimg} ms-2`} /> : ""}
                                                </div>
                                                <FaAngleDown fill='#fff' />
                                                </div>
                                                </button>
                                        </div>
                                        <div className={`${cascading.righsideinout}`}>
                                        <label className={`${cascading.righsideinoutlabel}`}>Amount</label>
                                           
                                            <input type="email" autoComplete={"off"} className={`from-control`} id="exampleInputEmail1" aria-describedby="emailHelp" name='toValue' placeholder="0.0"
                                                value={isEmpty(toToken) || isEmpty(toValue) || isNaN(toValue) ? '' : toFixedNumber(toValue)} onChange={(e) => { onChange(e) }} disabled={isEmpty(toToken)} />
                                        </div>

                                       


                                    </div>

                                    <div className={`${cascading.balancerow} mt-3 mb-1`}>
                                        {userdetail?.walletStatus == 'connect' && !isEmpty(getWalletAddress()) && <p className={`${cascading.inputlabel} mb-0`}>Balance :{isEmpty(toToken) ? 0 : roundToSignificant(toToken.balance, 6)}</p>}
                                   
                                        {userdetail?.walletStatus == 'connect' && !isEmpty(getWalletAddress()) && <button className={`btn ${cascading.currencybtnmx}`} onClick={() => { toMax() }} > Max </button>}
                                   
                                    </div>

                                   
                                </div>

                                {pairInfo == ZEROTH_ADDRESS ? <div className={`${cascading.valuecardsec} mb-4`}>
                                    <p className={`${cascading.descpara}`}>Initial prices and pool share</p>
                                    <div className={`${cascading.secrow}`}>
                                        <div>
                                            <p className={`${cascading.label}`}>{!isEmpty(toTokenper) && !isNaN(toTokenper) && !isEmpty(toValue) ? roundToSignificant(toTokenper, 6) : "-"}</p>
                                            <p className={`${cascading.labels}`}> {toToken?.symbol} per {fromToken?.symbol}</p>
                                        </div>
                                        <div>
                                            <p className={`${cascading.label}`}> {!isEmpty(fromTokenper) && !isNaN(fromTokenper) && !isEmpty(fromValue) ? roundToSignificant(fromTokenper, 6) : "-"}</p>
                                            <p className={`${cascading.labels}`}>  {fromToken?.symbol} per {toToken?.symbol}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p className={`${cascading.label}`}>  {!isEmpty(poolShare) && !isNaN(poolShare) && !isEmpty(fromValue) && !isEmpty(toValue) ? roundToSignificant(poolShare, 6) : "-"}%</p>
                                        <p className={`${cascading.labels}`}> Share of Pool</p>
                                    </div>






                                </div> : <div className={`${cascading.valuecardsec} mb-4`}>
                                    <p className={`${cascading.descpara}`}>Prices and pool share</p>
                                    <div className={`${cascading.secrow}`}>
                                        <div>
                                            <p className={`${cascading.label}`}>{!isEmpty(toTokenper) && !isNaN(toTokenper) && !isEmpty(toValue) ? roundToSignificant(toTokenper, 6) : "-"}</p>
                                            <p className={`${cascading.labels}`}> {toToken?.symbol} per {fromToken?.symbol}</p>
                                        </div>
                                        <div>
                                            <p className={`${cascading.label}`}> {!isEmpty(fromTokenper) && !isNaN(fromTokenper) && !isEmpty(fromValue) ? roundToSignificant(fromTokenper, 6) : "-"}</p>
                                            <p className={`${cascading.labels}`}>  {fromToken?.symbol} per {toToken?.symbol}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p className={`${cascading.label}`}> {!isEmpty(poolShare) && !isNaN(poolShare) && !isEmpty(fromValue) && !isEmpty(toValue) ? roundToSignificant(poolShare, 6) : "-"}%</p>
                                        <p className={`${cascading.labels}`}> Share of Pool</p>
                                    </div>






                                </div>}

                                {console.log('enable_condition', !isEmpty(toToken) && toTokenApprove == false, !isEmpty(fromToken) && fromTokenApprove == false)}

                                <div className={`${cascading.btnsec}`}>
                                    {console.log(userdetail?.walletStatus == 'connect' && !isEmpty(getWalletAddress()), isEmpty(getWalletAddress()), 'walletCheck_condition')}
                                    {userdetail?.walletStatus !== 'connect' && isEmpty(getWalletAddress()) ?
                                        <button className={`btn ${cascading.connect_btn}`} onClick={() => setWalletmodalshow(true)}>Connect Wallet</button> :
                                        isEmpty(fromToken) && isEmpty(toToken) ?
                                            <button className={`btn ${cascading.connect_btn}`} disabled >Select a token to add your liquidity.</button> :

                                            // Insufficient && Insufficient  ? <button className={`btn ${cascading.connect_btn}`} disabled >{InsufficientText &&InsufficientText}</button> :
                                            toValue > toToken.balance || fromValue > fromToken.balance ?
                                                <button className={`btn ${cascading.connect_btn}`} disabled >
                                                    {fromValue > fromToken.balance ? `Insufficient ${fromToken.symbol} balance` : toValue > toToken.balance ? `Insufficient ${toToken.symbol} balance` : ''}
                                                </button> :
                                                // fromToken?.balance == 0 ? <button className={`btn ${cascading.connect_btn}`} disabled >Insufficient {fromToken?.symbol} balance</button> :

                                                //     toToken?.balance == 0 ? <button className={`btn ${cascading.connect_btn}`} disabled>Insufficient {toToken?.symbol} balance</button> :
                                                (!isEmpty(fromValue) && !isEmpty(toValue) && !isEmpty(fromToken) && !isEmpty(toToken) && fromTokenApprove && toTokenApprove) ?
                                                    <button className={`btn ${cascading.connect_btn}`} onClick={() => { setConfirmSupply(true) }}>Supply</button>

                                                    : !isEmpty(fromToken) && fromTokenApprove == false ? <button className={`btn ${cascading.connect_btn}`}
                                                        onClick={async () => {
                                                            let result = await ApproveToken(fromToken?.address, getRouterAddress());
                                                            setfromTokenApprove(result.status)
                                                        }}>{`Enable ${fromToken?.symbol}`}</button>

                                                        : !isEmpty(toToken) && toTokenApprove == false ? <button className={`btn ${cascading.connect_btn}`}
                                                            onClick={async () => {
                                                                let result = await ApproveToken(toToken?.address, getRouterAddress());
                                                                settoTokenApprove(result.status)
                                                                // if(result){
                                                                //     toastAlert("success", `Approved successfully`, "approve");
                                                                // }
                                                            }}>{`Enable ${toToken?.symbol}`}</button>

                                                            : <button className={`btn ${cascading.connect_btn}`} disabled={isEmpty(fromValue) && isEmpty(toValue)} >Enter an Amount</button>}



                                </div>

                                <div className='mt-3'>
                                <svg width="100%" height="40" viewBox="0 0 600 40" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_198_4495)"><path d="M589.112 2.43879C592.59 0.713634 597.454 1.94477 597.795 5.30097C598.044 7.75539 595.581 9.55112 592.465 9.68443C592.248 9.69227 592.031 9.68443 591.814 9.68443C590.375 9.71579 588.936 9.68443 587.498 9.68443L331.554 9.68443C324.837 9.68443 318.338 9.88831 311.932 11.7938C307.223 13.1975 302.98 15.252 299.223 17.8162C298.199 18.5141 299.71 19.6589 300.734 18.961C307.958 14.0365 317.076 11.3625 326.783 11.2998C327.88 11.2998 328.987 11.2998 330.085 11.2998L588.823 11.2998C589.982 11.2998 591.141 11.3312 592.3 11.2998C598.137 11.143 602.173 6.39095 598.737 2.45447C596.326 -0.305775 591.534 -0.713537 588.036 1.0273C586.887 1.59974 587.964 3.00338 589.112 2.43095V2.43879Z" fill="#475467"></path><path d="M290.469 28.5748C292.694 24.6383 295.974 21.1802 299.979 18.3964C303.985 21.188 307.265 24.6383 309.49 28.5748L299.979 40L290.469 28.5748Z" fill="#475467"></path><path d="M300.735 17.824C292.352 12.1153 282.117 9.68443 271.022 9.68443L38.1571 9.68443C27.9943 9.68443 17.6348 10.1314 7.4823 9.68443C4.66733 9.55896 2.2457 8.06122 2.11118 5.81068C1.88348 2.20354 7.18216 0.63522 10.8458 2.43879C12.0049 3.01122 13.0812 1.60758 11.9221 1.03514C8.40341 -0.697851 3.64279 -0.290087 1.2211 2.46231C-0.972931 4.95594 -0.144989 8.42977 3.04254 10.1863C4.86398 11.19 6.81998 11.2998 8.93118 11.2998L266.81 11.2998C272.43 11.2998 277.925 11.1822 283.441 12.3349C289.351 13.566 294.68 15.8636 299.224 18.961C300.248 19.6589 301.759 18.5141 300.735 17.8162V17.824Z" fill="#475467"></path></g><defs><clipPath id="clip0_198_4495"><rect width="600" height="40" fill="white" transform="matrix(-1 0 0 -1 600 40)"></rect></clipPath></defs></svg>
                                </div>
                            </div>
                            {lpBalance > 0 && pairInfo !== ZEROTH_ADDRESS ?
                                <div className={`${cascading.balancecardsec} mt-4`}>
                                    <p className={`${cascading.head}`}>LP tokens in your wallet</p>
                                    <ul>
                                        <li>
                                            <p><img src={fileObjectUrl(fromToken?.image)} className={`img-fluid ${cascading.coinimg}`} />
                                                <img src={fileObjectUrl(toToken?.image)} className={`img-fluid ${cascading.coinimg}`} />
                                                {/* <OverlayTrigger
                                            className={`${cascading.tooltip_style}`}
                                            key="right"
                                            placement="right"
                                            overlay={
                                                <Tooltip id={`tooltip-right`}>
                                                    Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution.
                                                </Tooltip>
                                            }
                                        >
                                            <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                                        </OverlayTrigger> */}
                                                {`${fromToken?.symbol}-${toToken?.symbol}`}  LP</p>
                                            <p>{roundToSignificant(lpBalance, 6)}</p>
                                        </li>
                                        <li>
                                            <p>Share of Pool:</p>
                                            <p>{!isEmpty(poolShare) && !isNaN(poolShare) ? roundToSignificant(poolShare, 6) : "-"}%</p>
                                        </li>
                                        <li>
                                            <p>Pooled {fromToken?.symbol}:</p>
                                            <p>{roundToSignificant(poolA, 6)}</p>
                                        </li>
                                        <li>
                                            <p>Pooled {toToken?.symbol}:</p>
                                            <p>{roundToSignificant(poolB, 6)}</p>
                                        </li>
                                    </ul>

                                </div>
                                :

                                pairInfo !== ZEROTH_ADDRESS ?
                                    <div className={`${cascading.desccardsec} mt-4`}>
                                        <p className={`${cascading.head}`}>
                                            {/* <img src={iconimg1} className={`img-fluid ${cascading.coinimg}`} /> */}
                                             By adding liquidity you will earn 0.17% of all trades on this pair propotional to your share of the pool. Fees are added to the pool, accure in real time and can be claimed by withdrawing your liquidity.</p>
                                    </div> : ''
                            }
                        </div>

                    </div>


                </div>


            </div>



            <Footer />
            {walletmodalshow && <Walletmodal show={walletmodalshow} onHide={() => setWalletmodalshow(false)} />}
            {recentshow && <Recenthistory show={recentshow} onHide={() => setRecentshow(false)} />}
            {tokenshow && <Selecttoken show={tokenshow} SetToken={(data) => { onFromTokenChange(data) }} commontoken={commontoken} onHide={() => { setTokenshow(false); setCommontoken(false) }} />}
            {tokenshow2 && <Selecttoken show={tokenshow2} SetToken={(data) => { console.log(data, "onToTokenChange"); onToTokenChange(data) }} commontoken={commontoken} onHide={() => { setTokenshow2(false); setCommontoken(false) }} />}

            {settingshow && <Settingsmodal show={settingshow} onHide={() => setSettingshow(false)} />}
            {confirmSupply && <ConfirmSupplymodal show={confirmSupply} supplyData={() => { supplyData() }} supplyObj={obj} pairInfo={pairInfo} onHide={() => setConfirmSupply(false)} />}
            {waitingSupply && <WaitingSupplymodal show={waitingSupply} supplyObj={obj} onHide={() => setWaitingSupply(false)} />}
            {succesSupply && <CompletedSupplymodal show={succesSupply} onHide={() => setSuccesSupply(false)} />}

        </>
    )
}
