import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import store from "./store";
import { Provider } from "react-redux";
import HelperRoute from "./helperRoutes";
// import { Redirect } from 'react-router'
import "bootstrap/dist/css/bootstrap.css";
import Dashboard from "./pages/Dashboard";
import Yieldbooster from "./pages/Yieldbooster";
import Roundtable from "./pages/Roundtable";
import Tabledetail from "./pages/tabledetail";
import Launchpad from "./pages/Launchpad";
import Launchpaddetail from "./pages/Launchpaddetail";
import Launchpadxgrail from "./pages/Launchpadxgrail";
import Landingpage from "./pages/Landingpage";
import Swap from "./pages/Trade/swap";
import Liquidity from "./pages/Trade/liquidity";
import AddLiquidity from "./pages/Trade/addliquidity";
import FindLiquidity from "./pages/Trade/findliquidity";
import Farms from "./pages/Earn/Farms";
import Pools from "./pages/Earn/Pools";
import RemoveLiquidity from "./pages/Trade/removeliquidity";

import Xgrailpools from "./pages/xgrail/Xgrailpools";
import Xgrailpooldetail from "./pages/xgrail/Xgrailpooldetail";
import Createposition from "./pages/xgrail/createposition";
import Positions from "./pages/xgrail/positions";
import Yumremoveliquidity from "./pages/xgrail/yumremoveliquidity";
import Dividents from "./pages/xgrail/Dividents";

import Comingsoon from "./pages/Comingsoon";
import NewLanding from "./pages/NewLanding";
import Privacy from "./pages/privacypolicy";
import Terms from "./pages/terms";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <div>
          <Toaster />
        </div>

        <HelperRoute />

        <Routes>
          {/* <Route exact path="/">
  <Redirect exact
        from="/"
     to="/" /> 
</Route> */}
          <Route exact path="/" element={<NewLanding />} />
          <Route exact path="/newlanding" element={<NewLanding />} />
          <Route exact path="/xflash" element={<Dashboard />} />
          <Route exact path="/yieldbooster" element={<Yieldbooster />} />
          <Route exact path="/bakery" element={<Roundtable />} />
          <Route exact path="/bakerydetail" element={<Tabledetail />} />
          <Route exact path="/launchpad" element={<Launchpad />} />
          <Route exact path="/launchpaddetail" element={<Launchpaddetail />} />
          <Route exact path="/launchpadxflash" element={<Launchpadxgrail />} />

          <Route exact path="/swap" element={<Swap />} />
          <Route exact path="/liquidity" element={<Liquidity />} />
          <Route exact path="/add" element={<AddLiquidity />} />
          <Route exact path="/find" element={<FindLiquidity />} />
          <Route exact path="/remove" element={<RemoveLiquidity />} />
          <Route exact path="/farms" element={<Farms />} />
          <Route exact path="/pools" element={<Pools />} />

          <Route exact path="/xflashpools" element={<Xgrailpools />} />
          <Route exact path="/xflashpooldetail" element={<Xgrailpooldetail />} />
          <Route exact path="/xflashpooldetail/:poolAddress" element={<Xgrailpooldetail />} />
          <Route exact path="/createposition" element={<Createposition />} />
          <Route exact path="/positions" element={<Positions />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/terms" element={<Terms />} />

          <Route
            exact
            path="/xflashremoveliquidity"
            element={<Yumremoveliquidity />}
          />
          <Route exact path="/dividends" element={<Dividents />} />

          <Route exact path="/comingsoon" element={<Comingsoon />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
