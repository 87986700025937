// local

// export default {
//     nativetoken: {
//         56: '',
//         97: '0xB91cE3a9095CD9A01Ab419081c8F63bf6d9ABe2a',
//     },
//     xtoken: {
//         56: '',
//         97: '0x0D3B32a16dab18173696b6cbB267D6fc0018D3a8',
//     },
//     dividends: {
//         56: '',
//         97: '0x51cf7388044bB4C119941973161328aEbB52809b',
//     },
//     multicall:{
//         56: '',
//         97: '0x232e410b1fa58fa37921ad03E5deC6a507cd5CDe',
//     },
//     router:{
//         56: '',
//         97: '0x773212bAea847b7b091eCc2Eb9a96E3b240Af21D'//'0x4E03F9e5B375008d84F54C5feE6f5835843fAE77' //'0x15b9456f3F4c6F147bE2493B45C19f510cf1337f',
//     },
//     factory:{
//         56: '',
//         97: '0x60e6d21F53b5cB53085B71785F26afC0Dd923aa8'//'0xD919BC4Bed9FcA4264dc178D94D2Db762833Dc96' //'0xCAEE07F8A8C1DA4546E864863139137F0951F450',
//         // 97: '0x4E03F9e5B375008d84F54C5feE6f5835843fAE77',
//     },
//     masterChef:{
//         56: '',
//         97:'0x52883c4253b064e3787FD808fdDe6d7d5cEC8bfB'//"0x82928cBB69CED2D7a301c2B468acbD066cF1c3Fc",//'0x99Cc266c865f480d9C21Dbd0C538CFC9c843e4d8'//'0x05990d2619b89BdDa6eD51A3e461A9145487634E' //'0xCde08C466BD78a67f1c0a7b27779aBc012063d25',
//     },
//     SmartChefFactory:{
//         56: '',
//         97:'0x9ce139296D1c1d2704f384f57055fd2c59C12853'
//     },
//     poolAddress:{
//         56:'',
//         97:'0xeAa81cc2174e73b4C4311f4c7182dC746D1B1627'
//     }
// }

// let userB = ((multiper/lpSupply)/maxBoostMultiplier) * (lpAmount * poolAllocation )
//demo
export default {
    nativetoken: {

        //bsc
        56: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45',  //M
        97: '0xEcEb014048E59398eC5BAfE5bc9361AD66C8f218',//'0x6705f12e33896259Dbd9298e1E39CC5AB1BdfEE4',//'0xB91cE3a9095CD9A01Ab419081c8F63bf6d9ABe2a', //T

        //Eth
        1: '', //M
        11155111: '0xbF985636FAa141d222A802CDf51AAA2171F58fFf', //T

        //Avax
        43114: '0xBdf1f2b34B480A05fF3dbF848b5A1efA106804a8', //M
        43113: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    xtoken: {

        //bsc
        56: '0xc5c26A06FF435859d32c0D07c4418d8877b1E1dB', //M
        97: '0x846FE8E884B862F720Cc7728eE42f1a29300AB17',//'0x0D3B32a16dab18173696b6cbB267D6fc0018D3a8', //T

        //Eth
        1: '', //M
        11155111: '', //T

        //Avax
        43114: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45', //M
        43113: '', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    dividends: {

        //bsc
        56: '', //M
        97:'0x87B8FFc91A8805e58e8CF72F6b7D936b0174d3B4',// '0x51cf7388044bB4C119941973161328aEbB52809b', //T

        //Eth
        1: '', //M
        11155111: '', //T

        //Avax
        43114: '', //M
        43113: '', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    multicall: {

        //bsc
        56: '0xc2DC6f4f0a87F2CA78cB0c5b6B3C0B9A5f7be9c6', //M
        97: '0x232e410b1fa58fa37921ad03E5deC6a507cd5CDe', //T

        //Eth
        1: '', //M
        11155111: '0xE8039f1fbbd2531AA92f553De008510CdA2F7EB9', //T

        //Avax
        43114: '0xC049F2a686BeD7a27C7f20c657FBd88446F368F5', //M
        43113: '0x279A2739dF44E9E12968909968985bAd8e5520dB', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    router: {

        //bsc
        56: '0xC4e02705F9513fB3ce120F95aB8a0d8c4F0419bf', //M
        97:'0x173b336C11167fF4141E1D19fE4C5d93837b7FB6', // '0x2bbF56DE1F839495e3F97850E81a61343F74Dc4C',//'0x4ac4356ab7Ef501a184B91061d651Ae4771197FF', //0x0DEb4ccCd468Ca51b56641a40570b24b61087Fb6  //T

        //Eth
        1: '', //M
        11155111: '0x7C41926caEC2490C32f2388C8eBA1B3cAb9D7c21', //T

        //Avax
        43114: '0xD37836684547e0aDed54e3a1831F0A7ff6952719', //M
        43113: '0xC4e02705F9513fB3ce120F95aB8a0d8c4F0419bf', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    factory: {
        //bsc
        56: '0xD37836684547e0aDed54e3a1831F0A7ff6952719', //M
        97:'0x7079DdAfd1DB8B75822Dc1f6F6F034F8c14B3b53', //'0xB61bB68236D35a3365E93570959516F1C84E5E2F', // '0xf6DFAC0edd10Fc06D5B69B8bAE8E287850F6848f',//'0x582fDAE9a8dEfa3C59e989F25e7ADE2Dc5B29538', //T

        //Eth
        1: '', //M
        11155111: '0x4A2d94f887841e760468f9c0Ee7b4a6A870fDf70', //T

        //Avax
        43114: '0xc5c26A06FF435859d32c0D07c4418d8877b1E1dB', //M
        43113: '0xc5c26A06FF435859d32c0D07c4418d8877b1E1dB', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    masterChef: {

        //bsc
        56: '0xC049F2a686BeD7a27C7f20c657FBd88446F368F5', //M
        97: '0xd1d82FDC5Bf119260d5Ae55EAC0d6Ab507A57C63', //'0x72c51E8C460e90D3C13170711f2Dcf9e3D6064B6',//'0x7482005B3271CfbAc64D1928E17194287f02EB6e', //T

        //Eth
        1: '', //M
        11155111: '0x015f856b67133936dd5DA05817D1E509798F5c29', //T

        //Avax
        43114: '0x38EaA8cdEDcbF8B66914E7fb38D04a47ACA49b61', //M
        43113: '0xC049F2a686BeD7a27C7f20c657FBd88446F368F5', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    SmartChefFactory: {

        //bsc
        56: '0x38EaA8cdEDcbF8B66914E7fb38D04a47ACA49b61', //M
        97: '0x036068610C528e3a8D8726c1A7ebDFF39a82Eb12',//'0x758c2861114970b01dD1981fCc19Cb7B64cc956f', //T

        //Eth
        1: '', //M
        11155111: '', //T

        //Avax
        43114: '0x38EaA8cdEDcbF8B66914E7fb38D04a47ACA49b61', //M
        43113: '0x38EaA8cdEDcbF8B66914E7fb38D04a47ACA49b61', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    poolAddress: {

        //bsc
        56: '', //M
        97: '0x6e8F15C4ce04E5ACeE231d158399006775D322D8', //T

        //Eth
        1: '', //M
        11155111: '', //T

        //Avax
        43114: '', //M
        43113: '', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    master: {

        //bsc
        56: '', //M
        97:'0x426e9cD984a65581Ea8e151EF4181E02B00E3C76', //'0x8783CB2493c98D6a3c15f8cEf6dE30Cdb97d7CbF', //T

        //Eth
        1: '', //M
        11155111: '', //T

        //Avax
        43114: '', //M
        43113: '', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T

    },
    NFTPoolFactory:{
         //bsc
         56: '', //M
         97:'0xE59c664FAB555C944F1FdA1c35Dc47927C62c7E1',//'0xDE4B4fb0D099cDf70f9CbBf7449ED4D1223A6e3b',//'0x51f4a11cD70CC739995baD8c77DBaab9AA9609A7',//'0xAB62eDf33fCe6E7A3592F39B196D753384094b42',//'0xDE0B095bE4D78e89c479eBD2853485154076EB87',//'0xf0461Ca4f16AbD880866B1d062E6e19521a5ec1A' ,//'0x9AB7040c94923DF533FE5415d85FFBdE17B2b4d6', //'0x5C5b51624F438fC475961be7c42Cf7fcA5054BE8', //'0x27C32BE4F2a2E0Ca792962f716622573CAC221C7',// '0x4A3AEa0bE14573f09440b2156Aa471Ae67179f37', //'0x4348c2773bDFF951940B7e3C2c9735e955644a59', //T
 
         //Eth
         1: '', //M
         11155111: '', //T
 
         //Avax
         43114: '', //M
         43113: '', //T
 
         //Arbitrum
         42161: '', //M
         421614: '', //T
 
         //zkSync
         324: '', //M
         280: '', //T
    },
    yeildBooster:{
        //bsc
        56: '', //M
        97: '0xe4663C4716FF909149612a25CD46Bca3431fedB0', //T

        //Eth
        1: '', //M
        11155111: '', //T

        //Avax
        43114: '', //M
        43113: '', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T
    },
    positionhelper:{
        //bsc
        56: '', //M
        97:'0xB575933dA19be71d80b6d2B70138E8De8f778145',//'0x8b8aE8C750c7b27932644F7E084263209a57859c', //'0x1263C79c37Cb26C5b5f328EF57FC1Ff479704F3B', //'0x3B2Ad7E204f4f07ca156DFE732EF06E137329FE2',//'0x279A2739dF44E9E12968909968985bAd8e5520dB',  //'0x820B2c1975c464662feb98Cfa0aCD1a2FC66bA18', //T

        //Eth
        1: '', //M
        11155111: '', //T

        //Avax
        43114: '', //M
        43113: '', //T

        //Arbitrum
        42161: '', //M
        421614: '', //T

        //zkSync
        324: '', //M
        280: '', //T
    }
}

export const PROXY = {
	56: "", //bsc mainnet

    97: '0x9b1CB0dA9ac2Feb3107b9027897e311421736F9c',//'0xB9E67D7c3b6957CacF9D835691a45BF62260F7a5',//"0x8eb14f50074D487c62F646F53F629d092B25c0fC", // new bsc testnet

    137: "", //polygon mainnet

	1: "",

	3: "",

	43113 :"", // avalanche testnet 

	42161 : ""  //arbitrum
};


export const XLAUNCH = {
	56: "", //bsc mainnet

    97: '0x317a0AaaDc9c98684cD7e2F8dbA29594bf7C1367',//'0x03A8243291e5EaF4be46580Af3e7b625fC96300A',//'0xb4Eb2e12c13cC4f76DBF196Dee38e0C6C2F8D789',

    137: "", //polygon mainnet

	1: "",

	3: "",

	43113 :"", // avalanche testnet 

	42161 : ""  //arbitrum
};