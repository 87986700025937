import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link as ScrollLink } from "react-scroll";

import cascading from "../assests/css/newheaderstyle.module.css";
// import logo from "../assests/images/headerlogo.png";
import logo from "../assests/images/logo.svg";

const NewHeader = () => {
  const [show, setShow] = useState(false);
  const [nav, setNav] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Scroll Navbar
  const changeValueOnScroll = () => {
    const scrollValue = document?.documentElement?.scrollTop;
    scrollValue > 100 ? setNav(true) : setNav(false);
  };
  window.addEventListener("scroll", changeValueOnScroll);

  return (
    <>
      <header className={`${cascading.navhead}`}>
        <div
          className={`${cascading.header} navbar ${
            nav === true ? "sticky" : ""
          } d-flex align-items-center justify-content-between `}
        >
          <div
            className={`${cascading.headerLeft} d-none d-lg-flex align-items-center h-100`}
          >
            <Link to="/" className="text-decoration-none">
              <img src={logo} alt="logo" className={`${cascading.logo}`} />
            </Link>
            <ul className={`${cascading.ul} d-flex align-items-center h-100`}>
              <li>
                <ScrollLink
                  to="features"
                  spy={true}
                  smooth={true}
                  offset={-120}
                  duration={100}
                  className="text-decoration-none"
                >
                  Features
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  to="trading"
                  spy={true}
                  smooth={true}
                  offset={-120}
                  duration={100}
                  className="text-decoration-none"
                >
                  Trading
                </ScrollLink>
              </li>{" "}
              <li>
                <ScrollLink
                  to="earning"
                  spy={true}
                  smooth={true}
                  offset={-30}
                  duration={100}
                  className="text-decoration-none"
                >
                  Earning
                </ScrollLink>
              </li>{" "}
              <li>
                <ScrollLink
                  to="tokenomics"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={100}
                  className="text-decoration-none"
                >
                  Tokenomics
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  to="roadmap"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={100}
                  className="text-decoration-none"
                >
                  Roadmap
                </ScrollLink>
              </li>
            </ul>
          </div>
          <div className={`${cascading.headerRight} d-none d-lg-block`}>
            <Link to="/swap">
            <button className={`${cascading.notchButton} position-relative`}>
              <span></span>
              <div
                className={`${cascading.text} d-flex align-items-center justify-content-center`}
              >
                Launch App
              </div>
            </button>
            </Link>
          </div>
          <div
            className={`${
              show ? "d-none" : "d-flex"
            }  align-items-center justify-content-between  d-lg-none w-100`}
          >
            <div>
              <Link to="/" className="text-decoration-none">
                <img src={logo} alt="logo" className={`${cascading.logo}`} />
              </Link>
            </div>
            <button onClick={handleShow} className={`${cascading.hamburger}`}>
              <div
                className={`${cascading.border} ${show ? "transLeft" : ""}`}
              ></div>
              <div
                className={`${cascading.border} ${
                  show ? "transRight" : ""
                } mt-2`}
              ></div>
            </button>
          </div>
        </div>
      </header>
      <>
        <Offcanvas
          show={show}
          placement="top"
          onHide={handleClose}
          backdrop="static"
          className="custom_canvas"
        >
          <Offcanvas.Header>
            <div
              className={`${cascading.navhead} d-flex align-items-center justify-content-between w-100`}
            >
              <div>
                <Link to="/" className="text-decoration-none">
                  <img src={logo} alt="logo" className={`${cascading.logo}`} />
                </Link>
              </div>
              <button
                onClick={handleClose}
                className={`${cascading.hamburger} position-relative`}
              >
                <div
                  className={`${cascading.border} ${show ? "transLeft" : ""}`}
                ></div>
                <div
                  className={`${cascading.border} ${
                    show ? "transRight" : ""
                  } mt-2`}
                ></div>
              </button>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div>
              <ul>
                <li>
                  <ScrollLink
                    to="features"
                    spy={true}
                    smooth={true}
                    offset={-120}
                    duration={100}
                    className="text-decoration-none"
                    onClick={handleClose}
                  >
                    Features
                  </ScrollLink>
                </li>{" "}
                <li>
                  <ScrollLink
                    to="trading"
                    spy={true}
                    smooth={true}
                    offset={-120}
                    duration={100}
                    className="text-decoration-none"
                    onClick={handleClose}
                  >
                    Trading
                  </ScrollLink>
                </li>{" "}
                <li>
                  <ScrollLink
                    to="earning"
                    spy={true}
                    smooth={true}
                    offset={-30}
                    duration={100}
                    className="text-decoration-none"
                    onClick={handleClose}
                  >
                    Earning
                  </ScrollLink>
                </li>{" "}
                <li>
                  <ScrollLink
                    to="tokenomics"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={100}
                    className="text-decoration-none"
                    onClick={handleClose}
                  >
                    Tokenomics
                  </ScrollLink>
                </li>
                <li>
                  <ScrollLink
                    to="roadmap"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={100}
                    className="text-decoration-none"
                    onClick={handleClose}
                  >
                    Roadmap
                  </ScrollLink>
                </li>
                <li className="mt-3">
                <Link to="/swap">
            <button className={`${cascading.connectwalletbtn} position-relative`}>
              
                Launch App
            </button>
            </Link>
                </li>
              </ul>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    </>
  );
};

export default NewHeader;
