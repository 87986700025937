import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/positionmodalstyle.module.css';
import toast, { Toaster } from 'react-hot-toast';
import Accordion from 'react-bootstrap/Accordion';
import CopyToClipboard from "react-copy-to-clipboard";
import { FaInfoCircle, FaChevronLeft, FaTrophy, FaCopy, FaAngleUp, FaExternalLinkAlt, FaRegQuestionCircle, FaRegTimesCircle, FaDownload, FaUpload, FaLock, FaRocket, FaCoins, FaCheckCircle } from "react-icons/fa";


import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import bnblogo from '../assests/images/bnb.png';
import coinlogo from '../assests/images/logomini.png';


import { useSelector } from 'react-redux';


import { IoMdClose } from "react-icons/io";

//hooks
import { HarvestPosition } from '../hooks/useNFTpool';
import { getWalletAddress } from '../lib/localStorage';
export default function Harvestpositionmodal(props) {

  const [harverstpositionmodal, setHarvestpositionmodal] = useState(true);

  const { positionrecord } = useSelector((state) => (state.position))
  const {onSuccess, SetsuccessLoader, setError } = props
  const OnHarvest = async()=>{
    try{
      let loaderText = 'Harvesting position.....'
      onSuccess(loaderText, 'Harvest position')
      let result  =  await HarvestPosition(positionrecord.poolAddress,positionrecord.tokenId)
      if(result){
        let successLoaderText = `You've successfully harvested ${positionrecord.dspRewards} of FLASH rewards`
        SetsuccessLoader(successLoaderText, result.transactionHash)
      }else{
        let errorText = `Error ! When harvest position`
        setError(errorText)
      }
    }catch(err){
      console.log(err,'OnHarvest__err')
    }
  }

  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal centered show={harverstpositionmodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
          <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>
          <svg width="21" height="26" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.1951 9.08478C20.9946 9.45252 21.3445 10.3992 20.9771 11.1995L15.6224 22.8572L16.699 12.5645C16.7624 11.9559 16.4314 11.3742 15.8759 11.1185L10.7707 8.76967L12.8315 16.5138L0.499673 0.0214844L11.2076 4.94854L20.1957 9.08411L20.1951 9.08478Z" fill="#EAECF0"></path><path d="M10.8033 26.9189C10.0037 26.5512 9.65388 25.6045 10.0213 24.8042L15.3759 13.1465L14.2993 23.4392C14.2359 24.0478 14.5669 24.6294 15.1224 24.8852L20.2276 27.234L18.1668 19.4898L30.5 35.9808L19.7921 31.0538L10.8039 26.9175L10.8033 26.9189Z" fill="#EAECF0"></path></svg>
            <span className='mx-2'>Harvest Position</span>
            <svg width="21" height="26" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8049 9.08478C10.0054 9.45252 9.65555 10.3992 10.0229 11.1995L15.3776 22.8572L14.301 12.5645C14.2376 11.9559 14.5686 11.3742 15.1241 11.1185L20.2293 8.76967L18.1685 16.5138L30.5003 0.0214844L19.7924 4.94854L10.8043 9.08411L10.8049 9.08478Z" fill="#EAECF0"></path><path d="M20.1967 26.9189C20.9963 26.5512 21.3461 25.6045 20.9787 24.8042L15.6241 13.1465L16.7007 23.4392C16.7641 24.0478 16.4331 24.6294 15.8776 24.8852L10.7724 27.234L12.8332 19.4898L0.5 35.9808L11.2079 31.0538L20.1961 26.9175L20.1967 26.9189Z" fill="#EAECF0"></path></svg>
           
            </h4>
            {/* <h4 className={`${cascading.modaltitle}`}>Harvest Position</h4> */}
            <IoMdClose className={`${cascading.closebtn}`} onClick={() => { props.onHide(); props.onhideposition(); }} />

          </Modal.Header>
          <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>

            <div>
              <div className={`${cascading.imgsec}`}>
                <div className={`${cascading.leftimgsec}`}>
                  <img src={bnblogo} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                  <img src={coinlogo} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                </div>
                <div>
                  <p className={`${cascading.coinname} mb-0`}>{positionrecord.lpsymbol}</p>
                  <p className={`${cascading.coinnamegrey} mb-0`}>#ID-{positionrecord.tokenId}</p>
                </div>

              </div>

              {/* <p className={`${cascading.tokenvalue}`}>$1.03 - <span>20.03%</span> APR</p> */}
              <p className={`${cascading.tokenvaluedesc} mt-3`}>Collect your farming & rewards</p>
              {/* <p className={`${cascading.innerheadwhitetitle} mt-4`}>All Rewards
              </p>
              <p className={`${cascading.tokenvaluedesc} mb-1 text-start`}>Harvest farming and rewards</p> */}
              <p className={`${cascading.innerheadwhitetitle} mt-4`}>spNFT Rewards
              </p>
              <p className={`${cascading.tokenvaluedesc} mb-1 text-start`}>Harvest farming rewards only</p>

              <p className={`${cascading.tokenvaluedesctheme} text-start`}>{positionrecord.YUMRewards} xFLASH</p>
              <p className={`${cascading.tokenvaluedesctheme} text-start`}>{positionrecord.dspRewards} FLASH</p>


              <p className={`${cascading.innerheadwhitetitle} mt-4`}>Rewards Breakdown
              </p>

              <div className='row mt-3 align-items-center'>
                <div className='col-12 col-sm-4 col-md-4'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>FLASH
                    <span className='px-2'>farm</span></p>
                </div>
                <div className='col-12 col-sm-8 col-md-8'>
                  <p className={`mb-0 text-end d-flex align-items-center justify-content-sm-end ${cascading.innerheadwhitesm}`}>
                    {/* <span>($<FaChevronLeft />)</span> */}
                    <FaChevronLeft />{positionrecord.dspRewards}
                    <div className={`${cascading.tropyhytheme} ms-2`}>
                      <FaTrophy />
                    </div>
                  </p>
                </div>
              </div>

              <div className='row mt-3 align-items-center'>
                <div className='col-12 col-sm-4 col-md-4'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>xFLASH
                    <span className='px-2'>farm</span></p>
                </div>
                <div className='col-12 col-sm-8 col-md-8'>
                  <p className={`mb-0 text-end d-flex align-items-center justify-content-sm-end ${cascading.innerheadwhitesm}`}>
                    {/* <span>($<FaChevronLeft />0.001)</span> */}
                    <FaChevronLeft />{positionrecord.YUMRewards}
                    <div className={`${cascading.tropyhyblack} ms-2`}>
                      <FaTrophy />
                    </div>
                  </p>
                </div>
              </div>

            </div>

            <div className='mt-5'>

              <button className={`btn ${cascading.connectwalletbtn}`} type='button' onClick={() => { OnHarvest() }}>Harvest</button>
              <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>

            </div>
            {/* <div className='p-3 text-center'>
<button className={`btn ${cascading.connectwalletbtn}`} type='button'>Close</button>

</div> */}

          </Modal.Body>

        </Modal>
      </div>
    </>
  )
}

