import React, { useState, useEffect } from 'react';
import { IoMdSettings } from "react-icons/io";
import { MdHistory } from "react-icons/md";
import cascading from '../../assests/css/liquidity.module.css';
import Footer from '../../common/footer'
import { FaArrowDown } from "react-icons/fa";
import Header from '../../common/header';
import { Link, useNavigate, } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";
import bnbimg from '../../assests/images/bnb.png'
import Walletmodal from '../../modals/walletmodal';
import { useSelector } from 'react-redux';
import Recenthistory from '../../modals/recenthistory';
import Selecttoken from '../../modals/selecttoken';
import Settingsmodal from '../../modals/settingsmodal';
import BigNumber from 'bignumber.js';
import infoicon from '../../assests/images/infoicon.png'
//hooks
import { GetallPairsLength, GetLiquidityETH } from '../../hooks/useFactory'
import { getTokens, GetCurrencyBalance, multicall } from '../../hooks/useTokens'
import { ApproveToken, checkIsApproved, Balance, TokenInfo } from '../../hooks/useErc20'
import { RemoveLiquidity, RemoveLiquidityETH, RemoveliquidityWithPermit } from '../../hooks/useRouter'
import {roundToSignificant} from '../../hooks/useCommon'

//abi 
import pair_ABI from '../../config/abi/pairAbi.json'
import ERC20_ABI from "../../config/abi/ERC20.json";

//lib 
import fileObjectUrl from '../../lib/img'
import { isEmpty } from "../../lib/isEmpty";
import { toFixedNumber } from "../../lib/FixedNumber"


import { Accordion } from 'react-bootstrap';
import { getWalletAddress } from '../../lib/localStorage';
import { IsCurrency } from '../../hooks/useCommon';


export default function Liquidity() {
    const navigate = useNavigate()
    const [swapsec, setSwapsec] = useState(true)
    const [walletmodalshow, setWalletmodalshow] = useState(false)
    const [recentshow, setRecentshow] = useState(false);
    const [tokenshow, setTokenshow] = useState(false);
    const [settingshow, setSettingshow] = useState(false)
    const userdetail = useSelector((state) => state.admin)
    const [pairLength, setPairLength] = useState(0)
    const [token, setToken] = useState([])
    const [poolShare, setPoolShare] = useState(0);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getPair()
        // GetLiquidityETH()
    }, [])

    // useEffect(()=>{
    //     RemoveliquidityWithPermit()
    // })

    const getPair = async () => {
        try{
        console.log("getPair__call")
        var data = await GetallPairsLength()
        console.log("GetallPairsLength12345", data, Array(data))
        setPairLength(data)
        let result = []
        if (data > 0) {
            setLoading(true)

            for (let i = 0; i < data; i++) {
                let address = await GetLiquidityETH(i)
                let { reserveA, reserveB, tokenA, tokenB, lpBalance, tokenAbal, tokenBbal, totalSupply, userlpBalance } = await getcall(address)
                console.log("tokenA", toFixedNumber(reserveA), toFixedNumber(reserveB))
                let token1 = getTokens().find((val) => (val?.address?.toLowerCase() == tokenA?.toLowerCase()));
                token1 = isEmpty(token1) ?  await TokenInfo(tokenA) : token1

                console.log("token1", token1, totalSupply)
                let token2 = getTokens().find((val) => (val?.address?.toLowerCase() == tokenB?.toLowerCase()));
                token2 = isEmpty(token2) ?  await TokenInfo(tokenB) : token2

                let currencyBal
                // console.log("currencyBal",token1?.symbol == 'BNB' || token2?.symbol == 'BNB')
                // if(token1?.symbol == 'BNB' || token2?.symbol == 'BNB' ){
                if(IsCurrency(token1) || IsCurrency(token2)){
                    currencyBal =  await GetCurrencyBalance()
                    // console.log("currencyBal",currencyBal,token1.symbol == 'BNB' ? { ...token1, 'balance': currencyBal }: { ...token1, 'balance': tokenAbal })
                }
                let obj = {
                    reserveA: reserveA,
                    reserveB: reserveB,
                    token1: IsCurrency(token1) ? { ...token1, 'balance': currencyBal }: { ...token1, 'balance': tokenAbal },
                    token2:IsCurrency(token2) ? { ...token2, 'balance': currencyBal }:{ ...token2, 'balance': tokenBbal },
                    lpBalance: lpBalance,
                    totalSupply: totalSupply,
                    lpaddress: address,
                    // userlpBalance:userlpBalance,
                    poolShare: (lpBalance * 100 / totalSupply).toFixed(2)
                }
                setPoolShare((lpBalance * 100 / totalSupply).toFixed(2))
                if(obj.lpBalance > 0){
                    result.push(obj)
                }
               
                if (i == data - 1) {
                    console.log()
                    setToken(result)
                    setLoading(false)

                }
                console.log("token2", token2)
            }
        } else {
            setLoading(false)
        }

    }
    catch(err){
        console.log("liquidity_errr",err)
    }
    }

    const getcall = async (address) => {
        let calls = [{
            address: address,
            name: 'getReserves'
        }, {
            address: address,
            name: 'token0'
        }, {
            address: address,
            name: 'token1'
        },
        {
            address: address,
            name: 'totalSupply'
        }
        ]
        if (!isEmpty(getWalletAddress())) {
            calls.push({
                address: address,
                name: 'balanceOf',
                params: [getWalletAddress()]
            })
        }
        console.log("calls_callscalls", calls)
        var pair = await multicall(pair_ABI, calls)
        console.log("pair", pair)
        let call = [{
            address: pair[1][0],
            name: 'balanceOf',
            params: [getWalletAddress()]
        }, {
            address: pair[2][0],
            name: 'balanceOf',
            params: [getWalletAddress()]
        }]
        var tokenbalance = await multicall(ERC20_ABI, call)
        console.log("tokenbalance", tokenbalance)
        // var bal = await Balance(address)
        // console.log("bal", bal)

        return {
            reserveA: new BigNumber(pair[0][0]._hex).toNumber() / 10 ** 18,
            reserveB: new BigNumber(pair[0][1]._hex).toNumber() / 10 ** 18,
            tokenA: pair[1][0],
            tokenB: pair[2][0],
            totalSupply: new BigNumber(pair[3][0]._hex).toNumber() / 10 ** 18,
            // userlpBalance: new BigNumber(pair[4]._hex).toNumber() / 10 ** 18,
            lpBalance: isEmpty(getWalletAddress()) ? 0 : new BigNumber(pair[4][0]._hex).toNumber() / 10 ** 18,
            tokenAbal: isEmpty(getWalletAddress()) ? 0 : new BigNumber(tokenbalance[0]).toNumber() / 10 ** 18,
            tokenBbal: isEmpty(getWalletAddress()) ? 0 : new BigNumber(tokenbalance[1]).toNumber() / 10 ** 18,

        }
    }

console.log("token_array",token)

    return (
        <>
            <Header />
            <div className={`${cascading.innerpages} ${cascading.liquiditypage}`}>
                <div className={`container`}>
                    <div className={`row justify-content-center ${cascading.swapcardrow}`}>
                        <div className={`col-12 col-sm-10 col-md-9 col-lg-8 col-xl-7`}>
                            <div className={` ${cascading.cardstyle}`}>
                            <div className={`d-flex align-items-center justify-content-center ${cascading.cardstylecolor}`}>
                                    <svg width="31" height="36" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.1951 9.08478C20.9946 9.45252 21.3445 10.3992 20.9771 11.1995L15.6224 22.8572L16.699 12.5645C16.7624 11.9559 16.4314 11.3742 15.8759 11.1185L10.7707 8.76967L12.8315 16.5138L0.499673 0.0214844L11.2076 4.94854L20.1957 9.08411L20.1951 9.08478Z" fill="#EAECF0"></path><path d="M10.8033 26.9189C10.0037 26.5512 9.65388 25.6045 10.0213 24.8042L15.3759 13.1465L14.2993 23.4392C14.2359 24.0478 14.5669 24.6294 15.1224 24.8852L20.2276 27.234L18.1668 19.4898L30.5 35.9808L19.7921 31.0538L10.8039 26.9175L10.8033 26.9189Z" fill="#EAECF0"></path></svg>
                                      
                                      <span>LIQUIDITY</span>

                                      <svg width="31" height="36" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8049 9.08478C10.0054 9.45252 9.65555 10.3992 10.0229 11.1995L15.3776 22.8572L14.301 12.5645C14.2376 11.9559 14.5686 11.3742 15.1241 11.1185L20.2293 8.76967L18.1685 16.5138L30.5003 0.0214844L19.7924 4.94854L10.8043 9.08411L10.8049 9.08478Z" fill="#EAECF0"></path><path d="M20.1967 26.9189C20.9963 26.5512 21.3461 25.6045 20.9787 24.8042L15.6241 13.1465L16.7007 23.4392C16.7641 24.0478 16.4331 24.6294 15.8776 24.8852L10.7724 27.234L12.8332 19.4898L0.5 35.9808L11.2079 31.0538L20.1961 26.9175L20.1967 26.9189Z" fill="#EAECF0"></path></svg>
                                    </div>
                                <div className={`${cascading.toprow} mt-4`}>
                                    <div>
                                        {/* <p className={`${cascading.headings}`}>Your Liquidity</p> */}
                                        <p className={`${cascading.desc}`}>Remove liquidity to receive tokens back</p>
                                    </div>
                                    <div className={`${cascading.iconsec}`}>
                                        <IoMdSettings onClick={() => setSettingshow(true)} />
                                        <MdHistory onClick={() => setRecentshow(true)} />
                                    </div>
                                </div>
                                <div className={`${cascading.innercontentsec}`}>
                                    {console.log(userdetail?.walletStatus !== 'connect' && !isEmpty(getWalletAddress()), userdetail?.walletStatus, userdetail?.walletStatus == 'connect', !isEmpty(getWalletAddress()), getWalletAddress(), 'condition_cheeck')}
                                    {userdetail?.walletStatus == 'connect' && !isEmpty(getWalletAddress()) ?
                                        <>
                                            {console.log("token_token", token)}

                                            {/* { ?  :""} */}

                                            {loading && loading ?
                                                <p className={`${cascading.labelname} mb-4`}>Loading...</p> :
                                                token && token?.length == 0 ? 
                                                <p className={`${cascading.labelname} mb-4`}>No liquidity found.</p>
                                                    :
                                                    token && token?.map((val, i) => {
                                                        console.log("val_val",val)
                                                        return (
                                                            <div className={`${cascading.poolaccordion}`}>
                                                                {val?.lpBalance !== 0 ?
                                                                    <>
                                                                        <Accordion defaultActiveKey="0">
                                                                            <Accordion.Item eventKey={i}>
                                                                                <Accordion.Header>
                                                                                    <div>
                                                                                        <p className={`${cascading.labelhead}`}><img src={fileObjectUrl(val?.token1?.image)} alt='image' className={`img-fluid ${cascading.coinimg}`} /> <img src={fileObjectUrl(val?.token2?.image)} alt='image' className={`img-fluid ${cascading.coinimg}`} /> {`${val?.token1?.symbol}/${val?.token2?.symbol}`}</p>
                                                                                        <p className={`${cascading.balance}`}> {roundToSignificant(val?.lpBalance,6)}</p>
                                                                                    </div>

                                                                                </Accordion.Header>

                                                                                <Accordion.Body>
                                                                                    <div>
                                                                                        <ul className={`${cascading.poolaccordion_ul}`}>
                                                                                            <li>
                                                                                                {console.log("val?.token1?.image", val?.token1?.image)}
                                                                                                <p className={`${cascading.labelname}`}>
                                                                                                    <img src={fileObjectUrl(val?.token1?.image)} alt='image' className={`img-fluid ${cascading.coinimg} me-2`} />
                                                                                                    Pooled {val?.token1?.symbol}
                                                                                                </p>
                                                                                                <p className={`${cascading.labelname}`}>  {roundToSignificant(val?.reserveA,6)}</p>
                                                                                            </li>
                                                                                            <li>
                                                                                                <p className={`${cascading.labelname}`}>
                                                                                                    <img src={fileObjectUrl(val?.token2?.image)} alt='image' className={`img-fluid ${cascading.coinimg} me-2`} />
                                                                                                    Pooled {val?.token2?.symbol}
                                                                                                </p>
                                                                                                <p className={`${cascading.labelname}`}>{roundToSignificant(val?.reserveB,6)}</p>
                                                                                            </li>
                                                                                            <li>
                                                                                                <p className={`${cascading.labelname}`}>
                                                                                                    Share of pool
                                                                                                </p>
                                                                                                <p className={`${cascading.labelname}`}>{roundToSignificant(val?.poolShare,6)}</p>
                                                                                            </li>
                                                                                        </ul>
                                                                                        {/* {val?.token1?.name !== "BNB" || val?.token2?.name !== "BNB" ?
                                                                        <button className={`btn ${cascading.walletbtn}`} onClick={() => { RemoveLiquidity(val?.token1?.address, val?.token2?.address) }}>Remove</button> :
                                                                        <button className={`btn ${cascading.walletbtn}`} onClick={() => { RemoveLiquidityETH() }}>Remove</button>} */}
                                                                                        <button className={`btn ${cascading.walletbtn}`} onClick={() => { navigate("/remove", { state: { token: val } }) }}>Remove</button>
                                                                                        <button className={`btn ${cascading.transbtn}`} onClick={() => { navigate("/add", { state: { key: 'add', token: val } }) }} > + Add Liquidity instead</button>
                                                                        
                                                                                    </div>
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>

                                                                        </Accordion>
                                                                        <div style={{ color: "white" }}>

                                                                        </div>
                                                                    </>
                                                                    : ""}
                                                            </div>)
                                                    })}



                                            <div className={`${cascading.findbtnsec} mb-4`}>
                                                <p className={`${cascading.labelname}`}>Don't see a pool you joined ?</p>
                                                <button className={`${cascading.findbtn}`} onClick={() => { navigate("/find", { state: { key: 'find', poolShare: poolShare } }) }} >Find other LP tokens</button>
                                            </div>


                                        </> :
                                        <p className={`${cascading.labelname}`}>Connect to a wallet to view your liquidity.</p>
                                    }

                                </div>
                                <div className={`${cascading.btnsec}`}>
                                    <Link to='/add' className={`btn ${cascading.connect_btn}`} >+ Add Liquidity</Link>



                                </div>
                                <div className='mt-3'>
                                <svg width="100%" height="40" viewBox="0 0 600 40" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_198_4495)"><path d="M589.112 2.43879C592.59 0.713634 597.454 1.94477 597.795 5.30097C598.044 7.75539 595.581 9.55112 592.465 9.68443C592.248 9.69227 592.031 9.68443 591.814 9.68443C590.375 9.71579 588.936 9.68443 587.498 9.68443L331.554 9.68443C324.837 9.68443 318.338 9.88831 311.932 11.7938C307.223 13.1975 302.98 15.252 299.223 17.8162C298.199 18.5141 299.71 19.6589 300.734 18.961C307.958 14.0365 317.076 11.3625 326.783 11.2998C327.88 11.2998 328.987 11.2998 330.085 11.2998L588.823 11.2998C589.982 11.2998 591.141 11.3312 592.3 11.2998C598.137 11.143 602.173 6.39095 598.737 2.45447C596.326 -0.305775 591.534 -0.713537 588.036 1.0273C586.887 1.59974 587.964 3.00338 589.112 2.43095V2.43879Z" fill="#475467"></path><path d="M290.469 28.5748C292.694 24.6383 295.974 21.1802 299.979 18.3964C303.985 21.188 307.265 24.6383 309.49 28.5748L299.979 40L290.469 28.5748Z" fill="#475467"></path><path d="M300.735 17.824C292.352 12.1153 282.117 9.68443 271.022 9.68443L38.1571 9.68443C27.9943 9.68443 17.6348 10.1314 7.4823 9.68443C4.66733 9.55896 2.2457 8.06122 2.11118 5.81068C1.88348 2.20354 7.18216 0.63522 10.8458 2.43879C12.0049 3.01122 13.0812 1.60758 11.9221 1.03514C8.40341 -0.697851 3.64279 -0.290087 1.2211 2.46231C-0.972931 4.95594 -0.144989 8.42977 3.04254 10.1863C4.86398 11.19 6.81998 11.2998 8.93118 11.2998L266.81 11.2998C272.43 11.2998 277.925 11.1822 283.441 12.3349C289.351 13.566 294.68 15.8636 299.224 18.961C300.248 19.6589 301.759 18.5141 300.735 17.8162V17.824Z" fill="#475467"></path></g><defs><clipPath id="clip0_198_4495"><rect width="600" height="40" fill="white" transform="matrix(-1 0 0 -1 600 40)"></rect></clipPath></defs></svg>
                                </div>

                            </div>
                        </div>

                    </div>


                </div>


            </div>



            <Footer />
            {walletmodalshow && <Walletmodal show={walletmodalshow} onHide={() => setWalletmodalshow(false)} />}
            {recentshow && <Recenthistory show={recentshow} onHide={() => setRecentshow(false)} />}
            {tokenshow && <Selecttoken show={tokenshow} onHide={() => setTokenshow(false)} />}
            {settingshow && <Settingsmodal show={settingshow} onHide={() => setSettingshow(false)} />}
        </>
    )
}
