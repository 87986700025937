import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/addliquiditystyle.module.css';
import toast, { Toaster } from 'react-hot-toast';
import Accordion from 'react-bootstrap/Accordion';
import CopyToClipboard from "react-copy-to-clipboard";
import { FaRegQuestionCircle, FaArrowRight, FaChevronLeft, FaCopy } from "react-icons/fa";


import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import bnblogo from '../assests/images/bnb.png';
import coinlogo from '../assests/images/logomini.png';

//lib
import fileObjectUrl from '../lib/img';
import { Duration } from '../lib/DateTimeHelper';
import { ZEROTH_ADDRESS } from '../config/env';
import { consolelog } from '../lib/consolelog';

import { useSelector } from 'react-redux';
import { IoMdClose } from "react-icons/io";
import { GetBounsApr, LockPosition,GetMultiplierByLockDuration } from '../hooks/useNFTpool';
import { GetBlockTimeStamp, roundToSignificant } from '../hooks/useCommon';
import { isEmpty } from '../lib/isEmpty';

export default function Lockmodal(props) {

  const [lockmodal, setLockmodal] = useState(true);
  const [poolInfo, setPoolInfo] = useState({})
  const [Lockduration, setLockDuration] = useState(0)
  const [incDisable, setincDisable] = useState(false)
  const [DecDisable, setDecDisable] = useState(false)
  const [days, Setdays] = useState('0')
  const [months, Setmonths] = useState('0')
  const [lockPerc, setLockPerc] = useState('0')
  const [lockBonusApr, setlockBonusApr] = useState('0')
  const [totalApr, settotalApr] = useState('0')
  const [multiper, setmultiper] = useState('0')
  const [errors,setErrors] =useState({})
  const { nftpooldata } = useSelector((state) => (state.nftpool))
  const { positionrecord } = useSelector((state) => (state.position))
  const {onSuccess, SetsuccessLoader, setError } = props

  useEffect(()=>{
    CalculateBonusApr()
  },[lockPerc,poolInfo])

  const CalculateBonusApr = ()=>{
    try{
      let bonusPercentage = parseFloat(lockPerc) + parseFloat(positionrecord?.boostMultiplier)*100
      let BonusApr =  GetBounsApr({
        annualInterestRate:poolInfo.FramBaseApr,
        bonusPercentage:bonusPercentage
      })
      BonusApr = isNaN(BonusApr) ? 0: BonusApr
      setlockBonusApr(BonusApr)
      let totalApr = parseFloat(BonusApr)+parseFloat(poolInfo.FramBaseApr)+parseFloat(poolInfo.SwapFeeApr)
      settotalApr(totalApr)
    }catch(err){
      console.log('CalculateBonusApr__err')
    }
  }

  useEffect(()=>{
    SetMultiperval()
  },[Lockduration,poolInfo])

  const SetMultiperval = async()=>{
    try{
      if(!isEmpty(poolInfo)&&!isEmpty(LockPosition)){
        let multiperVal =  await GetMultiplierByLockDuration(poolInfo?.poolAddress,Lockduration)
        console.log(multiperVal,'SetMultiperval')
        setmultiper(multiperVal)
      }
    
    }catch(err){
      console.log(err,'SetMulitplierval__Err')
    }
  }

  useEffect(() => {
    InitialSetDuration()
  }, [positionrecord, nftpooldata])

  //lockvalidation 
  const validateLock = async(lockDuration)=>{
    try{
      if(parseFloat(positionrecord.lockDuration) > 0){
        let  endTime = parseFloat(positionrecord.startLockTime) + parseFloat(positionrecord.lockDuration);
        let  currentBlockTimestamp = await GetBlockTimeStamp();
        console.log(currentBlockTimestamp,'currentBlockTimestamp',endTime > currentBlockTimestamp)
        if (endTime > currentBlockTimestamp) {
          let condOne  = !(lockDuration >= (endTime- currentBlockTimestamp))
          let condTwo = !(lockDuration > 0)
          console.log(condTwo,'currentBlockTimestamp',condOne)
          if(condOne || condTwo){
            return false
          }else{
            return true
          }
        }
      }else{
        return true
      }
      
    }catch(err){
      console.log(err,'validateLock__err')
    }
  }
 

  //Set initial lock duration
  const InitialSetDuration = ()=>{
    try{
      let PoolInfo = nftpooldata?.find((val) => (val?.poolAddress == positionrecord?.poolAddress))
      setPoolInfo(PoolInfo)


      let months = Duration(positionrecord.lockDuration, 'months')
      let days = Duration(positionrecord.lockDuration, 'days')
      console.log(months, days, 'lockDuration',positionrecord.lockDuration)
      let lockPercn = (positionrecord.lockDuration / PoolInfo.maxLockDuration) * 100
      setLockPerc((lockPercn).toFixed(2))
      days = days % 30
      Setdays(days)
      Setmonths(months)
      setLockDuration(parseFloat(positionrecord.lockDuration))
    }catch(err){
      console.log(err,'InitialSetDuration')
    }
  }

  //change func
  const inCreaseDuration = () => {
    try {
      setErrors({})
      let Time = Lockduration
      let interval = 1 * 86400
      Time = Time + interval
      console.log(!(Time > poolInfo.maxLockDuration),Time, poolInfo.maxLockDuration,'inCreaseDuration')
      if (!(Time > poolInfo.maxLockDuration)) {
        if (Time == poolInfo.maxLockDuration) {
          setincDisable(true)
        }
        let days = Duration(Time, 'days')
        let lockPercn = (Time / poolInfo.maxLockDuration) * 100
        setLockPerc((lockPercn).toFixed(2))
        days = days % 30
        if (days == 0) {
          Setmonths(parseFloat(months) + 1)
          Setdays(0)
        }
        else {
          Setdays(parseFloat(days))
        }
        setDecDisable(false)
        setLockDuration(Time)
      }

    } catch (err) {
      consolelog('inCreaseDuration__err', err, true)
    }
  }

  const DeCreaseDuration = async() => {
    try {
      setErrors({})
      let Time = Lockduration
      let interval = 1 * 86400
      Time = Time - interval
      let isValid = await validateLock(Time)
      if(!isValid){
        setDecDisable(true)
        return false
      }
      if (!(Time < 0)) {
        if (Time == 0) {
          setDecDisable(true)
        }
        let lockPercn = (Time / poolInfo.maxLockDuration) * 100
        setLockPerc((lockPercn).toFixed(2))
        let Days = Duration(Lockduration, 'days')
        Days = Days % 30
        if (Days == 0) {
          Setmonths(parseFloat(months) - 1)
          Setdays(29)
        }
        else {
          Setdays(parseFloat(Days) - 1)
        }
        setincDisable(false)
        setLockDuration(Time)
      }
    } catch (err) {
      consolelog('DeCreaseDuration__err', err, true)
    }
  }

  const maxLockDuration = () => {
    try {
      setErrors({})
      let months = Duration(poolInfo.maxLockDuration, 'months')
      let days = Duration(poolInfo.maxLockDuration, 'days')
      console.log(months, days, 'maxLockDuration')
      let lockPercn = (poolInfo.maxLockDuration / poolInfo.maxLockDuration) * 100
      setLockPerc((lockPercn).toFixed(2))
      days = days % 30
      Setdays(days)
      Setmonths(months)
      setLockDuration(poolInfo.maxLockDuration)
      setincDisable(true)
      setDecDisable(false)

    } catch (err) {
      consolelog('maxLockDuration__err', err, true)
    }
  }

  //onSubmit
  const SetLockPosition = async () => {
    try {
      if(Lockduration <= 0 ){
        let error= {}
        error['Lockduration'] = 'Lock duration must be greater than 0 days'
        setErrors(error)
        return false
      }
      let loaderText = 'Locking position.....'
      onSuccess(loaderText, 'Lock position')
      let result = await LockPosition(positionrecord.poolAddress, positionrecord.tokenId, Lockduration)
      if (result) {
        let successLoaderText = `You've successfully locked for ${days} days your ${positionrecord.lpsymbol} position`
        SetsuccessLoader(successLoaderText, result.transactionHash)
      } else {
        let errorText = `Error ! When locked position`
        setError(errorText)
      }
    } catch (err) {
      console.log(err, 'SetLockPosition__err')
    }
  }

  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal centered show={lockmodal} onHide={() => { props.onHide() }} className={`${cascading.modalstyle}`} backdrop="static">
          <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>
          <svg width="21" height="26" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.1951 9.08478C20.9946 9.45252 21.3445 10.3992 20.9771 11.1995L15.6224 22.8572L16.699 12.5645C16.7624 11.9559 16.4314 11.3742 15.8759 11.1185L10.7707 8.76967L12.8315 16.5138L0.499673 0.0214844L11.2076 4.94854L20.1957 9.08411L20.1951 9.08478Z" fill="#EAECF0"></path><path d="M10.8033 26.9189C10.0037 26.5512 9.65388 25.6045 10.0213 24.8042L15.3759 13.1465L14.2993 23.4392C14.2359 24.0478 14.5669 24.6294 15.1224 24.8852L20.2276 27.234L18.1668 19.4898L30.5 35.9808L19.7921 31.0538L10.8039 26.9175L10.8033 26.9189Z" fill="#EAECF0"></path></svg>
            <span className='mx-2'>Lock Position</span>
            <svg width="21" height="26" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8049 9.08478C10.0054 9.45252 9.65555 10.3992 10.0229 11.1995L15.3776 22.8572L14.301 12.5645C14.2376 11.9559 14.5686 11.3742 15.1241 11.1185L20.2293 8.76967L18.1685 16.5138L30.5003 0.0214844L19.7924 4.94854L10.8043 9.08411L10.8049 9.08478Z" fill="#EAECF0"></path><path d="M20.1967 26.9189C20.9963 26.5512 21.3461 25.6045 20.9787 24.8042L15.6241 13.1465L16.7007 23.4392C16.7641 24.0478 16.4331 24.6294 15.8776 24.8852L10.7724 27.234L12.8332 19.4898L0.5 35.9808L11.2079 31.0538L20.1961 26.9175L20.1967 26.9189Z" fill="#EAECF0"></path></svg>
           
            </h4>
            {/* <h4 className={`${cascading.modaltitle}`}>Lock Position</h4> */}
            <IoMdClose className={`${cascading.closebtn}`} onClick={() => { props.onHide(); props.onhideposition(); }} />

          </Modal.Header>
          <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>

            <div>

              <div className={`${cascading.imgsec}`}>
                <div className={`${cascading.leftimgsec}`}>
                  <img src={bnblogo} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                  <img src={coinlogo} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                </div>
                <div>
                  <p className={`${cascading.coinname} mb-0`}>{positionrecord?.lpsymbol}</p>
                  <p className={`${cascading.coinnamegrey} mb-0`}>#ID-{positionrecord?.tokenId}</p>
                </div>


              </div>


              {/* <p className={`${cascading.tokenvalue}`}>$1.03 - <span>20.03%</span> APR</p> */}
              <p className={`${cascading.tokenvaluedesc} mt-3`}>Provide long-term liquidity to increase your yield</p>





              <p className={`${cascading.innerheadwhitetitle} mt-4`}>Settings
              </p>



              <div className='row mt-4'>
                <div className='col-12 col-md-5'>
                  <p className={`mb-0 ${cascading.innerheadwhite}`}>Lock duration
                  </p>
                  <a href="javascript:void(0)" className={`${cascading.themelink}`} onClick={() => { maxLockDuration() }}>Set Max</a>
                </div>
                <div className='col-12 col-md-7 mt-3 mt-md-0'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <button className={`btn ${cascading.themelink} ${cascading.buttonstyle_ul}`} disabled={DecDisable} onClick={() => { DeCreaseDuration() }}>
                      <FaMinus />
                    </button>
                    <div>
                      <p className={`mb-1 text-center ${cascading.textgreydesc}`}>Months</p>
                      <p className={`mb-0 text-center ${cascading.textwhitedesc}`} >{months}</p>


                    </div>
                    <div>
                      <p className={`mb-1 text-center ${cascading.textgreydesc}`}>Days</p>
                      <p className={`mb-0 text-center ${cascading.textwhitedesc}`} >{days}</p>


                    </div>
                    <button className={`btn ${cascading.themelink} ${cascading.buttonstyle_ul}`} disabled={incDisable} onClick={() => { inCreaseDuration() }}>
                      <FaPlus />
                    </button>
                  </div>
                  <p className={`mb-1 text-end mt-3 ${cascading.textgreydesc}`}>{lockPerc}% lock bonus (x{multiper})</p>
                </div>
                <p className="text-danger f-12 d-block text-left">{errors?.Lockduration}</p>
              </div>




              <p className={`${cascading.innerheadwhitetitle} mt-4`}>Estimates
              </p>


              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Deposit value</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadthemesm} ${cascading.innerheadthemesmbreak}`}>{poolInfo.notinusd ? '':'$'}{isEmpty(poolInfo?.lpPriceInusd) ? 0 :roundToSignificant(parseFloat(positionrecord.amount) * parseFloat(poolInfo.lpPriceInusd),6)}</p>
                </div>
              </div>



              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Swap fees APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadthemesmbreak}`}>{roundToSignificant(poolInfo.SwapFeeApr,4)}%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Farm base APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadthemesmbreak}`}>{roundToSignificant(poolInfo.FramBaseApr,4)}%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Lock bonus APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadthemesmbreak}`}>{roundToSignificant(lockBonusApr,6)}%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadthemesmbreak}`}>{roundToSignificant(totalApr,4)}%</p>
                </div>
              </div>


            </div>





            <div className='mt-4'>


              <button className={`btn ${cascading.connectwalletbtn}`} type='button' onClick={() => { SetLockPosition() }}>Lock</button>
              <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={() => { props.onHide() }}>Cancel</button>

            </div>



            {/* <div className='p-3 text-center'>
<button className={`btn ${cascading.connectwalletbtn}`} type='button'>Close</button>

</div> */}
          </Modal.Body>

        </Modal>
      </div>
    </>
  )
}

