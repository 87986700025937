import React, { useState, useEffect } from 'react';
import cascading from '../assests/css/all.module.css';
import Footer from '../common/footer';
import ReactDatatable from '@ashvin27/react-datatable';
import tick from '../assests/images/tick.png';
import close from '../assests/images/Close.png'
import pfimg from '../assests/images/roundtable.png'
import Header from '../common/header';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import coinicon from "../../src/assests/images/coinicon.png"

//hooks
import { getTotalSalesInfo, UseTokenInfo, getlaunchpaddatalimit, isSaleLive, isUpcoming, DeletePresale } from '../hooks/useContract'
import { GetUsageAllocations, getXLaunchAddress } from '../hooks/useXtoken'
import { getUserWhitelistAmount, getLaunchReceipt } from '../hooks/useBuy';

//lib
import { padToDigits } from '../lib/DateTimeHelper'
import { getWalletAddress } from "../lib/localStorage";
import { isEmpty } from '../lib/isEmpty';

export default function Launchpad() {


    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [allocBal, setAllocBal] = useState(0);
    const [loaded, setisloaded] = useState(false);

    const [proxy, setProxy] = useState([])
    const [original, setOriginal] = useState([])
    const [loadinterval, setLoadinterval] = useState(3)
    const [searchInterval, setSearchInterval] = useState(3)
    const [interval, setInterval] = useState(3)
    const [totalSales, setTotalSales] = useState([])
    const [buyTokenDecimal, setBuyTokenDecimal] = useState(18)
    const [buyToken, setBuyToken] = useState('')
    const [loading, setLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [searchSale, setSearchSale] = useState([])
    const [search, setSearch] = useState('')
    const [searchProxy, setSearchProxy] = useState([])
    const [receiptAdd, setReceiptAdd] = useState('')
    const [searchResults, setSearchResults] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [isSearch, setisSearch] = useState(false);

    console.log(proxy.length, "proxy.length");

    const config = {
        page_size: proxy && proxy.length,
        button: {
            excel: false,
            print: false,
            extra: false,
        },
        // show_filter:false,
        show_length_menu: true,
        length_menu: ['all'],
        show_pagination: false,
        dynamic: true,
        total_record: proxy && proxy.length
    }

    const columns = [
        {
            key: "symbol",
            text: "Name",
            className: "name",
            align: "left",
            sortable: false,
            cell: (record) => {
                return (
                    <>
                        <div className={`${cascading.launchname}`}>
                            <img src={record.logo} alt='img' className={`img-fluid`} />
                            <div>
                                {console.log("record_record", record, buyToken)}
                                <p className={`${cascading.name}`}>{record.symbol}</p>
                                <p className={`${cascading.label}`}>Raising {record.tokensymbol}</p>
                            </div>


                        </div>

                    </>
                );
            }

        },
        {
            key: "hardcap",
            text: "Hardcap",
            className: "hardcap",
            align: "left",
            sortable: true,
            width: 80,
            cell: records => {
                return (
                    <img src={tick} alt='tick' className={`img-fluid`} width={17} height={17} />
                )

            }
        },
        {
            key: "wlstage",
            text: "WL stage",
            className: "wlstage",
            sortable: true,
            width: 80,
            cell: (record) => {
                return (
                    <p>{allocBal > 0 ?
                        <img src={tick} alt='tick' className={`img-fluid`} width={17} height={17} /> :
                        <img src={close} alt='tick' className={`img-fluid`} width={17} height={17} />
                    }
                    </p>


                )

            }
        },
        {
            key: "status",
            text: "Status",
            className: "status",
            align: "left",
            sortable: true,
            cell: (record) => {
                console.log("RECORDS", record)
                return (
                    <p>{record && isSaleLive(parseFloat(record.startTime), parseFloat(record.endTime), record.isPresaleOpen) ? 'Live' :
                        isUpcoming(parseFloat(record.startTime)) ?
                            'Upcoming' :
                            'Closed'
                    }</p>
                )
            }
        },
        {
            key: "total",
            text: "Total raised",
            className: "total",
            sortable: true,
            align: "left",
            cell: (record) => {
                console.log(record, 'total__record')
                return (
                    <p>{parseInt(record.earnedCap) / 10 ** parseInt(buyTokenDecimal)}</p>
                )
            }
        },
        {
            key: "allocation",
            text: "Your allocation",
            className: "allocation",
            sortable: true,
            align: "left",
            cell: () => {
                return (
                    <p>{allocBal}</p>
                )
            }
        },

    ];

    const records = [
        {
            "name": "FLASH-BNB.e",
            "hardcap": "",
            "wlstage": "",
            "status": "Ended",
            "total": "750,000 USDC.e",
            "allocation": "0"
        },
        {
            "name": "FLASH-BNB.e",
            "hardcap": "",
            "wlstage": "",
            "status": "Ended",
            "total": "750,000 USDC.e",
            "allocation": "0"
        },
        {
            "name": "FLASH-BNB.e",
            "hardcap": "",
            "wlstage": "",
            "status": "Ended",
            "total": "750,000 USDC.e",
            "allocation": "0"
        },
        {
            "name": "FLASH-BNB.e",
            "hardcap": "",
            "wlstage": "",
            "status": "Ended",
            "total": "750,000 USDC.e",
            "allocation": "0"
        },
        {
            "name": "FLASH-BNB.e",
            "hardcap": "",
            "wlstage": "",
            "status": "Ended",
            "total": "750,000 USDC.e",
            "allocation": "0"
        },
        {
            "name": "FLASH-BNB.e",
            "hardcap": "",
            "wlstage": "",
            "status": "Ended",
            "total": "750,000 USDC.e",
            "allocation": "0"
        },

    ];


    const SearchChange =async(e)=> {
        let addressRegx = "^(0x)[0-9A-Fa-f]{40}$"
        var pattern = new RegExp(addressRegx)
        const TotalSaleData = await getlaunchpaddatalimit(proxy,0,proxy.length)
        setSearchTerm(e.target.value);
        if (!isEmpty(e.target.value)) {
            let regx = new RegExp(e.target.value, 'i')
            let results = TotalSaleData.filter((val) => {
                return (val?.name?.match(regx) || val?.symbol?.match(regx))
            })
            setisSearch(true)
            setSearchResults(results);
        } else {
            setisSearch(false)
            setSearchResults(totalSales);
        }
        console.log(searchResults, "search")
    };



    const showLoader = () => {
        setisloaded(true);
        document.getElementsByTagName("body")[0].classList.add("overflow_loading")
    }

    const hideLoader = () => {
        setisloaded(false);
        document.getElementsByTagName("body")[0].classList.remove("overflow_loading")
    }


    useEffect(() => {
        totalSaleInfo()
        getAllocations()
    }, [])

    const totalSaleInfo = async () => {
        showLoader()
        console.log('totalSaleInfo')
        setLoading(true)
        const Proxydata = await getTotalSalesInfo();
        console.log("Proxydata", Proxydata);
        // const totalproxydata = Proxydata.filter(data => data._isWhitelisted == true)
        // console.log("totalproxydata", totalproxydata);

        // let trenddata = await gettrendingdisplayhook();
        // console.log("dataa trending", trenddata?.data?.data);
        // if (trenddata?.data?.data?.length > 0)
        // setTrending(trenddata?.data?.data)
        // var trending = trenddata?.data?.data
        // console.log("trendingdataaaaaaaaaaaaaa", trending);

        let newtotalproxy = [];
        Proxydata?.map((val, i) => {
            let singledata = Proxydata.find(e => e?._sale == val.saleaddress);
            if (singledata) {
                newtotalproxy.push(singledata)
            }
        })

        Proxydata?.map((datas, i) => {
            let singlesale = newtotalproxy.find(e => e._sale == datas?._sale);
            if (!singlesale) {
                newtotalproxy.push(datas)
            }
        })


        setProxy(newtotalproxy)
        console.log("trendingdataaaaaaaaaaaaaa", newtotalproxy);
        console.log("total proxy data", Proxydata);
        setOriginal(newtotalproxy)
        const total = await getlaunchpaddatalimit(newtotalproxy, 0, loadinterval);
        console.log("total>>>", total, total.length, newtotalproxy?.useWithToken);
        let Data = []
        for (let i = 0; i < total.length; i++) {
            let val = { ...total[i] }
            let { symbol, tokenDecimal, saleAddress, allocation } = await GetBuytokeninfo(val?.useWithToken)
            console.log('GetBuytokeninfo', saleAddress, val)
            // let amount = await getUserWhitelistAmount(val.saleAddress)
            // val['allocation'] = amount
            val['tokensymbol'] = symbol
            val['tokenDecimal'] = tokenDecimal
            // val['saleAddress'] = saleAddress
            let data = await getLaunchReceipt(val.saleAddress)
            console.log("getLaunchReceipt_data", data)
            let allocate = await getAllocations(data)
            console.log("allocate", allocate)
            val['allocate'] = allocate

            console.log("symbol_tokenDecimal", symbol, tokenDecimal, val)
            Data[i] = { ...val }
            // return val
        }
        // let data =await total.map(async (val) => {

        // })
        // console.log(data,'setTotalSales')
        setTotalSales(Data);
        setSearchResults(Data)
        setInterval(loadinterval);
        setLoading(false)
        hideLoader()

    }


    const GetBuytokeninfo = async (value) => {
        try {
            if (value === "0x0000000000000000000000000000000000000000") {

                // setBuyToken("BNB");
                setBuyTokenDecimal(18)
                return { symbol: 'BNB', tokenDecimal: 18 }
            } else {

                const token = await UseTokenInfo(value);
                console.log("buyToken_buyToken", token, value)
                setBuyTokenDecimal(Number(token?.decimals))
                return { symbol: token?.symbol, tokenDecimal: Number(token?.decimals) }
                // setBuyToken(token?.symbol);

            }
        }
        catch (err) {
            console.log("err", err)
        }
    }


    const loadMore = async () => {
        console.log("Lad more", totalSales.length < proxy.length)
        setIsLoading(true);
        const newly = await getlaunchpaddatalimit(proxy, interval, interval + loadinterval);
        const total = totalSales.concat(newly);
        // await this.sleep(1000)
        console.log("Lad more", total)

        let Data = []
        for (let i = 0; i < total.length; i++) {
            let val = { ...total[i] }
            // let { symbol, tokenDecimal, saleAddress,allocation } = await GetBuytokeninfo(val?.useWithToken)
            // console.log('GetBuytokeninfo',saleAddress,val)
            // let amount = await getUserWhitelistAmount(val.saleAddress)
            // val['allocation'] = amount
            // val['tokensymbol'] = symbol
            // val['tokenDecimal'] = tokenDecimal 
            // val['saleAddress'] = saleAddress
            let data = await getLaunchReceipt(val.saleAddress)
            console.log("getLaunchReceipt_data_Load", data)
            let allocate = await getAllocations(data)
            console.log("allocate_Load", allocate)
            val['allocate'] = allocate

            // console.log("symbol_tokenDecimal", symbol, tokenDecimal,val)
            Data[i] = { ...val }
            // return val
        }
        let result =Data
        if(!isEmpty(searchTerm)){
            let regx = new RegExp(searchTerm, 'i')
            result  = Data.filter((val)=>{
                return (val?.name?.match(regx) || val?.symbol?.match(regx))
            })
    
        }
        setTotalSales(result)
        setSearchResults(result)
        setInterval(interval + loadinterval);
        setIsLoading(false);
    }

    console.log(totalSales, 'loadMore_total')

    //   const loadSearchMore = async() => {
    //     console.log("Lad more", totalSales.length < proxy.length)
    //     setIsLoading(true);
    //     const newly = await getlaunchpaddatalimit(searchProxy,searchInterval,searchInterval + loadinterval);
    //     const total = searchSale.concat(newly);
    //     await this.sleep(1000)
    //     setSearchSale(total)
    //     setSearchInterval(searchInterval + loadinterval);
    //     setIsLoading(false);
    //   }




    // useEffect(() => {
    //     getreceiptAdd()
    // }, [])


    // const getreceiptAdd = async () => {
    //     console.log("saleAdd", saleAddress)
    //     let data = await getLaunchReceipt()
    //     console.log("getreceiptAdd", data)
    //     setReceiptAdd(data)
    //     getAllocations(data)
    // }



    const getAllocations = async (receiptAdd) => {
        let allocate = await GetUsageAllocations(receiptAdd)
        console.log("allocate_bal", allocate)
        setAllocBal(allocate)
        return allocate
    }


    return (
        <>
            <div className={loaded ? 'loader_div' : 'loader_div d-none'} id="loader_div">
                <div className="circle">
                </div>
                <div className="circle_img">
                    <img src={coinicon} className='img-fluid' />
                </div>
            </div>
            <div className={loaded ? 'logo_overlay' : 'logo_overlay_none'}>
                <Header />
                <div className={`${cascading.innerpages} ${cascading.launchpadpage}`}>
                    <div className={`container`}>
                        <div className={`row ${cascading.headrow}`}>
                            <p className={`${cascading.pagehead}`}>Launchpad</p>
                            <p className={`${cascading.pagedesc}`}>Custom-built infrastructure for Multi-chain public sales</p>
                        </div>

                        <div className={`${cascading.tabstyle} mt-3`} id='launchpadtable' >

                            <div className={`${cascading.tablefiltercustom}`}>
                                <input type="search" className="form-control" placeholder="Search in records..." onChange={SearchChange} />
                            </div>

                            <div className={`${cascading.cutomtablereactres} table-responsive`}>
                                <table className={`${cascading.cutomtablereact} ${cascading.customlaunchpad} table`}>
                                    <thead>
                                        <th>Name</th>
                                        <th>Hardcap</th>
                                        <th>WL stage</th>
                                        <th>Status</th>
                                        <th>Total raised</th>
                                        <th>Your allocation</th>
                                    </thead>
                                    <tbody>

                                        {totalSales && totalSales.length == 0 ?
                                            <tr><td colspan={15} className="text-center"><p className={`${cascading.name}`}>There is no data</p></td></tr>
                                            :
                                            searchResults && searchResults?.map((val, index) => {
                                                return <tr className={`${cascading.pointerrow}`} onClick={() => { navigate(`/launchpaddetail/${val.saleAddress}`, { state: val }) }}>
                                                    <td className="name">
                                                        <div className={`${cascading.launchname}`}>
                                                            <img src={val.logo} alt="img" className="img-fluid" /><div>
                                                                <p className={`${cascading.name}`}>{val.symbol}</p>
                                                                <p className={`${cascading.label}`}>Raising {val.tokensymbol}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <img src={tick} alt="tick" className="img-fluid" width="17" height="17" />

                                                    </td>
                                                    <td>
                                                        <img src={tick} alt="tick" className="img-fluid" width="17" height="17" />

                                                    </td>
                                                    <td className="status">{val && isSaleLive(parseFloat(val.startTime), parseFloat(val.endTime), val.isPresaleOpen) ? 'Live' :
                                                        isUpcoming(parseFloat(val.startTime)) ?
                                                            'Upcoming' :
                                                            'Closed'}</td>
                                                    <td className="total">{parseInt(val.earnedCap) / 10 ** parseInt(buyTokenDecimal)}</td>
                                                    <td className="allocation">{isEmpty(val.allocate) ? 0 : val.allocate}</td>
                                                </tr>
                                            })
                                        }



                                    </tbody>
                                </table>
                            </div>


                            {/* 
                        <ReactDatatable
                            // data={data}
                            className={`${cascading.datatablestyle}`}
                            config={config}
                            columns={columns}
                            records={totalSales && totalSales}
                            onRowClicked={(e, record) => {
                                console.log(record, 'record')
                                navigate(`/launchpaddetail/${record.saleAddress}`, { state: record })
                            }}
                            loading={loading ? true : false}
                            length={totalSales.length}
                            total_record={proxy.length}

                        /> */}
                        </div>
                        <div className='text-center'>
                            {totalSales && totalSales.length < proxy.length && !isSearch ?
                                <button className={`btn mt-4 ${cascading.loadbtn}`} onClick={() => { loadMore() }}>{isLoading ? 'Loading...' : 'Load More'}</button>
                                : <></>}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
