import React, { useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/yourwalletstyle.module.css';

import { useDispatch, useSelector } from 'react-redux';
import { FaRegCopy,FaExternalLinkAlt } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import toast, { Toaster } from 'react-hot-toast';
import CopyToClipboard from "react-copy-to-clipboard";

import { getWalletAddress, removeWallteAddress, walletControl } from '../lib/localStorage';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { WALLET_ADDRESS } from '../constants';
import { GetCurrencyBalance } from '../hooks/useTokens';
import { GetChainIndex } from '../hooks/useWeb3';
import { roundToSignificant } from '../hooks/useCommon';
import { CHAINS } from '../config/env';
import { isEmpty } from '../lib/isEmpty';
export default function Yourwallet(props) {
  const dispatch = useDispatch()
  const userdetail = useSelector((state) => state.admin)
  const walletdetail = useSelector((state) => state.wallet)
  const [tabwallet, setTabwallet] = useState(props.statedata)
  const [walletBalance, setwalletBalance] = useState(0)
  const [addLiquidity, setAddLiquidity] = useState("")
  const [removeLiquidity, setRemoveLiquidity] = useState("")
  const [swap, setSwap] = useState("")
  const [approve, setApprove] = useState("")

  const transaction = async () => {
    if (localStorage.getItem('addLiquidity')) {
      setAddLiquidity(localStorage.getItem('addLiquidity'))
    }
    if (localStorage.getItem('removeLiquidity')) {
      setRemoveLiquidity(localStorage.getItem('removeLiquidity'))
    }
    if (localStorage.getItem('swap')) {
      setSwap(localStorage.getItem('swap'))
    }
    if (localStorage.getItem('approve')) {
      setApprove(localStorage.getItem('approve'))
    }
  }
  const walletdis = () => {
    dispatch(walletControl('disconnect'))
    dispatch({
      type: WALLET_ADDRESS,
      payload: ''
    })
    removeWallteAddress()
    sessionStorage.removeItem('wallet')
    sessionStorage.setItem('walletStatus', 'disconnect')
  }

  useEffect(() => {
    fetchBalance()
    transaction()
  }, [walletdetail])

  const fetchBalance = async () => {
    try {
      if (getWalletAddress()) {
        let balance = await GetCurrencyBalance()
        setwalletBalance(balance)
      }

    } catch (err) {
      console.log(err, 'fetchBalance__err')
    }
  }
  const clearAll = async () => {
    localStorage.removeItem("addLiquidity")
    localStorage.removeItem("removeLiquidity")
    localStorage.removeItem('swap')
    localStorage.removeItem('approve');
    setAddLiquidity("")
    setRemoveLiquidity("")
    setSwap("")
    setApprove("")
  }
  return (
    <div className={`${cascading.walletmodalstyle}`}>
      <Modal centered show={props.show} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>
          <svg width="21" height="26" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.1951 9.08478C20.9946 9.45252 21.3445 10.3992 20.9771 11.1995L15.6224 22.8572L16.699 12.5645C16.7624 11.9559 16.4314 11.3742 15.8759 11.1185L10.7707 8.76967L12.8315 16.5138L0.499673 0.0214844L11.2076 4.94854L20.1957 9.08411L20.1951 9.08478Z" fill="#EAECF0"></path><path d="M10.8033 26.9189C10.0037 26.5512 9.65388 25.6045 10.0213 24.8042L15.3759 13.1465L14.2993 23.4392C14.2359 24.0478 14.5669 24.6294 15.1224 24.8852L20.2276 27.234L18.1668 19.4898L30.5 35.9808L19.7921 31.0538L10.8039 26.9175L10.8033 26.9189Z" fill="#EAECF0"></path></svg>
            <span className='mx-2'>Your Wallet</span>
            <svg width="21" height="26" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8049 9.08478C10.0054 9.45252 9.65555 10.3992 10.0229 11.1995L15.3776 22.8572L14.301 12.5645C14.2376 11.9559 14.5686 11.3742 15.1241 11.1185L20.2293 8.76967L18.1685 16.5138L30.5003 0.0214844L19.7924 4.94854L10.8043 9.08411L10.8049 9.08478Z" fill="#EAECF0"></path><path d="M20.1967 26.9189C20.9963 26.5512 21.3461 25.6045 20.9787 24.8042L15.6241 13.1465L16.7007 23.4392C16.7641 24.0478 16.4331 24.6294 15.8776 24.8852L10.7724 27.234L12.8332 19.4898L0.5 35.9808L11.2079 31.0538L20.1961 26.9175L20.1967 26.9189Z" fill="#EAECF0"></path></svg>
           
            </h4>
            <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />
          {/* <button className={`${cascading.closebtn}`} onClick={props.onHide}>X</button> */}
        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          <div className={`${cascading.tabsec}`}>
            <button onClick={() => setTabwallet(true)} className={tabwallet ? `btn ${cascading.tabbtn} ${cascading.active}` : `btn ${cascading.tabbtn}`}>Wallet</button>
            <button onClick={() => setTabwallet(false)} className={!tabwallet ? `btn ${cascading.tabbtn} ${cascading.active}` : `btn ${cascading.tabbtn}`}>Transactions</button>
          </div>
          <div className={`${cascading.tabinnersec}`}>
            {tabwallet ? <>
              <div className={`mb-4`}>
              <p className={`${cascading.label}`}>YOUR ADDRESS</p>
                <div className={`input-group mb-3 mt-3 ${cascading.copyinput}` }>
               
                  <input type="text" class="form-control" value={`${(walletdetail?.walletaddress).substring(0, 5)}...${walletdetail?.walletaddress.substring(38, 42)}`} placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                    <span class="input-group-text" id="basic-addon2">
                    <CopyToClipboard
                        text={walletdetail?.walletaddress}
                        onCopy={() =>
                          toast.success('Address copied')

                        }
                      >
                        <FaRegCopy fill='#fff' />
                      </CopyToClipboard>
                      {/* <FaRegCopy fill='#fff' /> */}
                      </span>
                </div>
                </div>
                <div className={`${cascading.balrow}`}>
                  <p>{CHAINS[GetChainIndex()].SYMBOL} Balance</p>
                  <div className={`${cascading.rightside}`}> <p>{roundToSignificant(walletBalance, 6)}</p>
                  <p style={{ cursor:"pointer" }} className={`${cascading.declabel} ${cascading.hoveritem} mt-3`}
                      onClick={() => {
                        window.open(`${CHAINS[GetChainIndex()].Testnet}/${walletdetail.walletaddress}}`, '_blank');
                      }}>
                      View on BscScan
                      <FaExternalLinkAlt className={`ms-2`}/>
                    </p>
                  {/* <Link to="">View on BscScan <FaExternalLinkAlt /></Link> */}
                  </div>
                 

                </div>



              </> :<>
              {/* <p className={`${cascading.label}`}>RECENT TRANSACTIONS</p>
              <div className={`${cascading.norecent}`}>
                <p className={`${cascading.recentlabel}`}>No recent transactions</p>
              </div> */}
              {
                  isEmpty(addLiquidity) && isEmpty(removeLiquidity) && isEmpty(swap) && isEmpty(approve) ?
                    <p className={`${cascading.recentlabel}`}>No recent transactions</p> : ''
                }
                {!isEmpty(addLiquidity) || !isEmpty(removeLiquidity) || !isEmpty(swap) || !isEmpty(approve) ?
                  <div className={`${cascading.clrsec} mb-4`}>
                    {/* <p className={` ${cascading.label}`}>Recent transactions</p> */}
                    <button className={`ms-auto btn ${cascading.clrbtn} ${cascading.themebtns}`} onClick={() => { clearAll() }}>Clear all</button>
                  </div> : ""}


                {!isEmpty(addLiquidity) ?
                  <p
                    className={`${cascading.translist} ${cascading.translistcenter} ${cascading.hoveritem}`}
                    onClick={() => {
                      window.open(`${CHAINS[GetChainIndex()].TRANSACTION}/${JSON.parse(localStorage.getItem("addLiquidity")).txhash}`, '_blank');
                      // window.location.href=`${CHAINS[GetChainIndex()].TRANSACTION}/${localStorage.getItem("addLiquidity")}`
                    }} >
                    {JSON.parse(localStorage.getItem("addLiquidity")).qureyString}
                    <FaExternalLinkAlt className={`ms-2`} />
                    {/* <TiTick /> */}
                  </p> : ""}

                {!isEmpty(removeLiquidity) ?
                  <p
                    className={`${cascading.translist} ${cascading.translistcenter} ${cascading.hoveritem}`}
                    onClick={() => {
                      window.open(`${CHAINS[GetChainIndex()].TRANSACTION}/${JSON.parse(localStorage.getItem("removeLiquidity")).txhash}`, '_blank');
                      // window.location.href=`${CHAINS[GetChainIndex()].TRANSACTION}/${localStorage.getItem("removeLiquidity")
                    }} >
                    {JSON.parse(localStorage.getItem("removeLiquidity")).qureyString}
                    <FaExternalLinkAlt className={`ms-2`}/>
                    {/* <TiTick /> */}
                  </p> : ""}

                {!isEmpty(swap) ?
                  <p
                    className={`${cascading.translist} ${cascading.translistcenter} ${cascading.hoveritem}`}
                    onClick={() => {
                      window.open(`${CHAINS[GetChainIndex()].TRANSACTION}/${JSON.parse(localStorage.getItem("swap")).txhash}`, '_blank');
                      // window.location.href=`${CHAINS[GetChainIndex()].TRANSACTION}/${localStorage.getItem("swap")}`
                    }} >
                    {JSON.parse(localStorage.getItem("swap")).qureyString}
                    <FaExternalLinkAlt className={`ms-2`} />
                    {/* <TiTick /> */}
                  </p> : ""}

                {!isEmpty(approve) ?
                  <div className='text-center'>
                    <p
                      className={`${cascading.translist} ${cascading.translistcenter} ${cascading.hoveritem}`}
                      onClick={() => {
                        window.open(`${CHAINS[GetChainIndex()].TRANSACTION}/${JSON.parse(localStorage.getItem('approve')).txhash}`, '_blank');
                        // window.location.href=`${CHAINS[GetChainIndex()].TRANSACTION}/${localStorage.getItem("approve")}`
                      }} >
                      {JSON.parse(localStorage.getItem('approve')).qureyString} <FaExternalLinkAlt className={`ms-2`} />
                      {/* <TiTick /> */}
                    </p> </div> : ""}
              
              </>
            }

            </div>

        </Modal.Body>
        {tabwallet && 
        <Modal.Footer className={`${cascading.modalclr} ${cascading.modalfooter}`}>

          <Button className={`${cascading.btnstyle}`} onClick={()=> {walletdis();props.onHide()}}>
            Disconnect Wallet
          </Button>

        </Modal.Footer>
        }
      </Modal>
    </div>
  )
}

