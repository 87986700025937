import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/positionmodalstyle.module.css';
import toast, { Toaster } from 'react-hot-toast';
import Accordion from 'react-bootstrap/Accordion';
import CopyToClipboard from "react-copy-to-clipboard";
import { FaInfoCircle, FaChevronLeft, FaCopy, FaAngleUp, FaExternalLinkAlt, FaRegQuestionCircle, FaRegTimesCircle, FaDownload, FaUpload, FaLock, FaRocket, FaCoins, FaCheckCircle } from "react-icons/fa";


import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import bnblogo from '../assests/images/bnb.png';
import coinlogo from '../assests/images/logomini.png';

import { useSelector } from 'react-redux';



import { IoMdClose } from "react-icons/io";

//lib
import { roundToSignificant } from '../hooks/useCommon';

//hooks
import { ApproveToken, CheckIsApproved } from '../hooks/useTokens';
import { getWalletAddress } from '../lib/localStorage';
import { AddtoPosition, GetBounsApr } from '../hooks/useNFTpool';
import { toFixedNumber } from '../lib/FixedNumber';
import { isEmpty } from '../lib/isEmpty';
import { Duration } from '../lib/DateTimeHelper';
export default function Addpositionmodal(props) {

  const [addpositionmodal, setAddpositionmodal] = useState(true);
  const [amount, setAmount] = useState('')
  const [approve, setApprove] = useState(false)
  const [errors, setErrors] = useState({})
  const [poolInfo, setPoolInfo] = useState({})
  const [lockBonusApr, setlockBonusApr] = useState('0')
  const [totalApr, settotalApr] = useState('0')
  const [depositValinusd, setdepositValinusd] = useState('0')
  const [isApprove, setisApprove] = useState(false)
  const [btnenable, setbtnenable] = useState(false)
  const { onSuccess, SetsuccessLoader, setError } = props
  // console.log(props,'Addpositionmodal')
  const { positionrecord } = useSelector((state) => (state.position))
  const { nftpooldata } = useSelector((state) => (state.nftpool))
  console.log(poolInfo, 'Addpositionmodal')
  useEffect(() => {
    let result = nftpooldata?.find((val) => val?.poolAddress.toLocaleLowerCase() == positionrecord.poolAddress.toLocaleLowerCase())
    setPoolInfo(result)
  }, [positionrecord, nftpooldata])

  useEffect(() => {
    CalculateBonusApr()
  }, [poolInfo, positionrecord])

  const CalculateBonusApr = () => {
    try {
      let bonusPercentage = positionrecord?.lockMultiplier * 100 + positionrecord?.boostMultiplier * 100
      console.log(bonusPercentage, 'lockPerc_useEffect')
      let BonusApr = GetBounsApr({
        annualInterestRate: poolInfo.FramBaseApr,
        bonusPercentage: bonusPercentage
      })
      console.log(BonusApr, 'lockPerc_useEffect')
      BonusApr = isNaN(BonusApr) ? 0 : BonusApr
      setlockBonusApr(BonusApr)
      let totalApr = parseFloat(BonusApr) + parseFloat(poolInfo?.FramBaseApr) + parseFloat(poolInfo?.SwapFeeApr)
      console.log(totalApr, 'totalApr')
      settotalApr(totalApr)
    } catch (err) {
      console.log('CalculateBonusApr__err')
    }
  }
  //onChange func
  const SetMax = () => {
    try {
      setErrors({})
      setAmount(positionrecord.lpBalance)
      IsApproved(positionrecord.lpBalance)
      let depositValinusd = isEmpty(poolInfo.lpPriceInusd) ? 0 : parseFloat(positionrecord.lpBalance) * parseFloat(poolInfo.lpPriceInusd)
      setdepositValinusd(depositValinusd)
    } catch (err) {
      console.log(err, 'SetMax__err')
    }
  }

  const handleChange = (e) => {
    try {
      setErrors({})
      const { name, value } = e.target
      var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
      if (!numbers.test(value) && value !== "") {
        return false
      }
      if (name == 'amount') {
        setAmount(value)
        if (value != '') {
          let depositValinusd = isEmpty(poolInfo.lpPriceInusd) ? 0 : parseFloat(value) * parseFloat(poolInfo.lpPriceInusd)
          setdepositValinusd(depositValinusd)
        }
        IsApproved(value)
      }
    } catch (err) {
      console.log(err, 'onChange__err')
    }
  }

  const IsApproved = async (value) => {
    try {
      let Amount = (value * 10 ** 18).toFixed(0)
      Amount =  toFixedNumber(Amount)
      let reult = await CheckIsApproved(positionrecord.poolAddress, Amount, getWalletAddress(), positionrecord.lpToken)
      if (reult) {
        setApprove(true)
      } else {
        setApprove(false)
      }
    } catch (err) {
      console.log(err, 'IsApproved___err')
    }
  }
  //submit
  const handleSubmit = async () => {
    try {
      let value = {
        amount: amount
      }
      let isValidate = validation(value)
      if (!isEmpty(isValidate)) {
        setErrors(isValidate)
        return true
      }
      let Amount = (amount * 10 ** 18).toFixed(0)
      Amount =  toFixedNumber(Amount)
      let loaderText = 'Adding liquidity.....'
      onSuccess(loaderText, 'Add Liquidity')
      let Result = await AddtoPosition(positionrecord.poolAddress, positionrecord.tokenId, Amount)
      if (Result) {
        let successLoaderText = `You've successfully added ${amount} of ${positionrecord.lpsymbol} liquidity`
        SetsuccessLoader(successLoaderText, Result.transactionHash)
      } else {
        let errorText = `Error ! When Add amount to position`
        setError(errorText)
      }
    } catch (err) {
      console.log(err, 'handleSubmit__err')
    }
  }
  const validation = (value) => {
    try {
      let error = {}
      if (isEmpty(value.amount)) {
        error['amount'] = 'Amount feild is required'
      } else if (parseFloat(value.amount) > parseFloat(positionrecord.lpBalance)) {
        error['amount'] = 'Insufficient balance'
      }
      return error
    } catch (err) {
      console.log(err, 'validation__err')
    }
  }
  const ApproveLp = async () => {
    try {
      let Data = {
        amount: amount
      }
      let isValidate = validation(Data)
      console.log(isValidate,'isValidate')
      if (!isEmpty(isValidate)) {
        setErrors(isValidate)
        return false
      }
      setisApprove(true)
      setbtnenable(true)
      let Amount = (amount * 10 ** 18).toFixed(0)
      Amount =  toFixedNumber(Amount)
      let approve = await ApproveToken(positionrecord.poolAddress, Amount, getWalletAddress(), positionrecord.lpToken)
      if (approve) {
        setisApprove(false)
        setbtnenable(false)
        setApprove(true)
      } else {
        setisApprove(false)
        setbtnenable(false)
        setApprove(false)
      }
    } catch (err) {
      setisApprove(false)
      setbtnenable(false)
      console.log(err, 'ApproveLp__err')
      setApprove(false)
    }
  }
  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal centered show={addpositionmodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
          <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>
          <svg width="21" height="26" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.1951 9.08478C20.9946 9.45252 21.3445 10.3992 20.9771 11.1995L15.6224 22.8572L16.699 12.5645C16.7624 11.9559 16.4314 11.3742 15.8759 11.1185L10.7707 8.76967L12.8315 16.5138L0.499673 0.0214844L11.2076 4.94854L20.1957 9.08411L20.1951 9.08478Z" fill="#EAECF0"></path><path d="M10.8033 26.9189C10.0037 26.5512 9.65388 25.6045 10.0213 24.8042L15.3759 13.1465L14.2993 23.4392C14.2359 24.0478 14.5669 24.6294 15.1224 24.8852L20.2276 27.234L18.1668 19.4898L30.5 35.9808L19.7921 31.0538L10.8039 26.9175L10.8033 26.9189Z" fill="#EAECF0"></path></svg>
            <span className='mx-2'>Add Position</span>
            <svg width="21" height="26" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8049 9.08478C10.0054 9.45252 9.65555 10.3992 10.0229 11.1995L15.3776 22.8572L14.301 12.5645C14.2376 11.9559 14.5686 11.3742 15.1241 11.1185L20.2293 8.76967L18.1685 16.5138L30.5003 0.0214844L19.7924 4.94854L10.8043 9.08411L10.8049 9.08478Z" fill="#EAECF0"></path><path d="M20.1967 26.9189C20.9963 26.5512 21.3461 25.6045 20.9787 24.8042L15.6241 13.1465L16.7007 23.4392C16.7641 24.0478 16.4331 24.6294 15.8776 24.8852L10.7724 27.234L12.8332 19.4898L0.5 35.9808L11.2079 31.0538L20.1961 26.9175L20.1967 26.9189Z" fill="#EAECF0"></path></svg>
           
            </h4>
            {/* <h4 className={`${cascading.modaltitle}`}>Add Position</h4> */}
            <IoMdClose className={`${cascading.closebtn}`} onClick={() => { props.onHide(); props.onhideposition(); }} />

          </Modal.Header>
          <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>

            <div>

              <div className={`${cascading.imgsec}`}>
                <div className={`${cascading.leftimgsec}`}>
                  <img src={bnblogo} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                  <img src={coinlogo} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                </div>
                <div>
                  <p className={`${cascading.coinname} mb-0`}>{positionrecord.lpsymbol}</p>
                  <p className={`${cascading.coinnamegrey} mb-0`}>#ID-{positionrecord.tokenId}</p>
                </div>


              </div>


              {/* <p className={`${cascading.tokenvalue}`}>$1.03 - <span>20.03%</span> APR</p> */}
              <p className={`${cascading.tokenvaluedesc} mt-3`}>Deposit more into this spNFT to increase your yield.</p>





              <p className={`${cascading.innerheadwhitetitle} mt-4`}>Amount
              </p>
              <p className={`${cascading.tokenvaluedesc} text-start`}>You need to own {positionrecord.lpsymbol} LP tokens to directly add more liquidity to this position. If that's not the case, head to the liquidity page first.</p>


              <div className={`${cascading.inputsec} my-3`}>
                <div className={`input-group mb-3`}>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder="0"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={amount}
                    name='amount'
                    onChange={(e) => { handleChange(e) }}
                  />
                  <div className={`input-group-append`} >
                    <button
                      className={` ${cascading.inputspan}`}
                      id="basic-addon1"
                      onClick={() => {
                        SetMax()
                      }}
                    >
                      Max
                    </button>
                  </div>
                </div>
                <p className="text-danger f-12 d-block text-left">{errors?.amount}</p>
                <p className={`${cascading.balsec}`}>balance : {positionrecord.lpBalance} {positionrecord.lpsymbol}</p>
              </div>

              <p className={`${cascading.innerheadwhitetitle} mt-4`}>Estimates
              </p>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Deposit Value</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadthemesm} ${cascading.innerheadthemesmbreak}`}>{poolInfo.notinusd ? '' : '$'} {roundToSignificant(depositValinusd, 6)}</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Swap fees APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadthemesmbreak}`}>{roundToSignificant(poolInfo?.SwapFeeApr, 4)}%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Farm base APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadthemesmbreak}`}>{roundToSignificant(poolInfo?.FramBaseApr, 4)}%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Lock bonus APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadthemesmbreak}`}>{roundToSignificant(lockBonusApr, 4)}%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadthemesm} ${cascading.innerheadthemesmbreak}`}>{roundToSignificant(totalApr, 4)}%</p>
                </div>
              </div>

              <div className={`${cascading.warning_border} mt-4`}>
                <div className='d-flex align-items-start justify-content-start'>
                  <FaInfoCircle className={`${cascading.themeicon}`} />
                  <span className={`${cascading.label}`}>By making a new deposit on this position, you will renew its lock for {Duration(positionrecord.lockDuration, 'days')} days from now.</span>
                </div>

              </div>
            </div>





            <div className='mt-4'>


              {
                isEmpty(amount) ?
                  <button
                    disabled={true}
                    className={`btn ${cascading.connectwalletbtn}`}
                    type='button'
                  >
                    Enter amountss
                  </button>
                  :
                  approve ?
                    <button
                      className={`btn ${cascading.connectwalletbtn}`}
                      type='button'
                      onClick={() => {
                        // props.onHide();
                        // props.onSuccess();
                        handleSubmit()
                      }}
                    >
                      Add Liquidity
                    </button>
                    :
                    <button
                      className={`btn ${cascading.connectwalletbtn}`}
                      type='button'
                      onClick={() => {
                        ApproveLp()
                      }}
                      disabled={btnenable}
                    >
                      {isApprove ? 'Approving...':'Approve'}
                    </button>
              }
              <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>

            </div>



            {/* <div className='p-3 text-center'>
<button className={`btn ${cascading.connectwalletbtn}`} type='button'>Close</button>

</div> */}







          </Modal.Body>

        </Modal>
      </div>
    </>
  )
}

