import React, { useState, useEffect } from 'react'
import cascading from '../assests/css/all.module.css';
import { Button, ButtonGroup } from 'react-bootstrap';
import { BiRightArrowAlt } from "react-icons/bi";
import { useSelector } from 'react-redux';

//lib
import { toFixedNumber } from '../lib/toFixedOf'
import { getWalletAddress } from '../lib/localStorage'
import { Duration } from '../lib/DateTimeHelper';

//hooks
import { ApproveToken, CheckIsApproved } from '../hooks/useNativeToken';
import { consolelog } from '../lib/consolelog';
import { getXAddress, ConvertToken, getXtokenInfo, RedeemToken, GetTokenOut, getXtokenusage } from '../hooks/useXtoken';
import { isEmpty } from '../lib/isEmpty';

//modals
import ComingSoonmodal from '../modals/comingSoonmodal';
import { roundToSignificant } from '../hooks/useCommon';

//validation 
import { validateAmount } from '../validation/AmountValidation';


export default function Approvecard(props) {

    // const [duration, setDuration] = useState([{
    //     time: "1M"
    // },
    // {
    //     time: "2M"
    // },
    // {
    //     time: "3M"
    // },
    // {
    //     time: "4M"
    // },
    // {
    //     time: "5M"
    // },
    // {
    //     time: "6M"
    // }])

    //selector 
    const { nativebalance } = useSelector((state) => state.native)
    console.log(nativebalance, 'nativebalance')
    // const { nativebalance } = useSelector((state) => state.wallet)
    const { xtokenbalance } = useSelector((state) => (state.xtoken))
    //state 
    const [convertAmount, setConvertAmount] = useState('0')
    const [redeemAmount, SetredeemAmount] = useState('0')
    const [approve, setApprove] = useState(false)
    const [btnenable, setBtnenable] = useState(true);
    const [selecttab, setSelecttab] = useState(true);
    const [activebtn, setActivebtn] = useState(0)
    const [duration, setDuration] = useState(0)
    const [redeePercent, setredeePercent] = useState('0')
    const [XtokenDetail, setXtokenDetail] = useState({})
    const [incDisable, setincDisable] = useState(false)
    const [DecDisable, setDecDisable] = useState(false)
    const [days, Setdays] = useState('0')
    const [months, Setmonths] = useState('0')
    const [OutPut, setOutPut] = useState(0)
    const [modalcoming, setmodalcoming] = useState(false);
    const [loader, setLoader] = useState(false)
    const [errors, seterrors] = useState({})

    const [isApprove, setIsApprove] = useState(false)
    //useEffect
    useEffect(() => {
        fetchDatas()

    }, [])
    useEffect(() => {
        getOutput()
    }, [redeemAmount, duration])
    //get function
    const fetchDatas = async () => {
        try {
            let XtokenDetails = await getXtokenInfo()
            if (!isEmpty(XtokenDetails)) {
                setXtokenDetail(XtokenDetails)
                setredeePercent(XtokenDetails.minRedeemRatio)
                setDecDisable(true)
                Setdays(Duration(XtokenDetails.minRedeemDuration, 'days'))
                Setmonths(Duration(XtokenDetails.minRedeemDuration, 'months'))
                setDuration(XtokenDetails.minRedeemDuration)
            }
            consolelog('XtokenDetails', XtokenDetails, true)
        } catch (err) {
            consolelog('fetchDatas__err', err, true)
        }

    }

    const getOutput = async () => {
        try {
            let amount = toFixedNumber(redeemAmount * 10 ** 18).toString()
            let Durations = duration
            let account = getWalletAddress()
            let outAmount = await GetTokenOut(amount, Durations, account)
            let percen = (outAmount * 100) / redeemAmount
            console.log(percen, 'getOutput__percen', outAmount, redeemAmount)
            setOutPut(outAmount)
            if (!isNaN(percen)) {
                setredeePercent(parseFloat(percen).toFixed(0))
            }
        } catch (err) {
            consolelog('getOutput__err', err, true)
        }
    }

    const percentCal = (val) => {
        return parseFloat(val) / 100
    }

    //OnChange
    const handelchange = (e) => {
        try {
            const { value, id } = e.target
            seterrors({})
            var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
            if (!numbers.test(value) && value !== "") {

                return false;
            }
            if (id == 'convertAmount') {
                setConvertAmount(value)
                iSApproved(value)
            }
            if (id == 'redeemAmount') {
                SetredeemAmount(value)
            }
            if (id == 'months') {
                if (value == '') {
                    Setmonths('')
                    return
                }
                let seconds = value * 2628000
                if (seconds > XtokenDetail.maxRedeemDuration) {
                    return false
                }
                let days = Duration(seconds, 'days')
                let months = Duration(seconds, 'months')
                // console.log(months, days, 'months')
                // days = (days % 30).toFixed(0)
                Setdays(0)
                Setmonths(months)
                setDuration(seconds)
            }
            if (id == 'days') {
                if (value == '') {
                    Setdays('')
                    return
                }
                consolelog('handelchange', { value, id }, true)
                let seconds = parseFloat(value) * 86400
                if (seconds > XtokenDetail.maxRedeemDuration) {
                    return false
                }
                let days = Duration(seconds, 'days')
                days = days % 30
                let months = Duration(seconds, 'months')
                console.log(months, 'months')
                if (parseFloat(value) >= 30) {
                    Setmonths(months)
                } else {
                    Setmonths(0)
                }
                Setdays(days)

                setDuration(seconds)
            }

            if (e.target.value.length > 0) {
                setBtnenable(false)
            }
            else {
                setBtnenable(true)
            }
        } catch (err) {
            consolelog("handelchange__err", err, true)
        }
    }

    const inCreaseDuration = () => {
        try {
            let Time = duration
            let interval = 1 * 86400
            Time = Time + interval
            if (!(Time > XtokenDetail.maxRedeemDuration)) {
                if (Time == XtokenDetail.maxRedeemDuration) {
                    setincDisable(true)
                }
                let days = Duration(Time, 'days')
                days = days % 30
                if (days == 0) {
                    Setmonths(parseFloat(months) + 1)
                    Setdays(0)
                }
                else {
                    Setdays(parseFloat(days))
                }
                setDecDisable(false)
                setDuration(Time)
            }

        } catch (err) {
            consolelog('inCreaseDuration__err', err, true)
        }
    }

    const DeCreaseDuration = () => {
        try {
            let Time = duration
            let interval = 1 * 86400
            Time = Time - interval
            if (!(Time < XtokenDetail.minRedeemDuration)) {
                if (Time == XtokenDetail.minRedeemDuration) {
                    setDecDisable(true)
                }
                let Days = Duration(duration, 'days')
                Days = Days % 30
                if (Days == 0) {
                    Setmonths(parseFloat(months) - 1)
                    Setdays(29)
                }
                else {
                    Setdays(parseFloat(Days) - 1)
                }
                setincDisable(false)
                setDuration(Time)
            }
        } catch (err) {
            consolelog('DeCreaseDuration__err', err, true)
        }
    }

    const maxRedeemDuration = () => {
        try {
            let months = Duration(XtokenDetail.maxRedeemDuration, 'months')
            let days = Duration(XtokenDetail.maxRedeemDuration, 'days')
            days = days % 30
            Setdays(days)
            Setmonths(months)
            setDecDisable(false)
            setincDisable(true)
            setDuration(XtokenDetail.maxRedeemDuration)

        } catch (err) {
            consolelog('maxRedeemDuration__err', err, true)
        }
    }

    const iSApproved = async (convertAmount) => {
        try {
            let spenderAddress = getXAddress()
            let account = getWalletAddress()
            let amount = toFixedNumber(parseFloat(convertAmount) * 10 ** 18).toString()
            let isApproved = await CheckIsApproved(spenderAddress, amount, account)
            if (isApproved) {
                setApprove(true)
                return true
            } else {
                setApprove(false)
            }
        } catch (err) {
            consolelog("iSApproved__err", err, true)
        }
    }
    //submit function
    const ApproveCall = async (e) => {
        try {
            setBtnenable(true)
            setIsApprove(true)
            let Data = {
                amount: convertAmount
            }
            let isValidate = validateAmount(Data, nativebalance)
            if (!isEmpty(isValidate)) {
                let error = { ...errors }
                error['convertAmount'] = isValidate.amount
                seterrors(error)
                return false
            }
            let spenderAddress = getXAddress()
            let amount = (convertAmount * 10 ** 18).toFixed(0)
            amount = toFixedNumber(amount)
            // let amount = toFixedNumber(parseFloat(convertAmount) * 10 ** 18).toString()
            let account = getWalletAddress()
            let isApproved = await CheckIsApproved(spenderAddress, amount, account)
            if (isApproved) {
                setBtnenable(false)
                setIsApprove(false)
                setApprove(true)
                return true
            }
            let approve = await ApproveToken(spenderAddress, amount, account)
            if (approve) {
                setBtnenable(false)
                setIsApprove(false)
                setApprove(true)
            } else {
                setBtnenable(false)
                setIsApprove(false)
            }
        } catch (err) {
            setBtnenable(false)
            setIsApprove(false)
            consolelog("Approvefunc__err", err, true)
        }
    }

    const ConvertCall = async () => {
        try {
            setBtnenable(true)
            let Data = {
                amount: convertAmount
            }
            let isValidate = validateAmount(Data, nativebalance)
            if (!isEmpty(isValidate)) {
                let error = { ...errors }
                error['convertAmount'] = isValidate.amount
                seterrors(error)
                return false
            }
            setLoader(true)
            // let amount = toFixedNumber(parseFloat(convertAmount) * 10 ** 18).toString()
            let amount = (convertAmount * 10 ** 18).toFixed(0)
            amount = toFixedNumber(amount)
            let account = getWalletAddress()
            let approve = await ConvertToken(amount, account)
            if (approve) {
                setBtnenable(false)
                setLoader(false)
                setConvertAmount('0')
            } else {
                setBtnenable(false)
                setLoader(false)
            }
        } catch (err) {
            setBtnenable(false)
            consolelog("ConvertCall__err", err, true)
            setLoader(false)
        }
    }

    const RedeemCall = async () => {
        try {
            setBtnenable(true)
            let Data = {
                amount: redeemAmount
            }
            let isValidate = validateAmount(Data, xtokenbalance)
            if (!isEmpty(isValidate)) {
                let error = { ...errors }
                error['redeemAmount'] = isValidate.amount
                seterrors(error)
                return false
            }
            setLoader(true)
            let amount = toFixedNumber(parseFloat(redeemAmount) * 10 ** 18).toString()
            let account = getWalletAddress()
            let Duration = duration
            console.log(Duration, 'RedeemCall__Duration')
            let redeem = await RedeemToken(amount, Duration, account)
            if (redeem) {
                setBtnenable(false)
                setLoader(false)
                SetredeemAmount('0')
            } else {
                setBtnenable(false)
                setLoader(false)
            }
            consolelog('RedeemCall', redeem, true)
        } catch (err) {
            setBtnenable(false)
            consolelog('RedeemCall__err', err, true)
            setLoader(false)
        }
    }
    return (
        <div className={`${cascading.approvecard} mb-4`}>
            <div className={`${cascading.toptab}`}>
                <div onClick={() => setSelecttab(true)} className={selecttab ? `${cascading.tabsec} ${cascading.activetab} col-6` : `${cascading.tabsec} col-6`}>
                    <p className={`${cascading.tabname}`}>Convert</p>
                </div>
                <div onClick={() => setSelecttab(false)} className={!selecttab ? `${cascading.tabsec} ${cascading.activetab} col-6` : `${cascading.tabsec} col-6`}>
                    <p className={`${cascading.tabname}`}>Redeem</p>
                </div>
            </div>
            <div className={`${cascading.innercard}`}>
                <p className={`${cascading.head}`}>
                    {selecttab ? "Get xFLASH" : "Redeem FLASH"}
                </p>
                <p className={`${cascading.desc}`}>
                    {selecttab ?
                        "Unlock bonus rewards and exclusive benefits by converting your FLASH to xFLASH." :
                        `Redeem your xFLASH back into xFLASH over a vesting period of ${Duration(XtokenDetail.minRedeemDuration, 'days')} days (1: ${percentCal(XtokenDetail.minRedeemRatio)} ratio) to ${Duration(XtokenDetail.maxRedeemDuration, 'months')} months (1 :  ${percentCal(XtokenDetail.maxRedeemRatio)} ratio).`}
                </p>
                <div className={`${cascading.inputsec} my-3`}>
                    <div className={`input-group mb-3`}>
                        <input
                            type="text"
                            autoComplete={"off"}
                            className={`form-control`}
                            id={selecttab ? 'convertAmount' : 'redeemAmount'}
                            placeholder="0"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={selecttab ? convertAmount : redeemAmount}
                            onChange={handelchange}
                        />
                        <div className={`input-group-append`} >
                            <button className={` ${cascading.inputspan}`} id="basic-addon1"
                                onClick={() => {
                                    if (selecttab) {
                                        setConvertAmount(nativebalance)
                                        setBtnenable(false)
                                    } else {
                                        SetredeemAmount(xtokenbalance)
                                        setBtnenable(false)
                                    }
                                }}>
                                Max
                            </button>
                        </div>
                    </div>
                    <p className="text-danger f-12 d-block text-left">{selecttab ? errors?.convertAmount : errors?.redeemAmount}</p>
                    <p className={`${cascading.balsec}`}>{selecttab ? `balance : ${roundToSignificant(nativebalance, 6)} FLASH` : `Wallet balance : ${roundToSignificant(xtokenbalance, 6)} xFLASH`}</p>
                </div>
                {selecttab == false &&
                    <>
                        <div className={`${cascading.redmsec}`}>
                            <div className="">
                                <p className={`${cascading.redmlabel}`}>Redeem duration</p>
                                <p className={`${cascading.redmbtn}`} onClick={() => { maxRedeemDuration() }}>Max</p>

                            </div>
                            <div className={`${cascading.grpbtnsec}`}>
                                {/* <input className="selectcount themeselect  w-full"
                                type='text'
                                name="tpPip"
                                min='0'
                                value={tpPip}
                                onChange={handleChange}
                            /> */}
                                {/* <div className='input-group-append font_sm_ap' onClick={() => { increasetpPip('takeprofit') }}>
                                <i className='fa fa-plus' />
                            </div>
                            <div className='input-group-append font_sm_ap' onClick={() => { decreasetpPip('takeprofit') }}>
                                <i className='fa fa-minus' />
                            </div> */}


                                <div className={`${cascading.inputsec}`}>
                                    <Button variant="secondary" id={'0'} disabled={DecDisable} onClick={() => { DeCreaseDuration() }}>-</Button>
                                    <div className={`${cascading.inpt_rows}`}>
                                        <p>Months</p>
                                        <input className="selectcount themeselect  w-full" autoComplete={"off"} type='text' id='months' value={months} onChange={handelchange} />
                                    </div>
                                    <div className={`${cascading.inpt_rows}`}>
                                        <p>Days</p>
                                        <input className="selectcount themeselect  w-full" autoComplete={"off"} type='text' id='days' value={days} onChange={handelchange} />
                                    </div>
                                    <Button variant="secondary" disabled={incDisable} id={'0'} onClick={() => { inCreaseDuration() }}>+</Button>
                                </div>



                                {/* <ButtonGroup aria-label="Basic example">
                                {duration.map((e, i) => (
                                    <Button variant="secondary" className={activebtn == i ? `${cascading.activebtn}` : ""} id={i} onClick={() => setActivebtn(i)}>{e.time}</Button>
                                ))}

                            </ButtonGroup> */}
                            </div>

                        </div>
                        <div className={`${cascading.listsec}`}>
                            <div className={`${cascading.listrow}`}>
                                <p>Redeem Ratio</p>
                                <p>{redeePercent}%</p>
                            </div>
                            <div className={`${cascading.listrow}`}>
                                <p>xFLASH output</p>
                                <p>{OutPut} xFLASH</p>
                            </div>
                        </div>
                    </>
                }


                <div className={`${cascading.btnsec}`}>
                    {selecttab ?
                        <button
                            disabled={btnenable}
                            className={`btn ${cascading.btn}`}
                            onClick={() => {
                                if (!approve) {
                                    ApproveCall()
                                } else {
                                    ConvertCall()
                                }
                            }}
                        >
                            {approve ? loader ? "Processing..." : "CONVERT" : isApprove ? "Approving..." : "APPROVE"}
                        </button>

                        // <button
                        //     className={`btn ${cascading.btn}`}
                        //     onClick={() => {
                        //         setmodalcoming(true)
                        //     }}
                        // >
                        //     {approve ? "CONVERT" : "APPROVE"}
                        // </button> 
                        :
                        <button
                            disabled={btnenable}
                            className={`btn ${cascading.btn} mt-3`}
                            onClick={() => { RedeemCall() }}
                        >
                            {loader ? "Processing..." : 'REDEEM'}
                        </button>
                        // <button
                        //     className={`btn ${cascading.btn}`}
                        //     onClick={() => { setmodalcoming(true) }}
                        // >
                        //     REDEEM
                        // </button>
                    }
                    {/* <button 
                    disabled={btnenable} 
                    className={`btn ${cascading.btn}`}
                    >{selecttab ? "APPROVE" : "REDEEM"}</button> */}
                </div>
            </div>
            {modalcoming && <ComingSoonmodal show={modalcoming} onHide={() => { setmodalcoming(false); }} />}
        </div>
    )
}



