import React from 'react'
import cascading from '../assests/css/all.module.css';
import { BiRightArrowAlt } from "react-icons/bi";
import { Link } from 'react-router-dom';

export default function Dashcard(props) {
    console.log(props.navi,"navi",props);
  return (
    <div className={`${cascading.dashcard} mb-4`} onClick={props.navi}>
        <div className={`${cascading.topsec} mb-3`}>
            <div className={`${cascading.imgsec}`}>
                <img src={props.datas.img} alt='img' className={`img-fluid ${cascading.dashimg}`}/>
            </div>
            <div>
                <Link to={props.datas.path} className={`btn ${cascading.stakebtn}`}>
                    Stake <BiRightArrowAlt />
                </Link>

            </div>

        </div>
        <div>
            <p className={`${cascading.name}`}>{props.datas.name}</p>
            <p className={`${cascading.desc}`}>{props.datas.desc}</p>
            <div className={`${cascading.innercard}`}>
            <div>
                <p className={`${cascading.labelname}`}>Your allocation</p>
                <p className={`${cascading.value}`}>{props.datas.mybal} xFLASH</p>
            </div>
            <div>
                <p className={`${cascading.labelname}`}>Total allocations</p>
                <p className={`${cascading.value}`}>{props.datas.total} xFLASH</p>
            </div>
            <div>
                <p  className={`${cascading.labelname}`}>Deallocation fee</p>
                <p className={`${cascading.value}`}>{props.datas.fees} %</p>
            </div>
        </div>
        </div>
      
                              

    </div>
  )
}
