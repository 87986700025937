import React, { useState,useEffect } from 'react'
import cascading from '../assests/css/claimcardstyle.module.css';
import { BiRightArrowAlt } from "react-icons/bi";
import netimg from '../assests/images/roundtable.png'
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';

//modals
import ComingSoonmodal from '../modals/comingSoonmodal';

//lib
import { toFixedNumber } from '../lib/FixedNumber';

export default function Endcard({buyToken}) {

    const [modalcoming, setmodalcoming] = useState(false);
    const [saleData , setSaleData] = useState({})
    const [UserData, setUserData] = useState({})

    const { LaunchData } = useSelector((state) => state.launch)
    const  {saleInfo, userData,UseTokenInfo,AffliateInfo,getChildrendata }=LaunchData
    console.log("LaunchData", LaunchData)

    const { saleAddress } = useParams()
    console.log("LaunchpadDetail_state", saleAddress)


    useEffect(()=>{
        if(saleInfo){
            console.log(saleInfo,userData,'End_useEffect')
            setSaleData(saleInfo)
            setUserData(userData)
        }
    },[saleInfo])

    console.log("Endcard",saleInfo,saleData)




    return (
        <>
            <div className={`${cascading.claimcard}`}>
                <div className={`${cascading.toprow} mb-4`}>
                    <div>
                        <p className={`${cascading.cardname}`}>Claim</p>
                    </div>
                    {/* <div className={`${cascading.rightsec}`}>
                        <div className={`${cascading.inputsec} `}>
                            <div className={`input-group`}>
                                <input type="text" className={`form-control`} placeholder="0" aria-label="Username" aria-describedby="basic-addon1" />
                                <div className={`input-group-append`} >
                                    <button className={` ${cascading.inputspan}`} id="basic-addon1">Max</button>
                                </div>
                            </div>
                        </div>
                       
                        <button className={`btn ${cascading.btnstyle}`}>Buy</button>

                    </div> */}
                </div>
                <div className={`${cascading.swaprow}`}>
                    <div className={`${cascading.swapsec}`}>
                        <div>
                            <img src={saleData.logo} alt='img' className={`img-fluid ${cascading.claimimg}`} />
                        </div>
                        <div>
                            <p className={`${cascading.netname}`}>{saleData.name}</p>
                            <p className={`${cascading.netprice}`}>{UserData && (toFixedNumber(parseInt(UserData?.userInvested) / 10 ** parseInt(saleData.decimals)))}</p>
                        </div>
                    </div>
                    {/* <div className={`${cascading.swapsec}`}> 
                        <div>
                            <img src={saleData.logo} alt='img' className={`img-fluid  ${cascading.claimimg}`} />
                        </div>
                        <div>
                            <p className={`${cascading.netname}`}>xGMBL</p>
                            <p className={`${cascading.netprice}`}>0</p>
                        </div>
                    </div> */}
                </div>
                <div className={`${cascading.btmsec}`}>
                <div className={`${cascading.contrirow}`}>
                        <p>Your contribution</p>
                        <p>{UserData && (isNaN(UserData?.userInvested) ? 0 : (parseInt(UserData?.userInvested) / 10 ** parseInt(saleData.decimals)).toFixed(10))} {buyToken}</p>
                    </div>
                    <div className={`${cascading.contrirow}`}>
                        <p>Your claimable</p>
                        <p>{UserData && (isNaN(UserData?.actualBalance) ? 0 : (parseInt(UserData?.actualBalance) / 10 ** parseInt(saleData.decimals)).toFixed(10)) - (isNaN(UserData?.userClaimbale) ? 0 : (parseInt(UserData?.userClaimbale) / 10 ** parseInt(saleData.decimals)).toFixed(10))} {saleData && saleData.symbol}</p>
                    </div>
                    <button className={`btn ${cascading.btnstyle}`} disabled>Claim</button>
                    {/* <button className={`btn ${cascading.btnstyle}`}  onClick={()=>{setmodalcoming(true)}}>Claim</button> */}


                </div>
            </div>

            {modalcoming && <ComingSoonmodal show={modalcoming} onHide={() => { setmodalcoming(false); }} />}


        </>
    )
}
