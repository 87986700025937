import React from "react";
import cascading from "../assests/css/newfooterstyle.module.css";
import footerBg from "../assests/images/footer.webp";
import audit from "../assests/images/audited.webp";
// import logo from "../assests/images/headerlogo.png";
import logo from "../assests/images/logo.svg";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";


import medium from "../assests/images/medium.svg";
import telegram from "../assests/images/telegram.svg";
import twitter from "../assests/images/twitter.svg";
import footerCenter from "../assests/images/launchpadHead.png";
import { useEffect } from "react";
import { getCmslists, getSocaillinks } from "../Actions/User";
import { useState } from "react";
import { API_URL } from "../config/env";


const NewFooter = () => {


  const [AllCmsdata, setAllCmsdata] = useState([])
  const [socialdata, setSocialdata] = useState([])
  console.log("socialdata", socialdata);

  useEffect(() => {
    GetCmslists()
    GetSociallink()
  }, [])
  const GetCmslists = async () => {
    try {
      let resp = await getCmslists();
      console.log("getCmslists", resp.data.data.data);
      setAllCmsdata(resp.data.data.data)
    } catch (error) {
      console.log("error", error);
    }
  }

  const GetSociallink = async () => {
    try {
      let resp = await getSocaillinks()
      console.log("asfsdgf", resp.data.data.data);
      setSocialdata(resp.data.data.data)
    } catch (error) {
      console.log();
    }
  }

  const CmsData = {
    sociallink: AllCmsdata.find((val) => val.identifier == "socialLink"),
    termsandcondition: AllCmsdata.find((val)=> val.identifier == "termscondition"),
    privacy: AllCmsdata.find((val)=> val.identifier == "privacypolicy")

  }
  console.log("CmsData", CmsData);

  return (
    <div className={`${cascading.newfooter} position-relative`}>
      <img
        src={footerBg}
        alt="footer"
        className={`${cascading.footerBg} ${cascading.zindex} position-absolute img-fluid`}
      />
      <img
        src={audit}
        alt="footer"
        className={`${cascading.auditBg} position-absolute img-fluid`}
      />
      <div className={`${cascading.container} container`}>
        <div className="row flex-column-reverse flex-lg-row">
          <div className="col-lg-6">
            <div className={`${cascading.left}`}>
              <div
                className={`${cascading.options} d-flex flex-wrap justify-content-start`}
              >
                <ScrollLink
                  to="features"
                  spy={true}
                  smooth={true}
                  offset={-120}
                  duration={100}
                  className="text-decoration-none"
                >
                  <button>
                    Features</button>
                </ScrollLink>
                <ScrollLink
                  to="trading"
                  spy={true}
                  smooth={true}
                  offset={-120}
                  duration={100}
                  className="text-decoration-none"
                >
                  <button>Trading</button>
                </ScrollLink>
                <ScrollLink
                  to="earning"
                  spy={true}
                  smooth={true}
                  offset={-30}
                  duration={100}
                  className="text-decoration-none"
                >
                  <button>Earning</button>
                </ScrollLink>
                <ScrollLink
                  to="tokenomics"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={100}
                  className="text-decoration-none"
                >
                  <button>Tokenomics</button>
                </ScrollLink>
                <ScrollLink
                  to="roadmap"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={100}
                  className="text-decoration-none"
                >
                  <button>Roadmap</button>
                </ScrollLink>
              </div>
              <div className={`${cascading.copyright}`}>
                <div className={`${cascading.text}`}>© Copyright Flashpad</div>
                <div className={`${cascading.links}`}>
                  <Link to="/privacy">Privacy policy</Link>
                  <Link to="/terms">Terms of service</Link>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${cascading.overlay} col-lg-6 d-flex align-items-center position-relative`}
          >
            <img
              src={footerCenter}
              alt="logo"
              className={` ${cascading.footerCenter} position-absolute d-none d-lg-block`}
            />
            <div className={`${cascading.right} d-flex flex-column `}>
              <Link to="/">
                <div className={`${cascading.logo}`}>
                  <img src={logo} alt="logo" className="img-fluid" />
                </div>
              </Link>

              <h1 className={`${cascading.textOrbiXxl}  mb-0`}>{CmsData?.sociallink?.Title}</h1>

              <div className={`${cascading.social} d-flex align-items-center`}>
                {/* <button>
                  <a href="https://twitter.com/flashpad_io" target="_blank">
                  <img src={twitter} alt="twitter" className="img-fluid" />
                  </a>
                </button>{" "}
                <button>
                <a href="https://medium.com/@flashpad.io" target="_blank">
                  <img src={medium} alt="medium" className="img-fluid" />
                  </a>
                </button>{" "}
                <button>
                <a href="https://t.me/flashpad" target="_blank">
                  <img src={telegram} alt="telegram" className="img-fluid" />
                  </a>
                </button> */}
                {socialdata.map((mlink) => (

                  <>
                    {
                      <a href={mlink.link} target="blank">

                        <img src={`${API_URL}/sociallink/${mlink?.image}`} className="img-fluid simg" />
                      </a>
                    }
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFooter;
