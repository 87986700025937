import React, { useEffect, useState } from 'react';
import cascading from '../assests/css/all.module.css';
import cascadings from '../assests/css/xgrailpools.module.css';
import { Dropdown } from 'react-bootstrap';
import Footer from '../common/footer'
import Balancecard from '../components/balancecard';
import Boostercard from '../components/boostercard';
import Yieldlist from '../components/yieldlist';
import Yieldlistadvanced from "../components/yieldlistadvanced";
import Applyallocationmodal from '../modals/applyallocationmodal';
import { IoIosSearch } from "react-icons/io";
import { FaInfoCircle } from "react-icons/fa";

import Switch from "react-switch";
import Header from '../common/header';
import logohome from '../../src/assests/images/coinicon.png';


//modals
import ComingSoonmodal from '../modals/comingSoonmodal';

import { useDispatch, useSelector } from 'react-redux';

import { getAllocationDetails } from '../hooks/useyeildBooster';
import { isEmpty } from '../lib/isEmpty';
import { roundToSignificant } from '../hooks/useCommon';
import { getWalletAddress } from '../lib/localStorage';

const filterEvents = {
    'Yield_bearing': "Yield-bearing only",
    'Locked': 'Locked only',
    'Boosted': 'Boosted only'
}
export default function Yieldbooster() {
    const dispatch = useDispatch()

    const { xtokenbalance } = useSelector((state) => (state.xtoken))
    const { walletaddress } = useSelector((state) => (state.wallet))
    const { allocationDetails, allocateDetails } = useSelector((state) => (state.yield))
    const { isposition,positionList } = useSelector((state) => (state.position))

    const [checked, setChecked] = useState(false);
    const [applyallocationmodal, setApplyallocationmodal] = useState(false);
    const [modalcoming, setmodalcoming] = useState(false);

    const [loaded, setisloaded] = useState(false);
    const [filter, setFilter] = useState('')

    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };
    const [detailcard, setDetailcard] = useState([
        {
            label: "TOTAL ALLOCATIONS",
            key: 'total',
            value: "0",
            img: require("../assests/images/totalalloc.png")

        },
        {
            label: "YOUR ALLOCATIONS",
            key: 'user',
            value: "0",
            img: require("../assests/images/allocation.png")

        },
        {
            label: "DEALLOCATION FEE",
            key: 'fee',
            value: "0",
            img: require("../assests/images/upload.png")

        },
    ])

    useEffect(() => {
        console.log((isEmpty(positionList)) && (isposition == '' && !isEmpty(getWalletAddress())), 'Positions')
        if( isposition == '' && !isEmpty(getWalletAddress())){
            setloader()
        }
        else if ((isEmpty(positionList)) && (isposition == '' && !isEmpty(getWalletAddress()))) {
            setloader()
        }
        else if (isposition == false || isposition == true) {
            Hideloader()
        }
    }, [isposition,positionList])

    const setloader = () => {
        try {
            setisloaded(true)
        } catch (err) {
            console.log(err, 'setloader__Err')
        }
    }
    const Hideloader = () => {
        try {
            setisloaded(false)
        } catch (err) {
            console.log(err, 'setloader__Err')
        }
    }
    useEffect(() => {
        if (!isEmpty(allocationDetails)) {
            SetAllocationCard()
        }
    }, [allocationDetails])

    const SetAllocationCard = () => {
        try {
            console.log(allocationDetails, 'allocationDetails')
            let CardDetail = [...detailcard]
            for (let i = 0; i < CardDetail.length; i++) {
                if (CardDetail[i].key == 'total') {
                    CardDetail[i].value = parseFloat(allocationDetails.totalAllocation)
                }
                if (CardDetail[i].key == 'user') {
                    CardDetail[i].value = isEmpty(allocationDetails.UserTotalAllocation) ? 0 : parseFloat(allocationDetails.UserTotalAllocation)
                }
                if (CardDetail[i].key == 'fee') {
                    CardDetail[i].value = isEmpty(allocationDetails.deallocationFee) ? 0 : `${allocationDetails.deallocationFee}%`
                }
                if (i == CardDetail.length - 1) {
                    console.log(CardDetail, 'SetAllocationCard')
                    setDetailcard(CardDetail)
                }
            }
         
        } catch (err) {
            console.log(err, 'SetAllocationCard__err')
        }
    }

    return (
        <>
               <div className={loaded? `${cascadings.loader_div}` : `${cascadings.loader_div} d-none`} id="loader_div">
                <div className={`${cascadings.circle}`}>
                </div>
                <div className={`${cascadings.circle_img}`}>
                    <img src={logohome} className='img-fluid' />
                </div>
            </div>

            <Header />
        
            <div className={loaded ? `${cascadings.logo_overlay}` : `${cascadings.logo_overlay_none}`}>

            <div className={`${cascading.innerpages} ${cascading.yieldpage}`}>
                <div className={`container`}>
                    <div className={`row ${cascading.headrow}`}>
                        <p className={`${cascading.pagehead}`}>Yield booster</p>
                        <p className={`${cascading.pagedesc}`}>Allocate xFLASH here to increase the yield of your staking positions up to +100%.</p>
                    </div>
                    <div className={`row ${cascading.balancesec}`}>
                        {detailcard.map((e) => (

                            <div className={`col-lg-4 mb-4`}>
                                <Balancecard datas={e} />

                            </div>

                        ))}


                    </div>
                    <div className={`mb-4 `}>

                        <div className={`row justify-content-between ${cascading.searchcard}`}>
                            <div className={` col-md-4 col-lg-3`}>

                                <div className={`${cascading.yiled_filter}`}>
                                    <p className={`${cascading.labelname} mb-2`}>Filters</p>
                                    <Dropdown
                                        className={`${cascading.dropdownstyle}`}
                                        onSelect={(e) => {
                                            console.log(e, 'Dropdown')
                                            setFilter(e)
                                        }}>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            {isEmpty(filter) ? 'select filter' : filterEvents[`${filter}`]}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className={`${cascading.dropdownmenustyle}`}>
                                            <Dropdown.Item href="#/action-2" eventKey={''}>No filter</Dropdown.Item>
                                            <Dropdown.Item href="#/action-1" eventKey={'Yield_bearing'}>Yield-bearing only</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2" eventKey={'Locked'}>Locked only</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3" eventKey={'Boosted'}>Boosted only</Dropdown.Item>

                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>



                            </div>
                            {/* <div className={`col-lg-8`}>
                                <div className={`${cascading.inputsec} my-3`}>
                                    <div className={`input-group mb-3`}>
                                        <input type="text" className={`form-control`} placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" />
                                        <div className={`input-group-append`} >
                                            <button className={` ${cascading.inputspan}`} id="basic-addon1"><IoIosSearch /></button>
                                        </div>
                                    </div>

                                </div>
                            </div> */}
                            <div className={`col-md-4 col-lg-2 mt-3 mt-lg-0 text-end ${cascading.togglesec}`}>

                                <Switch
                                    onChange={handleChange}
                                    checked={checked}
                                    className="react-switch"
                                    onHandleColor="#232833"
                                    offHandleColor='#232833'
                                    onColor="#ffaf1d"
                                    // handleDiameter={10}
                                    offColor='#6a6c6e'
                                    // handleDiameter={10}
                                    height={20}
                                    width={40}
                                    uncheckedIcon={false}
                                    checkedIcon={false}

                                />
                                <span className={`${cascading.spanlabel}`}>Advanced view</span>


                            </div>

                        </div>
                    </div>
                    <div className='mt-4 pt-0'>
                        <div className='row align-items-center justify-content-between'>
                            <div className='text-center text-md-start col-12 col-md-6'>
                                <p className={`${cascading.themelighttext} mb-0`}>Remaining available balance: <span className={`ps-1 ${cascading.tetxwhitebold}`}>{roundToSignificant(xtokenbalance, 6)} xFLASH</span></p>

                            </div>
                            {checked &&
                                <div className='col-12 col-md-6 mt-4 mt-md-0 text-center text-md-end'>
                                    <button
                                        className={`btn ${cascading.connect_btn_theme} me-3`}
                                        type='button'
                                        disabled={allocateDetails.length > 0 ? false : true}
                                        onClick={() => { setApplyallocationmodal(true) }}
                                    >
                                        Apply all allocations
                                    </button>
                                    {/* <button className={`btn ${cascading.connect_btn_theme} me-3`} type='button'
                                        onClick={() => { setmodalcoming(true) }}>

                                        Apply all allocations</button> */}
                                    {/* <button className={`btn ${cascading.connect_btn_grey}`} type='button'
                                    onClick={()=>{setLiquiditymodal(true)}} //alreadyCommentbyUI
                                    > */}

                                    {/* <button className={`btn ${cascading.connect_btn_grey}`} type='button'
                                        onClick={() => { setmodalcoming(true) }}
                                    >
                                        Reset</button> */}

                                </div>
                            }
                        </div>

                        <div className={`${cascading.warning_border} mt-4`}>
                            <div className='d-flex align-items-start justify-content-start'>
                                <FaInfoCircle className={`${cascading.themeicon}`} />
                                <span className={`${cascading.labelswarn}`}>Only yield farming rewards can be boosted. Nitro pools, Genesis pools, and swap fees APRs won't be affected by any YieldBooster allocation.</span>
                            </div>

                        </div>
                        <div className='mt-3 pt-3'>
                            {checked ?
                                <Yieldlistadvanced
                                    filter={filter}
                                />
                                :
                                <Yieldlist
                                    filter={filter}
                                />

                            }
                        </div>
                    </div>
                    {/* <div className={`row`}>
                        {boost.map((e) => (
                            <div className={`col-lg-3 mb-4`}>
                                <Boostercard datas={e} />
                            </div>
                        ))}

                    </div> */}


                </div>


            </div>

            {applyallocationmodal &&
                <Applyallocationmodal
                    onHide={() => { setApplyallocationmodal(false) }}

                />}
            {modalcoming && <ComingSoonmodal show={modalcoming} onHide={() => { setmodalcoming(false); }} />}


            </div>

            <Footer />
        </>
    )
}
