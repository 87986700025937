import React from 'react'
import cascading from '../assests/css/all.module.css';
import { BiRightArrowAlt } from "react-icons/bi";
import { Link } from 'react-router-dom';


//lib
import { dateTimeFormat } from '../lib/DateFormat'

//hooks
import { isSaleLive, isUpcoming } from '../hooks/useContract'


export default function Perkcard(props) {
    console.log(props, props.datas.saleAddress, "navi");
    const {Onset} = props
    return (

        <div className={`${cascading.innercard} mb-3`} onClick={()=>{{Onset(props.datas.saleAddress)};window.scrollTo(0,0)}}>
            <div className='w-100'>

                <p className={`${cascading.value} text-end`}>{dateTimeFormat(props.datas.endTime*1000)}</p>
            </div>
            <div className='d-flex align-items-center justify-content-start'>
                <div className={`${cascading.imgsec}`}>
                    <img src={props.datas.logo} alt='img' className={`img-fluid ${cascading.perkcardimg}`} />
                </div>
                <div className='ms-2'>
                    <p className={`${cascading.nameperk}`}>{props.datas.name}
                        {isSaleLive(parseFloat(props.datas.startTime), parseFloat(props.datas.endTime), props.datas.isPresaleOpen) ?
                            <span className={`${cascading.opentext}`}>OPEN</span>
                            : isUpcoming(parseFloat(props.datas.startTime)) ?
                                <span className={`${cascading.opentext}`}>UPCOMING</span>
                                :
                                <span className={`${cascading.closetext}`}>CLOSED</span>
                        }

                        {/* isSaleLive(parseFloat(record.startTime), parseFloat(record.endTime), record.isPresaleOpen) ? 'Live' :
                        isUpcoming(parseFloat(record.startTime)) ?
                            'Upcoming' :
                            'Closed' */}


                    </p>
                    <p className={`${cascading.desc} mb-0`}>{props.datas.description}</p>
                </div>
            </div>


        </div>


    )
}
