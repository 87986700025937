import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/apycalculatorstyle.module.css';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import infoimg from '../assests/images/infoicon.png'
import coinimg from '../assests/images/infoicon.png'
import { FaAngleDown } from "react-icons/fa";


import fileObjectUrl from '../lib/img'
import { isEmpty } from '../lib/isEmpty';



import { IoMdClose } from "react-icons/io";
import { FaDownLong } from 'react-icons/fa6';
import { FaPlus, FaMinus } from 'react-icons/fa6';

import { useSelector } from 'react-redux';
//env
import { ZEROTH_ADDRESS, MINIMUM_LIQUIDITY, MAX_UNIT256 } from '../config/env';
import BigNumber from 'bignumber.js';

//lib
import { toFixedNumber } from '../lib/FixedNumber';
import { Duration } from '../lib/DateTimeHelper';
import { consolelog } from '../lib/consolelog';
import { getWalletAddress } from '../lib/localStorage';
import { roundToSignificant } from '../hooks/useCommon';
//hooks
import { CreatePool, GetPool } from '../hooks/useNFTpoolFactory';
import { GetMultiplierSettings, CreatePosition, GetBounsApr, GetMultiplierByLockDuration } from '../hooks/useNFTpool';
import { CheckIsApproved, ApproveToken } from '../hooks/useTokens';

export default function Createpositionmodal(props) {


  const { selectToken, SetsuccessLoader, onSuccess, setError } = props
  console.log("selectToken", selectToken, SetsuccessLoader, onSuccess, setError, props)

  const [poolAddress, setpoolAddress] = useState('')
  const [MultiplierSettings, setMultiplierSettings] = useState({});
  const [Lockduration, setLockDuration] = useState(0)
  const [incDisable, setincDisable] = useState(false)
  const [DecDisable, setDecDisable] = useState(false)
  const [days, Setdays] = useState('0')
  const [months, Setmonths] = useState('0')
  const [lockPerc, setLockPerc] = useState('0')
  const [amount, setAmount] = useState('')
  const [approve, setApprove] = useState(false)
  const [errors, setErrors] = useState({})
  const [poolInfo, setPoolInfo] = useState({})
  const [lockBonusApr, setlockBonusApr] = useState('0')
  const [totalApr, settotalApr] = useState('0')
  const [multiper, setmultiper] = useState('0')
  const [isApprove, setisApprove] = useState(false)
  const [btnenable, setbtnenable] = useState(false)
  const { nftpooldata } = useSelector((state) => (state.nftpool))
  console.log("poolsinfo", poolInfo);
  useEffect(() => {
    CalculateBonusApr()
  }, [lockPerc, poolInfo])

  const CalculateBonusApr = () => {
    try {
      console.log(lockPerc, 'lockPerc_useEffect')
      let BonusApr = GetBounsApr({
        annualInterestRate: poolInfo.FramBaseApr,
        bonusPercentage: lockPerc
      })
      console.log(BonusApr, 'lockPerc_useEffect')
      BonusApr = isNaN(BonusApr) ? 0 : BonusApr
      setlockBonusApr(BonusApr)
      let totalApr = parseFloat(BonusApr) + parseFloat(poolInfo?.FramBaseApr) + parseFloat(poolInfo?.SwapFeeApr)
      console.log(totalApr, 'totalApr')
      settotalApr(totalApr)
    } catch (err) {
      console.log('CalculateBonusApr__err')
    }
  }
  useEffect(() => {
    SetMultiperval()
  }, [Lockduration])

  const SetMultiperval = async () => {
    try {
      let multiperVal = await GetMultiplierByLockDuration(poolInfo?.poolAddress, Lockduration)
      console.log(multiperVal, 'SetMultiperval')
      setmultiper(multiperVal)
    } catch (err) {
      console.log(err, 'SetMulitplierval__Err')
    }
  }

  useEffect(() => {
    if (!isEmpty(selectToken)) {
      isPoolCheck(selectToken.lpAddress)
    }
  }, [selectToken])

  //get func 
  const isPoolCheck = async (lpAddress) => {
    try {
      if (lpAddress != ZEROTH_ADDRESS) {
        console.log(lpAddress, 'isPoolCheck')
        let poolAddress = await GetPool(lpAddress)
        console.log(poolAddress, 'isPoolCheck1')
        setpoolAddress(poolAddress)
        let result = nftpooldata?.find((val) => val?.poolAddress.toLocaleLowerCase() == poolAddress.toLocaleLowerCase())
        console.log("pooladdress_pooladdress", result, nftpooldata)
        setPoolInfo(result)
        let MultiplierSettings = await GetMultiplierSettings(poolAddress)
        setMultiplierSettings(MultiplierSettings)
      }
    } catch (err) {
      console.log('isPoolCheck__err', err)
    }
  }

  //change func
  const inCreaseDuration = () => {
    try {
      let Time = Lockduration
      let interval = 1 * 86400
      Time = Time + interval
      console.log(!(Time > MultiplierSettings.maxLockDuration), 'inCreaseDuration')
      if (!(Time > MultiplierSettings.maxLockDuration)) {
        if (Time == MultiplierSettings.maxLockDuration) {
          setincDisable(true)
        }
        let days = Duration(Time, 'days')
        let lockPercn = (Time / MultiplierSettings.maxLockDuration) * 100
        setLockPerc((lockPercn).toFixed(2))
        console.log("lackPerccs", lockPercn);
        days = days % 30
        if (days == 0) {
          Setmonths(parseFloat(months) + 1)
          Setdays(0)
        }
        else {
          Setdays(parseFloat(days))
        }
        setDecDisable(false)
        setLockDuration(Time)
      }

    } catch (err) {
      consolelog('inCreaseDuration__err', err, true)
    }
  }

  const DeCreaseDuration = () => {
    try {
      let Time = Lockduration
      let interval = 1 * 86400
      Time = Time - interval
      if (!(Time < 0)) {
        if (Time == 0) {
          setDecDisable(true)
        }
        let lockPercn = (Time / MultiplierSettings.maxLockDuration) * 100
        setLockPerc((lockPercn).toFixed(2))
        let Days = Duration(Lockduration, 'days')
        Days = Days % 30
        if (Days == 0) {
          Setmonths(parseFloat(months) - 1)
          Setdays(29)
        }
        else {
          Setdays(parseFloat(Days) - 1)
        }
        setincDisable(false)
        setLockDuration(Time)
      }
    } catch (err) {
      consolelog('DeCreaseDuration__err', err, true)
    }
  }

  const maxLockDuration = () => {
    try {
      let months = Duration(MultiplierSettings.maxLockDuration, 'months')
      let days = Duration(MultiplierSettings.maxLockDuration, 'days')
      console.log(months, days, 'maxLockDuration')
      let lockPercn = (MultiplierSettings.maxLockDuration / MultiplierSettings.maxLockDuration) * 100
      setLockPerc((lockPercn).toFixed(2))
      days = days % 30
      Setdays(days)
      Setmonths(months)
      setLockDuration(MultiplierSettings.maxLockDuration)
      setincDisable(true)
      setDecDisable(false)

    } catch (err) {
      consolelog('maxLockDuration__err', err, true)
    }
  }

  const handleChange = (e) => {
    try {
      setErrors({})
      const { name, value } = e.target
      var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
      if (!numbers.test(value) && value !== "") {
        return false
      }
      if (name == 'amount') {
        setAmount(value)
        IsApproved(value)
      }
    } catch (err) {
      console.log(err, 'onChange__err')
    }
  }

  const SetMax = () => {
    try {
      setErrors({})
      setAmount(selectToken.lpBalance)
    } catch (err) {
      console.log(err, 'SetMax')
    }
  }

  //submit
  const InitializePool = async () => {
    try {
      let result = await CreatePool(selectToken.lpAddress)
      console.log(result, 'InitializePool')
      if (result) {
        let poolAddress = await GetPool(selectToken.lpAddress)
        console.log(poolAddress, 'InitializePool1')
        setpoolAddress(poolAddress)
        let result = nftpooldata?.find((val) => val?.poolAddress.toLocaleLowerCase() == poolAddress.toLocaleLowerCase())
        setPoolInfo(result)
        let MultiplierSettings = await GetMultiplierSettings(poolAddress)
        setMultiplierSettings(MultiplierSettings)
      }
    } catch (err) {
      console.log('InitializePool__err', err)
    }
  }

  const createPosition = async () => {
    try {
      let value = {
        amount: amount
      }
      let isValidate = validation(value)
      if (!isEmpty(isValidate)) {
        setErrors(isValidate)
        return true
      }
      let Amount = (amount * 10 ** 18).toFixed(0)
      Amount = toFixedNumber(Amount)
      let loaderText = 'Creating position....'
      onSuccess(loaderText)
      let result = await CreatePosition(poolAddress, Amount, Lockduration)
      console.log(result, 'result')
      if (result) {
        let successText = `You've successfully created a position for ${amount} of ${selectToken.lpSymbol}`
        SetsuccessLoader(successText, result.transactionHash)
      } else {
        let errorText = 'Error ! When creating position'
        setError(errorText)
      }
    } catch (err) {
      console.log(err, 'createPosition__err')
    }
  }

  const IsApproved = async (value) => {
    try {
      let Amount = (value * 10 ** 18).toFixed(0)
      Amount = toFixedNumber(Amount)
      // let Amount = toFixedNumber(value * 10 ** 18)
      // Amount = parseFloat(Amount).toFixed(0)
      let approve = await CheckIsApproved(poolAddress, Amount, getWalletAddress(), selectToken.lpAddress)
      setApprove(approve)
    } catch (err) {
      console.log(err, 'IsApproved__err')
    }
  }

  const ApproveLp = async () => {
    try {
      let Data = {
        amount: amount
      }
      let isValidate = validation(Data)
      console.log(isValidate, 'isValidate')
      if (!isEmpty(isValidate)) {
        setErrors(isValidate)
        return false
      }
      setisApprove(true)
      setbtnenable(true)
      let Amount = (amount * 10 ** 18).toFixed(0)
      Amount = toFixedNumber(Amount)
      // let Amount = toFixedNumber(amount * 10 ** 18)
      // Amount = parseFloat(Amount).toFixed(0)
      let approve = await ApproveToken(poolAddress, Amount, getWalletAddress(), selectToken.lpAddress)
      if (approve) {
        setisApprove(false)
        setbtnenable(false)
        setApprove(true)
      } else {
        setisApprove(false)
        setbtnenable(false)
        setApprove(false)
      }
    } catch (err) {
      console.log(err, 'ApproveLp__err')
      setisApprove(false)
      setbtnenable(false)
      setApprove(false)
    }
  }

  const validation = (value) => {
    try {
      let error = {}
      if (isEmpty(value.amount)) {
        error['amount'] = 'Amount feild is required'
      } else if (parseFloat(value.amount) > parseFloat(selectToken.lpBalance)) {
        error['amount'] = 'Insufficient balance'
      }
      return error
    } catch (err) {
      console.log(err, 'validation__err')
    }
  }

  return (
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal centered show={props.show} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>
            <svg width="21" height="26" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.1951 9.08478C20.9946 9.45252 21.3445 10.3992 20.9771 11.1995L15.6224 22.8572L16.699 12.5645C16.7624 11.9559 16.4314 11.3742 15.8759 11.1185L10.7707 8.76967L12.8315 16.5138L0.499673 0.0214844L11.2076 4.94854L20.1957 9.08411L20.1951 9.08478Z" fill="#EAECF0"></path><path d="M10.8033 26.9189C10.0037 26.5512 9.65388 25.6045 10.0213 24.8042L15.3759 13.1465L14.2993 23.4392C14.2359 24.0478 14.5669 24.6294 15.1224 24.8852L20.2276 27.234L18.1668 19.4898L30.5 35.9808L19.7921 31.0538L10.8039 26.9175L10.8033 26.9189Z" fill="#EAECF0"></path></svg>
            <span className='mx-2'>Create Position</span>
            <svg width="21" height="26" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8049 9.08478C10.0054 9.45252 9.65555 10.3992 10.0229 11.1995L15.3776 22.8572L14.301 12.5645C14.2376 11.9559 14.5686 11.3742 15.1241 11.1185L20.2293 8.76967L18.1685 16.5138L30.5003 0.0214844L19.7924 4.94854L10.8043 9.08411L10.8049 9.08478Z" fill="#EAECF0"></path><path d="M20.1967 26.9189C20.9963 26.5512 21.3461 25.6045 20.9787 24.8042L15.6241 13.1465L16.7007 23.4392C16.7641 24.0478 16.4331 24.6294 15.8776 24.8852L10.7724 27.234L12.8332 19.4898L0.5 35.9808L11.2079 31.0538L20.1961 26.9175L20.1967 26.9189Z" fill="#EAECF0"></path></svg>

          </h4>
          {/* <h4 className={`${cascading.modaltitle}`}>Create Position</h4> */}
          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          {/* <div className='d-flex align-items-center justify-content-center mb-3'>
            {isEmpty(selectToken) ?
              <button className={`btn ${cascading.currencybtn}`} onClick={() => props.onShowtoken()}>
                <img src={coinimg} alt='bnb' className={`img-fluid ${cascading.coinimg}`} />Select Token <FaAngleDown fill='#fff' />
              </button> :
              ''}

          </div> */}
          {isEmpty(selectToken) ?
            <>
              <div className='d-flex align-items-center justify-content-center mb-3'>
                <button className={`btn ${cascading.currencybtn}`} onClick={() => { props.onShowtoken(); }}>

                  <img src={coinimg} alt='bnb' className={`img-fluid ${cascading.coinimg} ${cascading.coinimg1}`} /><img src={coinimg} alt='bnb' className={`img-fluid ${cascading.coinimg} ${cascading.coinimg2}`} />Select Token <FaAngleDown fill='#fff' />
                </button>
              </div>
              <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>
            </>
            :
            <>
              <div className='d-flex align-items-center justify-content-center mb-3'>
                <button className={`btn ${cascading.currencybtn}`} onClick={() => { props.onShowtoken(); }}>

                  <img src={isEmpty(selectToken.token1) ? coinimg : fileObjectUrl(selectToken.token1.image)} alt='bnb' className={`img-fluid ${cascading.coinimg} ${cascading.coinimg1}`} />
                  <img src={isEmpty(selectToken.token2) ? coinimg : fileObjectUrl(selectToken.token2.image)} alt='bnb' className={`img-fluid ${cascading.coinimg} ${cascading.coinimg2}`} />
                  {selectToken.lpSymbol} <FaAngleDown fill='#fff' />
                </button>

              </div>

              {poolAddress != ZEROTH_ADDRESS ?
                <div>
                  <p className={`${cascading.innerheadwhite}`}>Amount
                  </p>

                  <div className={`${cascading.inputsec} my-3`}>
                    <div className={`input-group mb-3`}>
                      <input
                        type="text"
                        className={`form-control`}
                        placeholder="0"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={amount}
                        name='amount'
                        onChange={(e) => { handleChange(e) }}
                      />
                      <div className={`input-group-append`} >
                        <button className={` ${cascading.inputspan}`} id="basic-addon1" onClick={() => { SetMax() }}>
                          Max
                        </button>
                      </div>
                    </div>
                    <p className="text-danger f-12 d-block text-left">{errors?.amount}</p>
                    <p className={`${cascading.balsec}`}>balance : {selectToken.lpBalance} {selectToken.lpSymbol}</p>
                  </div>

                  <div className='row'>
                    <div className='col-12 col-md-5'>
                      <p className={`mb-0 ${cascading.innerheadwhite}`}>Lock duration
                      </p>
                      <a href="javascript:void(0)" className={`${cascading.themelink}`} onClick={() => (maxLockDuration())}>Set Max</a>
                    </div>
                    <div className='col-12 col-md-7 mt-3 mt-md-0'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <button className={`btn ${cascading.themelink} ${cascading.buttonstyle_ul}`} disabled={DecDisable} onClick={() => { DeCreaseDuration() }}>
                          <FaMinus />
                        </button>
                        <div>
                          <p className={`mb-1 text-center ${cascading.textgreydesc}`}>Months</p>
                          <p className={`mb-0 text-center ${cascading.textwhitedesc}`} >{months}</p>


                        </div>
                        <div>
                          <p className={`mb-1 text-center ${cascading.textgreydesc}`}>Days</p>
                          <p className={`mb-0 text-center ${cascading.textwhitedesc}`} >{days}</p>


                        </div>
                        <button className={`btn ${cascading.themelink} ${cascading.buttonstyle_ul}`} disabled={incDisable} onClick={() => { inCreaseDuration() }}>
                          <FaPlus />
                        </button>
                      </div>
                      <p className={`mb-1 text-end mt-3 ${cascading.textgreydesc}`}>{lockPerc}% lock bonus (x{multiper})</p>
                    </div>
                  </div>

                  {/* <div className='row mt-4'>

                    <div className='col-12 col-md-12 mt-3 mt-md-0'>
                      <div className={`${cascading.inputsec}`}>

                        <p className={`${cascading.balsec} text-end`}>1.53% lock bonus (x1.02)</p>
                      </div>
                    </div>
                  </div> */}
                  <p className={`mb-0 mt-3 ${cascading.innerheadwhite}`}>Estimates
                  </p>
                  <div className='row mt-3'>
                    <div className='col-8 col-md-8'>
                      <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Deposit value</p>
                    </div>
                    <div className='col-4 col-md-4'>
                      <p className={`mb-0 text-end ${cascading.innerheadthemesm} ${cascading.innerheadwhitesmbrkval}`}>
                        {
                          poolInfo?.notinusd ? '' : '$'}
                        {
                          isEmpty(poolInfo?.lpPriceInusd) || isEmpty(amount) ?
                            0
                            :
                            (parseFloat(amount)
                              * parseFloat(poolInfo?.lpPriceInusd)).toFixed(20)
                        }</p>
                    </div>
                  </div>



                  <div className='row mt-3'>
                    <div className='col-8 col-md-8'>
                      <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Swap fees APR</p>
                    </div>
                    <div className='col-4 col-md-4'>
                      <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(poolInfo?.SwapFeeApr, 4)}%</p>
                    </div>
                  </div>

                  <div className='row mt-3'>
                    <div className='col-8 col-md-8'>
                      <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Farm base APR</p>
                    </div>
                    <div className='col-4 col-md-4'>
                      <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(poolInfo?.FramBaseApr, 6)}%</p>
                    </div>
                  </div>

                  <div className='row mt-3'>
                    <div className='col-8 col-md-8'>
                      <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Lock bonus APR</p>
                    </div>
                    <div className='col-4 col-md-4'>
                      <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(lockBonusApr, 6)}%</p>
                    </div>
                  </div>

                  <div className='row mt-3'>
                    <div className='col-8 col-md-8'>
                      <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total APR</p>
                    </div>
                    <div className='col-4 col-md-4'>
                      <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(totalApr, 4)}%</p>
                    </div>
                  </div>

                </div>
                :
                <div className={`mt-3 pb-1 ${cascading.cardstyle}`}>
                  <div className={`${cascading.valuecardsec} ${cascading.valuecardsecnpng} mb-4`}>
                    <div className={`${cascading.secrow}`}>
                      <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>
                        You are the very first spNFT minter for this asset! You will need to initialize the spNFT contract first.
                      </p>
                    </div>
                  </div>
                </div>
              }


              {
                poolAddress != ZEROTH_ADDRESS ?
                  isEmpty(amount) ?
                    <button className={`btn ${cascading.connectwalletbtn} w-100 mt-3`} type='button'>Enter Amount </button> :
                    approve ?
                      <button className={`btn ${cascading.connectwalletbtn} w-100 mt-3`} onClick={() => { createPosition() }} type='button'>Create</button>
                      :

                      <button className={`btn ${cascading.connectwalletbtn} w-100 mt-3`} disabled={btnenable} onClick={() => { ApproveLp() }} type='button'>{isApprove ? 'Approving...' : 'Approve'}</button>
                  :
                  <button className={`btn ${cascading.connectwalletbtn} w-100 mt-3`} onClick={() => { InitializePool() }} type='button'>Initialize</button>
              }
              <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>
            </>
          }

        </Modal.Body>

      </Modal>
    </div>
  )
}
