import axios from "axios";
import { API_URL } from "../config/env";
import { addsociallink, cmslist, createuserApi } from "../Routes/UserRoutes";


export const createuserhook = async (data) => {
    console.log("login data", data);
     
      try {
        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + createuserApi,
          data
        });
        console.log("response", respdata);
        return {
          data: respdata.data
        }
      }
      catch (err) {
        console.log("createuserhook__error", err)
        return {
          error: err
        }
      }
  }

  export const getCmslists = async (data) => {
    // console.log("data" , data);
    try {
      let respdata = await axios({
        'method': 'GET',
        'url': API_URL + cmslist,
        
      });
      console.log("response", respdata);
      return {
        data: respdata
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }

  export const getSocaillinks = async (data) => {
    // console.log("data" , data);
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + addsociallink,
        
      });
      console.log("response", respdata);
      return {
        data: respdata
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }

