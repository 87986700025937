import React, { useEffect, useState } from 'react'
import { Modal, Button, Dropdown } from 'react-bootstrap';
import cascading from '../assests/css/apycalculatorstyle.module.css';
import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { useSelector } from 'react-redux';
import { IoMdClose } from "react-icons/io";

//hooks
import { Deallocate, getXLaunchAddress, GetUsageAllocations, GetDeallocationTime } from '../hooks/useXtoken'
import { getLaunchReceipt } from '../hooks/useBuy'
import { getXtoken , roundToSignificant} from '../hooks/useCommon'

//lib
import { getWalletAddress } from '../lib/localStorage';
import { isEmpty } from '../lib/isEmpty';

//validations
import { validateDeallocation } from '../validation/LaunchValidation'


export default function Deallocatemodal(props) {


  console.log("SaleData_dealoc", props.SaleData)
  const {launchshare,totalShare} = props


  const [deallocatemodal, setDeallocatemodal] = useState(true);
  const [amount, setAmount] = useState('');
  const [deallocation, setDeallocation] = useState(false);
  const [errors, setErrors] = useState('')
  const [allocBal, setAllocBal] = useState(0);
  const [deallocTime, setDeallocTime] = useState(false);
  const [sale, setSale] = useState('')
  const [saleAdd, setSaleAdd] = useState('')
  const [receiptAdd, setReceiptAdd] = useState('')


  const onChange = (e) => {
    const { name, value } = e.target;
    if (name == "amount") {
      var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
      if (!numbers.test(e.target.value) && e.target.value !== "") {
        return false
      }
      setAmount(value)
      ShareAllocate(value)
    }
  };


  const Max = () => {
    setAmount(allocBal)
  }


  const Deallocation = async () => {
    let value = {
      amount: amount,
      allocBal: allocBal
    }
    let validation = validateDeallocation(value)
    if (isEmpty(validation)) {
      setDeallocation(true)
      setErrors('');
      let usageData = '0x'
      let data = await Deallocate(getXLaunchAddress(), (amount * 10 ** 18).toString(), usageData, getWalletAddress())
      console.log("Deallocation", data)
      if (data == false) {
        setDeallocation(false)
      }
      else {
        setDeallocation(false)
        setAmount('')
        getAllocations()
      }
      props.getreceiptAdd()
      props.onHide()

    }
    else {
      setErrors(validation);
    }

  }

  const getAllocations = async (receiptAdd) => {
    let allocate = await GetUsageAllocations(receiptAdd)
    console.log("allocate_bal", allocate)
    setAllocBal(allocate)
  }

  useEffect(() => {
    getAllocations()
    allocationTime()
  }, [])

  const allocationTime = async () => {
    let deallocateTime = await GetDeallocationTime()
    console.log("deallocateTime", deallocateTime)
    if (deallocateTime?.status == false) {
      setDeallocTime(true)
    }
    else {
      setDeallocTime(false)

    }
  }


  
  const getreceiptAdd = async (saleAdd) => {
    console.log("saleAdd", saleAdd)
    let data = await getLaunchReceipt(saleAdd)
    console.log("getreceiptAdd", data)
    setReceiptAdd(data)
    getAllocations(data)
  }

  useEffect(()=>{
    settotalAllocate(allocBal)
    settotalShare(launchshare)
  },[allocBal,totalShare])

  const[totalAllocate,settotalAllocate] = useState()
  const[totalshare,settotalShare] = useState()

  const ShareAllocate = (value)=>{
    let total  =  parseFloat(allocBal) - parseFloat(value)
    settotalAllocate(total)
    let totalalloc =  parseFloat(props.totalShare) - parseFloat(value) 
    let share =totalalloc == 0 ? 0 : (total/  totalalloc)*100
    settotalShare(share)
  }
  


  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal centered show={deallocatemodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
          <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>
          <svg width="21" height="26" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.1951 9.08478C20.9946 9.45252 21.3445 10.3992 20.9771 11.1995L15.6224 22.8572L16.699 12.5645C16.7624 11.9559 16.4314 11.3742 15.8759 11.1185L10.7707 8.76967L12.8315 16.5138L0.499673 0.0214844L11.2076 4.94854L20.1957 9.08411L20.1951 9.08478Z" fill="#EAECF0"></path><path d="M10.8033 26.9189C10.0037 26.5512 9.65388 25.6045 10.0213 24.8042L15.3759 13.1465L14.2993 23.4392C14.2359 24.0478 14.5669 24.6294 15.1224 24.8852L20.2276 27.234L18.1668 19.4898L30.5 35.9808L19.7921 31.0538L10.8039 26.9175L10.8033 26.9189Z" fill="#EAECF0"></path></svg>
            <span className='mx-2'>Deallocate xFLASH</span>
            <svg width="21" height="26" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8049 9.08478C10.0054 9.45252 9.65555 10.3992 10.0229 11.1995L15.3776 22.8572L14.301 12.5645C14.2376 11.9559 14.5686 11.3742 15.1241 11.1185L20.2293 8.76967L18.1685 16.5138L30.5003 0.0214844L19.7924 4.94854L10.8043 9.08411L10.8049 9.08478Z" fill="#EAECF0"></path><path d="M20.1967 26.9189C20.9963 26.5512 21.3461 25.6045 20.9787 24.8042L15.6241 13.1465L16.7007 23.4392C16.7641 24.0478 16.4331 24.6294 15.8776 24.8852L10.7724 27.234L12.8332 19.4898L0.5 35.9808L11.2079 31.0538L20.1961 26.9175L20.1967 26.9189Z" fill="#EAECF0"></path></svg>
           
            </h4>
            {/* <h4 className={`${cascading.modaltitle}`}>Deallocate xFLASH</h4> */}
            <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

          </Modal.Header>
          <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>

            <div className={`${cascading.modal_ddrop}`}>
              <p className={`${cascading.innerheadwhite}`}>Sale Address
              </p>
              <Dropdown className={`${cascading.dropdownstyle}`}>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {isEmpty(sale) ? 'Select sale' : sale}
                </Dropdown.Toggle>

                <Dropdown.Menu className={`${cascading.dropdownmenustyle} ${cascading.dropdownmenuscrl}`}>
                  {props.SaleData.map((e, i) => {
                    console.log("e", e)
                    return (
                      <>
                        <Dropdown.Item href="#/action-1" onClick={() => {setSale(e._name); setSaleAdd(e._sale); getreceiptAdd(e._sale)  }}>{e._name}</Dropdown.Item>
                      </>
                    )

                  })
                  }

                  {/* <Dropdown.Item href="#/action-2">0x12367587967676hjuy</Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className='mt-3'>
              <p className={`${cascading.innerheadwhite}`}>Amount
              </p>

              <div className={`${cascading.inputsec} my-3`}>
                <div className={`input-group mb-3`}>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder="0"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={amount}
                    name='amount'
                    onChange={(e) => { onChange(e) }}
                  />
                  <div className={`input-group-append`} >
                    <button className={` ${cascading.inputspan}`} id="basic-addon1" onClick={() => { Max() }}>
                      Max
                    </button>
                  </div>
                </div>
                <p className="text-danger f-12 d-block text-left">{errors?.amount}</p>
                <p className={`${cascading.balsec}`}>Balance : {isEmpty(allocBal) ? 0 : allocBal} xFLASH</p>
              </div>

              <p className={`${cascading.innerheadwhitetitle} mt-4`}>Estimates
              </p>


              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total allocated amount</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{isNaN(totalAllocate) ? 0 : roundToSignificant(totalAllocate,6)} xFLASH</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total allocation share</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{isNaN(totalshare) ? 0 :roundToSignificant(totalshare,6)}%</p>
                </div>
              </div>
              {/* 
              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Daily returns/xFLASH</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>$0.0082</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total daily returns</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>$0</p>
                </div>
              </div> */}

              {/* <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Deallocation fee</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>0 xFLASH</p>
                </div>
              </div> */}


              <Button className={`${cascading.btnstyle} mt-4`} onClick={() => { Deallocation() }} disabled={deallocTime}>
                {deallocation ? 'Deallocating...' : 'Deallocate'}
              </Button>

              <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>


            </div>


            {/* <div className='p-3 text-center'>
<button className={`btn ${cascading.connectwalletbtn}`} type='button'>Close</button>

</div> */}







          </Modal.Body>

        </Modal>
      </div>
    </>
  )
}

