import React, { useEffect, useState } from 'react';
import { IoMdSettings } from "react-icons/io";
import { MdHistory } from "react-icons/md";
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FaRegQuestionCircle } from "react-icons/fa";
import cascading from '../../assests/css/swap.module.css';
import Footer from '../../common/footer'
import { FaArrowDown } from "react-icons/fa";
import { FaSyncAlt } from "react-icons/fa";


import Header from '../../common/header';
import { useFetcher, useNavigate } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";
import bnbimg from '../../assests/images/bnb.png'
import Walletmodal from '../../modals/walletmodal';
import { useSelector } from 'react-redux';
import Recenthistory from '../../modals/recenthistory';
import Selecttoken from '../../modals/selecttoken';
import Settingsmodal from '../../modals/settingsmodal';
import coinimg from '../../assests/images/infoicon.png'
import { ZEROTH_ADDRESS } from '../../config/env'
import BigNumber from 'bignumber.js';
import ConfirmSwapmodal from '../../modals/confirmSwapmodal';
import WaitingSwapmodal from '../../modals/waitingSwapmodal';
import CompletedSwapmodal from '../../modals/completedSwapmodal';

//import lib
import { consolelog } from '../../lib/consolelog';
import { isEmpty } from '../../lib/isEmpty';
import fileObjectUrl from '../../lib/img'

import { BLOCKED_PRICE_IMPACT_NON_EXPERT, ALLOWED_PRICE_IMPACT_HIGH, ALLOWED_PRICE_IMPACT_MEDIUM, ALLOWED_PRICE_IMPACT_LOW } from '../../config/env'

//hooks
import { GetPairInfo } from '../../hooks/useFactory';
import { GetAmountsIn, GetAmountsOut, getRouterAddress } from '../../hooks/useRouter';
import { toFixedNumber } from '../../lib/FixedNumber';
import { getSlippage, getWalletAddress } from '../../lib/localStorage';
import { multicall } from '../../hooks/useMultiCall';
import { SwapExactETHForTokens, SwapETHForExactTokens, SwapExactTokensForETH, SwapTokensForExactETH, SwapExactTokensForTokens, SwapTokensForExactTokens } from '../../hooks/useRouter';
import { UseAllCommonPairs } from '../../hooks/useTokens';
import { UseTradeExactIn, UseTradeExactOut } from '../../hooks/useExactTrade';
import { CheckIsApproved, ApproveToken } from '../../hooks/useTokens';

//import abi
import pairABI from '../../config/abi/pairAbi.json'

//config
import { MAX_UNIT256 } from '../../config/env';
import { UnWrap, Wrap } from '../../hooks/useErc20';
import CompletedWrapmodal from '../../modals/compeleteWrapmodal';
import toast, { Toaster } from 'react-hot-toast';
import { IsCurrency, IsWrappedCurrency, roundToSignificant } from '../../hooks/useCommon';



export default function Swap() {
    const navigate = useNavigate()

  //state
  const [token0, setToken0] = useState('')
  const [token1, setToken1] = useState('')
  const [swapsec, setSwapsec] = useState(true)
  const [walletmodalshow, setWalletmodalshow] = useState(false)
  const [recentshow, setRecentshow] = useState(false);
  const [tokenshowA, setTokenshowA] = useState(false);
  const [tokenshowB, setTokenshowB] = useState(false);
  const [settingshow, setSettingshow] = useState(false)
  const [tokenA, setTokenA] = useState({})
  const [tokenB, setTokenB] = useState({})
  const [tokenAval, setTokenAval] = useState('')
  const [tokenBval, setTokenBval] = useState('')
  const [lpAddress, setlpAddress] = useState('')
  const [price, setPrice] = useState('')
  const [MiniReceived, setMiniReceived] = useState('')
  const [MaxSold, setMaxSold] = useState('')
  const [ReserveA, setReserveA] = useState('')
  const [ReserveB, setReserveB] = useState('')
  const [priceImpact, setpriceImpact] = useState('')
  const [lpFee, setlpFee] = useState('')
  const [disableSwap, setdisableSwap] = useState(false)
  const [SwapShow, setSwapShow] = useState('')
  const [isFromEdit, setisFromEdit] = useState(true)
  const [isToEdit, setisToEdit] = useState(false)
  const [confirmShow, setCofirmShow] = useState(false)
  const [waitingShow, setWaitingShow] = useState(false)
  const [compeletedShow, setCompeletedShow] = useState(false)
  const [approve, setApprove] = useState(false)
  const [addsend, setAddsend] = useState(false);
  const [recipient, setRecipient] = useState('')
  const [Routepath, setRoutepath] = useState([])
  const [Route, setRoute] = useState('')
  const [priceDis, setpriceDis] = useState(true)
  const [Insufficient, setInsufficient] = useState(false)
  const [wrapShow, setwrapShow] = useState(false)
  const [wrap, setWrap] = useState(undefined)

  const [insuffBalance, setInsuffBalance] = useState(false)
  const [insuffText, setinsuffText] = useState('')

  const [wrapTnx, setWrapTnx] = useState('')

  //selector
  const userdetail = useSelector((state) => state.admin)
  const walletData = useSelector((state) => state.wallet)
  const { slippage, deadline, expertmode, multihops } = useSelector((state) => state.settings)
  console.log("userdetail", userdetail, multihops)


  useEffect(() => {
      let Wrapp = wrap
      console.log('useEffect1', lpAddress, wrap, !isEmpty(tokenA) && !isEmpty(tokenB), IsCurrency(tokenA) && IsWrappedCurrency(tokenB))
      CheckBalance()
      if (!isEmpty(tokenA) && !isEmpty(tokenB)) {
          if (IsCurrency(tokenA) && IsWrappedCurrency(tokenB)) {
              setWrap(true)
              Wrapp = true
              setApprove(true)
              setInsufficient(false)
              setTokenBval(tokenAval)
              setlpAddress(ZEROTH_ADDRESS)
              console.log(tokenAval, 'useEffect3')
          } else if (IsWrappedCurrency(tokenA) && IsCurrency(tokenB)) {
              setWrap(false)
              Wrapp = false
              setInsufficient(false)
              console.log(tokenAval, 'useEffect2')
              setlpAddress(ZEROTH_ADDRESS)
              setTokenBval(tokenAval)
          } else {
              setWrap(undefined)
              Wrapp = undefined
              console.log('useEffect')
              if (isFromEdit) {
                  setTokenBval('')
              }
              if (isToEdit) {
                  setTokenBval('')
              }
              getPairAddress()
          }
      }
  }, [tokenA, tokenB])

  useEffect(() => {
      if (!isEmpty(parseFloat(tokenAval)) && !isNaN(parseFloat(tokenAval)) && !isEmpty(tokenA) && !isEmpty(tokenB) && wrap == undefined) {
          console.log('useEffect4', lpAddress, !isEmpty(parseFloat(tokenAval)), parseFloat(tokenAval), tokenAval)
          // UseTradeExactIn(tokenAval, tokenA.address, tokenB.address)   
          if (lpAddress !== ZEROTH_ADDRESS && !isEmpty(lpAddress)) {
              if (isFromEdit) {
                  getAmountOut(tokenAval, tokenA.address, tokenB.address, swapsec)
              }
              CheckApprove()
          }
      }
      if (!isEmpty(tokenA) && !isEmpty(tokenB) && !isEmpty(parseFloat(tokenBval)) && !isNaN(parseFloat(tokenBval)) && wrap == undefined) {
          console.log('useEffect5', lpAddress, !isEmpty(parseFloat(tokenBval)), tokenAval)
          if (lpAddress !== ZEROTH_ADDRESS && !isEmpty(lpAddress)) {
              if (isToEdit) {
                  getAmountIn(tokenBval, tokenA.address, tokenB.address, swapsec)
              }
              CheckApprove()
          }
      }
  }, [multihops, lpAddress, wrap])

  useEffect(() => {
      console.log('MidPrice2', !isEmpty(parseFloat(tokenAval)) && !isNaN(parseFloat(tokenAval)) && !isEmpty(parseFloat(tokenBval)) && !isNaN(parseFloat(tokenBval)), tokenAval, tokenBval)
      if (!isEmpty(parseFloat(tokenAval)) && !isNaN(parseFloat(tokenAval)) && !isEmpty(parseFloat(tokenBval)) && !isNaN(parseFloat(tokenBval))) {
          console.log('MidPrice2')
          MidPrice(tokenBval, tokenAval)
      }
  }, [swapsec])

  // useEffect(() => {
  //     if (!isEmpty(parseFloat(tokenAval)) && !isEmpty(tokenA) && !isEmpty(tokenB) && wrap == undefined) {
  //         console.log('useEffect4', lpAddress, !isEmpty(parseFloat(tokenAval)), tokenAval)
  //         // UseTradeExactIn(tokenAval, tokenA.address, tokenB.address)   
  //         if (lpAddress !== ZEROTH_ADDRESS && !isEmpty(lpAddress)) {
  //             if (isFromEdit) {
  //                 getAmountOut(tokenAval, tokenA.address, tokenB.address)
  //             }
  //             CheckApprove()
  //         }
  //     }
  //     if (!isEmpty(tokenA) && !isEmpty(tokenB) && !isEmpty(parseFloat(tokenBval)) && wrap == undefined) {
  //         console.log('useEffect5', lpAddress, !isEmpty(parseFloat(tokenBval)), tokenAval)
  //         if (lpAddress !== ZEROTH_ADDRESS && !isEmpty(lpAddress)) {
  //             if (isToEdit) {
  //                 getAmountIn()
  //             }
  //             CheckApprove()
  //         }

  //     }
  // }, [multihops, lpAddress, wrap])

  // useEffect(() => {
  //     if (!isEmpty(parseFloat(tokenAval)) && !isEmpty(tokenA) && !isEmpty(tokenB) && wrap == undefined) {
  //         console.log('useEffect6', lpAddress, parseFloat(tokenAval), !isEmpty(parseFloat(tokenAval)))
  //         // UseTradeExactIn(tokenAval, tokenA.address, tokenB.address)
  //         if (lpAddress !== ZEROTH_ADDRESS && !isEmpty(lpAddress)) {
  //             if (isFromEdit) {
  //                 getAmountOut(tokenAval, tokenA.address, tokenB.address)
  //             }
  //             CheckApprove()
  //         }
  //     }
  // }, [tokenAval, wrap])

  // useEffect(() => {
  //     if (!isEmpty(tokenA) && !isEmpty(tokenB) && !isEmpty(parseFloat(tokenBval)) && wrap == undefined) {
  //         console.log('useEffect7', lpAddress, parseFloat(tokenAval), !isEmpty(parseFloat(tokenAval)))
  //         if (lpAddress !== ZEROTH_ADDRESS && !isEmpty(lpAddress)) {
  //             if (isToEdit) {
  //                 console.log('call__UseTradeExactIn3', lpAddress, parseFloat(tokenAval), !isEmpty(parseFloat(tokenAval)))
  //                 getAmountIn(tokenBval,tokenA.address,tokenB.address)

  //             }
  //             CheckApprove()
  //         }

  //     }
  // }, [tokenBval, wrap])

  const CheckBalance = () => {
      try {
          if (getWalletAddress()) {
              if (!isEmpty(parseFloat(tokenAval) && !isNaN(parseFloat(tokenAval)))) {
                  if (tokenAval > tokenA.balance) {
                      setInsuffBalance(true)
                      setinsuffText(`Insufficient ${tokenA.symbol} balance`)
                  } else {
                      setInsuffBalance('')
                      setinsuffText(``)
                  }
              }
          }
      } catch (err) {
          console.log(err, "CheckBalance___err")
      }
  }
  const CheckApprove = async () => {
      try {
          // if (tokenA.symbol == 'BNB') {
          //     setApprove(true)
          //     return true
          // }
          if (IsCurrency(tokenA)) {
              setApprove(true)
              return true
          }
          let account = getWalletAddress()
          let amount = toFixedNumber(parseFloat(tokenAval) * 10 ** 18).toString()
          let isApproved = await CheckIsApproved(getRouterAddress(), amount, account, tokenA.address)
          setApprove(isApproved)
      } catch (err) {
          consolelog('CheckApprove__err', err, true)
      }
  }

  const ApproveCall = async () => {
      try {
          let spenderAddress = getRouterAddress()
          let amount = toFixedNumber(parseFloat(tokenAval) * 10 ** 18).toString()
          let account = getWalletAddress()
          let isApproved = await CheckIsApproved(spenderAddress, amount, account, tokenA.address)
          if (isApproved) {
              setApprove(true)
              return true
          }
          let approve = await ApproveToken(spenderAddress, MAX_UNIT256, account, tokenA.address)
          if (approve) {
              setApprove(true)
          }
      } catch (err) {
          consolelog('CheckApprove__err', err, true)
      }
  }

  //get function
  const getPairAddress = async () => {
      try {
          let pairaddress = await GetPairInfo(tokenB.address, tokenA.address)

          if (pairaddress != ZEROTH_ADDRESS) {
              let calls = [
                  {
                      address: pairaddress,
                      name: 'getReserves',
                  },
                  {
                      address: pairaddress,
                      name: 'token0',
                  },
                  {
                      address: pairaddress,
                      name: 'token1',
                  }
              ]
              var pooldata = await multicall(pairABI, calls)
              let Swapsec = swapsec
              if (pooldata) {
                  let token0 = pooldata[1][0]
                  let token1 = pooldata[2][0]
                  let reserveA = new BigNumber(pooldata[0][0]._hex).toNumber() / 10 ** 18
                  let reserveB = new BigNumber(pooldata[0][1]._hex).toNumber() / 10 ** 18
                  if (reserveA == 0 && reserveB == 0) {
                    setInsufficient(true)
                 }
                  console.log("getPairAddress", pooldata[1][0], pooldata[2][0], reserveA, reserveB, pairaddress)
                  if (tokenA.address.toLowerCase() == token1.toLowerCase() && tokenB.address.toLowerCase() == token0.toLowerCase()) {
                      console.log('condition__Swapsec', false)
                      Swapsec = false
                      setSwapsec(false)
                  } else {
                      console.log('condition__Swapsec', true)
                      Swapsec = true
                      setSwapsec(true)
                  }
                  setToken0(token0)
                  setToken1(token1)
                  setReserveA(toFixedNumber(new BigNumber(pooldata[0][0]._hex).toNumber() / 10 ** 18))
                  setReserveB(toFixedNumber(new BigNumber(pooldata[0][1]._hex).toNumber() / 10 ** 18))
              }
              setlpAddress(pairaddress)
              if (isFromEdit) {
                  getAmountOutCall(tokenAval, pairaddress, Swapsec)
              } else {
                  getAmountnInCall(tokenBval, pairaddress, Swapsec)
              }
              return true
          }
          setlpAddress(pairaddress)
      } catch (err) {
          consolelog("getPairAddress__err", err, true)
      }
  }

  const getAmountOut = async (tokenAval, tokenAaddress, tokenBaddress, Swapsec) => {
      try {
          console.log(multihops, 'multihops')
          if (multihops) {
              let { priceImpact, amountOut, routePath, route, realizedLPFee, status } = await UseTradeExactIn(tokenAval, tokenAaddress, tokenBaddress)
              console.log(routePath, 'routePath')
              if (status) {
                  if (!isNaN(amountOut)) {
                      setInsufficient(false)
                      setTokenBval(amountOut)
                      miniReciviedOut(amountOut)
                      setRoutepath(routePath)
                      setRoute(route)
                      setlpFee(parseFloat(realizedLPFee).toFixed(16))
                      // MidPrice(amountOut)
                      priceImpactValidation(priceImpact)
                      priceImpact = parseFloat(priceImpact.toFixed(2)) < 0.01 ? '<0.01' : priceImpact.toFixed(2)
                      setpriceImpact(priceImpact)
                  }
                  let price = tokenAval / amountOut
                  if (!isNaN(price)) {
                      setPrice(price)
                  }
              } else {
                  let AddressArr = [tokenAaddress, tokenBaddress]
                  let amountsIn = toFixedNumber(tokenAval * 10 ** 18).toFixed(0)
                  let { price, amountOut, status } = await GetAmountsOut(AddressArr, amountsIn)
                  consolelog('getAmountOut', { price, amountOut }, true)
                  if (!status) {
                      console.log("useEffect10");
                      setInsufficient(true)
                      setTokenBval('')
                  } else {
                      setInsufficient(false)
                      if (!isNaN(amountOut)) {
                          setRoutepath([])
                          setRoute('')
                          setTokenBval(amountOut)
                          miniReciviedOut(amountOut)
                          MidPrice(amountOut, tokenAval, Swapsec)
                          LpFee(tokenAval)
                      }
                      if (!isNaN(price)) {
                          setPrice(price)
                      }
                  }

              }
          } else {
              let AddressArr = [tokenAaddress, tokenBaddress]
              let amountsIn = toFixedNumber(tokenAval * 10 ** 18).toFixed(0)
              let { price, amountOut, status } = await GetAmountsOut(AddressArr, amountsIn)
              consolelog('getAmountOut', { price, amountOut }, true)
              if (!status) {
                  console.log("useEffect14");
                  setInsufficient(true)
                  setTokenBval('')
              } else {
                  setInsufficient(false)
                  if (!isNaN(amountOut)) {
                      console.log("useEffect11");
                      setRoutepath([])
                      setRoute('')
                      setTokenBval(amountOut)
                      miniReciviedOut(amountOut)
                      MidPrice(amountOut, tokenAval, Swapsec)
                      LpFee(tokenAval)
                  }
                  if (!isNaN(price)) {
                      setPrice(price)
                  }
              }

          }
      } catch (err) {
          consolelog("getPairAddress__err", err, true)
          setInsufficient(true) 
      }
  }

  const getAmountIn = async (tokenBval, tokenAaddress, tokenBaddress, Swapsec) => {
      try {
          console.log(multihops, 'multihops', tokenBval)
          if (multihops) {
              let { priceImpact, amountIn, routePath, route, realizedLPFee, status } = await UseTradeExactOut(tokenBval, tokenAaddress, tokenBaddress)
              console.log(routePath, 'getAmountIn_routePath')
              if (status) {
                  if (!isNaN(amountIn)) {
                      setInsufficient(false)
                      setTokenAval(amountIn)
                      maximumsold(amountIn)
                      setRoutepath(routePath)
                      setRoute(route)
                      setlpFee(parseFloat(realizedLPFee).toFixed(16))
                      // MidPrice(amountOut)
                      priceImpactValidation(priceImpact)
                      priceImpact = parseFloat(priceImpact.toFixed(2)) < 0.01 ? '<0.01' : priceImpact.toFixed(2)
                      setpriceImpact(priceImpact)
                      console.log(amountIn > tokenA.balance, amountIn, tokenA.balance, 'amountIn > tokenA.balance')
                      if (amountIn > tokenA.balance) {
                          setinsuffText(`Insufficient ${tokenA.symbol} balance`)
                          setInsuffBalance(true)
                          // setTokenAval(amountIn)
                      } else {
                          setinsuffText('')
                          setInsuffBalance(false)
                      }
                  }
                  let price = amountIn / tokenBval
                  if (!isNaN(price)) {
                      setPrice(price)
                  }
              } else {
                  let AddressArr = [tokenAaddress, tokenBaddress]
                  let amountsOut = toFixedNumber(tokenBval * 10 ** 18).toFixed(0)
                  console.log(amountsOut, "amountIn", tokenBval)
                  let { price, amountIn, status } = await GetAmountsIn(AddressArr, amountsOut)
                  consolelog('getAmountIn', { price, amountIn }, true)

                  if (!status) {
                      setInsufficient(true)
                      setTokenAval('')
                  } else {
                      setInsufficient(false)
                      if (!isNaN(amountIn)) {
                          setRoutepath([])
                          setRoute('')
                          setTokenAval(amountIn)
                          maximumsold(amountIn)
                          MidPrice(tokenBval, amountIn, Swapsec)
                          LpFee(amountIn)
                      }
                      if (!isNaN(price)) {
                          setPrice(price)
                      }
                      if (amountIn > tokenA.balance) {
                          setinsuffText(`Insufficient ${tokenA.symbol} balance`)
                          setInsuffBalance(true)
                          // setTokenAval(amountIn)
                      } else {
                          setinsuffText('')
                          setInsuffBalance(false)
                      }
                  }

              }
          } else {
              let AddressArr = [tokenAaddress, tokenBaddress]
              let amountsIn = toFixedNumber(tokenBval * 10 ** 18).toFixed(0)
              console.log(amountsIn, "amountsOut", tokenBval)
              let { price, amountIn, status } = await GetAmountsIn(AddressArr, amountsIn)

              if (!status) {
                  setInsufficient(true)
                  setTokenAval('')
              } else {
                  setInsufficient(false)
                  consolelog('getAmountOut', { price, amountIn }, true)
                  if (!isNaN(amountIn)) {
                      setRoutepath([])
                      setRoute('')
                      setTokenAval(amountIn)
                      maximumsold(amountIn)
                      MidPrice(tokenBval, amountIn, Swapsec)
                      LpFee(amountIn)
                  }
                  if (!isNaN(price)) {
                      setPrice(price)
                  }
                  if (amountIn > tokenA.balance) {
                      setinsuffText(`Insufficient ${tokenA.symbol} balance`)
                      setInsuffBalance(true)
                      // setTokenAval(amountIn)
                  } else {
                      setinsuffText('')
                      setInsuffBalance(false)
                  }
              }

          }
      } catch (err) {
          console.log(err, "getAmountIn__err")
          setInsufficient(true) 
      }
  }

  const getAmountOutCall = (tokenAval, lpAddress, Swapsec) => {
      try {
          if (!isEmpty(parseFloat(tokenAval)) && !isNaN(parseFloat(tokenAval)) && !isEmpty(tokenA) && !isEmpty(tokenB) && wrap == undefined) {
              console.log('useEffect6', lpAddress, parseFloat(tokenAval), !isEmpty(parseFloat(tokenAval)))
              // UseTradeExactIn(tokenAval, tokenA.address, tokenB.address)
              if (lpAddress !== ZEROTH_ADDRESS && !isEmpty(lpAddress)) {
                  if (isFromEdit) {
                      getAmountOut(tokenAval, tokenA.address, tokenB.address, Swapsec)
                  }
                  CheckApprove()
              }
          }
      } catch (err) {
          console.log(err, "getAmountCall__err")
      }
  }

  const getAmountnInCall = (tokenBval, lpAddress, Swapsec) => {
      try {
          if (!isEmpty(tokenA) && !isEmpty(tokenB) && !isEmpty(parseFloat(tokenBval)) && !isEmpty(parseFloat(tokenBval)) && wrap == undefined) {
              console.log('useEffect7', lpAddress, parseFloat(tokenAval), !isEmpty(parseFloat(tokenAval)))
              if (lpAddress !== ZEROTH_ADDRESS && !isEmpty(lpAddress)) {
                  if (isToEdit) {
                      console.log('call__UseTradeExactIn3', lpAddress, parseFloat(tokenAval), !isEmpty(parseFloat(tokenAval)))
                      getAmountIn(tokenBval, tokenA.address, tokenB.address, Swapsec)

                  }
                  CheckApprove()
              }

          }
      } catch (err) {
          console.log(err, "getAmountCall__err")
      }
  }


  //Change func
  const onTokenAChange = (value) => {
      try {
          // let check = !((tokenB.symbol == 'BNB' && value.symbol == 'WBNB') || (tokenB.symbol == 'WBNB' && value.symbol == 'BNB'))
          let check = !((IsCurrency(tokenB) && IsWrappedCurrency(value)) || (IsWrappedCurrency(tokenB) && IsCurrency(value)))
          console.log(check, 'onTokenAChange')
          if (value.address == tokenB?.address && check) {
              setTokenB(tokenA)
          }
          setTokenA(value);
          if (value.balance <= 0 || tokenAval > value.balance) {
              setInsuffBalance(true)
              setinsuffText(`Insufficient ${value.symbol} balance`)
          } else {
              setInsuffBalance(false)
              setinsuffText('')
          }
      } catch (err) {
          consolelog("HandleChange__err", err, true)
      }
  }
  const onTokenBChange = (value) => {
      try {
          // let check = !((tokenA.symbol == 'BNB' && value.symbol == 'WBNB') || (tokenA.symbol == 'WBNB' && value.symbol == 'BNB'))
          let check = !((IsCurrency(tokenA) && IsWrappedCurrency(value)) || (IsWrappedCurrency(tokenA) && IsCurrency(value)))
          console.log(check, 'onTokenBChange', tokenB)
          if (value.address == tokenA?.address && check) {
              setTokenA(tokenB)
          }
          setTokenB(value);
      } catch (err) {
          consolelog("HandleChange__err", err, true)
      }
  }

  const handleChange = (e) => {
      try {
          const { value, id } = e.target
          var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
          setInsufficient(false)
          consolelog('handleChange', { val: value, id: id, 'con': !numbers.test(value) && value !== "" }, true)
          if (!numbers.test(value) && value !== "") {
              return false
          }
          if (id == 'tokenAval') {
              if (value > tokenA.balance) {
                  setinsuffText(`Insufficient ${tokenA.symbol} balance`)
                  setInsuffBalance(true)
              } else {
                  setinsuffText('')
                  setInsuffBalance(false)
              }
              setisFromEdit(true)
              setisToEdit(false)
              console.log("useEffect12");
              setTokenBval('')
              setTokenAval(value)
              console.log('handleChange__wrap', wrap)
              if (wrap == true || wrap == false) {
                  console.log('handleChange__wrap')
                  setTokenBval(value)
              } else {
                  getAmountOutCall(value, lpAddress, swapsec)
              }
          }
          if (id == 'tokenBval') {
              // if(value > tokenA.balance){
              //     setSwapShow(`Insufficient ${tokenB.symbol} balance`)
              //     setdisableSwap(true)
              // }
              setisToEdit(true)
              setisFromEdit(false)
              setTokenAval('')
              setTokenBval(value)
              if (wrap == true || wrap == false) {
                  console.log('handleChange__wrap')
                  setTokenAval(value)
              } else {
                  getAmountnInCall(value, lpAddress)
              }
          }
      } catch (err) {
          consolelog("HandleChange__err", err, true)
      }
  }

  //calulation 
  const miniReciviedOut = (amountOut) => {
      try {

          //minimumReceived
          let slippageval = parseFloat(slippage) / 100
          var minimumReceived = parseFloat(amountOut) * (1 - slippageval);
          minimumReceived = toFixedNumber(minimumReceived)
          // minimumReceived = Math.round(minimumReceived)
          setMiniReceived(minimumReceived)


          consolelog('miniRecivied', { minimumReceived: minimumReceived }, true)

      } catch (err) {
          consolelog('miniRecivied__err', err, true)
      }
  }

  const maximumsold = (amountIn) => {
      try {

          //minimumReceived
          let slippageval = parseFloat(slippage) / 100
          var minimumReceived = parseFloat(amountIn) * (1 + slippageval);
          minimumReceived = toFixedNumber(minimumReceived)
          // minimumReceived = Math.round(minimumReceived)
          setMaxSold(minimumReceived)


          consolelog('miniRecivied', { minimumReceived: minimumReceived }, true)

      } catch (err) {
          consolelog('miniRecivied__err', err, true)
      }
  }

  const LpFee = (amountIn) => {
      try {
          //lp fee
          let realizedLPFee = amountIn * 0.25 / 100
          setlpFee(toFixedNumber(realizedLPFee))
      } catch (err) {
          consolelog('LpFee__err', err, true)
      }
  }

  console.log(swapsec, 'MidPrice1')
  const MidPrice = (amountOut, amountIn, swapsec) => {
      try {
          console.log(swapsec, 'MidPrice')
          let midprice
          if (swapsec) {
              midprice = Number(ReserveB) / Number(ReserveA)
          } else {
              midprice = Number(ReserveA) / Number(ReserveB)
          }
          var exactQuote = (midprice) * parseFloat(amountIn)
          var priceImpact = ((exactQuote - amountOut) / exactQuote)
          priceImpact = (priceImpact - (0.25 / 100)) * 100
          console.log(priceImpact, "priceImpact1", ReserveA, ReserveB)
          priceImpactValidation(priceImpact)
          priceImpact = parseFloat(priceImpact.toFixed(2)) < 0.01 ? '<0.01' : priceImpact.toFixed(2)
          setpriceImpact(priceImpact)
          // }
          // else {
          //         midprice = Number(ReserveB) / Number(ReserveA)
          //         var exactQuote = (midprice) * parseFloat(amountIn)
          //         var priceImpact = ((exactQuote - amountOut) / exactQuote)
          //         priceImpact = (priceImpact - (0.25 / 100)) * 100
          //         console.log(priceImpact, "priceImpact")
          //         priceImpactValidation(priceImpact)
          //         priceImpact = parseFloat(priceImpact.toFixed(2)) < 0.01 ? '<0.01' : priceImpact.toFixed(2)
          //         setpriceImpact(priceImpact)
          // }
      } catch (err) {
          consolelog('MidPrice__err', err, true)
      }
  }

  //validation 
  const priceImpactValidation = (priceImpact) => {
      try {
          if (!(priceImpact < BLOCKED_PRICE_IMPACT_NON_EXPERT)) {
              setSwapShow('Price Impact High')
              setdisableSwap(true)
              return 4
          }
          if (!(priceImpact < ALLOWED_PRICE_IMPACT_HIGH)) {
              setSwapShow('Swap Anyway')
              setdisableSwap(false)
              return 3
          }
          if (!(priceImpact < ALLOWED_PRICE_IMPACT_MEDIUM)) {
              setSwapShow('Swap')
              setdisableSwap(false)
              return 2
          }
          if (!(priceImpact < ALLOWED_PRICE_IMPACT_LOW)) {
              setSwapShow('Swap')
              setdisableSwap(false)
              return 1
          }
          else {
              setSwapShow('Swap')
              setdisableSwap(false)
              return 0
          }
      } catch (err) {
          consolelog('priceImpactValidation__err', err, true)
      }
  }



  //submit 
  const SwapSubmit = async () => {
      try {
          setWaitingShow(true)
          let to = expertmode && !isEmpty(recipient) ? recipient : getWalletAddress()
          // console.log(tokenA.symbol == 'BNB' && isFromEdit, tokenA.symbol == 'BNB' && isToEdit, 'SwapSubmit', tokenA.symbol, tokenB.symbol)
          if (IsCurrency(tokenA) && isFromEdit) {
              let amountETH = toFixedNumber(tokenAval * 10 ** 18).toFixed(0)
              let amountOutMin = toFixedNumber(MiniReceived * 10 ** 18).toFixed(0)
              let path = isEmpty(Routepath) ? [tokenA.address, tokenB.address] : Routepath
              let deadLine = (new Date().getTime() / 1000 + parseFloat(deadline * 60)).toFixed(0)
              let qureyString = `Swap ${toFixedNumber(tokenAval)} ${tokenA.symbol} for ${toFixedNumber(tokenBval)} ${tokenB.symbol}`
              let result = await SwapExactETHForTokens(amountETH, amountOutMin, path, to, deadLine, qureyString)
              if (result) {
                  setWaitingShow(false)
                  setCompeletedShow(true)
                  initalStateSet()
                  return true
              } else {
                  setCofirmShow(true)
                  setWaitingShow(false)
              }
          }
          else if (IsCurrency(tokenA) && isToEdit) {
              let amountETH = toFixedNumber(tokenAval * 10 ** 18).toFixed(0)
              let amountOut = toFixedNumber(tokenBval * 10 ** 18).toFixed(0)
              let path = isEmpty(Routepath) ? [tokenA.address, tokenB.address] : Routepath
              // let to = getWalletAddress()
              let deadLine = (new Date().getTime() / 1000 + parseFloat(deadline * 60)).toFixed(0)
              console.log('SwapETHForExactTokens')
              let qureyString = `Swap ${toFixedNumber(tokenAval)} ${tokenA.symbol} for ${toFixedNumber(tokenBval)} ${tokenB.symbol}`
              let result = await SwapETHForExactTokens(amountETH, amountOut, path, to, deadLine, qureyString)
              if (result) {
                  setWaitingShow(false)
                  setCompeletedShow(true)
                  initalStateSet()
              } else {
                  setCofirmShow(true)
                  setWaitingShow(false)
              }

          }
          else if (IsCurrency(tokenB) && isFromEdit) {
              let amountIn = toFixedNumber(tokenAval * 10 ** 18).toFixed(0)
              let amountOutMin = toFixedNumber(MiniReceived * 10 ** 18).toFixed(0)
              let path = isEmpty(Routepath) ? [tokenA.address, tokenB.address] : Routepath
              // let to = getWalletAddress()
              let deadLine = (new Date().getTime() / 1000 + parseFloat(deadline * 60)).toFixed(0)
              let qureyString = `Swap ${toFixedNumber(tokenAval)} ${tokenA.symbol} for ${toFixedNumber(tokenBval)} ${tokenB.symbol}`
              let result = await SwapExactTokensForETH(amountIn, amountOutMin, path, to, deadLine, qureyString)
              if (result) {
                  setWaitingShow(false)
                  setCompeletedShow(true)
                  initalStateSet()
              } else {
                  setCofirmShow(true)
                  setWaitingShow(false)
              }

          }
          else if (IsCurrency(tokenB) && isToEdit) {
              let amountOut = toFixedNumber(tokenBval * 10 ** 18).toFixed(0)
              let amountInMax = toFixedNumber(MaxSold * 10 ** 18).toFixed(0)
              let path = isEmpty(Routepath) ? [tokenA.address, tokenB.address] : Routepath
              // let to = getWalletAddress()
              let deadLine = (new Date().getTime() / 1000 + parseFloat(deadline * 60)).toFixed(0)
              let qureyString = `Swap ${toFixedNumber(tokenAval)} ${tokenA.symbol} for ${toFixedNumber(tokenBval)} ${tokenB.symbol}`
              let result = await SwapTokensForExactETH(amountOut, amountInMax, path, to, deadLine, qureyString)
              if (result) {
                  setWaitingShow(false)
                  setCompeletedShow(true)
                  initalStateSet()
              } else {
                  setCofirmShow(true)
                  setWaitingShow(false)
              }

          }
          else if (isFromEdit) {
              let amountIn = toFixedNumber(tokenAval * 10 ** 18).toFixed(0)
              let amountOutMin = toFixedNumber(MiniReceived * 10 ** 18).toFixed(0)
              let path = isEmpty(Routepath) ? [tokenA.address, tokenB.address] : Routepath
              // let to = getWalletAddress()
              let deadLine = (new Date().getTime() / 1000 + parseFloat(deadline * 60)).toFixed(0)
              let qureyString = `Swap ${toFixedNumber(tokenAval)} ${tokenA.symbol} for ${toFixedNumber(tokenBval)} ${tokenB.symbol}`
              let result = await SwapExactTokensForTokens(amountIn, amountOutMin, path, to, deadLine, qureyString)
              if (result) {
                  setWaitingShow(false)
                  setCompeletedShow(true)
                  initalStateSet()
              } else {
                  setCofirmShow(true)
                  setWaitingShow(false)
              }

          }
          else if (isToEdit) {
              let amountOut = toFixedNumber(tokenBval * 10 ** 18).toFixed(0)
              let amountInMax = toFixedNumber(MaxSold * 10 ** 18).toFixed(0)
              let path = isEmpty(Routepath) ? [tokenA.address, tokenB.address] : Routepath
              // let to = getWalletAddress()
              let deadLine = (new Date().getTime() / 1000 + parseFloat(deadline * 60)).toFixed(0)
              let qureyString = `Swap ${toFixedNumber(tokenAval)} ${tokenA.symbol} for ${toFixedNumber(tokenBval)} ${tokenB.symbol}`
              let result = await SwapTokensForExactTokens(amountOut, amountInMax, path, to, deadLine, qureyString)
              if (result) {
                  setWaitingShow(false)
                  setCompeletedShow(true)
                  initalStateSet()
              } else {
                  setCofirmShow(true)
                  setWaitingShow(false)
              }
          }
      } catch (err) {
          consolelog('SwapSubmit_err', err, true)
      }
  }

  const WrapAndUnwarp = async () => {
      try {
          if (wrap) {
              let amount = toFixedNumber(tokenAval * 10 ** tokenA.decimal)
              amount = parseFloat(amount).toFixed(0)
              let wrapres = await Wrap(tokenB.address, amount)
              if (wrapres) {
                  setWrapTnx(wrapres)
                  setwrapShow(true)
              } else {
                  toast.error('Try again', {
                      style: {
                          minWidth: '300px',
                          minHeight: '55px'
                      }
                  });
              }
          } else if (wrap == false) {
              let amount = toFixedNumber(tokenAval * 10 ** tokenB.decimal)
              amount = parseFloat(amount).toFixed(0)
              let unwrapres = await UnWrap(tokenA.address, amount)
              if (unwrapres) {
                  setWrapTnx(unwrapres)
                  setwrapShow(true)
              } else {
                  toast.error('Try again', {
                      style: {
                          minWidth: '300px',
                          minHeight: '55px'
                      }
                  });
              }
          }
      } catch (err) {
          console.log(err, 'WrapAndUnwarp__err')
      }
  }

  const initalStateSet = () => {
      try {
          setTokenAval(0)
          setTokenBval(0)
          setMiniReceived(0)
          setMaxSold(0)
          setisFromEdit(true)
          setisToEdit(false)
          setpriceImpact(0)
          setPrice(0)
          setlpFee(0)
          setRecipient('')
          setSwapsec(true)
          setTokenA({})
          setTokenB({})
      } catch (err) {
          consolelog('initalStateSet__err', err, true)
      }
  }

  console.log('tokenBval', expertmode, tokenA, tokenBval, true)
    return (
        <>
            <Header />
            <div className={`${cascading.innerpages} ${cascading.swappage}`}>
                <div className={`container`}>
                    <div className={`row justify-content-center ${cascading.swapcardrow}`}>
                        <div className={`col-12 col-sm-10 col-md-9 col-lg-8 col-xl-7`}>
                            <div className={` ${cascading.cardstyle}`}>

                            <div className={`d-flex align-items-center justify-content-center ${cascading.cardstylecolor}`}>
                                    <svg width="31" height="36" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.1951 9.08478C20.9946 9.45252 21.3445 10.3992 20.9771 11.1995L15.6224 22.8572L16.699 12.5645C16.7624 11.9559 16.4314 11.3742 15.8759 11.1185L10.7707 8.76967L12.8315 16.5138L0.499673 0.0214844L11.2076 4.94854L20.1957 9.08411L20.1951 9.08478Z" fill="#EAECF0"></path><path d="M10.8033 26.9189C10.0037 26.5512 9.65388 25.6045 10.0213 24.8042L15.3759 13.1465L14.2993 23.4392C14.2359 24.0478 14.5669 24.6294 15.1224 24.8852L20.2276 27.234L18.1668 19.4898L30.5 35.9808L19.7921 31.0538L10.8039 26.9175L10.8033 26.9189Z" fill="#EAECF0"></path></svg>
                                      
                                      <span>SWAP</span>

                                      <svg width="31" height="36" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8049 9.08478C10.0054 9.45252 9.65555 10.3992 10.0229 11.1995L15.3776 22.8572L14.301 12.5645C14.2376 11.9559 14.5686 11.3742 15.1241 11.1185L20.2293 8.76967L18.1685 16.5138L30.5003 0.0214844L19.7924 4.94854L10.8043 9.08411L10.8049 9.08478Z" fill="#EAECF0"></path><path d="M20.1967 26.9189C20.9963 26.5512 21.3461 25.6045 20.9787 24.8042L15.6241 13.1465L16.7007 23.4392C16.7641 24.0478 16.4331 24.6294 15.8776 24.8852L10.7724 27.234L12.8332 19.4898L0.5 35.9808L11.2079 31.0538L20.1961 26.9175L20.1967 26.9189Z" fill="#EAECF0"></path></svg>
                                    </div>
                                <div className={`${cascading.toprow} mt-4`}>
                                    <div>
                                        {/* <p className={`${cascading.headings}`}>Swap</p> */}
                                        <p className={`${cascading.desc}`}>Trade tokens in an instant</p>
                                    </div>
                                    <div className={`${cascading.iconsec}`}>
                                        <IoMdSettings onClick={() => setSettingshow(true)} />
                                        <MdHistory onClick={() => setRecentshow(true)} />
                                    </div>
                                </div>
                                <div className={`${cascading.inputsec} mb-4`}>

                             

                                        <div className={`${cascading.inputsecrow}`}>

                                        <div className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}>
                                           
                                            <button className={`btn ${cascading.currencybtn}`} onClick={() => setTokenshowA(true)}>
                                                <div className='d-flex align-items-center'>
                                                {isEmpty(tokenA) ? 'From' : tokenA.symbol} 
                                                <img src={isEmpty(tokenA) ? fileObjectUrl(coinimg) : fileObjectUrl(tokenA.image)} alt='bnb' className={`img-fluid ${cascading.coinimg} ms-2`} />
                                                </div>
                                                <FaAngleDown fill='#fff' />
                                                </button>
                                        </div>
                                        <div className={`${cascading.righsideinout}`}>
                                        <label className={`${cascading.righsideinoutlabel}`}>Amount</label>
                                           
                                            <input
                                                autoComplete={"off"}
                                                type="text"
                                                className={`from-control`}
                                                id="tokenAval"
                                                aria-describedby="emailHelp"
                                                placeholder="0.0"
                                                value={tokenAval}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        {/* {swapsec ? */}
                                        {/* <div className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}>
                                                {(userdetail?.walletStatus == 'connect') && 
                                                <button 
                                                className={`btn ${cascading.currencybtn}`}
                                                onClick={()=>{
                                                    setTokenAval(tokenA?.balance)
                                                }}
                                                > Max 
                                                </button>}
                                                <button className={`btn ${cascading.currencybtn}`} onClick={()=> setTokenshowA(true)}>
                                                    <img src={isEmpty(tokenA)? '':fileObjectUrl(tokenA.image)} alt='bnb' className={`img-fluid ${cascading.coinimg}`} /> {isEmpty(tokenA)? '':tokenA.symbol} <FaAngleDown fill='#fff' /></button>
                                            </div>
                                            : */}
                                     

                                        {/* } */}


                                    </div>


                                    <div className={`${cascading.balancerow} mt-3 mb-1`}>
                                        {/* {(userdetail?.walletStatus == 'connect') &&  */}
                                        <p className={`${cascading.inputlabel} mb-0`}>Balance :{isEmpty(tokenA) ? 0 : roundToSignificant(tokenA.balance,6)}</p>
                                        {/* } */}
                                        {(userdetail?.walletStatus == 'connect') && !isEmpty(tokenA) &&
                                                <button
                                                    className={`btn ${cascading.currencybtnmx}`}
                                                    onClick={() => {
                                                        setTokenAval(tokenA?.balance)
                                                    }}> Max </button>
                                                    }
                                                    
                                    </div>

                                  

                                </div>
                                <div
                                    className={`row ${cascading.arrowrow}`}
                                    onClick={() => {
                                        // if (tokenB.address.toLowerCase() == token1.toLowerCase() && tokenA.address.toLowerCase() == token0.toLowerCase()) {
                                        //     console.log('condition__Swapsec2',false)
                                        //     setSwapsec(false)
                                        // }else{
                                        //     setSwapsec(true)
                                        // }                
                                        setisFromEdit(!isFromEdit)
                                        setisFromEdit(!isToEdit)
                                        setTokenA(tokenB)
                                        setTokenB(tokenA)
                                        setTokenAval(tokenBval)
                                        setTokenBval(tokenAval)
                                    }
                                    }>
                                    <div className={`${cascading.swaparrow}`}>
                                    <svg width="18" height="26" viewBox="0 0 18 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.29418 8.54902C4.29418 9.29735 4.04614 10.04 3.52393 10.6398C3.13881 11.0796 2.61661 11.4738 1.90511 11.6737C2.84507 12.1821 3.4456 12.9476 3.81767 13.7245C3.20409 14.9184 2.08788 16.3465 0.103516 16.9177C2.29676 18.0888 3.39991 20.1396 3.95475 21.8876C4.49653 23.647 4.49653 25.1094 4.49653 25.1094C4.49653 25.1094 4.49653 23.647 5.05137 21.8876C5.59316 20.1396 6.69631 18.0888 8.90261 16.9177C6.91824 16.3522 5.80204 14.9184 5.1754 13.7245C5.56052 12.9476 6.16105 12.1821 7.10101 11.6737C6.57229 11.5252 6.14147 11.2681 5.80204 10.9654C5.07748 10.3199 4.71847 9.43445 4.71847 8.53188L4.30071 8.54902H4.29418Z" stroke="white"></path><path d="M12.9548 4.11885C12.3999 5.87043 11.3033 7.9254 9.10352 9.09884C11.0879 9.66553 12.2041 11.1023 12.8177 12.2986C12.4456 13.0771 11.8451 13.8441 10.9051 14.3536C11.6166 14.5539 12.1388 14.9489 12.5239 15.3896C13.0461 15.9849 13.2942 16.7348 13.2942 17.4847L13.7119 17.5018C13.7119 16.5974 14.071 15.7102 14.7955 15.0634C15.1349 14.7657 15.5658 14.5081 16.0945 14.3536C15.1545 13.8441 14.554 13.0771 14.1689 12.2986C14.789 11.1023 15.9117 9.67125 17.8961 9.09884C15.6898 7.9254 14.5932 5.87043 14.0448 4.11885C13.49 2.35583 13.49 0.890482 13.49 0.890482C13.49 0.890482 13.49 2.35583 12.9482 4.11885H12.9548Z" stroke="white"></path></svg>
                                    </div>
                                </div>
                                <div className={`${cascading.inputsec} mb-4`}>
                                    

                                    <div className={`${cascading.inputsecrow}`}>
                                      
                                        {/* {!swapsec ?
                                            <div className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}>
                                                {(userdetail?.walletStatus == 'connect') && <button className={`btn ${cascading.currencybtn}`}> Max </button>}
                                                <button className={`btn ${cascading.currencybtn}`} onClick={()=> setTokenshowB(true)}><img src={bnbimg} alt='bnb' className={`img-fluid ${cascading.coinimg}`} />  <FaAngleDown fill='#fff' /></button>
                                            </div>
                                            : */}
                                        <div className={` ${cascading.rightbtnsec}`}>
                                        {(userdetail?.walletStatus == 'connect') && isToEdit ?
                                                <button className={`btn ${cascading.currencybtn}`}>
                                                    Max </button>
                                                : ""
                                            }   
                                            <button className={`btn ${cascading.currencybtn}`} onClick={() => setTokenshowB(true)}>
                                               <div className='d-flex align-items-center'>
                                                {isEmpty(tokenB) ? 'To' : tokenB.symbol}
                                                <img src={isEmpty(tokenB) ? fileObjectUrl(coinimg) : fileObjectUrl(tokenB.image)} alt='bnb' className={`img-fluid ms-2 ${cascading.coinimg}`} />
                                                </div>
                                                 <FaAngleDown fill='#fff' /></button>
                                        </div>


                                        <div className={`${cascading.righsideinout}`}>
                                            <label className={`${cascading.righsideinoutlabel}`}>Amount</label>
                                            <input
                                                type="type"
                                                autoComplete={"off"}
                                                className={`from-control`}
                                                id="tokenBval"
                                                aria-describedby="emailHelp"
                                                value={tokenBval}
                                                onChange={handleChange}
                                                placeholder="0.0" />
                                        </div>

                                        {/* } */}

                                    </div>

                                    <div className={`${cascading.balancerow} mt-3`}>
                                        {(userdetail?.walletStatus == 'connect') && 
                                        <p className={`${cascading.inputlabel} mb-1`}>Balance :{isEmpty(tokenB) ? 0 : roundToSignificant(tokenB.balance,6)}
                                        </p>
                                        }
                                    </div>
                                    <div className={`${cascading.inputsecrow}`}>
                                        {/* <div >
                                            <input
                                                type="type"
                                                autoComplete={"off"}
                                                className={`from-control`}
                                                id="tokenBval"
                                                aria-describedby="emailHelp"
                                                value={tokenBval}
                                                onChange={handleChange}
                                                placeholder="0.0" />
                                        </div> */}
                                        {/* {!swapsec ?
                                            <div className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}>
                                                {(userdetail?.walletStatus == 'connect') && <button className={`btn ${cascading.currencybtn}`}> Max </button>}
                                                <button className={`btn ${cascading.currencybtn}`} onClick={()=> setTokenshowB(true)}><img src={bnbimg} alt='bnb' className={`img-fluid ${cascading.coinimg}`} />  <FaAngleDown fill='#fff' /></button>
                                            </div>
                                            : */}
                                        {/* <div className={` ${cascading.rightbtnsec}`}>
                                            {(userdetail?.walletStatus == 'connect') && isToEdit ?
                                                <button className={`btn ${cascading.currencybtn}`}>
                                                    Max </button>
                                                : ""
                                            }
                                            <button className={`btn ${cascading.currencybtn}`} onClick={() => setTokenshowB(true)}>
                                                <img src={isEmpty(tokenB) ? fileObjectUrl(coinimg) : fileObjectUrl(tokenB.image)} alt='bnb' className={`img-fluid ${cascading.coinimg}`} />
                                                {isEmpty(tokenB) ? 'Select a Currency' : tokenB.symbol} <FaAngleDown fill='#fff' /></button>
                                        </div> */}

                                        {/* } */}

                                    </div>
                                </div>



                                {(expertmode) && wrap == undefined ?
                                    !addsend ?
                                        <p className={`${cascading.cursorpointer} text-center text-white`} onClick={() => { setAddsend(true) }}>Add a transfer user (optional)</p>
                                        :
                                        <>
                                            <div className={`row d-flex align-items-center justify-content-ceenter text-center ${cascading.arrowrow}`}>
                                                <div className={`${cascading.swaparrow}`}>
                                                    <FaArrowDown />
                                                </div>
                                                <div>
                                                    <p className={`${cascading.cursorpointer} text-center text-white`} onClick={() => { setAddsend(false) }}>Remove transfer user</p>
                                                </div>
                                            </div>
                                        </> : ''
                                }

                                {expertmode && addsend && wrap == undefined &&
                                    <div className={`${cascading.valuecardsec} mb-4`}>
                                        <div className={`${cascading.secrow}`}>
                                            <p className={`mb-0 ${cascading.label}`}>Recipient</p>
                                        </div>

                                        <div className={`${cascading.inputsecrowecipeinet}`}>
                                            <div >
                                                <input
                                                    type="text"
                                                    autoComplete={"off"}
                                                    className={`from-control`}
                                                    id="recipient"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Wallet address or ENS name"
                                                    onChange={(e) => {
                                                        setRecipient(e.target.value)
                                                    }}
                                                    value={recipient}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                }


                                {!isEmpty((tokenAval)) && !isEmpty((tokenBval)) && !isEmpty(tokenA) && !isEmpty(tokenB) && wrap == undefined ?
                                    <div className={`${cascading.valuecardsec} mb-4`}>
                                        <div className={`${cascading.secrow}`}>
                                            <p className={`mb-0 ${cascading.label}`}>Price</p>
                                            <p className={`mb-0 ${cascading.labels}`}>{roundToSignificant(price,6)} {''}
                                                {/* { `${tokenA.symbol} per ${tokenB.symbol}`} */}
                                                {priceDis && priceDis ? `${tokenA.symbol} per ${tokenB.symbol}` : `${tokenB.symbol} per ${tokenA.symbol}`}
                                                <button className={`${cascading.roundiconbtn}`}
                                                    onClick={() => {
                                                        setpriceDis(!priceDis)
                                                        if (!priceDis) {
                                                            let price = tokenAval / tokenBval
                                                            setPrice(price)
                                                        } else {
                                                            let price = tokenBval / tokenAval
                                                            setPrice(price)
                                                        }
                                                    }}><FaSyncAlt /></button></p>
                                        </div>
                                        <div className={`mt-3 pb-2 ${cascading.secrow}`}>
                                            <p className={`mb-0 ${cascading.label}`}>Slippage Tolerance</p>
                                            <p className={`mb-0 ${cascading.labels}`}>{slippage}%
                                            </p>
                                        </div>
                                    </div> : ""}
                                <div className={`${cascading.btnsec}`}>
                                    {(userdetail?.walletStatus == null || userdetail?.walletStatus == 'disconnect') ?
                                        <button
                                            className={`btn ${cascading.connect_btn}`}
                                            onClick={() => setWalletmodalshow(true)}>
                                            Connect Wallet
                                        </button> :
                                        (lpAddress == ZEROTH_ADDRESS || Insufficient) && wrap == undefined ?
                                            <button
                                                className={`btn ${cascading.connect_btn}`}
                                            >
                                                Insufficient liquidity for this trade.
                                            </button>
                                            :
                                            (isToEdit && isEmpty(tokenBval)) || isFromEdit && isEmpty(tokenAval) ?
                                                <button
                                                    className={`btn ${cascading.connect_btn}`} disabled={true}
                                                >
                                                    Enter an Amount
                                                </button> :
                                                (isEmpty(tokenA) || isEmpty(tokenB)) ?
                                                    <button
                                                        className={`btn ${cascading.connect_btn}`}
                                                        disabled={true}
                                                    >
                                                        Select a token
                                                    </button> :
                                                    ((isFromEdit && isEmpty(tokenBval)) || (isToEdit && isEmpty(tokenAval))) ?
                                                        <button
                                                            className={`btn ${cascading.connect_btn}`}
                                                            disabled={true}
                                                        >
                                                            Loading...
                                                        </button>
                                                        :
                                                        approve ?
                                                            insuffBalance ?
                                                                <button
                                                                    className={`btn ${cascading.connect_btn}`}
                                                                    disabled={true}
                                                                >
                                                                    {insuffText}
                                                                </button> :
                                                                wrap ?
                                                                    <button
                                                                        className={`btn ${cascading.connect_btn}`}
                                                                        onClick={() => {
                                                                            // setCofirmShow(true)\
                                                                            WrapAndUnwarp()
                                                                            // SwapSubmit()
                                                                        }}
                                                                    >
                                                                        Wrap
                                                                    </button> :
                                                                    wrap == false ?
                                                                        <button
                                                                            className={`btn ${cascading.connect_btn}`}
                                                                            disabled={disableSwap}
                                                                            onClick={() => {
                                                                                // setCofirmShow(true)
                                                                                WrapAndUnwarp()
                                                                                // SwapSubmit()
                                                                            }}
                                                                        >
                                                                            UnWrap
                                                                        </button> :
                                                                        <button
                                                                            className={`btn ${cascading.connect_btn}`}
                                                                            disabled={disableSwap}
                                                                            onClick={() => {
                                                                                setCofirmShow(true)
                                                                                // SwapSubmit()
                                                                            }}
                                                                        >
                                                                            {SwapShow}
                                                                        </button>
                                                            :
                                                            <button
                                                                className={`btn ${cascading.connect_btn}`}
                                                                onClick={() => {
                                                                    ApproveCall()
                                                                    // SwapSubmit()
                                                                }}
                                                            >
                                                                Enable {tokenA.symbol}
                                                            </button>
                                    }
                                </div>
<div className='mt-3'>
    
                                <svg width="100%" height="40" viewBox="0 0 600 40" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_198_4495)"><path d="M589.112 2.43879C592.59 0.713634 597.454 1.94477 597.795 5.30097C598.044 7.75539 595.581 9.55112 592.465 9.68443C592.248 9.69227 592.031 9.68443 591.814 9.68443C590.375 9.71579 588.936 9.68443 587.498 9.68443L331.554 9.68443C324.837 9.68443 318.338 9.88831 311.932 11.7938C307.223 13.1975 302.98 15.252 299.223 17.8162C298.199 18.5141 299.71 19.6589 300.734 18.961C307.958 14.0365 317.076 11.3625 326.783 11.2998C327.88 11.2998 328.987 11.2998 330.085 11.2998L588.823 11.2998C589.982 11.2998 591.141 11.3312 592.3 11.2998C598.137 11.143 602.173 6.39095 598.737 2.45447C596.326 -0.305775 591.534 -0.713537 588.036 1.0273C586.887 1.59974 587.964 3.00338 589.112 2.43095V2.43879Z" fill="#475467"></path><path d="M290.469 28.5748C292.694 24.6383 295.974 21.1802 299.979 18.3964C303.985 21.188 307.265 24.6383 309.49 28.5748L299.979 40L290.469 28.5748Z" fill="#475467"></path><path d="M300.735 17.824C292.352 12.1153 282.117 9.68443 271.022 9.68443L38.1571 9.68443C27.9943 9.68443 17.6348 10.1314 7.4823 9.68443C4.66733 9.55896 2.2457 8.06122 2.11118 5.81068C1.88348 2.20354 7.18216 0.63522 10.8458 2.43879C12.0049 3.01122 13.0812 1.60758 11.9221 1.03514C8.40341 -0.697851 3.64279 -0.290087 1.2211 2.46231C-0.972931 4.95594 -0.144989 8.42977 3.04254 10.1863C4.86398 11.19 6.81998 11.2998 8.93118 11.2998L266.81 11.2998C272.43 11.2998 277.925 11.1822 283.441 12.3349C289.351 13.566 294.68 15.8636 299.224 18.961C300.248 19.6589 301.759 18.5141 300.735 17.8162V17.824Z" fill="#475467"></path></g><defs><clipPath id="clip0_198_4495"><rect width="600" height="40" fill="white" transform="matrix(-1 0 0 -1 600 40)"></rect></clipPath></defs></svg>
                                </div>
                            </div>
                            {console.log(isFromEdit && !isEmpty(tokenBval), tokenBval, isToEdit && !isEmpty(parseFloat(tokenAval)), 'isToEdit && !isEmpty(parseFloat(tokenAval))')}
                            {(isFromEdit && !isEmpty(tokenBval) || isToEdit && !isEmpty(tokenAval)) && wrap == undefined ?
                                <div className={`mt-3 pb-1 ${cascading.cardstyle}`}>
                                    <div className={`${cascading.valuecardsec} ${cascading.valuecardsecnpng} mb-4`}>
                                        <div className={`${cascading.secrow} ${cascading.secrowcolmob}  `}>
                                            <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>{isFromEdit ? 'Minimum Received' : isToEdit ? 'Maximum sold' : ''}
                                                <OverlayTrigger
                                                    className={`${cascading.tooltip_style}`}
                                                    key="top"
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id={`tooltip-top`}>
                                                            Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                                                </OverlayTrigger>
                                            </p>
                                            <p className={`mb-0 ${cascading.labels}`}>{isFromEdit ? `${roundToSignificant(MiniReceived,6)} ${tokenB.symbol}` : isToEdit ? `${roundToSignificant(MaxSold,6)} ${tokenA.symbol}` : ''}
                                            </p>
                                        </div>

                                        <div className={`mt-3 ${cascading.secrow} ${cascading.secrowcolmob}`}>
                                            <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>Price Impact
                                                <OverlayTrigger
                                                    className={`${cascading.tooltip_style}`}
                                                    key="top"
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id={`tooltip-top`}>
                                                            The difference between the market price and estimated price due to trade size.
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                                                </OverlayTrigger>
                                            </p>
                                            <p className={`mb-0 ${cascading.labels}`}>{priceImpact} %
                                            </p>
                                        </div>


                                        <div className={`mt-3 ${cascading.secrow}  ${cascading.secrowcolmob}`}>
                                            <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>Liquidity Provider Fee
                                                <OverlayTrigger
                                                    className={`${cascading.tooltip_style}`}
                                                    key="top"
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id={`tooltip-top`}>
                                                            <p className='mb-1'>For each trade a 0.25% fee is paid</p>
                                                            <p className='mb-1'>- 0.17% to LP token holders</p>
                                                            <p className='mb-1'>- 0.03% to the Treasury</p>
                                                            <p className='mb-1'>- 0.05% towards FLASH buyback and burn</p>


                                                        </Tooltip>
                                                    }
                                                >
                                                    <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                                                </OverlayTrigger>
                                            </p>
                                            <p className={`mb-0 ${cascading.labels}`}>{roundToSignificant(lpFee,6)} {tokenA.symbol}
                                            </p>
                                        </div>
                                        {Route && !isEmpty(Route) ?
                                            <div className={`mt-3 ${cascading.secrow} ${cascading.secrowcolmob}`}>
                                                <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>Route</p>
                                                <p className={`mb-0 ${cascading.labels}`}>{Route}
                                                </p>
                                            </div> : ''}
                                    </div>

                                </div> : ""}
                        </div>

                    </div>


                </div>


            </div>



            <Footer />
            {compeletedShow && <CompletedSwapmodal show={compeletedShow} onHide={() => setCompeletedShow(false)} />}
            {waitingShow && <WaitingSwapmodal show={waitingShow} onHide={() => { setWaitingShow(false) }} tokenA={tokenA} tokenB={tokenB} tokenAval={tokenAval} tokenBval={tokenBval} />}
            {confirmShow && <ConfirmSwapmodal show={confirmShow} onHide={() => { setCofirmShow(false) }} onConfirm={() => { SwapSubmit() }} price={price} miniRecivied={MiniReceived} maxSold={MaxSold} priceImpact={priceImpact} lpFee={lpFee} tokenA={tokenA} tokenB={tokenB} tokenAval={tokenAval} tokenBval={tokenBval} isToEdit={isToEdit} isFromEdit={isFromEdit} />}
            {walletmodalshow && <Walletmodal show={walletmodalshow} onHide={() => setWalletmodalshow(false)} />}
            {recentshow && <Recenthistory show={recentshow} onHide={() => setRecentshow(false)} />}
            {tokenshowA && <Selecttoken show={tokenshowA} SetToken={(data) => { onTokenAChange(data) }} onHide={() => setTokenshowA(false)} />}
            {tokenshowB && <Selecttoken show={tokenshowB} SetToken={(data) => { onTokenBChange(data) }} onHide={() => setTokenshowB(false)} />}
            {settingshow && <Settingsmodal show={settingshow} onHide={() => setSettingshow(false)} />}
            {wrapShow && <CompletedWrapmodal show={wrapShow} onHide={() => setwrapShow(false)} txn={wrapTnx} />}
        </>
    )
}
