import React from 'react'
import cascading from '../assests/css/footerstyle.module.css';
import { FaTelegramPlane } from "react-icons/fa";
import logo from '../assests/images/headerlogo.png';
import minilogo from '../assests/images/logomini.png';

import media1 from '../assests/images/media1.png';
import mediahover1 from '../assests/images/mediahover1.png';

import media2 from '../assests/images/media2.png';
import mediahover2 from '../assests/images/mediahover2.png';

import media3 from '../assests/images/media3.png';
import mediahover3 from '../assests/images/mediahover3.png';

import media4 from '../assests/images/media4.png';
import mediahover4 from '../assests/images/mediahover4.png';

import { Link } from 'react-router-dom';
import { IoMdMail } from "react-icons/io";
import { FaFile } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { FaTwitter } from "react-icons/fa";


import { CHAINS, FRONT_URL,ANALYTICS_URL } from '../config/env';


export default function Footer() {
  return (
    <>
      <div className={`${cascading.footersec}`}>
        <div className={`${cascading.customcontainer} container`}>
          <div className={`row ${cascading.row_marginminus}`}>
            <div className={`col-sm-12 col-lg-2 ${cascading.leftsec} mt-lg-1`}>
              <div className={`row ${cascading.leftsecrowmargin}`}>
                <div className={`col-sm-6 col-lg-12`}>
              <img src={logo} alt='logo' className={`img-fluid ${cascading.logoimg}`}/>
                </div>
                <div className={`col-sm-6 col-md-4 col-lg-12`}>
                  <div className={`${cascading.socialsec} d-none d-lg-block`}>
                    <div className={`${cascading.dflex}`}>
                    <a href="https://docs.flashpad.io/" target="_blank"><svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="jsx-92e3fa8ef98875f7 hover-icon"><path d="M10.8049 17.4468C11.1799 17.4468 11.5174 17.7468 11.5174 18.1593C11.5174 18.5343 11.2174 18.8718 10.8049 18.8718C10.4299 18.8718 10.0924 18.5718 10.0924 18.1593C10.0924 17.7468 10.4299 17.4468 10.8049 17.4468ZM21.8299 13.0968C21.4549 13.0968 21.1174 12.7968 21.1174 12.3843C21.1174 12.0093 21.4174 11.6718 21.8299 11.6718C22.2049 11.6718 22.5424 11.9718 22.5424 12.3843C22.5424 12.7593 22.2049 13.0968 21.8299 13.0968ZM21.8299 10.2093C20.6299 10.2093 19.6549 11.1843 19.6549 12.3843C19.6549 12.6093 19.6924 12.8343 19.7674 13.0593L12.6049 16.8843C12.1924 16.2843 11.5174 15.9468 10.8049 15.9468C9.97993 15.9468 9.22994 16.4343 8.85494 17.1468L2.40494 13.7718C1.72994 13.3968 1.20494 12.3093 1.27994 11.2593C1.31744 10.7343 1.50494 10.3218 1.76744 10.1718C1.95494 10.0593 2.14244 10.0968 2.40494 10.2093L2.44244 10.2468C4.16744 11.1468 9.75494 14.0718 9.97994 14.1843C10.3549 14.3343 10.5424 14.4093 11.1799 14.1093L22.7299 8.10932C22.9174 8.03432 23.1049 7.88432 23.1049 7.62182C23.1049 7.28432 22.7674 7.13432 22.7674 7.13432C22.0924 6.83432 21.0799 6.34682 20.1049 5.89682C18.0049 4.92182 15.6049 3.79682 14.5549 3.23432C13.6549 2.74682 12.9049 3.15932 12.7924 3.23432L12.5299 3.34682C7.76744 5.74682 1.46744 8.85932 1.09244 9.08432C0.454935 9.45932 0.0424353 10.2468 0.00493533 11.2218C-0.0700647 12.7593 0.717435 14.3718 1.84244 14.9343L8.66744 18.4593C8.81744 19.5093 9.75494 20.3343 10.8049 20.3343C12.0049 20.3343 12.9424 19.3968 12.9799 18.1968L20.4799 14.1468C20.8549 14.4468 21.3424 14.5968 21.8299 14.5968C23.0299 14.5968 24.0049 13.6218 24.0049 12.4218C24.0049 11.1843 23.0299 10.2093 21.8299 10.2093Z" fill="white" class="jsx-92e3fa8ef98875f7"></path></svg></a>
                    <a href="/"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="jsx-92e3fa8ef98875f7 hover-icon"><path d="M3.58 21.196H17.839L17.158 18.991L18.787 20.389L20.28 21.727L23 24V2.475C22.932 1.137 21.78 0 20.352 0L3.585 0.003C2.158 0.003 1 1.142 1 2.48V18.72C1 20.131 2.156 21.196 3.58 21.196ZM14.128 5.683L14.095 5.695L14.107 5.683H14.128ZM6.497 6.952C8.33 5.618 10.029 5.682 10.029 5.682L10.166 5.817C7.923 6.352 6.906 7.354 6.906 7.354C6.906 7.354 7.178 7.221 7.653 7.018C10.674 5.83 13.973 5.916 17.027 7.42C17.027 7.42 16.008 6.483 13.903 5.883L14.089 5.7C14.38 5.701 15.92 5.755 17.568 6.96C17.568 6.96 19.412 10.11 19.412 13.98C19.351 13.906 18.268 15.646 15.481 15.706C15.481 15.706 15.009 15.172 14.673 14.706C16.303 14.238 16.913 13.302 16.913 13.302C16.378 13.639 15.89 13.839 15.494 14.039C14.885 14.307 14.275 14.439 13.666 14.574C10.782 15.042 9.163 14.259 7.633 13.638L7.11 13.372C7.11 13.372 7.719 14.308 9.284 14.776C8.873 15.245 8.466 15.778 8.466 15.778C5.68 15.712 4.664 13.972 4.664 13.972C4.664 10.096 6.497 6.952 6.497 6.952Z" fill="white" class="jsx-92e3fa8ef98875f7"></path><path d="M14.3081 12.7709C15.0191 12.7709 15.5981 12.1709 15.5981 11.4309C15.5981 10.6959 15.0221 10.0959 14.3081 10.0959V10.0989C13.6001 10.0989 13.0201 10.6969 13.0181 11.4369C13.0181 12.1709 13.5971 12.7709 14.3081 12.7709Z" fill="white" class="jsx-92e3fa8ef98875f7"></path><path d="M9.6899 12.7709C10.4009 12.7709 10.9799 12.1709 10.9799 11.4309C10.9799 10.6959 10.4049 10.0959 9.6939 10.0959L9.6899 10.0989C8.9789 10.0989 8.3999 10.6969 8.3999 11.4369C8.3999 12.1709 8.9789 12.7709 9.6899 12.7709Z" fill="white" class="jsx-92e3fa8ef98875f7"></path></svg></a>
                    <a href="https://twitter.com/flashpad_io" target="_blank"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="jsx-92e3fa8ef98875f7 hover-icon"><path d="M24 4.54511C23.1 4.93511 22.17 5.20511 21.18 5.32511C22.2 4.72511 22.98 3.76511 23.34 2.59511C22.38 3.16511 21.33 3.55511 20.22 3.79511C19.32 2.83511 18.03 2.23511 16.62 2.23511C13.89 2.23511 11.7 4.45511 11.7 7.15511C11.7 7.54511 11.73 7.90511 11.82 8.26511C7.74 8.08511 4.14 6.10511 1.71 3.13511C-0.0299997 6.25511 1.92 8.83511 3.21 9.70511C2.43 9.70511 1.65 9.46511 0.99 9.10511C0.99 11.5351 2.7 13.5451 4.92 13.9951C4.44 14.1451 3.36 14.2351 2.7 14.0851C3.33 16.0351 5.16 17.4751 7.29 17.5051C5.61 18.8251 3.15 19.8751 0 19.5451C2.19 20.9551 4.77 21.7651 7.56 21.7651C16.62 21.7651 21.54 14.2651 21.54 7.78511C21.54 7.57511 21.54 7.36511 21.51 7.15511C22.53 6.40511 23.37 5.53511 24 4.54511Z" fill="white" class="jsx-92e3fa8ef98875f7"></path></svg></a>
                    <a href="https://t.me/flashpad" target="_blank"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="jsx-92e3fa8ef98875f7 hover-icon"><path d="M9.41718 15.1809L9.02018 20.7649C9.58818 20.7649 9.83418 20.5209 10.1292 20.2279L12.7922 17.6829L18.3102 21.7239C19.3222 22.2879 20.0352 21.9909 20.3082 20.7929L23.9302 3.82092L23.9312 3.81992C24.2522 2.32392 23.3902 1.73892 22.4042 2.10592L1.11418 10.2569C-0.338822 10.8209 -0.316822 11.6309 0.867178 11.9979L6.31018 13.6909L18.9532 5.77992C19.5482 5.38592 20.0892 5.60392 19.6442 5.99792L9.41718 15.1809Z" fill="white" class="jsx-92e3fa8ef98875f7"></path></svg></a>
                    <a href="https://medium.com/@flashpad.io" target="_blank" className={`${cascading.mediumlink}`}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="jsx-cb8f5e45b5356279 hover-icon"><g clip-path="url(#clip0_202_6356)" class="jsx-cb8f5e45b5356279"><path d="M22 0H2C0.89543 0 0 0.89543 0 2V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V2C24 0.89543 23.1046 0 22 0Z" fill="white" class="jsx-cb8f5e45b5356279"></path><path d="M4.17773 18.4V17.4926L5.92856 17.2416V6.71973L4.25304 6.50737V5.59998H8.45125L12.1223 15.1951L15.5863 5.59998H19.7845V6.50737L18.2408 6.68112C18.2282 7.47911 18.2157 8.28997 18.2031 9.1137C18.2031 9.92456 18.2031 10.7483 18.2031 11.5849V12.4151C18.2031 13.2388 18.2031 14.0625 18.2031 14.8862C18.2157 15.6971 18.2282 16.508 18.2408 17.3188L19.8222 17.4926V18.4H14.2685V17.4926L15.7558 17.2995C15.7809 16.5015 15.7934 15.71 15.7934 14.9249C15.7934 14.1269 15.7934 13.3611 15.7934 12.6274L15.8122 8.03256L12.0847 18.4H10.8798L6.92634 8.03256L7.02047 11.8166V17.2416L8.58303 17.4926V18.4H4.17773Z" fill="#0C111D" class="jsx-cb8f5e45b5356279"></path></g><defs class="jsx-cb8f5e45b5356279"><clipPath id="clip0_202_6356" class="jsx-cb8f5e45b5356279"><rect width="24" height="24" fill="white" class="jsx-cb8f5e45b5356279"></rect></clipPath></defs></svg></a>
                    </div>
                  </div>
                  <div className={` ${cascading.rightsec} ${cascading.rightsecmble} d-block d-lg-none`}>
                    <div className={`${cascading.labelsec} mb-3`}>
                      {/* <img src={minilogo} alt='logo' className={`img-fluid ${cascading.minilogo}`} /> */}
                <svg width="25" height="25" viewBox="0 0 234 234" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M88.6193 63.2869C83.8032 65.5021 81.6956 71.2047 83.9087 76.0254L116.164 146.25L109.679 84.2481C109.297 80.5818 111.291 77.0781 114.637 75.5376L145.39 61.3887L132.976 108.038L207.261 8.69092L142.759 38.3708L88.6153 63.2828L88.6193 63.2869Z" fill="#0A071E"></path><path d="M43.9423 61.7829C66.7684 31.5665 104.689 19.5149 139.218 28.1888L171.291 13.4302C121.529 -12.8232 58.6873 0.0576083 23.6584 46.4308C-11.6589 93.1861 -6.56255 157.903 33.1202 198.614L48.6083 177.905C20.097 145.937 17.1488 97.2548 43.9464 61.7788L43.9423 61.7829Z" fill="#0A071E"></path><path d="M200.695 35.3873L185.207 56.0966C213.718 88.0607 216.667 136.747 189.869 172.223C167.043 202.439 129.123 214.491 94.5932 205.817L62.5205 220.575C112.282 246.829 175.124 233.948 210.153 187.575C245.47 140.819 240.374 76.1026 200.691 35.3914L200.695 35.3873Z" fill="#0A071E"></path><path d="M145.196 170.714C150.012 168.499 152.12 162.797 149.907 157.976L117.651 87.7515L124.136 149.753C124.518 153.419 122.524 156.923 119.178 158.464L88.4253 172.613L100.839 125.963L26.5459 225.302L91.0486 195.622L145.192 170.706L145.196 170.714Z" fill="#0A071E"></path></svg>
                     
                      <p className={`${cascading.labeltxt}`}>$1,617.363</p>
                    </div>
                    <div className={`${cascading.labelsec} mb-3`}>
                      <p className={`${cascading.labeltxt}`}>TVL</p>
                      <p className={`${cascading.labeltxt}`}>$115.23M</p>
                    </div>

                  </div>
                </div>
              </div>


            </div>
            <div className={` col-lg-8 ${cascading.centersec}`}>
              <div className={`row`}>
                <div className={`col-sm-6 col-lg-3 ${cascading.routerow}`}>
                  <ul className={`${cascading.firstul}`}>
                    <li><p className={`${cascading.title}`}>Resources</p></li>
                    <li><Link to="/" className={`${cascading.pageroute}`} target='_blank'>Audit</Link></li>
                    <li><Link to="/" className={`${cascading.pageroute}`} target='_blank'>Contact us</Link></li>
                    <li><Link to="/" className={`${cascading.pageroute}`} target='_blank'>Contracts</Link></li>
                    <li><Link to="/" className={`${cascading.pageroute}`} target='_blank'>Documentation</Link></li>
                  </ul>
                </div>
                <div className={`col-sm-6 col-lg-3 ${cascading.routerow}`}>
                  <ul className={`${cascading.secondul}`}>
                    <li><p className={`${cascading.title}`}>Help</p></li>
                    <li><Link to="/" className={`${cascading.pageroute}`} target='_blank'>FAQ</Link></li>
                    <li><Link to="/" className={`${cascading.pageroute}`} target='_blank'>Guides</Link></li>
                    <li><Link to="/" className={`${cascading.pageroute}`} target='_blank'>Support</Link></li>
                  </ul>
                </div>
                <div className={`col-sm-6 col-lg-3 ${cascading.routerow}`}>
                  <ul className={`${cascading.thirdul}`}>
                    <li><p className={`${cascading.title}`}>Tools</p></li>
                    {/* <li><Link to="/" className={`${cascading.pageroute}`}>Analytics</Link></li> */}
                    <li><Link to="/" className={`${cascading.pageroute}`}>Bridge</Link></li>
                    <li><Link to="/" className={`${cascading.pageroute}`}>CoinGecko</Link></li>
                    <li><Link to="/" className={`${cascading.pageroute}`}>DexTools</Link></li>
                    {/* <li><Link to="#" className={`${cascading.pageroute}`}>GeckoTerminal</Link></li> */}
                    <li><Link to="/" className={`${cascading.pageroute}`}>Governance</Link></li>
                  </ul>
                </div>
                <div className={`col-sm-6 col-lg-3 ${cascading.routerow}`}>
                  <ul className={`${cascading.fourthul}`}>
                    <li><p className={`${cascading.title}`}>Ecosystem</p></li>
                    <li><Link to="/xflashpools" className={`${cascading.pageroute}`}>Create a pool</Link></li>
                    <li><Link to="/positions" className={`${cascading.pageroute}`}>Positions</Link></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={`col-sm-6 col-lg-2 ${cascading.rightsec} d-none d-lg-flex`}>
              <div className={`${cascading.labelsec} mb-3`}>
                {/* <img src={minilogo} alt='logo' className={`img-fluid ${cascading.minilogo}`} /> */}
                <svg width="25" height="25" viewBox="0 0 234 234" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M88.6193 63.2869C83.8032 65.5021 81.6956 71.2047 83.9087 76.0254L116.164 146.25L109.679 84.2481C109.297 80.5818 111.291 77.0781 114.637 75.5376L145.39 61.3887L132.976 108.038L207.261 8.69092L142.759 38.3708L88.6153 63.2828L88.6193 63.2869Z" fill="#0A071E"></path><path d="M43.9423 61.7829C66.7684 31.5665 104.689 19.5149 139.218 28.1888L171.291 13.4302C121.529 -12.8232 58.6873 0.0576083 23.6584 46.4308C-11.6589 93.1861 -6.56255 157.903 33.1202 198.614L48.6083 177.905C20.097 145.937 17.1488 97.2548 43.9464 61.7788L43.9423 61.7829Z" fill="#0A071E"></path><path d="M200.695 35.3873L185.207 56.0966C213.718 88.0607 216.667 136.747 189.869 172.223C167.043 202.439 129.123 214.491 94.5932 205.817L62.5205 220.575C112.282 246.829 175.124 233.948 210.153 187.575C245.47 140.819 240.374 76.1026 200.691 35.3914L200.695 35.3873Z" fill="#0A071E"></path><path d="M145.196 170.714C150.012 168.499 152.12 162.797 149.907 157.976L117.651 87.7515L124.136 149.753C124.518 153.419 122.524 156.923 119.178 158.464L88.4253 172.613L100.839 125.963L26.5459 225.302L91.0486 195.622L145.192 170.706L145.196 170.714Z" fill="#0A071E"></path></svg>
                <p className={`${cascading.labeltxt}`}>$1,617.363</p>
              </div>
              <div className={`${cascading.labelsec} mb-3`}>
                <p className={`${cascading.labeltxt}`}>TVL</p>
                <p className={`${cascading.labeltxt}`}>$115.23M</p>
              </div>

            </div>

            <div className={`${cascading.socialsec} d-block d-lg-none`}>
                    <div className={`${cascading.dflex}`}>
                    <a href="https://docs.flashpad.io/" target="_blank"><svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="jsx-92e3fa8ef98875f7 hover-icon"><path d="M10.8049 17.4468C11.1799 17.4468 11.5174 17.7468 11.5174 18.1593C11.5174 18.5343 11.2174 18.8718 10.8049 18.8718C10.4299 18.8718 10.0924 18.5718 10.0924 18.1593C10.0924 17.7468 10.4299 17.4468 10.8049 17.4468ZM21.8299 13.0968C21.4549 13.0968 21.1174 12.7968 21.1174 12.3843C21.1174 12.0093 21.4174 11.6718 21.8299 11.6718C22.2049 11.6718 22.5424 11.9718 22.5424 12.3843C22.5424 12.7593 22.2049 13.0968 21.8299 13.0968ZM21.8299 10.2093C20.6299 10.2093 19.6549 11.1843 19.6549 12.3843C19.6549 12.6093 19.6924 12.8343 19.7674 13.0593L12.6049 16.8843C12.1924 16.2843 11.5174 15.9468 10.8049 15.9468C9.97993 15.9468 9.22994 16.4343 8.85494 17.1468L2.40494 13.7718C1.72994 13.3968 1.20494 12.3093 1.27994 11.2593C1.31744 10.7343 1.50494 10.3218 1.76744 10.1718C1.95494 10.0593 2.14244 10.0968 2.40494 10.2093L2.44244 10.2468C4.16744 11.1468 9.75494 14.0718 9.97994 14.1843C10.3549 14.3343 10.5424 14.4093 11.1799 14.1093L22.7299 8.10932C22.9174 8.03432 23.1049 7.88432 23.1049 7.62182C23.1049 7.28432 22.7674 7.13432 22.7674 7.13432C22.0924 6.83432 21.0799 6.34682 20.1049 5.89682C18.0049 4.92182 15.6049 3.79682 14.5549 3.23432C13.6549 2.74682 12.9049 3.15932 12.7924 3.23432L12.5299 3.34682C7.76744 5.74682 1.46744 8.85932 1.09244 9.08432C0.454935 9.45932 0.0424353 10.2468 0.00493533 11.2218C-0.0700647 12.7593 0.717435 14.3718 1.84244 14.9343L8.66744 18.4593C8.81744 19.5093 9.75494 20.3343 10.8049 20.3343C12.0049 20.3343 12.9424 19.3968 12.9799 18.1968L20.4799 14.1468C20.8549 14.4468 21.3424 14.5968 21.8299 14.5968C23.0299 14.5968 24.0049 13.6218 24.0049 12.4218C24.0049 11.1843 23.0299 10.2093 21.8299 10.2093Z" fill="white" class="jsx-92e3fa8ef98875f7"></path></svg></a>
                    <a href="#"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="jsx-92e3fa8ef98875f7 hover-icon"><path d="M3.58 21.196H17.839L17.158 18.991L18.787 20.389L20.28 21.727L23 24V2.475C22.932 1.137 21.78 0 20.352 0L3.585 0.003C2.158 0.003 1 1.142 1 2.48V18.72C1 20.131 2.156 21.196 3.58 21.196ZM14.128 5.683L14.095 5.695L14.107 5.683H14.128ZM6.497 6.952C8.33 5.618 10.029 5.682 10.029 5.682L10.166 5.817C7.923 6.352 6.906 7.354 6.906 7.354C6.906 7.354 7.178 7.221 7.653 7.018C10.674 5.83 13.973 5.916 17.027 7.42C17.027 7.42 16.008 6.483 13.903 5.883L14.089 5.7C14.38 5.701 15.92 5.755 17.568 6.96C17.568 6.96 19.412 10.11 19.412 13.98C19.351 13.906 18.268 15.646 15.481 15.706C15.481 15.706 15.009 15.172 14.673 14.706C16.303 14.238 16.913 13.302 16.913 13.302C16.378 13.639 15.89 13.839 15.494 14.039C14.885 14.307 14.275 14.439 13.666 14.574C10.782 15.042 9.163 14.259 7.633 13.638L7.11 13.372C7.11 13.372 7.719 14.308 9.284 14.776C8.873 15.245 8.466 15.778 8.466 15.778C5.68 15.712 4.664 13.972 4.664 13.972C4.664 10.096 6.497 6.952 6.497 6.952Z" fill="white" class="jsx-92e3fa8ef98875f7"></path><path d="M14.3081 12.7709C15.0191 12.7709 15.5981 12.1709 15.5981 11.4309C15.5981 10.6959 15.0221 10.0959 14.3081 10.0959V10.0989C13.6001 10.0989 13.0201 10.6969 13.0181 11.4369C13.0181 12.1709 13.5971 12.7709 14.3081 12.7709Z" fill="white" class="jsx-92e3fa8ef98875f7"></path><path d="M9.6899 12.7709C10.4009 12.7709 10.9799 12.1709 10.9799 11.4309C10.9799 10.6959 10.4049 10.0959 9.6939 10.0959L9.6899 10.0989C8.9789 10.0989 8.3999 10.6969 8.3999 11.4369C8.3999 12.1709 8.9789 12.7709 9.6899 12.7709Z" fill="white" class="jsx-92e3fa8ef98875f7"></path></svg></a>
                    <a href="https://twitter.com/flashpad_io" target="_blank"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="jsx-92e3fa8ef98875f7 hover-icon"><path d="M24 4.54511C23.1 4.93511 22.17 5.20511 21.18 5.32511C22.2 4.72511 22.98 3.76511 23.34 2.59511C22.38 3.16511 21.33 3.55511 20.22 3.79511C19.32 2.83511 18.03 2.23511 16.62 2.23511C13.89 2.23511 11.7 4.45511 11.7 7.15511C11.7 7.54511 11.73 7.90511 11.82 8.26511C7.74 8.08511 4.14 6.10511 1.71 3.13511C-0.0299997 6.25511 1.92 8.83511 3.21 9.70511C2.43 9.70511 1.65 9.46511 0.99 9.10511C0.99 11.5351 2.7 13.5451 4.92 13.9951C4.44 14.1451 3.36 14.2351 2.7 14.0851C3.33 16.0351 5.16 17.4751 7.29 17.5051C5.61 18.8251 3.15 19.8751 0 19.5451C2.19 20.9551 4.77 21.7651 7.56 21.7651C16.62 21.7651 21.54 14.2651 21.54 7.78511C21.54 7.57511 21.54 7.36511 21.51 7.15511C22.53 6.40511 23.37 5.53511 24 4.54511Z" fill="white" class="jsx-92e3fa8ef98875f7"></path></svg></a>
                    <a href="https://t.me/flashpad" target="_blank"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="jsx-92e3fa8ef98875f7 hover-icon"><path d="M9.41718 15.1809L9.02018 20.7649C9.58818 20.7649 9.83418 20.5209 10.1292 20.2279L12.7922 17.6829L18.3102 21.7239C19.3222 22.2879 20.0352 21.9909 20.3082 20.7929L23.9302 3.82092L23.9312 3.81992C24.2522 2.32392 23.3902 1.73892 22.4042 2.10592L1.11418 10.2569C-0.338822 10.8209 -0.316822 11.6309 0.867178 11.9979L6.31018 13.6909L18.9532 5.77992C19.5482 5.38592 20.0892 5.60392 19.6442 5.99792L9.41718 15.1809Z" fill="white" class="jsx-92e3fa8ef98875f7"></path></svg></a>
                    <a href="https://medium.com/@flashpad.io" target="_blank" className={`${cascading.mediumlink}`}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="jsx-cb8f5e45b5356279 hover-icon"><g clip-path="url(#clip0_202_6356)" class="jsx-cb8f5e45b5356279"><path d="M22 0H2C0.89543 0 0 0.89543 0 2V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V2C24 0.89543 23.1046 0 22 0Z" fill="white" class="jsx-cb8f5e45b5356279"></path><path d="M4.17773 18.4V17.4926L5.92856 17.2416V6.71973L4.25304 6.50737V5.59998H8.45125L12.1223 15.1951L15.5863 5.59998H19.7845V6.50737L18.2408 6.68112C18.2282 7.47911 18.2157 8.28997 18.2031 9.1137C18.2031 9.92456 18.2031 10.7483 18.2031 11.5849V12.4151C18.2031 13.2388 18.2031 14.0625 18.2031 14.8862C18.2157 15.6971 18.2282 16.508 18.2408 17.3188L19.8222 17.4926V18.4H14.2685V17.4926L15.7558 17.2995C15.7809 16.5015 15.7934 15.71 15.7934 14.9249C15.7934 14.1269 15.7934 13.3611 15.7934 12.6274L15.8122 8.03256L12.0847 18.4H10.8798L6.92634 8.03256L7.02047 11.8166V17.2416L8.58303 17.4926V18.4H4.17773Z" fill="#0C111D" class="jsx-cb8f5e45b5356279"></path></g><defs class="jsx-cb8f5e45b5356279"><clipPath id="clip0_202_6356" class="jsx-cb8f5e45b5356279"><rect width="24" height="24" fill="white" class="jsx-cb8f5e45b5356279"></rect></clipPath></defs></svg></a>
                    </div>
                  </div>


          </div>
          {/* <div className={`${cascading.copysec}`}>

            <p className={`${cascading.copytext}`}>Copyright © 2024 Flashpad - All Rights Reserved.</p>
            <div className={`${cascading.socialsec} d-block d-lg-none`}>
              <div className={`${cascading.dflex}`}>
                <Link to="https://x.com/DonutSwapDEX" target='_blank'>
                  <img src={media1} className={`img-fluid ${cascading.mediass}`} />
                  <img src={mediahover1} className={`img-fluid ${cascading.mediasshover}`} />
                </Link>
                <Link to="https://t.me/Donut_Swap" target='_blank'>
                  <img src={media2} className={`img-fluid ${cascading.mediass}`} />
                  <img src={mediahover2} className={`img-fluid ${cascading.mediasshover}`} />
                </Link>
                <Link to="https://whitepaper.donutswap.finance" target='_blank'>
                  <img src={media3} className={`img-fluid ${cascading.mediass}`} />
                  <img src={mediahover3} className={`img-fluid ${cascading.mediasshover}`} />
                </Link>
                <Link to="https://donutswap-1.gitbook.io/donutswap/" target='_blank' >
                  <img src={media4} className={`img-fluid ${cascading.mediass}`} />
                  <img src={mediahover4} className={`img-fluid ${cascading.mediasshover}`} />
                </Link>
              </div>

            </div>
          </div> */}

        </div>

      </div>



    </>
  )
}
