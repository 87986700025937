import { CURRENT_CHAIN_ID, UseWeb3, useWeb3 } from "./useWeb3"
import BigNumber from "bignumber.js"

import DividendABI from '../config/abi/DividendsABI.json'
import contractAddress from '../config/constant/contract'

//lib
import { consolelog } from "../lib/consolelog"
import { getWalletAddress } from "../lib/localStorage"
import { isEmpty } from "../lib/isEmpty"

//hooks
import { EstGas, GetBlockTimeStamp, IsLpToken } from "./useCommon"
import { multicall } from "./useMultiCall"
import { toFixedNumber } from "../lib/toFixedOf"
import { Allocate, Deallocate, GetDeallocateFee } from "./useXtoken"

import pairABI from '../config/abi/pairAbi.json'
import ERC20_ABI from '../config/abi/ERC20.json'
//constant 
import { DISTRIBUTE_INFO, DIVIDENDS_DETAILS, DIVI_USER_INFO, IS_LOAD_DIVIDENDS } from "../constants"
import { ZEROTH_ADDRESS } from "../config/env"

//hooks 
import { getTokenPricesFromFarm } from "./usePools"
import { getTokens } from "./useTokens"
import { GetTotalSupply } from "./useXtoken"
import { getNativeAddress } from "./useNativeToken"
import { TokenInfo } from "./useErc20"

//store
import store from "../store";

export const getDividendAddress = () => {
    try {
        const CHAINID = CURRENT_CHAIN_ID()
        let DividendAddress = contractAddress.dividends[`${CHAINID}`]
        return DividendAddress
    } catch (err) {
        consolelog('getXAddress__err', err, true)
    }
}

export const useDividendcontract = async () => {
    const web3 = await useWeb3()
    try {
        let DividendAddress = getDividendAddress()
        let contract = new web3.eth.Contract(DividendABI, DividendAddress)
        return contract
    } catch (err) {
        consolelog('Xcontract__err', err, true)
        return false
    }
}

export const HarvestAllDividends = async (account) => {
    const web3 = await useWeb3()
    try {
        let contract = await useDividendcontract()
        let params = []
        const { gasLimit, gasPrice } = await EstGas(params, DividendABI, getDividendAddress(), 'harvestAllDividends', account)
        let HarvetDividendAll = await contract.methods.harvestAllDividends().send({ from: web3.utils.toChecksumAddress(account), gasLimit: gasLimit, gasPrice: gasPrice })
        getDividendDetails(store.dispatch)
        return HarvetDividendAll
    } catch (err) {
        consolelog('HarvestAllDividends__err', err, true)
        return false
    }
}

export const HarvestDividends = async (account, tokenAddress) => {
    const web3 = await useWeb3()
    try {
        let contract = await useDividendcontract()
        let params = [tokenAddress]
        const { gasLimit, gasPrice } = await EstGas(params, DividendABI, getDividendAddress(), 'harvestDividends', account)
        let HarvetDividend = await contract.methods.harvestDividends(web3.utils.toChecksumAddress(tokenAddress)).send({ from: web3.utils.toChecksumAddress(account), gasLimit: gasLimit, gasPrice: gasPrice })
        getDividendDetails(store.dispatch)
        return HarvetDividend
    } catch (err) {
        consolelog('HarvestDividends__err', err, true)
        return false
    }
}

export const AllocateTODividends = async (amount) => {
    try {
        let usageData = '0x';
        let result = await Allocate(getDividendAddress(), amount, usageData, getWalletAddress())
        if (result) {
            return result
        } else {
            return false
        }
    } catch (err) {
        console.log(err, 'AllocateTODividends___err')
        return false
    }
}

export const DeallocateTODividends = async (amount) => {
    try {
        let usageData = '0x';
        let result = await Deallocate(getDividendAddress(), amount, usageData, getWalletAddress())
        if (result) {
            return result
        } else {
            return false
        }
    } catch (err) {
        console.log(err, 'DeallocateTODividends___err')
        return false
    }
}

export const getDividendDetails = async (dispatch) => {
    try {
        dispatch({
            type :IS_LOAD_DIVIDENDS,
            payload:true
        })
        console.log('getAllocationDetails')
        let DividendAddress = getDividendAddress()
        let calls = [
            {
                address: DividendAddress,
                name: 'totalAllocation'
            },
            {
                address: DividendAddress,
                name: 'nextCycleStartTime'
            },
            {
                address: DividendAddress,
                name: 'distributedTokensLength'
            },
            {
                address: DividendAddress,
                name: 'cycleDurationSeconds'

            }
        ]
        if (getWalletAddress()) {
            calls.push({
                address: DividendAddress,
                name: 'usersAllocation',
                params: [getWalletAddress()]
            })
        }
        const [
            totalAllocation,
            nextCycleStartTime,
            distributedTokensLength,
            cycleDurationSeconds,
            usersAllocation,
        ] = await multicall(DividendABI, calls)
        let distributedLength = new BigNumber(distributedTokensLength[0]._hex).toNumber()
        let currentepoch
        let Apy
        if (distributedLength > 0) {

            let { DividendInfos, apy } = await getDividendInfo(distributedLength, dispatch, new BigNumber(totalAllocation[0]._hex).toNumber())
            currentepoch = DividendInfos.reduce((total, currentValue) => {
                if (!currentValue?.notinusd) {
                    return total = parseFloat(total) + parseFloat(currentValue.currDistributionInusd)
                } else {
                    return total
                }
            }, 0)
            Apy = apy

        }

        let deallocationFee = await GetDeallocateFee(DividendAddress)
        console.log(new BigNumber(totalAllocation[0]._hex).toNumber(), 'getAllocationDetails')
        let obj = {
            totalAllocation: new BigNumber(totalAllocation[0]._hex).toNumber() / 10 ** 18,
            nextCycleStartTime: new BigNumber(nextCycleStartTime[0]._hex).toNumber(),
            usersAllocation: isEmpty(getWalletAddress()) ? 0 : new BigNumber(usersAllocation[0]._hex).toNumber() / 10 ** 18,
            share: 0,
            currentepoch: currentepoch,
            deallocationFee: deallocationFee / 100,
            cycleDurationSeconds: new BigNumber(cycleDurationSeconds[0]._hex).toNumber()
        }
        console.log("objects",obj)
        if (!isEmpty(obj.usersAllocation)) {
            obj['share'] = (parseFloat(obj.usersAllocation) / parseFloat(obj.totalAllocation)) * 100
        }
        obj['apy'] = Apy
        dispatch({
            type: DIVIDENDS_DETAILS,
            payload: obj
        })
        dispatch({
            type :IS_LOAD_DIVIDENDS,
            payload:false
        })
        console.log('getDividendDetails', obj)
    } catch (err) {
        dispatch({
            type :IS_LOAD_DIVIDENDS,
            payload:false
        })
        console.log(err, 'getAllocationDetails__err')
    }
}

export const getDividendInfo = async (distributedLength, dispatch, totalAllocation) => {
    try {
        let DividendInfos = []
        let userInfos = []
        let farms = JSON.parse(localStorage.getItem("Farms"))
        let price = await getTokenPricesFromFarm(farms)
        let AllDividendShare = 0
        let nativeAddress = getNativeAddress()
        let dspinusd = price[nativeAddress.toLowerCase()]
        for (let i = 0; i < distributedLength; i++) {
            let tokenAddress = await GetDistributedToken(i)
            if (tokenAddress != ZEROTH_ADDRESS) {
                let notinusd = isEmpty(price[tokenAddress.toLowerCase()]) ? true : false
                let tokenInusd = isEmpty(price[tokenAddress.toLowerCase()]) ? 1 : price[tokenAddress.toLowerCase()]
                let Calls = [
                    {
                        address: getDividendAddress(),
                        name: 'dividendsInfo',
                        params: [tokenAddress]
                    }
                ]
                if (getWalletAddress()) {
                    Calls.push({
                        address: getDividendAddress(),
                        name: 'users',
                        params: [tokenAddress, getWalletAddress()]
                    }, {
                        address: getDividendAddress(),
                        name: 'pendingDividendsAmount',
                        params: [tokenAddress, getWalletAddress()]
                    })
                }

                const [dividendsInfo,userInfo,pendingDividendsAmount] =await multicall(DividendABI, Calls)
                console.log(dividendsInfo,userInfo,pendingDividendsAmount,'getDividendInfo')
                if(!isEmpty(userInfo)){
                    console.log(userInfo, 'GetuserInfo')
                    let UserInfo = {}
                    UserInfo['notinusd'] = notinusd
                    UserInfo['tokenAddress'] = tokenAddress
                    UserInfo['pendingDividends'] = new BigNumber(pendingDividendsAmount[0]._hex).toNumber() / 10 ** 18
                    UserInfo['pendingDividendsinusd'] = UserInfo.pendingDividends * tokenInusd
                    UserInfo['rewardDebt'] = new BigNumber(userInfo.rewardDebt._hex).toNumber() / 10 ** 18
                    userInfos.push(UserInfo)
                    if (distributedLength - 1 == i) {
                        dispatch({
                            type: DIVI_USER_INFO,
                            payload: userInfos
                        })
                    }
                }
                let Dividends ={}
                Dividends['notinusd'] = notinusd
                Dividends['tokenAddress'] = tokenAddress
                Dividends['currentDistributionAmount'] = new BigNumber(dividendsInfo.currentDistributionAmount._hex).toNumber() / 10 ** 18
                Dividends['currDistributionInusd'] = (Dividends.currentDistributionAmount) * tokenInusd
                Dividends['currentCycleDistributedAmount'] = new BigNumber(dividendsInfo.currentCycleDistributedAmount._hex).toNumber() / 10 ** 20
                Dividends['currDistributedInusd'] = (Dividends.currentCycleDistributedAmount) * tokenInusd
                Dividends['pendingAmount'] = new BigNumber(dividendsInfo.pendingAmount._hex) / 10 ** 18
                Dividends['pendingAmountInUsd'] = (Dividends.pendingAmount) * tokenInusd
                Dividends['distributedAmount'] = new BigNumber(dividendsInfo.distributedAmount._hex).toNumber() / 10 ** 18
                Dividends['distributedAmountInusd'] = (Dividends.distributedAmount) * tokenInusd
                Dividends['accDividendsPerShare'] = new BigNumber(dividendsInfo.accDividendsPerShare._hex).toNumber()
                AllDividendShare = parseFloat(AllDividendShare) + parseFloat(Dividends['accDividendsPerShare'])
                Dividends['lastUpdateTime'] = new BigNumber(dividendsInfo.lastUpdateTime._hex).toNumber()
                Dividends['cycleDividendsPercent'] = new BigNumber(dividendsInfo.cycleDividendsPercent._hex).toNumber()
                Dividends['distributionDisabled'] = dividendsInfo.distributionDisabled
                let isLp = await IsLpToken(tokenAddress)
                let calls = [
                    {
                        address: tokenAddress,
                        name: 'symbol'
                    },
                    {
                        address: tokenAddress,
                        name: 'name'
                    }
                ]
                if (isLp) {
                    calls = [...calls, ...[{
                        address: tokenAddress,
                        name: 'token0',
                    },
                    {
                        address: tokenAddress,
                        name: 'token1',
                    }]]
                }

                var pooldata = await multicall(pairABI, calls)
                if (isLp) {
                    let call = [
                        {
                            address: pooldata[2],
                            name: 'symbol'
                        }, {
                            address: pooldata[3],
                            name: 'symbol'
                        }
                    ]
                    var tokenDatas = await multicall(ERC20_ABI, call)
                }
                Dividends['symbol'] = isLp ? `${tokenDatas[0][0]}-${tokenDatas[0][1]}` : pooldata[0][0]
                Dividends['isLp'] = isLp
                Dividends['token1'] = isLp ? getTokens().find((val) => (val.address == pooldata[2])) : {}
                Dividends['token1'] = isLp ? isEmpty(Dividends['token1']) ? await TokenInfo(pooldata[2]) : Dividends['token1'] : {}
                Dividends['token2'] = isLp ? getTokens().find((val) => (val.address == pooldata[3])) : {}
                Dividends['token2'] = isLp ? isEmpty(Dividends['token2']) ? await TokenInfo(pooldata[3]) : Dividends['token2'] : {}
                Dividends['token'] = !isLp ? getTokens().find((val) => (val.address == tokenAddress)) : {}
                Dividends['token'] = !isLp ? isEmpty(Dividends['token']) ?   await TokenInfo(tokenAddress)  : Dividends['token'] :{}
                console.log(pooldata, 'getDividendInfo')
                console.log(Dividends, 'DividendsInfo')
                DividendInfos.push(Dividends)
                if (distributedLength - 1 == i) {
                    let apy = await GetDividendsAPR(AllDividendShare, totalAllocation, dspinusd)
                    console.log(DividendInfos, 'DividendInfos', AllDividendShare)
                    dispatch({
                        type: DISTRIBUTE_INFO,
                        payload: DividendInfos
                    })
                    return { DividendInfos: DividendInfos, apy: apy }
                }
            }
        }
    } catch (err) {
        console.log(err, 'getDividendInfo___err')
    }
}
export const GetDividendsAPR = async (AllDividendShare, totalAllocation, dspinusd) => {
    try {
        let XtokenSupply = await GetTotalSupply()
        const poolWeight = new BigNumber(totalAllocation).div(new BigNumber(XtokenSupply))
        let totalAllocationInusd = parseFloat(totalAllocation / 10 ** 18) * parseFloat(dspinusd)
        const RewardPerSeond = new BigNumber(AllDividendShare || 1).times(new BigNumber(poolWeight)).div(new BigNumber(10).pow(18))
        const RewardPerYear = RewardPerSeond.times(31540000)
        let apy = RewardPerYear.div(new BigNumber(totalAllocationInusd));
        console.log((apy).toNumber(), 'RewardPerYear', totalAllocationInusd, dspinusd)
        return apy.toNumber()
    } catch (err) {
        console.log(err, 'GetDividendsAPR__err')
    }
}
export const GetDistributedToken = async (index) => {
    try {
        let contract = await useDividendcontract()
        console.log("Dividendss",contract)
        let tokenAddress = await contract.methods.distributedToken(index).call()
        return tokenAddress
    } catch (err) {
        console.log(err, 'GetDistributedToken__err')
        return ZEROTH_ADDRESS
    }
}

export const DividendsInfo = async (token) => {
    try {
        let contract = await useDividendcontract()
        let dividendsInfo = await contract.methods.dividendsInfo(token).call()
        return dividendsInfo
    } catch (err) {
        console.log(err, 'DividendsInfo__err')
        return {}
    }
}

export const GetuserInfo = async (tokenAddress) => {
    try {
        let contract = await useDividendcontract()
        let userInfo = await contract.methods.users(tokenAddress, getWalletAddress()).call()
        return userInfo
    } catch (err) {
        console.log(err, 'GetuserInfo__err')
        return {}
    }
}

export const dividendsAmountPerSecond = (dividendsInfo, _cycleDurationSeconds) => {
    try {
        // let _distributedTokens = dividendsInfo.find((val)=>(val.tokenAddress == tokenAddress))
        // if (isEmpty(_distributedTokens)) return 0;
        let _distributedTokens = dividendsInfo
        let currentDistributionAmount = new BigNumber(_distributedTokens.currentDistributionAmount * 10 ** 18)
        console.log(currentDistributionAmount, 'currentDistributionAmount', (currentDistributionAmount).toNumber())
        return currentDistributionAmount.times(100).div(new BigNumber(_cycleDurationSeconds));
    } catch (err) {
        console.log(err, 'dividendsAmountPerSecond')
    }
}

export const pendingDividendsAmount = async (dividendsInfo_, users, usersAllocation, nextCycleStartTime, _cycleDurationSeconds, totalAllocation) => {
    try {
        let OneDay = new BigNumber(86400)
        let UsersAllocation = new BigNumber(usersAllocation)
        let TotalAllocation = new BigNumber(totalAllocation)
        let accDividendsPerShare = new BigNumber(dividendsInfo_.accDividendsPerShare);
        let lastUpdateTime = new BigNumber(dividendsInfo_.lastUpdateTime);
        let _CycleDurationSeconds = new BigNumber(_cycleDurationSeconds)
        let dividendAmountPerSecond_ = dividendsAmountPerSecond(dividendsInfo_, _CycleDurationSeconds);
        console.log(dividendAmountPerSecond_, 'dividendAmountPerSecond_', new BigNumber(dividendAmountPerSecond_).toNumber())
        let currentBlockTimestamp = await GetBlockTimeStamp()
        let CurrentBlockTimestamp = new BigNumber(currentBlockTimestamp)
        let NextCycleStartTime = new BigNumber(nextCycleStartTime)
        let cycleDividendsPercent = new BigNumber(dividendsInfo_.cycleDividendsPercent)
        let pendingAmount = new BigNumber(dividendsInfo_.pendingAmount * 10 ** 18)
        let rewardDebt = new BigNumber(users.rewardDebt)
        let pendingDividends = new BigNumber(users.pendingDividends)
        if (CurrentBlockTimestamp > NextCycleStartTime) {
            accDividendsPerShare = accDividendsPerShare.plus(
                (NextCycleStartTime.minus(lastUpdateTime)).times(dividendAmountPerSecond_).times(new BigNumber(10).pow(16)).div(TotalAllocation)
            );
            lastUpdateTime = NextCycleStartTime;
            dividendAmountPerSecond_ = pendingAmount.times(cycleDividendsPercent).div(100).div(
                _CycleDurationSeconds
            );
        }
        // accDividendsPerShare = accDividendsPerShare.plus(
        //     (CurrentBlockTimestamp.minus(lastUpdateTime)).times(dividendAmountPerSecond_).times(new BigNumber(10).pow(16)).div(TotalAllocation)
        //   );
        accDividendsPerShare = accDividendsPerShare.plus(
            (OneDay).times(dividendAmountPerSecond_).times(new BigNumber(10).pow(16)).div(TotalAllocation)
        );
        console.log(accDividendsPerShare,'accDividendsPerShare',new BigNumber(rewardDebt),rewardDebt)
        let dividendsAmount = UsersAllocation.times(accDividendsPerShare).div(new BigNumber(10).pow(18)).minus(new BigNumber(rewardDebt)).plus(pendingDividends)
        console.log(dividendsAmount,'dividendsAmount')
        return dividendsAmount.toNumber()
    } catch (err) {
        console.log(err, 'pendingDividendsAmount__err')
    }
}