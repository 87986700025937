import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/recenthistorystyle.module.css';
import metaicon from '../assests/images/metamask.png';
import walleticon from '../assests/images/walletconnect.png';
import { FaExternalLinkAlt } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Walletmodal from './walletmodal';
import BigNumber from 'bignumber.js';
import { IoMdClose } from "react-icons/io";


//lib
import { walletControl, addLiquiditylocal, addLiquidityETHlocal, removeLiquiditylocal, removeLiquidityETHlocal, swaplocal, approvelocal, getWalletAddress } from '../lib/localStorage';
import { isEmpty } from "../lib/isEmpty"

//hooks
import { GetTransaction } from '../hooks/useWeb3'
import { getTokens, GetCurrencyBalance, multicall } from '../hooks/useTokens'
import { GetChainIndex } from '../hooks/useWeb3'
import { GetPairInfo } from '../hooks/useFactory'
import { Balance } from '../hooks/useErc20'

//abi 
import router_Abi from '../config/abi/router.json'
import ERC20_Abi from '../config/abi/ERC20.json'
import pair_ABI from '../config/abi/pairAbi.json'

import { CHAINS } from '../config/env'

export default function Recenthistory(props) {

  const userdetail = useSelector((state) => state.admin)
  const [addLiquidity, setAddLiquidity] = useState("")
  const [removeLiquidity, setRemoveLiquidity] = useState("")
  const [swap, setSwap] = useState("")
  const [approve, setApprove] = useState("")
  const [walletmodalshow, setWalletmodalshow] = useState(false)



  useEffect(() => {
    transaction()
  }, [])

  const transaction = async () => {
    // if (!isEmpty(localStorage.getItem("addLiquidity"))) {
    //   let hash = localStorage.getItem("addLiquidity")
    //   let ContractABI = router_Abi
    //   let type = "addLiquidity"
    //   let transact = await GetTransaction(hash, ContractABI, type)

    //   transact = { ...transact }

    //   let token1 = getTokens().find((val) => (val?.address?.toLowerCase() == transact?.tokenA?.toLowerCase()));
    //   transact['tokenA'] = token1
    //   console.log("token1", token1)
    //   let token2 = getTokens().find((val) => (val?.address?.toLowerCase() == transact?.tokenB?.toLowerCase()));
    //   transact['tokenB'] = token2
    //   console.log("transact", transact, transact?.tokenA?.address, transact?.tokenB?.address)
    //   setAddLiquidity(transact)

    // }
    // if (!isEmpty(localStorage.getItem("removeLiquidity"))) {
    //   let hash = localStorage.getItem("removeLiquidity")
    //   let ContractABI = router_Abi
    //   let type = "removeLiquidity"
    //   let transact = await GetTransaction(hash, ContractABI, type)
    //   transact = { ...transact }

    //   let token1 = getTokens().find((val) => (val?.address?.toLowerCase() == transact?.tokenA?.toLowerCase()));
    //   transact['tokenA'] = token1
    //   console.log("token1", token1)
    //   let token2 = getTokens().find((val) => (val?.address?.toLowerCase() == transact?.tokenB?.toLowerCase()));
    //   transact['tokenB'] = token2
    //   console.log("transact", transact, transact?.tokenA?.address, transact?.tokenB?.address, transact.liquidity)
    //   setRemoveLiquidity(transact)


    //   //       var get = await GetPairInfo(transact?.tokenA?.address,transact?.tokenB?.address)
    //   //       var bal = await Balance(get)
    //   //       console.log("bal", bal)
    //   //       let calls = [{
    //   //         address: get,
    //   //         name: 'getReserves',
    //   //     },
    //   //     {
    //   //         address:get,
    //   //         name:'totalSupply'
    //   //     }
    //   // ]

    //   //     var pooldata = await multicall(pair_ABI, calls)
    //   //     console.log("pooldata",pooldata)
    //   //     if (pooldata) {
    //   //       // setPoolA(new BigNumber(pooldata[0][0]._hex).toNumber() / 10 ** 18)
    //   //       // setPoolB(new BigNumber(pooldata[0][1]._hex).toNumber() / 10 ** 18)
    //   //       console.log("pooldata", pooldata, new BigNumber(pooldata[0][0]._hex).toNumber() / 10 ** 18, new BigNumber(pooldata[0][1]._hex).toNumber() / 10 ** 18)
    //   //       console.log("share", new BigNumber(pooldata[1][0]._hex).toNumber() / 10 ** 18)
    //   //       // setTotalVal(new BigNumber(pooldata[1][0]._hex).toNumber() / 10 ** 18)

    //   //   }
    // }

    // if (!isEmpty(localStorage.getItem("swap"))) {
    //   let hash = localStorage.getItem("swap")
    //   let ContractABI = router_Abi
    //   let type = "swap"
    //   let transact = await GetTransaction(hash, ContractABI, type)
    //   console.log("transact", transact)
    //   transact = { ...transact }

    //   let token1 = getTokens().find((val) => (val?.address?.toLowerCase() == transact?.tokenA?.toLowerCase()));
    //   transact['tokenA'] = token1
    //   console.log("token1", token1)
    //   let token2 = getTokens().find((val) => (val?.address?.toLowerCase() == transact?.tokenB?.toLowerCase()));
    //   transact['tokenB'] = token2
    //   console.log("transact", transact)
    //   setSwap(transact)

    // }
    // if (!isEmpty(localStorage.getItem("approve"))) {
    //   let hash = localStorage.getItem("approve")
    //   let ContractABI = ERC20_Abi
    //   let type = "approve"
    //   let transact = await GetTransaction(hash, ContractABI, type)
    //   console.log("transact", transact)
    //   setApprove(transact)

    // }
    if (localStorage.getItem('addLiquidity')) {
      setAddLiquidity(localStorage.getItem('addLiquidity'))
    }
    if (localStorage.getItem('removeLiquidity')) {
      setRemoveLiquidity(localStorage.getItem('removeLiquidity'))
    }
    if (localStorage.getItem('swap')) {
      setSwap(localStorage.getItem('swap'))
    }
    if (localStorage.getItem('approve')) {
      setApprove(localStorage.getItem('approve'))
    }
  }

  const clearAll = async () => {
    localStorage.removeItem("addLiquidity")
    localStorage.removeItem("removeLiquidity")
    localStorage.removeItem('swap')
    localStorage.removeItem('approve');
    setAddLiquidity("")
    setRemoveLiquidity("")
    setSwap("")
    setApprove("")
  }

  return (
    <div className={`${cascading.walletmodalstyle}`}>
      <Modal centered show={props.show} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>
          <svg width="21" height="26" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.1951 9.08478C20.9946 9.45252 21.3445 10.3992 20.9771 11.1995L15.6224 22.8572L16.699 12.5645C16.7624 11.9559 16.4314 11.3742 15.8759 11.1185L10.7707 8.76967L12.8315 16.5138L0.499673 0.0214844L11.2076 4.94854L20.1957 9.08411L20.1951 9.08478Z" fill="#EAECF0"></path><path d="M10.8033 26.9189C10.0037 26.5512 9.65388 25.6045 10.0213 24.8042L15.3759 13.1465L14.2993 23.4392C14.2359 24.0478 14.5669 24.6294 15.1224 24.8852L20.2276 27.234L18.1668 19.4898L30.5 35.9808L19.7921 31.0538L10.8039 26.9175L10.8033 26.9189Z" fill="#EAECF0"></path></svg>
            <span className='mx-2'>Recent Transactions</span>
            <svg width="21" height="26" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8049 9.08478C10.0054 9.45252 9.65555 10.3992 10.0229 11.1995L15.3776 22.8572L14.301 12.5645C14.2376 11.9559 14.5686 11.3742 15.1241 11.1185L20.2293 8.76967L18.1685 16.5138L30.5003 0.0214844L19.7924 4.94854L10.8043 9.08411L10.8049 9.08478Z" fill="#EAECF0"></path><path d="M20.1967 26.9189C20.9963 26.5512 21.3461 25.6045 20.9787 24.8042L15.6241 13.1465L16.7007 23.4392C16.7641 24.0478 16.4331 24.6294 15.8776 24.8852L10.7724 27.234L12.8332 19.4898L0.5 35.9808L11.2079 31.0538L20.1961 26.9175L20.1967 26.9189Z" fill="#EAECF0"></path></svg>
           
            </h4>

            <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />
        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody} px-3`}>
          {userdetail?.walletStatus == 'connect' && !isEmpty(getWalletAddress()) ?
            isEmpty(addLiquidity) && isEmpty(removeLiquidity) && isEmpty(swap) && isEmpty(approve) ?
              <p className={`text-white text-center`} >No recent transactions</p> : '' :
            <Button className={`${cascading.btnstyle}`} onClick={() => setWalletmodalshow(true)}>
              Connect Wallet
            </Button>
          }

          {!isEmpty(addLiquidity) || !isEmpty(removeLiquidity) || !isEmpty(swap) || !isEmpty(approve) ?
            <div className={`${cascading.clrsec} mb-4`}>
              {/* <p className={` ${cascading.label}`}>Recent transactions</p> */}
              <button className={`ms-auto btn ${cascading.clrbtn}`} onClick={() => { clearAll() }}>Clear all</button>
            </div> : ""}


          {!isEmpty(addLiquidity) ?
            <button
              className={`${cascading.translist} ${cascading.translistcenter}`}
              onClick={() => {
                window.open(`${CHAINS[GetChainIndex()].TRANSACTION}/${JSON.parse(localStorage.getItem("addLiquidity")).txhash}`, '_blank');
                // window.location.href=`${CHAINS[GetChainIndex()].TRANSACTION}/${localStorage.getItem("addLiquidity")}`
              }} >
              {JSON.parse(localStorage.getItem("addLiquidity")).qureyString}
              <FaExternalLinkAlt fill='#fff' />
              {/* <TiTick /> */}
            </button> : ""}

          {!isEmpty(removeLiquidity) ?
            <button
              className={`${cascading.translist} ${cascading.translistcenter}`}
              onClick={() => {
                window.open(`${CHAINS[GetChainIndex()].TRANSACTION}/${JSON.parse(localStorage.getItem("removeLiquidity")).txhash}`, '_blank');
                // window.location.href=`${CHAINS[GetChainIndex()].TRANSACTION}/${localStorage.getItem("removeLiquidity")
              }} >
              {JSON.parse(localStorage.getItem("removeLiquidity")).qureyString}
              <FaExternalLinkAlt fill='#fff' /> 
              {/* <TiTick /> */}
            </button> : ""}

          {!isEmpty(swap) ?
            <button
              className={`${cascading.translist} ${cascading.translistcenter}`}
              onClick={() => {
                window.open(`${CHAINS[GetChainIndex()].TRANSACTION}/${JSON.parse(localStorage.getItem("swap")).txhash}`, '_blank');
                // window.location.href=`${CHAINS[GetChainIndex()].TRANSACTION}/${localStorage.getItem("swap")}`
              }} >
              {JSON.parse(localStorage.getItem("swap")).qureyString}
              <FaExternalLinkAlt fill='#fff' /> 
              {/* <TiTick /> */}
            </button> : ""}

          {!isEmpty(approve) ?
            <div className='text-center'>
              <button
                className={`${cascading.translist} ${cascading.translistcenter}`}
                onClick={() => {
                  window.open(`${CHAINS[GetChainIndex()].TRANSACTION}/${JSON.parse(localStorage.getItem('approve')).txhash}`, '_blank');
                  // window.location.href=`${CHAINS[GetChainIndex()].TRANSACTION}/${localStorage.getItem("approve")}`
                }} >
                {JSON.parse(localStorage.getItem('approve')).qureyString} <FaExternalLinkAlt fill='#fff' /> 
                {/* <TiTick /> */}
              </button> </div> : ""}

        </Modal.Body>
        <Modal.Footer className={`${cascading.modalclr} ${cascading.modalfooter}`}>


        </Modal.Footer>
      </Modal>
      {walletmodalshow && <Walletmodal show={walletmodalshow} onHide={() => setWalletmodalshow(false)} />}

    </div>

  )
}

