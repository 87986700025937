// import constant
import {
    POOL_DATA,
    POOL_USER_DATA
 } from '../constants';
 
 const initialValue = {
     pooldata: [],
     pooluserData:[]
 }
 
 //  console.log("initialValue----->>>",initialValue);
 const PoolRedux = (state = initialValue, action) => {
 
     switch (action.type) {
         case POOL_DATA:
             return {
                 ...state,
                 pooldata: action.payload
             };
             case POOL_USER_DATA:
             return {
                 ...state,
                 pooluserData: action.payload
             };
         default:
             return state;
     }
 }
 
 export default PoolRedux;