import React, { useState } from "react";
import { IoCopyOutline } from "react-icons/io5";

import NewHeader from "../common/NewHeader";
import NewFooter from "../common/NewFooter";
import cascading from "../assests/css/newlandingstyle.module.css";
import { Link } from "react-router-dom";
import { FaPaste } from "react-icons/fa";
import Polygon from "../assests/svg/Polygon.js";
import Roadmap from "../assests/svg/Roadmap.js";
import IntroVideoModal from "../modals/IntroVideoModal.jsx";

import earnBg from "../assests/images/earn_bg.webp";
import launchpadImg from "../assests/images/launchpadHead.png";
import stealthLaunch from "../assests/images/stealth_launch.webp";
import audit from "../assests/images/audited.webp";
import linear from "../assests/images/linear.webp";
import swing from "../assests/images/swing.webp";
import xfai from "../assests/images/xfai.webp";
import rango from "../assests/images/rango.webp";
import symbios from "../assests/images/symbios.webp";
import layer3 from "../assests/images/layer3.webp";
import pink from "../assests/images/pink.webp";
import ca from "../assests/images/ca.webp";
import solid from "../assests/images/solid.webp";
import mises from "../assests/images/mises.webp";
import piena from "../assests/images/piena.webp";
import magic from "../assests/images/magic.webp";
import okapi from "../assests/images/okapi.webp";
import dex from "../assests/images/dex.webp";
import midle from "../assests/images/midle.webp";
import orbler from "../assests/images/orbler.webp";
import aws from "../assests/images/aws.webp";
import oat from "../assests/images/oat.webp";
import taskon from "../assests/images/taskon.webp";
import zealy from "../assests/images/zealy.webp";
import tokenomic from "../assests/images/tokenomic_single.webp";
import tokenomicLg from "../assests/images/tokenomic_lg.webp";
import trading from "../assests/images/trading.svg";
import ecoearn from "../assests/images/earn.svg";
import ecolaunch from "../assests/images/launch_pad.svg";
import tradeSwap from "../assests/images/swap.svg";
import videoIntro from "../assests/images/video_intro.webp";
import VideoPlay from "../assests/svg/VideoPlay.js";
import playButton from "../assests/images/icon-container.png";
import { getCmslists } from "../Actions/User.js";
import { useEffect } from "react";
import { API_URL } from '../config/env.js'

const partnerImages = [
  {
    id: 1,
    img: swing,
  },
  {
    id: 2,
    img: xfai,
  },
  {
    id: 3,
    img: rango,
  },
  {
    id: 4,
    img: symbios,
  },
  {
    id: 5,
    img: layer3,
  },
  {
    id: 6,
    img: pink,
  },
  {
    id: 7,
    img: ca,
  },
  {
    id: 8,
    img: solid,
  },
  {
    id: 9,
    img: mises,
  },
  {
    id: 10,
    img: piena,
  },
  {
    id: 11,
    img: magic,
  },
  {
    id: 12,
    img: okapi,
  },
  {
    id: 13,
    img: dex,
  },
  {
    id: 14,
    img: midle,
  },
  {
    id: 15,
    img: orbler,
  },
  {
    id: 16,
    img: aws,
  },
  {
    id: 17,
    img: oat,
  },
  {
    id: 18,
    img: taskon,
  },
  {
    id: 19,
    img: zealy,
  },
];

const roadmapDetails2023One = [
  {
    id: 1,
    content: "Research (visual, chain, marketing strategy, & partners)",
  },
  {
    id: 2,

    content: "UX & UI for DEX",
  },
  {
    id: 3,
    content: "Marketing Planning",
  },
  {
    id: 4,
    content: "Community Managing Planning",
  },
  {
    id: 5,
    content: "Social Channel Set-up",
  },
  {
    id: 6,
    content: "Partners List Collecting",
  },
  {
    id: 7,
    content: "Reach Out Linea",
  },
  {
    id: 8,
    content: "Testing Feature",
  },
];
const roadmapDetails2023Two = [
  {
    id: 1,
    content: "UX & UI for Launchpad",
  },
  {
    id: 2,

    content: "Official Meeting with Linea",
  },
  {
    id: 3,
    content: "Audit Research",
  },
  {
    id: 4,
    content: "Partners List Filtering",
  },
  {
    id: 5,
    content: "Audit with Solidproof.io",
  },
  {
    id: 6,
    content: "Onboarding Linea Ecosystem",
  },
];
const roadmapDetails2024One = [
  {
    id: 1,
    content: "Community Expanding",
  },
  {
    id: 2,

    content: "Prepared for Stealth Launch",
  },
  {
    id: 3,
    content: "Expanding Partners & Collaborators Ecosystem",
  },
  {
    id: 4,
    content: "Integrating Bridge Feature",
  },
  {
    id: 5,
    content: "Stealth Launch",
  },
  {
    id: 6,
    content: "Roadmap Updating",
  },
  {
    id: 7,
    content: "Website Updating",
  },
  {
    id: 8,
    content: "Partner Collaborating",
  },
];
const roadmapDetails2024Two = [
  {
    id: 1,
    content: "Launchpad Release",
  },
  {
    id: 2,

    content: "Lightning pool rewards can be harvested",
  },
  {
    id: 3,
    content: "Farming rewards start",
  },
  {
    id: 4,
    content: "Farms open",
  },
  {
    id: 5,
    content: "Lightning pool rewards start being allocated to depositors",
  },
  {
    id: 6,
    content: "Lightning pool is published and open to deposits",
  },
  {
    id: 7,
    content: "Done with Staking & Farming Feature",
  },
  {
    id: 8,
    content: "Done with Add Liquidity Feature",
  },
  {
    id: 9,
    content: "Release Swap & Bridge Feature",
  },
  {
    id: 10,
    content: "Partner Collaborating",
  },
  {
    id: 11,
    content: "Fair Launch",
  },
];
const roadmapDetails2024Three = [
  {
    id: 1,
    content: "Getting Projects on Launchpad",
  },
  {
    id: 2,

    content: "DEX Volume Boosting",
  },
  {
    id: 3,
    content: "Community Boosting",
  },
  {
    id: 4,
    content: "Partners Collaboration Boosting",
  },
  {
    id: 5,
    content: "Roadmap Updating",
  },
];


const NewLanding = () => {
  const [showVideoIntro, setShowVideoIntro] = useState(false);
  const [AllCmsdata, setAllCmsdata] = useState([])
  const [inputValue, setInputValue] = useState('');
  const handleVideoIntroClose = () => {
    setShowVideoIntro(false);
  };
  useEffect(() => {
    GetCmslists()

  }, [])

  const GetCmslists = async () => {
    try {
      let resp = await getCmslists();
      console.log("getCmslists", resp.data.data.data);
      setAllCmsdata(resp.data.data.data)
    } catch (error) {
      console.log("error", error);
    }
  }

  const CmsContent = {
    NATIVE_DEX: AllCmsdata.find((val) => val.identifier == "NativeDex"),
    WHAT_IS_FLASHPAD: AllCmsdata.find((val) => val.identifier == "WHAT IS FLASHPAD"),
    FlashpadEco: AllCmsdata.find((val) => val.identifier == "FlashpadEco"),
    Trading: AllCmsdata.find((val) => val.identifier == "Trading"),
    Earn: AllCmsdata.find((val) => val.identifier == "Earn"),
    FlashpadEcoLaunchpad: AllCmsdata.find((val) => val.identifier == "FlashpadEcoLaunchpad"),
    FlashpadTrading: AllCmsdata.find((val) => val.identifier == "FlashpadTrading"),
    Swap: AllCmsdata.find((val) => val.identifier == "Swap"),
    AddLiquidity: AllCmsdata.find((val) => val.identifier == "AddLiquidity"),
    Thunderpool: AllCmsdata.find((val) => val.identifier == "Thunderpool"),
    TimeLockBoosting: AllCmsdata.find((val) => val.identifier == "TimeLockBoosting"),
    Launchpad: AllCmsdata.find((val) => val.identifier == "Launchpad"),
    BasicLaunch: AllCmsdata.find((val) => val.identifier == "BasicLaunch"),
    PremiumLaunch: AllCmsdata.find((val) => val.identifier == "PremiumLaunch"),
    StealthLaunch: AllCmsdata.find((val) => val.identifier == "StealthLaunch"),
    SolidProof: AllCmsdata.find((val) => val.identifier == "SolidProof"),
    Partner: AllCmsdata.find((val) => val.identifier == "Partner"),
    videoIntro: AllCmsdata.find((val) => val.identifier == "videointro"),


  }
  console.log("CmsContent", CmsContent);

  return (
    <>
      <IntroVideoModal
        showVideoIntro={showVideoIntro} Data={CmsContent?.videoIntro}
        handleVideoIntroClose={handleVideoIntroClose}
      />
      {/* <div className={`${cascading.newlanding}`}>
        <NewHeader />

        <main>
          <div className={`${cascading.minht100}`}>
            <section className={`${cascading.banner}`}>
              <div className={`${cascading.container} container`}>1</div>
            </section>
          </div>
        </main>
        <NewFooter />
      </div> */}
      <NewHeader />
      <main className={`${cascading.newLanding}`}>
        <section className={`${cascading.banner}`}>
          <div
            className={`${cascading.landingContainer} container d-flex flex-column justify-content-between `}
          >
            <div>
              <h1 className={`${cascading.bannerh1}`}>
                {/* A Native DEX & Launchpad On The Linea Chain */}
                {CmsContent?.NATIVE_DEX?.Title}
              </h1>

              <div
                className={`d-flex align-items-center justify-content-center pt-4`}
              >
                <div className={`${cascading.contract} position-relative`}>
                  <span
                    className={`${cascading.contractbg} position-absolute`}
                  ></span>
                  <div
                    className={`${cascading.name} position-relative d-flex align-items-center justify-content-center`}
                  >
                    CONTRACT
                  </div>
                </div>
                <div
                  className={`${cascading.borderGradient} d-flex align-items-center`}
                >
                  <span className="d-none d-sm-block">
                    F8jePryFizz........rMye2GX
                  </span>{" "}
                  <span className=" d-sm-none">F8jePryFizz........rMye2GX</span>


                  <button
                    className={`${cascading.copyButton} d-flex align-items-center justify-content-center p-2`}
                     >
                    <IoCopyOutline 
                    />
                
                  </button>
                </div>
              </div>

              <div className="d-flex flex-column flex-sm-row mt-3 mt-sm-0 align-items-center justify-content-center gap-4 pt-4">
                <Link to="https://www.pinksale.finance/solana/launchpad/4uABsicd2ZusAeeqKua6RahSBheDnnZ8s5XSGnTR8GMh">
                  <button
                    className={`${cascading.notchedButton} position-relative`}
                  >
                    <span className={`${cascading.notchedBg}`}></span>
                    <div className={`${cascading.content}`}>Pinksale</div>
                  </button>
                </Link>
                <Link to="https://flashpaddex.maticz.in/swap">
                  <button
                    className={`${cascading.notchedButton} ${cascading.borderButton} position-relative`}
                  >
                    <span className={` ${cascading.notchBorder}`}></span>
                    <div className={`${cascading.content}`}>Access App</div>
                  </button>
                </Link>
              </div>
            </div>
            <div
              className={`${cascading.explore}  pt-5 d-flex flex-column-reverse flex-sm-row align-items-center justify-content-between `}
            >
              <div className={`d-flex align-items-center gap-3 mt-5 mt-sm-0`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  fill="none"
                >
                  <g clip-path="url(#scroll_svg__a)">
                    <path
                      fill="#fff"
                      d="M11.141 4.836c-1.582 0-2.387.17-3.128.565A3.45 3.45 0 0 0 6.565 6.85C6.169 7.59 6 8.395 6 9.976v5.718c0 1.582.169 2.387.565 3.128q.504.944 1.448 1.448c.74.396 1.546.565 3.128.565h1.718c1.582 0 2.387-.169 3.128-.565a3.45 3.45 0 0 0 1.448-1.448c.396-.74.565-1.546.565-3.128V9.976c0-1.582-.169-2.387-.565-3.128A3.45 3.45 0 0 0 15.987 5.4c-.74-.395-1.546-.564-3.127-.564zm0-2h1.718c2.014 0 3.094.278 4.072.801a5.45 5.45 0 0 1 2.268 2.268c.523.978.801 2.058.801 4.072v5.718c0 2.014-.278 3.094-.801 4.072a5.45 5.45 0 0 1-2.268 2.268c-.978.523-2.058.801-4.072.801H11.14c-2.014 0-3.094-.278-4.072-.8A5.45 5.45 0 0 1 4.8 19.766c-.522-.978-.8-2.058-.8-4.072V9.976c0-2.014.278-3.094.801-4.072A5.45 5.45 0 0 1 7.07 3.637c.977-.523 2.057-.8 4.071-.8m-.141 4h2v5h-2z"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="scroll_svg__a">
                      <path fill="#fff" d="M0 .836h24v24H0z"></path>
                    </clipPath>
                  </defs>
                </svg>
                <p className={`${cascading.textSm} mb-0`}>
                  Scroll to <b>Explore</b>
                </p>
              </div>
              <div className={`${cascading.exploreRight} `}>
                <div
                  className={`${cascading.textSm} text-center text-sm-start`}
                >
                  Follow More
                </div>
                <div
                  class={`${cascading.textSm} ${cascading.bannerLinks} d-flex align-items-center w-100 mt-2`}
                >
                  <a
                    href="https://twitter.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    X
                  </a>
                  <div className={`${cascading.whiteDot}`}></div>
                  <a
                    href="https://medium.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Medium
                  </a>
                  <div className={`${cascading.whiteDot}`}></div>
                  <a
                    href="https://t.me/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Telegram
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={`${cascading.about} mt-5`} name="features">
          <div className={`${cascading.aboutTop}`}>
            <div className={`${cascading.container} container`}>
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <div className="d-flex flex-column align">
                    <p className={`${cascading.titlexs} mb-0 text-center`}>
                      About
                    </p>
                    <p
                      className={`${cascading.textOrbiXxl} text-center w-100 mt-1`}
                    >
                      {/* What Is Flashpad? */}
                      {CmsContent?.WHAT_IS_FLASHPAD?.Title}
                    </p>
                    <p
                      className={`${cascading.subTextMd} ${cascading.subTextLg} mt-0 `}
                    >
                      {/* Inspired by Thunder & Lighting, powering all utilities to
                      run fast like a Flash. */}
                      {CmsContent?.WHAT_IS_FLASHPAD?.Content}
                    </p>
                    <Link to="https://docs.flashpad.io/" className="mx-auto">
                      <button
                        className={`${cascading.notchedButton} position-relative `}
                      >
                        <span className={`${cascading.notchedBg}`}></span>
                        <div className={`${cascading.content}`}>Learn More</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${cascading.aboutBottom} `}>
            <div className={`${cascading.container} container`}>
              <div className="row">
                <div className="col-12 position-relative">
                  <div>
                    <div className={`${cascading.titleCard} mx-auto d-xl-none`}>
                      {CmsContent?.videoIntro?.Title}
                    </div>
                    <div>
                      <img src={videoIntro} alt="intro" className="img-fluid" />
                    </div>
                    <div
                      className={`${cascading.videoPlay} position-absolute d-flex flex-column align-items-center`}
                    >
                      <div
                        className={`${cascading.titleCard} mx-auto d-none d-xl-block mb-4`}
                      >
                        {CmsContent?.videoIntro?.Title}
                      </div>
                      <button
                        className={`${cascading.videoPlayButton}`}
                        onClick={() => setShowVideoIntro(true)}
                      >
                        <img
                          src={playButton}
                          alt="play"
                          className="img-fluid"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={`${cascading.eco} `}>
          <div>
            <p className={`${cascading.titlexs} mb-0`}>Solutions</p>
            <p className={`${cascading.textOrbiXxl} text-start`}>
              {/* Flashpad Eco */}
              {CmsContent?.FlashpadEco?.Title}
            </p>
            <p className={`${cascading.subTextMd} ${cascading.subTextLg} `}>
              {/* The native DEX & Launchpad on the Linea chain has the following
              features. */}
              {CmsContent?.FlashpadEco?.Content}
            </p>

            <div className={`${cascading.ecoCard} position-relative`}>
              <div
                className={`${cascading.verticalLine} d-lg-none position-absolute`}
              ></div>{" "}
              <div
                className={`${cascading.horizontalLine} d-none d-lg-block position-absolute`}
              ></div>
              <div className={`row`}>
                <div
                  className={`col-12 col-lg-4 d-flex justify-content-center position-relative`}
                >
                  <div
                    className={`${cascading.colVertical} position-absolute d-none d-lg-block`}
                  ></div>
                  <div
                    className={`${cascading.content} d-flex flex-column align-items-center`}
                  >
                    <div
                      className={`${cascading.ecoCircle} d-flex align-items-center justify-content-center`}
                    >
                      <div
                        className={`${cascading.ecoImgwrap} d-flex align-items-center justify-content-center`}
                      >
                        <img
                          src={trading}
                          alt="trading"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <p className={`${cascading.title}`}>{CmsContent?.Trading?.Title}</p>
                    <p className={`${cascading.subTitle} text-center`}>
                      {CmsContent?.Trading?.Content}
                    </p>
                  </div>
                </div>
                <div
                  className={`col-12 col-lg-4 d-flex justify-content-center position-relative`}
                >
                  <div
                    className={`${cascading.colVertical} position-absolute d-none d-lg-block`}
                  ></div>
                  <div className={`${cascading.horizontal} d-lg-none`}></div>
                  <div
                    className={`${cascading.content} d-flex flex-column align-items-center`}
                  >
                    <div
                      className={`${cascading.ecoCircle}  d-flex align-items-center justify-content-center position-relative`}
                    >
                      <div
                        className={`${cascading.ecoImgwrap} d-flex align-items-center justify-content-center`}
                      >
                        <img
                          src={ecoearn}
                          alt="trading"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <p className={`${cascading.title}`}>{CmsContent?.Earn?.Title}</p>
                    <p className={`${cascading.subTitle} text-center`}>
                      {CmsContent?.Earn?.Content}
                    </p>
                  </div>
                </div>{" "}
                <div
                  className={`col-12 col-lg-4 d-flex justify-content-center position-relative`}
                >
                  <div
                    className={`${cascading.colVertical} position-absolute d-none d-lg-block`}
                  ></div>
                  <div
                    className={`${cascading.content} d-flex flex-column align-items-center pb-0`}
                  >
                    <div
                      className={`${cascading.ecoCircle} d-flex align-items-center justify-content-center`}
                    >
                      <div
                        className={`${cascading.ecoImgwrap} d-flex align-items-center justify-content-center`}
                      >
                        <img
                          src={ecolaunch}
                          alt="trading"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <p className={`${cascading.title}`}>{CmsContent?.FlashpadEcoLaunchpad?.Title}</p>
                    <p className={`${cascading.subTitle} text-center mb-0`}>
                      {CmsContent?.FlashpadEcoLaunchpad?.Content}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className={`${cascading.eco} ${cascading.trade}`}
          name="trading"
        >
          <div>
            <p className={`${cascading.titlexs} `}>Solutions</p>
            <p className={`${cascading.textOrbiXxl} text-start`}>
              {/* Flashpad Trading Options */}
              {CmsContent?.FlashpadTrading?.Title}
            </p>
            <p className={`${cascading.subTextMd} ${cascading.subTextLg} `}>
              {/* he native DEX & Launchpad on the Linea chain has the following
              features. */}
              {CmsContent?.FlashpadTrading?.Content}
            </p>

            <div
              className={`${cascading.ecoCard} ${cascading.tradeCard} position-relative`}
            >
              <div className="row">
                <div className={`${cascading.standardCol} col-12 col-lg-6`}>
                  <div>
                    <div className={`${cascading.outerline}`}>
                      <div className={`${cascading.innerBg}`}>
                        <div
                          className={`${cascading.shadowCircle} d-flex align-items-center justify-content-center`}
                        >
                          <img
                            src={tradeSwap}
                            alt="swap"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className={`${cascading.contentWrap}`}>
                      <p className={`${cascading.titlexs} mb-0`}>option 1</p>
                      <p className={`${cascading.title}`}>{CmsContent?.Swap?.Title}</p>
                      <p className={`${cascading.subContent}`}>{CmsContent?.Swap?.Content}</p>
                    </div>
                    <Link to="https://flashpaddex.maticz.in/swap">
                      <button
                        className={`${cascading.notchedButton} position-relative`}
                      >
                        <span className={`${cascading.notchedBg}`}></span>
                        <div className={`${cascading.content}`}>Access</div>
                      </button>
                    </Link>
                  </div>
                </div>{" "}
                <div
                  className={`${cascading.tradeCardCol} ${cascading.standardCol} col-12 col-lg-6 position-relative`}
                >
                  <div
                    className={`${cascading.blendBg} position-absolute`}
                  ></div>
                  <div>
                    <div className={`${cascading.outerline}`}>
                      <div className={`${cascading.innerBg}`}>
                        <div
                          className={`${cascading.shadowCircle} d-flex align-items-center justify-content-center`}
                        >
                          <img
                            src={tradeSwap}
                            alt="swap"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className={`${cascading.contentWrap}`}>
                      <p className={`${cascading.titlexs} mb-0`}>option 2</p>
                      <p className={`${cascading.title}`}>{CmsContent?.AddLiquidity?.Title}</p>
                      <p className={`${cascading.subContent}`}>
                        {/* Adding liquidity to the pool means you'll be rewarded
                        with LP tokens. These tokens grant you a share in the
                        liquidity pool and also entitle you to trading fees. */}
                        {CmsContent?.AddLiquidity?.Content}
                      </p>
                    </div>
                    <Link to="https://flashpaddex.maticz.in/liquidity">
                      <button
                        className={`${cascading.notchedButton} position-relative`}
                      >
                        <span className={`${cascading.notchedBg}`}></span>
                        <div className={`${cascading.content}`}>Access</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={`${cascading.earnings} `} name="earning">
          <div className={`${cascading.landingContainer} container`}>
            <div className="row">
              <div className={`${cascading.earnBorder} col-lg-7`}>
                <div>
                  <div className={`${cascading.padTopBottom} row`}>
                    <div className="col-12 col-sm-7 col-md-5">
                      <div>
                        <p className={`${cascading.titlexs} mb-0`}>Earning</p>
                        <p
                          className={`${cascading.titleSm}  ${cascading.marginTopBottom}`}
                        >
                          Earning
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-sm-5 col-md-7">
                      <div
                        className={`${cascading.flexCol} d-flex flex-column`}
                      >
                        <p className={`${cascading.textXl} mb-0`}>
                          {CmsContent?.Thunderpool?.Title}
                        </p>
                        <p className={`${cascading.subText} mb-0`}>
                          {/* Staking & Farming all in one place. */}
                          {CmsContent?.Thunderpool?.Content}
                        </p>
                        <Link to="/">
                          <button
                            className={`${cascading.notchedButton} position-relative`}
                          >
                            <span className={`${cascading.notchedBg}`}></span>
                            <div className={`${cascading.content}`}>Access</div>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className={`${cascading.earningImageWrap} mx-auto`}>
                    <img src={earnBg} alt="earning" className="img-fluid" />
                  </div>
                </div>
              </div>
              <div className={`${cascading.ptxs} col-lg-5`}>
                <div className={`${cascading.flexCol} d-flex flex-column`}>
                  <p className={`${cascading.textXl} mb-0`}>
                    {CmsContent?.TimeLockBoosting?.Title}
                  </p>
                  <p className={`${cascading.subText} mb-0`}>
                    {/* Time locking lets you decide how long to lock your staked
                    position. You can do this when you make the spNFT or later
                    in the position management panel. Pick any number of days
                    for the lock, but for the biggest boost, choose a 6-month
                    lock. This gives you the maximum boost, capped at double the
                    value. */}
                    {CmsContent?.TimeLockBoosting?.Content}
                  </p>
                  <Link to="/">
                    <button
                      className={`${cascading.notchedButton} position-relative`}
                    >
                      <span className={`${cascading.notchedBg}`}></span>
                      <div className={`${cascading.content}`}>Access</div>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={`${cascading.launchpad} position-relative`}>
          <div className={`${cascading.headerimg} position-absolute`}>
            <img src={launchpadImg} alt="launchpad" className="img-fluid" />
          </div>
          <div className={`${cascading.markcircle} position-absolute`}></div>
          <div className={`${cascading.centerline} position-absolute`}></div>
          <div className={`${cascading.container} container`}>
            <div className={`${cascading.launchContainer}`}>
              <p className={`${cascading.titlexs} mb-0`}>LAUNCHPAD</p>
              <p
                className={`${cascading.titleLg}  ${cascading.marginTopBottom}`}
              >
                {CmsContent?.Launchpad?.Title}
              </p>
              <p className={`${cascading.subTextMd} ${cascading.subTextLg}`}>
                {/* Flashpad introduces a distinct and impartial avenue for
                universal participation in token sales, devoid of any
                prerequisites. Employing an equitable launch model, all
                individuals are granted an equal chance to engage in token
                acquisition, irrespective of their influence or investment
                magnitude. */}
                {CmsContent?.Launchpad?.Content}
              </p>
              <div className="row">
                <div className="col-md-6 pe-lg-4">
                  <div
                    className={`${cascading.launchCard} position-relative d-flex flex-column flex-lg-row align-items-start justify-content-between h-100`}
                  >
                    <div
                      className={`${cascading.yellowLine} position-absolute`}
                    ></div>
                    <div
                      className={`${cascading.launchCol} d-flex flex-column`}
                    >
                      <p className={`${cascading.titlexs} mb-0`}>Option 1</p>
                      <p className={`${cascading.textOrbiSm} mb-0`}>
                        {CmsContent?.BasicLaunch?.Title}
                      </p>
                      <p className={`${cascading.subTextMd} mt-2 mb-0`}>
                        {/* Basic Badge, Supports & Marketing Activities */}
                        {CmsContent?.BasicLaunch?.Content}
                      </p>
                    </div>
                    <Link to="/">
                      <button
                        className={`${cascading.notchedButton} ${cascading.borderButton} position-relative mt-4`}
                      >
                        <span className={` ${cascading.notchBorder}`}></span>
                        <div className={`${cascading.content}`}>Launch Now</div>
                      </button>
                    </Link>
                  </div>
                </div>{" "}
                <div className="col-md-6 ps-lg-4 mt-5 mt-md-0">
                  <div
                    className={`${cascading.launchCard} position-relative d-flex flex-column flex-lg-row align-items-start justify-content-between h-100`}
                  >
                    <div
                      className={`${cascading.yellowLine} position-absolute`}
                    ></div>
                    <div
                      className={`${cascading.launchCol} d-flex flex-column`}
                    >
                      <p className={`${cascading.titlexs} mb-0`}>Option 2</p>
                      <p className={`${cascading.textOrbiSm} mb-0`}>
                        {CmsContent?.PremiumLaunch?.Title}

                      </p>
                      <p className={`${cascading.subTextMd} mt-2 mb-0`}>
                        {/* Premium Badge, Direct Supports, Connection Boost &amp;
                        Personal Customized Marketing Activities */}
                        {CmsContent?.PremiumLaunch?.Content}
                      </p>
                    </div>
                    <Link to="/">
                      <button
                        className={`${cascading.notchedButton} ${cascading.borderButton} position-relative mt-4`}
                      >
                        <span className={` ${cascading.notchBorder}`}></span>
                        <div className={`${cascading.content}`}>Launch Now</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={`${cascading.launchSolutions} ${cascading.py5}`}>
          <div className={`${cascading.landingContainer} container`}>
            <div className="row">
              <div
                className={`${cascading.solutionsFirst} col-lg-6 pe-lg-5 position-relative `}
              >
                <div
                  className={`${cascading.stealthBorder} position-absolute d-lg-none`}
                ></div>
                <div
                  className={`${cascading.markcircle} position-absolute d-lg-none`}
                ></div>{" "}
                <div
                  className={`${cascading.stealthHorizontalBorder} position-absolute d-none d-lg-block`}
                ></div>
                <div
                  className={`${cascading.horizontalMarkcircle} position-absolute d-none d-lg-block`}
                ></div>
                <div className={`${cascading.stealthCard}`}>
                  <p className={`${cascading.titlexs} mb-0`}>Solutions</p>
                  <p className={`${cascading.textOrbiXl}`}> {CmsContent?.StealthLaunch?.Title}</p>
                  <div className={`${cascading.data}`}>
                    <p className="">
                      {" "}
                      <b>Launch Date:</b> 24th, January 2024
                    </p>
                    <p className="mt-4">
                      <b>Status:</b> Launched{" "}
                    </p>
                    <p className="mt-4">
                      {" "}
                      <b>ATH: </b>$0.05275
                    </p>
                  </div>
                  <div className="mt-5 d-flex align-items-center flex-wrap gap-4">
                    <Link to="https://www.coingecko.com/en/coins/flashpad-token">
                      <button
                        className={`${cascading.notchedButton} position-relative`}
                      >
                        <span className={`${cascading.notchedBg}`}></span>
                        <div className={`${cascading.content}`}>Coingecko</div>
                      </button>
                    </Link>
                    <Link to="https://dappradar.com/token/flashpad-token">
                      <button
                        className={`${cascading.notchedButton} ${cascading.borderButton} position-relative `}
                      >
                        <span className={` ${cascading.notchBorder}`}></span>
                        <div className={`${cascading.content}`}>DappRadar</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className={`${cascading.py5sm} col-lg-6 ps-lg-5  position-relative`}
              >
                <div
                  className={`${cascading.stealthBorder} position-absolute`}
                ></div>
                <div
                  className={`${cascading.markcircle} position-absolute`}
                ></div>
                <div className={`${cascading.launchDashboard} `}>
                  <img
                    src={stealthLaunch}
                    alt="stealth launch"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={`${cascading.audit} position-relative`}>
          <img
            src={audit}
            alt="audit"
            className={`${cascading.auditBg} img-fluid position-absolute`}
          />
          <div className={`${cascading.container} ${cascading.py3} container`}>
            <div className="row justify-content-center">
              <div className="col-12 col-xl-6 d-flex justify-content-center">
                <div>
                  <p className={`${cascading.titlexs} mb-0`}>LAUNCHPAD</p>
                  <p className={`${cascading.textOrbiXxl}`}>
                    {CmsContent?.SolidProof?.Title}
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link to="https://docs.flashpad.io/references/audits/phase-1">
                      <button
                        className={`${cascading.notchedButton} position-relative`}
                      >
                        <span className={`${cascading.notchedBg}`}></span>
                        <div className={`${cascading.content}`}>
                          Read Report
                        </div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={`${cascading.partner}`}>
          <div
            className={`${cascading.container}  ${cascading.mt3} container `}
          >
            <div className="d-flex flex-column align-items-center">
              <h1 className={`${cascading.textOrbiXxl} text-center mb-0`}>
                {CmsContent?.Partner?.Title}
              </h1>
              <div className={`${cascading.currentPartner}`}>
                <img
                  src={linear}
                  alt="linear"
                  className={`${cascading.partnerImg} img-fluid`}
                />
              </div>

              <div className="row">
                {CmsContent?.Partner?.image?.map((val) => (
                  <div
                    className={`${cascading.logoCol} col-6 col-lg-4 col-xxl-2`}
                  >
                    <div
                      className={`${cascading.logoWrap} d-flex align-items-center justify-content-center`}
                    >
                      <img
                        src={`${API_URL}/cmsimages/${val}`}
                        // src = {val.img}
                        alt="partner"
                        className={`${cascading.logo} img-fluid`}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className={`${cascading.tokenomic}`} name="tokenomics">
          <div className={`${cascading.container} container`}>
            <div className={`d-flex flex-column align-items-center`}>
              <h1 className={`${cascading.textOrbiXxl} text-center mb-0`}>
                tokenomic
              </h1>
              <div className={`${cascading.imgWrap} d-lg-none`}>
                <img src={tokenomic} alt="" className="img-fluid" />
              </div>

              <div
                className={`${cascading.buttonWrap} d-flex flex-column align-items-center w-100 d-lg-none`}
              >
                <button
                  className={`${cascading.notchedButton} ${cascading.borderButton} position-relative mt-4 w-100`}
                >
                  <span className={` ${cascading.notchBorder}`}></span>
                  <div
                    className={`${cascading.content}  ${cascading.btnOrbiText}`}
                  >
                    Public Sale : <span>71%</span>
                  </div>
                </button>
                <button
                  className={`${cascading.notchedButton} ${cascading.borderButton} position-relative mt-4 w-100`}
                >
                  <span className={` ${cascading.notchBorder}`}></span>
                  <div
                    className={`${cascading.content} ${cascading.btnOrbiText}`}
                  >
                    {" "}
                    Ecosystem and Development: <span>10%</span>
                  </div>
                </button>{" "}
                <button
                  className={`${cascading.notchedButton} ${cascading.borderButton} position-relative mt-4 w-100`}
                >
                  <span className={` ${cascading.notchBorder}`}></span>
                  <div
                    className={`${cascading.content}  ${cascading.btnOrbiText}`}
                  >
                    {" "}
                    CEX Listing Tokens: <span>11%</span>
                  </div>
                </button>{" "}
                <button
                  className={`${cascading.notchedButton} ${cascading.borderButton} position-relative mt-4 w-100`}
                >
                  <span className={` ${cascading.notchBorder}`}></span>
                  <div
                    className={`${cascading.content}  ${cascading.btnOrbiText}`}
                  >
                    {" "}
                    Marketing & Partnerships: <span>8%</span>
                  </div>
                </button>
              </div>

              <div className={`${cascading.tokenomicLg} d-none d-lg-block`}>
                <img src={tokenomicLg} alt="tokenomic" className="img-fluid" />
              </div>
            </div>
          </div>
        </section>
        <section
          className={`${cascading.roadmap} position-relative`}
          name="roadmap"
        >
          {/* <img
            src={roadmapBg}
            alt="roadmap"
            className={`${cascading.roadmapBg} position-absolute`}
          /> */}
          <div className={`${cascading.container} container`}>
            <div className={`d-flex flex-column`}>
              <h1 className={`${cascading.textOrbiXxl} text-center mb-0`}>
                roadmap
              </h1>
              <div className={`${cascading.roadmapContent} d-lg-none`}>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div>
                      <h3 className={`${cascading.roadmapTitle}`}>
                        2023 <span>Q3</span>
                      </h3>
                      {roadmapDetails2023One.map((value) => (
                        <div className="d-flex align-items-center gap-4 mb-2">
                          <Polygon />
                          <p className={`mb-0 ${cascading.roadmapData}`}>
                            {value.content}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mt-4 mt-md-0">
                    <div>
                      <h3 className={`${cascading.roadmapTitle}`}>
                        2023 <span>Q4</span>
                      </h3>
                      {roadmapDetails2023Two.map((value) => (
                        <div className="d-flex align-items-center gap-4 mb-2">
                          <Polygon />
                          <p className={`mb-0 ${cascading.roadmapData}`}>
                            {value.content}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <div>
                      <h3 className={`${cascading.roadmapTitle}`}>
                        2024 <span>Q1</span>
                      </h3>
                      {roadmapDetails2024One.map((value) => (
                        <div className="d-flex align-items-center gap-4 mb-2">
                          <Polygon />
                          <p className={`mb-0 ${cascading.roadmapData}`}>
                            {value.content}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <div>
                      <h3 className={`${cascading.roadmapTitle}`}>
                        2024 <span>Q2</span>
                      </h3>
                      {roadmapDetails2024Two.map((value) => (
                        <div className="d-flex align-items-center gap-4 mb-2">
                          <Polygon />
                          <p className={`mb-0 ${cascading.roadmapData}`}>
                            {value.content}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <div>
                      <h3 className={`${cascading.roadmapTitle}`}>
                        2024 <span>Q3</span>
                      </h3>
                      {roadmapDetails2024Three.map((value) => (
                        <div className="d-flex align-items-center gap-4 mb-2">
                          <Polygon />
                          <p className={`mb-0 ${cascading.roadmapData}`}>
                            {value.content}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`${cascading.roadmapImageWrap} d-none d-lg-block`}
              >
                <Roadmap />
              </div>
            </div>
          </div>
        </section>
      </main>
      <NewFooter />
    </>
  );
};

export default NewLanding;
