import React, { useEffect, useState } from 'react';
import cascading from '../../assests/css/xgrailpools.module.css';
import Accordion from 'react-bootstrap/Accordion';
import Footer from '../../common/footer'
import Header from '../../common/header';
import { useNavigate } from 'react-router-dom';
import { FaThList } from "react-icons/fa";
import { BsFillGrid3X2GapFill } from "react-icons/bs";
import Switch from "react-switch";
import { Dropdown } from 'react-bootstrap';
import Farmcard from '../../components/farmcard';
import bnblogo from '../../assests/images/bnb.png';
import coinlogo from '../../assests/images/logomini.png';

import home_icon from '../../assests/images/home_icon.png';
import pools_icon from '../../assests/images/pools_icon.png';
import total_icon from '../../assests/images/totalalloc.png';
import time_icon from '../../assests/images/timer.png';


import logohome from '../../../src/assests/images/coinicon.png';

import Positionlist from '../../components/positionlist';


//modals
import ComingSoonmodal from '../../modals/comingSoonmodal';
import Addliquiditymodal from '../../modals/addliquiditymodal';

import Addsuccessmodal from '../../modals/addsuccessmodal';

import PositionTokenmodal from '../../modals/positionTokenmodal';
import Selecttoken from '../../modals/ImportSelectToken';
import Createpositionmodal from "../../modals/createpositionmodal";

import { useSelector } from 'react-redux';
import { roundToSignificant } from '../../hooks/useCommon';


import { isEmpty } from '../../lib/isEmpty';
import { getWalletAddress } from '../../lib/localStorage';
const filterEvents = {
    'Yield_bearing': "Yield-bearing only",
    'Locked': 'Locked only',
    'Boosted': 'Boosted only'
}
export default function Positions() {

    const [status, setStatus] = useState(true);
    const [liquiditymodal, setLiquiditymodal] = useState(false)
    const [modalsucess, setModalsucess] = useState(false);
    const [modalposition, setmodalposition] = useState(false);
    const [modaltoken, setmodaltoken] = useState(false);
    const [modalcreate, setmodalcreate] = useState(false);
    const [loaded, setisloaded] = useState(false);

    const [filter, setFilter] = useState('')
    console.log("fileterss",filter);

    const [modalcoming, setmodalcoming] = useState(false);

    const [selectedToken, SetselectedToken] = useState({})

    const { totalholding, positionList, spNFTReward, isposition } = useSelector((state) => (state.position))

    const [title, settitle] = useState('')
    const [successLoader, setsuccessLoader] = useState(false)
    const [loaderText, setloaderText] = useState('')
    const [successText, setsuccessText] = useState('')
    const [errorText, seterrorText] = useState('')
    const [Trxn, setTrxn] = useState('')

    //change func

    useEffect(() => {
        console.log((isEmpty(totalholding) || isEmpty(positionList) || isEmpty(spNFTReward)) && (isposition == '' && !isEmpty(getWalletAddress())), (isposition == '' && !isEmpty(getWalletAddress())), 'Positions')
        if (isposition == '' && !isEmpty(getWalletAddress())) {
            setloader()
        }
        else if ((isEmpty(totalholding) || isEmpty(positionList) || isEmpty(spNFTReward)) && (isposition == '' && !isEmpty(getWalletAddress()))) {
            setloader()
        }
        else if (isposition == false || isposition == true) {
            Hideloader()
        }
    }, [totalholding, positionList, spNFTReward, isposition])

    const setloader = () => {
        try {
            setisloaded(true)
        } catch (err) {
            console.log(err, 'setloader__Err')
        }
    }

    const Hideloader = () => {
        try {
            setisloaded(false)
        } catch (err) {
            console.log(err, 'setloader__Err')
        }
    }
    const onTokenChange = (val) => {
        try {
            SetselectedToken(val)
        } catch (err) {
            console.log(err, 'onTokenChange__err')
        }
    }

    const showsuccesModal = (text, title) => {
        try {
            settitle(title)
            setloaderText(text)
            setsuccessLoader(false)
            setModalsucess(true);
        } catch (err) {
            console.log(err, 'showsuccesModal___err')
        }
    }

    const SetsuccessLoader = (text, hash) => {
        setTrxn(hash)
        setsuccessText(text);
        setsuccessLoader(true)
    }

    const setError = (text) => {
        seterrorText(text);
        setsuccessLoader(undefined)
    }
    return (
        <>
            <div className={loaded ? `${cascading.loader_div}` : `${cascading.loader_div} d-none`} id="loader_div">
                <div className={`${cascading.circle}`}>
                </div>
                <div className={`${cascading.circle_img}`}>
                    <img src={logohome} className='img-fluid' />
                </div>
            </div>

            <Header />
            
            <div className={loaded ? `${cascading.logo_overlay}` : `${cascading.logo_overlay_none}`}>
            <div className={`${cascading.innerpages} ${cascading.earnpage}`}>
                <div className={`container`}>
                    <div className={`row ${cascading.headrow}`}>
                        <p className={`${cascading.pagehead}`}>Positions</p>
                        <p className={`${cascading.pagedesc}`}>Create and manage all your staking positions.</p>
                    </div>
                    <div className='row'>
                        <div className={`col-lg-3 mb-4`} >

                            <div className={`${cascading.balancecard}`}>
                                <div>
                                    <p className={`${cascading.labelname}`}>Positions</p>
                                    <p className={`${cascading.labelvalue}`}>{positionList.length}</p>
                                </div>
                                <div>
                                    <img className={`img-fluid ${cascading.balanceimg}`} src={home_icon} alt='img' />
                                </div>
                            </div>
                        </div>

                        <div className={`col-lg-3 mb-4`} >

                            <div className={`${cascading.balancecard}`}>
                                <div>
                                    <p className={`${cascading.labelname}`}>Total holdings</p>
                                    <p className={`${cascading.labelvalue}`}>$ {roundToSignificant(totalholding, 6)}</p>
                                </div>
                                <div>
                                    <img className={`img-fluid ${cascading.balanceimg}`} src={total_icon} alt='img' />
                                </div>
                            </div>
                        </div>

                        {/* <div className={`col-lg-3 mb-4`} >

                            <div className={`${cascading.balancecard}`}>
                                <div>
                                    <p className={`${cascading.labelname}`}>Pending rewards</p>
                                    <p className={`${cascading.labelvalue}`}>0</p>
                                </div>
                                <div>
                                    <img className={`img-fluid ${cascading.balanceimg}`} src={time_icon} alt='img' />
                                </div>
                            </div>
                        </div> */}
                        <div className={`col-lg-3 mb-4`} >
                            <div className={`${cascading.balancecard}`}>
                                <div>
                                    <p className={`${cascading.labelname}`}>SPNFTS</p>
                                    <p className={`${cascading.labelvalue}`}>${roundToSignificant(spNFTReward, 6)}</p>
                                </div>
                                <div>
                                    <img className={`img-fluid ${cascading.balanceimg}`} src={pools_icon} alt='img' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`row ${cascading.filterrow}`}>
                        <div className={`col-lg-6 mb-3 mb-lg-0`}>
                            <div className={`${cascading.leftfiltersec}`}>

                            </div>

                        </div>
                        <div className={`col-lg-12 mb-3`}>
                            <div className={`${cascading.rightfiltersec}`}>
                                <div>
                                    <p className={`${cascading.labelname} mb-2`}>Filters</p>
                                    <Dropdown
                                        className={`${cascading.dropdownstyle}`}
                                        onSelect={(e) => {
                                            console.log(e, 'Dropdown')
                                            setFilter(e)
                                        }}>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            {isEmpty(filter) ? 'select filter' : filterEvents[`${filter}`]}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className={`${cascading.dropdownmenustyle}`}>
                                            <Dropdown.Item href="#/action-2" eventKey={''}>No filter</Dropdown.Item>
                                            <Dropdown.Item href="#/action-1" eventKey={'Yield_bearing'}>Yield-bearing only</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2" eventKey={'Locked'}>Locked only</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3" eventKey={'Boosted'}>Boosted only</Dropdown.Item>

                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                                <button className={`btn ${cascading.connect_btn} ms-auto`} type='button' onClick={() => { setmodalcreate(true) }}>New Position</button>
                                {/* <button className={`btn ${cascading.connect_btn} ms-auto`} type='button'  onClick={() => { setmodalcoming(true) }}>New Position</button> */}



                                {/* <div>
                                    <p className={`${cascading.labelname} mb-2`}>Search</p>
                                    <input type="text" className={`form-control ${cascading.inputstyle}`} placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" />


                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className={`${cascading.farmcontentsec} mt-4`}>
                        {status ?
                            <>
                                <div className={`row`}>
                                    <Positionlist
                                        filter={filter}
                                    />
                                </div>
                            </>
                            :
                            <>
                                <p className={`${cascading.emptylabels}`}>These pools are no longer distributing rewards. Please unstake your tokens.</p>
                            </>
                        }
                    </div>
                </div>
            </div>
            </div>

            <Footer />

            {liquiditymodal && <Addliquiditymodal show={liquiditymodal} onHide={() => { setLiquiditymodal(false) }} onSuccess={() => { setModalsucess(true); }} />}

            {modalcoming && <ComingSoonmodal show={modalcoming} onHide={() => { setmodalcoming(false); }} />}
            {modalposition && <PositionTokenmodal
                show={modalposition}
                onHide={() => { setmodalposition(false); }}
                onShowtoken={() => { setmodaltoken(true); }}
                onShowcreate={() => { setmodalcreate(true); }}
                selectToken={selectedToken}
            />}
            {modaltoken && <Selecttoken
                show={modaltoken}
                onHide={() => { setmodaltoken(false); }}
                SetToken={(val) => { onTokenChange(val) }}
            />}

            {modalcreate && <Createpositionmodal
                show={modalcreate}
                onHide={() => { setmodalcreate(false); SetselectedToken({}) }}
                onShowtoken={() => { setmodaltoken(true); }}
                selectToken={selectedToken}
                onSuccess={(text, title) => {
                    showsuccesModal(text, title);
                    setmodalcreate(false);
                }}
                SetsuccessLoader={(text, hash) => { SetsuccessLoader(text, hash) }}
                setError={(text) => { setError(text) }}
                SetToken={() => { SetselectedToken({}) }}
            />}

            {modalsucess && <Addsuccessmodal
                show={modalsucess}
                title={title}
                loaderText={loaderText}
                successText={successText}
                isLoader={successLoader}
                lpSymbol={selectedToken.lpSymbol}
                hash={Trxn}
                errorText={errorText}
                onHide={() => { setModalsucess(false); SetselectedToken({}); }}
            />}

        </>
    )
}
