import React, { useEffect, useState } from 'react';
import cascading from '../../assests/css/xgrailpooldetail.module.css';
import Footer from '../../common/footer'
import Header from '../../common/header';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { AiOutlineCalculator } from "react-icons/ai";

import { Dropdown } from 'react-bootstrap';
import Farmcard from '../../components/farmcard';
import bnblogo from '../../assests/images/bnb.png';
import coinlogo from '../../assests/images/logomini.png';
import pools_icon from '../../assests/images/pools_icon.png';
import dollar_icon from '../../assests/images/dollar_icon.png';
import percentage_icon from '../../assests/images/percentage_icon.png';
import swap_icon from '../../assests/images/swap_icon.png';
import graph_icon from '../../assests/images/graph_icon.png';
import logo_bigger from '../../assests/images/logo_bigger.png';


//modals
import ComingSoonmodal from '../../modals/comingSoonmodal';
import Apycalculatormodal from "../../modals/apycalculatormodal";
import Yumpooldetailtable from "../../components/yumpooldetailtable";


import home_icon from '../../assests/images/home_icon.png';

import { FaAngleRight, FaRegQuestionCircle } from "react-icons/fa";

import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Duration } from '../../lib/DateTimeHelper';
import { roundToSignificant } from '../../hooks/useCommon';
import fileObjectUrl from '../../lib/img'
import { isEmpty } from '../../lib/isEmpty';

export default function Xgrailpooldetail() {
    const navigate = useNavigate()
    const [poolsdata, setPoolsdata] = useState(false);
    const [apycalculatormodal, setapyCalculatormodal] = useState(false);
    const [modalcoming, setmodalcoming] = useState(false);
    const [totalApr, settotalApr] = useState('0')
    const [poolInfo, setPoolInfo] = useState({})

    const { nftpooldata, poolrewards } = useSelector((state) => (state.nftpool))

    const { poolAddress } = useParams()

    useEffect(() => {
        let PoolInfo = nftpooldata?.find((val) => (val?.poolAddress == poolAddress))
        console.log(PoolInfo,'PoolInfo')
        setPoolInfo(PoolInfo)
    }, [nftpooldata, poolAddress])

    console.log(poolInfo, 'poolDetails', nftpooldata)
    return (
        <>
            <Header />
            <div className={`${cascading.innerpages} ${cascading.earnpage}`}>
                <div className={`container`}>
                    <div className={`row ${cascading.headrow}`}>
                        <div className='col-12 col-sm-9 col-md-10'>
                            <p className={`${cascading.pagehead}`}><Link to="/xflashpools" className={`${cascading.themelink}`}>Pools</Link><FaAngleRight />{poolInfo?.lpsymbol}</p>
                            <p className={`${cascading.pagedesc}`}>Create positions into pools to earn swap fees and xFLASH.</p>

                        </div>
                        <div className='col-12 col-sm-3 col-md-2 text-end'>
                            {/* <button className={`btn ${cascading.connect_btn_disable}`} onClick={() => { setapyCalculatormodal(true); }}> */}
                            <button className={`btn ${cascading.connect_btn_disable}`} onClick={() => { setapyCalculatormodal(true); }}>

                                <AiOutlineCalculator className='me-2' />APY</button>

                        </div>
                    </div>

                    <div className='d-flex align-items-center mt-4'>
                        <div className={`${cascading.leftimgsec}`}>
                            <img src={isEmpty(poolInfo?.token0) ? coinlogo : fileObjectUrl(poolInfo.token0.image)} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                            <img src={isEmpty(poolInfo?.token1) ? coinlogo : fileObjectUrl(poolInfo.token1.image)} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                        </div>
                        <p className={`ms-4 ps-3 mb-0 ${cascading.coinlogoname}`}>{poolInfo?.lpsymbol}</p>

                    </div>

                    <div className={`d-flex align-items-center ${cascading.felxdiv}`}>
                        <div className={'mt-3'}>
                            <img className={`img-fluid ${cascading.balanceimg}`} src={dollar_icon} alt='img' />
                            <span className={`ps-2 ${cascading.textwhitedesc}`}>{poolInfo?.notinusd ? '':'$'} {poolInfo?.lpSupplyInusd}</span>
                            <span className={`ps-1 ${cascading.textgreydesc}`}>TVL</span>
                        </div>

                        <div className={'mt-3 ms-sm-0 ms-md-4'}>
                            <img className={`img-fluid ${cascading.balanceimg}`} src={percentage_icon} alt='img' />
                            <span className={`ps-2 ${cascading.textwhitedesc}`}>{roundToSignificant(poolInfo?.SwapFeeApr, 6)}%</span>
                            <span className={`ps-1 ${cascading.textgreydesc}`}>Fees APR</span>
                        </div>

                        <div className={'mt-3 ms-sm-0 ms-md-4'}>
                            <img className={`img-fluid ${cascading.balanceimg}`} src={swap_icon} alt='img' />
                            <span className={`ps-2 ${cascading.textwhitedesc}`}>$231.3k</span>
                            <span className={`ps-1 ${cascading.textgreydesc}`}>24h volume</span>
                        </div>

                        <div className={'mt-3 ms-sm-0 ms-md-4'}>
                            <img className={`img-fluid ${cascading.balanceimg}`} src={graph_icon} alt='img' />
                            <span className={`ps-2 ${cascading.textwhitedesc}`}>$695</span>
                            <span className={`ps-1 ${cascading.textgreydesc}`}>24h fees</span>
                        </div>

                    </div>

                    <div className='row mt-4'>
                        <div className={`col-lg-12 mb-4`} >

                            <div className={`${cascading.balancecard}`}>
                                <div>
                                    <div className='pb-3'>
                                        <img className={`img-fluid ${cascading.balanceimg}`} src={pools_icon} alt='img' />
                                        <span className={`ps-2 ${cascading.textwhitedesc}`}>Incentivized pool</span>
                                    </div>
                                    <div className={`${cascading.carddetail}`}>
                                        <div className={`${cascading.carddetailval}`}>
                                            <p className={`mt-2 ${cascading.labelname}`}>APR Range</p>
                                            <div className={`d-flex align-items-center`}>
                                                <p className={`mb-0 d-flex align-items-center pt-1 ${cascading.textwhitedesc}`}>{roundToSignificant(poolInfo?.Apr,6)}%
                                                    {/* <FaAngleRight /><span className={`${cascading.themeapr}`}>14.39%</span> */}
                                                </p>
                                                <div>
                                                    <OverlayTrigger
                                                        className={`${cascading.tooltip_style} p-0`}
                                                        key="bottom"
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip id={`tooltip-bottom`}>
                                                                <p className='mb-1'>Swap fees APR: {roundToSignificant(poolInfo?.SwapFeeApr, 6)}%</p>
                                                                <p className='mb-1'>Farm APR:{roundToSignificant(poolInfo?.FramBaseApr, 6)} %</p>


                                                            </Tooltip>
                                                        }
                                                    >
                                                        <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${cascading.carddetailval}`}>
                                            <p className={`mt-2 ${cascading.labelname}`}>Daily Emissions</p>
                                            <div className={`d-flex align-items-center`}>
                                                <p className={`mb-0 d-flex align-items-center pt-1 ${cascading.textwhitedesc}`}>{roundToSignificant(poolInfo?.dailyReward,6)} xFLASH</p>

                                            </div>
                                        </div>
                                        <div className={`${cascading.carddetailval}`}>
                                            <p className={`mt-2 ${cascading.labelname}`}>FLASH / xFLASH</p>
                                            <div className={`d-flex align-items-center`}>
                                                <p className={`mb-0 d-flex align-items-center pt-1 ${cascading.textwhitedesc}`}>{100 - poolInfo?.YUMRewardsShare}% / {poolInfo?.YUMRewardsShare}%</p>

                                            </div>
                                        </div>
                                        <div className={`${cascading.carddetailval}`}>
                                            <p className={`mt-2 ${cascading.labelname}`}>Max Total Mult.</p>
                                            <div className={`d-flex align-items-center`}>
                                                <p className={`mb-0 d-flex align-items-center pt-1 ${cascading.textwhitedesc}`}>{poolInfo?.maxGlobalMultiplier}x</p>

                                            </div>
                                        </div>
                                        <div className={`${cascading.carddetailval}`}>
                                            <p className={`mt-2 ${cascading.labelname}`}>Max Boost Mult.</p>
                                            <div className={`d-flex align-items-center`}>
                                                <p className={`mb-0 d-flex align-items-center pt-1 ${cascading.textwhitedesc}`}>{poolInfo?.maxBoostMultiplier}x</p>

                                            </div>
                                        </div>

                                        <div className={`${cascading.carddetailval}`}>
                                            <p className={`mt-2 ${cascading.labelname}`}>Max Lock Mult.</p>
                                            <div className={`d-flex align-items-center`}>
                                                <p className={`mb-0 d-flex align-items-center pt-1 ${cascading.textwhitedesc}`}>{poolInfo?.maxLockMultiplier}x</p>

                                            </div>
                                        </div>

                                        <div className={`${cascading.carddetailval}`}>
                                            <p className={`mt-2 ${cascading.labelname}`}>Max Lock</p>
                                            <div className={`d-flex align-items-center`}>
                                                <p className={`mb-0 d-flex align-items-center pt-1 ${cascading.textwhitedesc}`}>{Duration(poolInfo?.maxLockDuration, 'days')} days</p>
                                            </div>
                                        </div>

                                        <div className={`${cascading.carddetailval}`}>
                                            <p className={`mt-2 ${cascading.labelname}`}>SPNFTS</p>
                                            <div className={`d-flex align-items-center`}>
                                                <p className={`mb-0 d-flex align-items-center pt-1 ${cascading.textwhitedesc}`}>${roundToSignificant(poolrewards[`${poolInfo?.poolAddress}`], 6)}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    {poolsdata ?
                        <>
                            <Yumpooldetailtable />
                        </>
                        :
                        <>
                            <div className='text-center mt-5'>
                                <div className='row justify-content-center'>
                                    <div className='col-10 col-md-10 col-lg-9 col-xl-8'>
                                        <img src={logo_bigger} className={`img-fluid ${cascading.nopoolimg}`} />
                                        <p className={`mt-4 ${cascading.textwhitedesclg}`}>Join the pool by creating a spNFT position</p>
                                        <p className={`${cascading.textgreydesc}`}>spNFTs are special staking positions that you can obtain by wrapping LP tokens. These spNFTs act as deposit receipts and provide additional features beyond typical DeFi farms. Start maximizing your yield and earning FLASH today by creating your own position.</p>
                                        <div className='text-center mt-4'>
                                            <Link
                                                to='/createposition'
                                                state={{
                                                    token0: poolInfo?.token0,
                                                    token1: poolInfo?.token1
                                                }}
                                                className={`btn ${cascading.connect_btn}`}
                                            >
                                                Create Position
                                            </Link>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </>
                    }





                </div>


            </div>

            {apycalculatormodal && <Apycalculatormodal show={apycalculatormodal} onHide={() => setapyCalculatormodal(false)} />}
            {/* {modalcoming && <ComingSoonmodal show={modalcoming} onHide={() => { setmodalcoming(false); }} />} */}


            <Footer />
        </>
    )
}
